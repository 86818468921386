import React from "react";
import update from "immutability-helper";
import { AnnouncementDTO } from "../model/announcements.generated";

type InternalMessagesContextType = {
  addMessage: (message: AnnouncementDTO) => void;
  clear: () => void;
  messages: AnnouncementDTO[];
};

export const InternalMessagesContext = React.createContext<InternalMessagesContextType>({
  clear: () => {
    throw new Error("replaceme");
  },
  addMessage: (_message: AnnouncementDTO) => {
    throw new Error("replaceme");
  },
  messages: [],
});

/**
 * This provides an internal bus that can be used to send messages. At the moment it is only used to handle the messages
 * shown when you navigate to an application page that someone else is also looking at.
 */
export function InternalMessagesContextProvider({ children }: React.PropsWithChildren<{}>) {
  const [messages, setMessages] = React.useState<AnnouncementDTO[]>([]);
  const addMessage = React.useCallback(
    (message: AnnouncementDTO) => {
      const fi = messages.findIndex(m => m.id === message.id);
      if (fi > -1) {
        // replace existing id if we have it
        setMessages(messages => [...messages, message]);
      } else {
        setMessages(update(messages, { $splice: [[fi, 1, message]] }));
      }
    },
    [messages],
  );

  const clear = React.useCallback(() => {
    setMessages([]);
  }, []);

  const value = React.useMemo(
    () => ({
      addMessage,
      clear,
      messages,
    }),
    [addMessage, clear, messages],
  );
  return <InternalMessagesContext.Provider value={value}> {children} </InternalMessagesContext.Provider>;
}
