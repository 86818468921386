import * as api from "../../api";
import * as pot from "../../api/pot";
import { AppError } from "../../errors";
import { LabelPrototype, SearchResults } from "../../model/types";
import { AppReducer } from "../actions";
import { fetchWithMutex } from "../fetch";
import { rootSelector as schoolSelector } from "../school";
import { AppState } from "../state";
import * as teams from "../teams";
import { initialState, MyState } from "./state";

///////////////
// SELECTORS //
///////////////

export function myState(state: AppState): MyState {
  return schoolSelector(state).labelPrototypeSearch;
}

export const results = myState;

export function fetching(state: AppState): boolean {
  return pot.fetching(myState(state));
}

/////////////
// ACTIONS //
/////////////

const FETCHING = "labelPrototypeSearch/FETCHING";
const RESET = "labelPrototypeSearch/RESET";
const FETCHED = "labelPrototypeSearch/FETCHED";
const FAILED = "labelPrototypeSearch/FAILED";

export type MyAction =
  | { type: "labelPrototypeSearch/FETCHING"; requestId: string }
  | { type: "labelPrototypeSearch/RESET" }
  | {
      type: "labelPrototypeSearch/FETCHED";
      results: SearchResults<LabelPrototype>;
    }
  | { type: "labelPrototypeSearch/FAILED"; error: AppError };

/////////////////////
// ACTION CREATORS //
/////////////////////

export function reset(): MyAction {
  return { type: RESET };
}

export function searchCurrentTeam() {
  return fetchWithMutex<SearchResults<LabelPrototype>>({
    mutex: "labelPrototypeSearch",

    request(dispatch, getState) {
      const team = teams.currentTeam(getState()).code;
      return api.searchLabelPrototypesByTeam(api.fetchParams(getState()), team);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: FETCHING, requestId });
    },

    success(dispatch, getState, results) {
      dispatch({ type: FETCHED, results });
    },
  });
}

export function searchAllTeams() {
  return fetchWithMutex<SearchResults<LabelPrototype>>({
    mutex: "labelPrototypeSearch",

    request(dispatch, getState) {
      return api.allLabelPrototypes(api.fetchParams(getState())).then(response => {
        return response;
      });
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: FETCHING, requestId });
    },

    success(dispatch, getState, results) {
      dispatch({ type: FETCHED, results });
    },
  });
}

/////////////
// REDUCER //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    case FETCHING:
      return pot.pending(action.requestId);
    case FETCHED:
      return pot.ready(action.results);
    case FAILED:
      return pot.failed(action.error);
    default:
      return state;
  }
};

export default reducer;
