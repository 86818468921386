import React, { useRef, useState } from "react";
import { GithubPicker } from "react-color";
import styled from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";
import labelColors from "../../utils/labelColors";
import { BaseFieldProps } from "./base";
import FieldWrapper from "./FieldWrapper";

export interface ColorFieldProps extends BaseFieldProps<string> {}

interface ColorButtonProps {
  color: string;
  readOnly?: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const ColorButtonStyles = styled.button<{
  color: string;
  readOnly?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  width: 4.5em;
  height: 3em;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  &:hover {
    cursor: ${props => (props.readOnly || props.disabled ? "default" : "pointer")};
    border: ${props => (props.readOnly || props.disabled ? "inherit" : "1px solid rgba(0, 0, 0, 0.3)")};
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .color {
    position: absolute;
    top: ${props => (props.readOnly || props.disabled ? "0" : "4px")};
    bottom: ${props => (props.readOnly || props.disabled ? "0" : "4px")};
    left: ${props => (props.readOnly || props.disabled ? "0" : "4px")};
    right: ${props => (props.readOnly || props.disabled ? "0" : "4px")};
    z-index: 1;
    background: ${props => props.color};
  }
`;

function ColorButton(props: ColorButtonProps) {
  const { color, onClick, readOnly, disabled } = props;
  return (
    <ColorButtonStyles color={color} onClick={onClick} readOnly={readOnly} disabled={disabled}>
      <div className="overlay" />
      <div className="color" />
    </ColorButtonStyles>
  );
}

export default function ColorField(props: ColorFieldProps) {
  const { value, onChange, readOnly, disabled, messages } = props;

  const clickOutsideRef = useRef<HTMLDivElement>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  useClickOutside(clickOutsideRef, () => setShowColorPicker(false));

  return (
    <FieldWrapper readOnly={readOnly} messages={messages}>
      <div ref={clickOutsideRef}>
        <ColorButton
          color={value}
          readOnly={readOnly}
          disabled={disabled}
          onClick={() => !readOnly && !disabled && setShowColorPicker(!showColorPicker)}
        />
        {showColorPicker && (
          <div
            style={{
              position: "absolute",
              zIndex: 2,
              boxSizing: "content-box",
            }}
          >
            <GithubPicker
              color={value}
              colors={labelColors}
              onChangeComplete={color => {
                onChange?.(color.hex);
                setShowColorPicker(false);
              }}
            />
          </div>
        )}
      </div>
    </FieldWrapper>
  );
}
