import React from "react";
import { Messages } from "../../model/errors";
import { SemanticPointing } from "./base";
import { ReactNodeLike } from "../../utils";
import ErrorDisplay from "../ErrorDisplay";

export interface FieldWrapperProps {
  readOnly?: boolean;
  messages?: Messages;
  errorPointing?: SemanticPointing;
  children?: ReactNodeLike;
}

export default function FieldWrapper(props: FieldWrapperProps) {
  const { readOnly, messages, errorPointing, children } = props;

  return (
    <span>
      {children}
      {!readOnly && messages != null && <ErrorDisplay pointing={errorPointing} attached messages={messages} />}
    </span>
  );
}
