import React from "react";
import { Requirements } from "../model/types";

type Props = {
  requirements: Requirements;
};

const RequirementsView = ({ requirements }: Props) => (
  <div>
    {requirements.alternatives
      .map(alt => (alt.overall.a2Grades.length > 0 ? alt.overall.a2Grades.join("") : "-"))
      .filter(x => !!x)
      .join(" or ")}
  </div>
);

export default RequirementsView;
