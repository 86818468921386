import React, { ExoticComponent } from "react";
import { Label, Loader, Menu, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { SelectorListApplicant } from "../hooks/useSelectorListActions";
import { SelectorAction } from "../model/types";
import { Opt } from "../utils";

const ScrollingMenu = styled(Menu)`
  max-height: 80vh;
  max-height: calc(100vh - 250px);
  overflow-x: hidden;
  overflow-y: auto;
`;

const actionCount = (applicant: SelectorListApplicant, pred: (action: SelectorAction) => boolean) => {
  let ans = 0;

  applicant.selectorRows.forEach(row => {
    row.actions.forEach(action => {
      if (pred(action)) {
        ans = ans + 1;
      }
    });
  });

  return ans;
};

const recommendedCount = (applicant: SelectorListApplicant) => actionCount(applicant, act => act.recommended);

const nonRecommendedCount = (applicant: SelectorListApplicant) => actionCount(applicant, act => !act.recommended);

interface ItemProps {
  applicant: SelectorListApplicant;
  onClick: () => void;
  active: boolean;
}

const Item: ExoticComponent<ItemProps> = React.memo(styled(({ active, onClick, applicant }: ItemProps) => {
  const recCount = recommendedCount(applicant);
  const nonRecCount = nonRecommendedCount(applicant);

  return (
    <Menu.Item onClick={onClick} active={active}>
      {recCount === 0 && <Label>{nonRecCount}</Label>}
      {recCount > 0 && <Label color="green">{recCount}</Label>}
      <strong>{applicant.ucasPersonalId}</strong>
      <br />
      {applicant.surname}, {applicant.forenames}
    </Menu.Item>
  );
})`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
`);

interface Props {
  applicants: SelectorListApplicant[];
  selectedApplicantId: Opt<string>;
  fetching: boolean;
  onClick: (ucasPersonalId: string) => void;
}

export default function SelectorListMenu({ applicants, selectedApplicantId, fetching, onClick }: Props) {
  const handleClick = (ucasPersonalId: string) => () => {
    onClick(ucasPersonalId);
  };

  if (fetching) {
    return (
      <Segment basic padded>
        <Loader active inline="centered" />
      </Segment>
    );
  }

  if (applicants.length === 0) {
    return (
      <Segment basic padded textAlign="center">
        No Applicants
      </Segment>
    );
  }

  return (
    <ScrollingMenu fluid vertical>
      {applicants.map(appt => (
        <Item
          key={appt.ucasPersonalId}
          active={appt.ucasPersonalId === selectedApplicantId}
          onClick={handleClick(appt.ucasPersonalId)}
          applicant={appt}
        />
      ))}
    </ScrollingMenu>
  );
}
