import { Just, Maybe } from "purify-ts/Maybe";
import React from "react";
import { Input as SemanticInput } from "semantic-ui-react";
import { Opt } from "../../utils";
import { BaseFieldProps } from "./base";
import { BaseInput, ExtraInputProps } from "./BaseInput";

export interface NullableTextInputProps extends BaseFieldProps<Opt<string>>, ExtraInputProps<SemanticInput> {}

export function format(str: Opt<string>): string {
  return str == null ? "" : str;
}

export function validate(str: string): Maybe<Opt<string>> {
  return Just(str).map(str => (str.length > 0 ? str : null));
}

export default function NullableTextInput(props: NullableTextInputProps) {
  return <BaseInput format={format} validate={validate} {...props} />;
}
