import lodash from "lodash";
import { HourAndMinute } from "./core.generated";

const localTimeRegex = /([0-9]{1,2})[.:]([0-9]{1,2})/;

export function isHourAndMinute(time: unknown): time is HourAndMinute {
  return Array.isArray(time) && time.length === 2 && typeof time[0] === "number" && typeof time[1] === "number";
}

export function hourAndMinuteToString(time: HourAndMinute): string {
  const [hours, minutes] = time;
  return [lodash.padStart(hours.toFixed(0), 2, "0"), lodash.padStart(minutes.toFixed(0), 2, "0")].join(":");
}

export function stringToHourAndMinute(str: string): HourAndMinute | undefined {
  const match = str.match(localTimeRegex);
  if (match == null) {
    return undefined;
  } else {
    const hour = parseInt(match[1], 10);
    const minute = parseInt(match[2], 10);
    return [hour, minute];
  }
}
