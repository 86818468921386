import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { AppReducer } from "../actions";
import applicationSearch from "../applicationSearch";
import reportingGroupSearch from "../reportingGroupSearch";
import labelPrototypeSearch from "../labelPrototypeSearch";
import programmeList from "../programmeList";
import programmeSearch from "../programmeSearch";
import savedSearches from "../savedSearches";
import schoolConfig, { isDirty as schoolConfigDirty } from "../schoolConfig";
import { AppState } from "../state";
import { SELECT_TEAM } from "../teams";
import { initialState, MyState } from "./state";

const schoolReducer: AppReducer<MyState> = combineReducers({
  applicationSearch,
  reportingGroupSearch,
  labelPrototypeSearch,
  programmeList,
  programmeSearch,
  savedSearches,
  schoolConfig,
});

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  switch (action.type) {
    case SELECT_TEAM:
      return schoolReducer(initialState(), action);

    default:
      return schoolReducer(state, action);
  }
};

export default reducer;

export const schoolDirtySelector = schoolConfigDirty;

const routeSelector = (state: AppState) => state.route; //cyclic dep

export const rootSelector: (state: AppState) => MyState = createSelector(routeSelector, state => state.school);
