import { range } from "lodash";
import { Opt } from "../utils";
import { Message, Path } from "./types";

export type Messages = Message[];

// Take a list of errors and a list of keys of subcomponents, return an error of
// arrays of errors that match each of the sub keys (with those keys removed
// from their paths) and a catch all at the end.

export type SubErrorKeys = { [name: string]: boolean };

type SubErrorFields<A extends object> = {
  [K in keyof A]: Messages;
};

type SubErrorRest = {
  _rest_: Messages;
};

export type SubErrors<A extends object> = SubErrorFields<A> & SubErrorRest;

export function errorMessage(level: "warning" | "error", text: string, path: Path = []): Message {
  return { level, text, path };
}

export function combineMessages(...args: Opt<Messages>[]): Messages {
  return args.every(arg => arg == null) ? [] : args.reduce<Messages>((a, b) => [...(a ?? []), ...(b ?? [])], []);
}

export function subErrors<A extends SubErrorKeys>(errors0: Opt<Messages>, keys: A): SubErrors<A> {
  const errors = errors0 || [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const output: any = {};

  for (const key in keys) {
    output[key] = errors.filter(({ path: [first] }) => first === key).map(e => ({ ...e, path: e.path.slice(1) }));
  }

  const allKeys = Object.keys(keys);
  output._rest_ = errors.filter(e => e.path.length === 0 || (typeof e.path[0] === "string" && allKeys.indexOf(e.path[0]) === -1));

  return output;
}

export function indexedErrors(errors0: Opt<Messages>, maxIndex: number): Messages[] {
  const errors = errors0 || [];

  return range(0, maxIndex).map(key => {
    const keyString = String(key);

    const subErrors: Messages = errors
      .filter(({ path: [first] }) => (typeof first === "string" ? first === keyString : first === key))
      .map(e => ({ ...e, path: e.path.slice(1) }));

    return subErrors;
  });
}

export function addPathPrefix(prefix: Path, messages: Messages): Messages {
  if (messages.length === 0) {
    return messages;
  }
  return messages.map(m => ({
    ...m,
    path: [...prefix, ...m.path],
  }));
}

export function hasErrors(messages: Messages): boolean {
  return messages.some(msg => msg.level === "error");
}

export function hasWarnings(messages: Messages): boolean {
  return messages.some(msg => msg.level === "warning");
}
