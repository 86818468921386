import React, { useCallback } from "react";
import { Message } from "@qmspringboard/shared/dist/model/core.generated";
import { qualificationHasSubject } from "@qmspringboard/shared/dist/validators";
import { Opt } from "../utils";
import AutocompleteField from "./fields/AutocompleteField";
import { DropdownOption, DropdownOptions } from "./fields/DropdownField";
import { useSubjects } from "../api";
import { Messages } from "../model/errors";
import { createValidSubjectOptions, filterSubjectsByString, Subjects } from "@qmspringboard/shared/dist/model/subjects";

type SubjectDropdownProps = {
  id?: string;
  qualification: string;
  readOnly?: boolean;
  disabled?: boolean;
  value: Opt<string>;
  onChange: (q: Opt<string>) => void;
  messages?: Message[];
};

const createOption = (subject: string): DropdownOption<string> => ({
  key: subject,
  label: subject,
  value: subject,
});

const createMenuOptions = (qualification: string, subjects: Subjects) => {
  const allSubjects = createValidSubjectOptions(qualification, subjects);
  const filterSubjects = filterSubjectsByString(allSubjects);

  return (value: Opt<string>): DropdownOptions<string> => {
    return (value ? filterSubjects(value) : allSubjects).map(createOption);
  };
};

export default function SubjectDropdown(props: SubjectDropdownProps) {
  const { id, qualification, readOnly, disabled, value, onChange, messages } = props;

  const hasSubject = qualificationHasSubject(qualification);

  const subjects = useSubjects();
  const handleChange = useCallback(
    (value: Opt<string>) => {
      onChange?.(value);
    },
    [onChange],
  );

  const extraMessages: Messages = subjects.error ? [{ level: "error", path: [], text: "Couldn't load subjects" }] : [];

  return (
    <AutocompleteField
      id={id}
      readOnly={readOnly || !hasSubject}
      disabled={disabled}
      value={value}
      loading={subjects.isLoading}
      onChange={handleChange}
      placeholder={hasSubject ? "Subject" : "Subject N/A"}
      createMenuOptions={subjects.data ? createMenuOptions(qualification, subjects.data) : () => []}
      messages={[...(messages || []), ...extraMessages]}
    />
  );
}
