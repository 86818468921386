import * as pot from "../../api/pot";
import { Programme, SearchResults } from "../../model/types";

export type Results = SearchResults<Programme>;

export type MyState = pot.Pot<Results>;

export const initialState: MyState = pot.empty();

export const emptyResults: Results = {
  items: [],
  total: 0,
  start: 0,
};
