import React from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { ReactNodeLike } from "../../utils";

const Label = styled.label`
  display: block;
  margin-top: 1em !important;
  margin-bottom: 0.5em !important;
`;

export interface FieldLabelProps {
  label?: ReactNodeLike;
  children?: ReactNodeLike;
}

export default function FieldLabel({ label, children }: FieldLabelProps) {
  return (
    <Form.Field>
      {label && <Label>{label}</Label>}
      {children}
    </Form.Field>
  );
}
