import { omit } from "lodash";
import { combineReducers, Reducer } from "redux";
import { createSelector } from "reselect";
import * as api from "../../api";
import * as pot from "../../api/pot";
import { ValidationError } from "../../errors";
import { AppDispatch, AppReducer, AppThunkAction } from "../actions";
import { fetchWithMutex } from "../fetch";
import { rootSelector as schoolSelector } from "../school";
import { AppState, GetState } from "../state";
import { currentSchoolCode } from "../teams";
import { MyAction } from "./actions";
import { initialSaveState, MyState, SaveState, SchoolConfig } from "./state";
import { Messages } from "../../model/errors";

const rootSelector = (state: AppState): MyState => schoolSelector(state).schoolConfig;

const resourceSelector = (state: AppState): pot.Pot<SchoolConfig> => rootSelector(state).resource;

const persistedSelector = (state: AppState): SchoolConfig => pot.getOrElse(resourceSelector(state), {});

const dirtySelector = (state: AppState): SchoolConfig => rootSelector(state).dirty;

const saveSelector = (state: AppState) => rootSelector(state).save;

// typed key/value reducers
// - date
// - time
// - datetime
// - string
// - number
// - boolean

const REQUEST = "schoolConfig/INIT_REQUEST";
const SUCCESS = "schoolConfig/INIT_SUCCESS";
const FAILURE = "schoolConfig/INIT_FAILURE";

function resourceReducer(state: pot.Pot<SchoolConfig> = pot.empty(), action: MyAction): pot.Pot<SchoolConfig> {
  switch (action.type) {
    case REQUEST:
      return pot.pending(action.requestId);
    case SUCCESS:
      return pot.ready(action.resource);
    case FAILURE:
      return pot.failed(action.error);
    default:
      return state;
  }
}

function innerInitialise(school: string) {
  return fetchWithMutex<SchoolConfig>({
    mutex: "schoolConfig",

    request(dispatch, getState) {
      return api.fetchSchoolConfig(api.fetchParams(getState()), school);
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: REQUEST, requestId });
    },

    success(dispatch, getState, resource) {
      dispatch({ type: SUCCESS, resource });
    },

    error(dispatch, getState, error) {
      dispatch({ type: FAILURE, error });
    },
  });
}

const fetching = (state: AppState): boolean => {
  return pot.fetching(resourceSelector(state));
};

const fetched = (state: AppState): boolean => {
  return pot.hasValue(resourceSelector(state));
};

export { fetching, fetched };

export const initialise = (): AppThunkAction => {
  return (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const school = currentSchoolCode(state);
    if (school) {
      dispatch(innerInitialise(school));
    }
  };
};

const SET_VALUE = "schoolConfig/SET_VALUE";
const RESET_VALUE = "schoolConfig/RESET_VALUE";
const SAVE_REQUEST = "schoolConfig/SAVE_REQUEST";
const SAVE_SUCCESS = "schoolConfig/SAVE_SUCCESS";
const SAVE_FAILURE = "schoolConfig/SAVE_FAILURE";

const initialDirtyState: SchoolConfig = {};

const dirtyReducer: AppReducer<SchoolConfig> = (state = initialDirtyState, action) => {
  switch (action.type) {
    case SET_VALUE:
      return {
        ...state,
        [action.key]: action.value,
      };

    case RESET_VALUE:
      return omit(state, action.key);

    case SAVE_SUCCESS:
      return initialDirtyState;

    default:
      return state;
  }
};

export const isDirty = createSelector(rootSelector, (state: MyState): boolean => Object.keys(state.dirty).length > 0);

export const setValue = (key: string) => (value: unknown) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    const persisted = persistedSelector(getState());
    if (persisted[key] === value) {
      dispatch({ type: RESET_VALUE, key });
    } else {
      dispatch({ type: SET_VALUE, key, value });
    }
  };
};

export const getValue = (id: string) => {
  return createSelector(persistedSelector, dirtySelector, (persisted, dirty) => (id in dirty ? dirty[id] : persisted[id]));
};

const saveReducer: AppReducer<SaveState> = (state = initialSaveState, action) => {
  switch (action.type) {
    case SAVE_REQUEST:
      return {
        ...state,
        saving: true,
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        saving: false,
      };

    case SAVE_FAILURE:
      return {
        ...state,
        messages: action.messages,
        saving: false,
      };

    default:
      return state;
  }
};

export const saving = createSelector(saveSelector, state => state.saving);

export const messages = createSelector(saveSelector, state => state.messages);

export function messagesFor(id: string) {
  return function (state: AppState): Messages {
    return messages(state).filter(message => message.path.length > 0 && message.path[0] === id);
  };
}

export const save = (): AppThunkAction => {
  return async (dispatch, getState) => {
    const state = getState();
    const schoolCode = currentSchoolCode(state);
    // if (isDirty(state) && team) {
    if (schoolCode) {
      dispatch({
        type: SAVE_REQUEST,
      });
      try {
        const resource = await api.saveSchoolConfig(api.fetchParams(state), schoolCode, dirtySelector(state));

        dispatch({
          type: SAVE_SUCCESS,
          resource: resource.data,
          messages: resource.messages ?? [],
        });

        const existing = persistedSelector(getState());

        dispatch({
          type: SUCCESS,
          resource: { ...existing, ...resource.data },
        });
      } catch (e) {
        if (e instanceof ValidationError) {
          dispatch({
            type: SAVE_FAILURE,
            messages: e.messages,
          });
        } else {
          throw e;
        }
      }
    }
  };
};

const reducer: Reducer<MyState, MyAction> = combineReducers({
  resource: resourceReducer,
  dirty: dirtyReducer,
  save: saveReducer,
});

export default reducer;
