import {
  programmeCodeToR900Label,
  programmeCodeToRouteCode,
  programmeCodeToString,
  programmeCodeToUcasCourseCode,
  programmeCodeValid,
  unsafeStringToProgrammeCode,
} from "@qmspringboard/shared/dist/model/programmeCode";
import { ProgrammeCode } from "@qmspringboard/shared/dist/model/types";
import { requirementsRule } from "./requirementsRules";
import * as rule from "./rules";
import { Programme } from "./types";

export type { ProgrammeCode };
export {
  programmeCodeValid,
  programmeCodeToString,
  unsafeStringToProgrammeCode,
  programmeCodeToR900Label,
  programmeCodeToUcasCourseCode,
  programmeCodeToRouteCode,
};

export const programmeRule: rule.Rule<Programme> = rule
  .create()
  .field("code", rule.required(rule.error("You must enter a programme code")))
  .field("code", rule.optional(rule.test(programmeCodeValid, rule.error("Invalid programme code"))))
  .field("name", rule.nonEmpty(rule.error("You must enter a name")))
  .field("schoolCode", rule.nonEmpty(rule.error("You must select a school")))
  .field("entryRequirements", requirementsRule)
  .finish();
