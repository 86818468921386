import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Programme } from "../model/types";
import { allProgrammes, initialise } from "../reducers/programmeList";
import useAppDispatch from "./useAppDispatch";

type UseProgrammeListResult = Programme[];

export default function useProgrammeList(): UseProgrammeListResult {
  const dispatch = useAppDispatch();

  const programmeList = useSelector(allProgrammes);
  const fetchProgrammes = useCallback(() => dispatch(initialise()), [dispatch]);

  useEffect(() => {
    fetchProgrammes();
  }, [fetchProgrammes]);

  return programmeList;
}
