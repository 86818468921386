import { ClearingConfig } from "@qmspringboard/app/src/model/types.generated";
import { z } from "zod";

const ClearingDatesSchema = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
});

export interface ClearingDates extends z.infer<typeof ClearingDatesSchema> {}

function parseConfig(clearingDates: { start: string; end: string }): ClearingDates | null {
  const parseResult = ClearingDatesSchema.safeParse(clearingDates);

  if (!parseResult.success) {
    console.log("Error parsing clearing dates " + parseResult.error.message);
  }

  return parseResult.success ? parseResult.data : null;
}

export const isInClearing = (clearingConfig: ClearingConfig | null | undefined) => {
  if (clearingConfig) {
    const data = parseConfig(clearingConfig);

    if (data) {
      const now = new Date().valueOf();
      return data.start.valueOf() < now && data.end.valueOf() > now;
    }
    return false;
  }

  return false;
};

export const warnAboutBeingInClearing = (clearingConfig: ClearingConfig | null | undefined) => {
  if (clearingConfig) {
    return isInClearing(clearingConfig) && clearingConfig.warnOnUsage;
  }
  return false;
};
