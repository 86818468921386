import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Role } from "../model/types";
import { search, results } from "../reducers/roleSearch";
import { MyState } from "../reducers/roleSearch/state";
import useAppDispatch from "./useAppDispatch";
import Opt from "@qmspringboard/shared/src/utils/opt";
import { getOrElse } from "../api/pot";

export default function useAllRoles(): Role[] {
  const dispatch = useAppDispatch();

  const searchResults: MyState = useSelector(results);

  const school: Opt<string> = null; // school does not play a part in `Role`

  const fetchRoles = useCallback(
    () => dispatch(search(school, { count: 99999 })), // we want all the roles.
    [dispatch, school],
  );

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return getOrElse(searchResults, { items: [], start: 0, total: 0 }).items;
}
