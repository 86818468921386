import { SchoolCode, TeamCode, unsafeRetag } from "@qmspringboard/shared/dist/model/types";
import { Opt } from "../utils";
import { School } from "./types";

export type { TeamCode };

export interface Team {
  code: TeamCode;
  name: string;
  autopilot: boolean;
}

export function unsafeStringToTeamCode(code: string) {
  return code as TeamCode;
}

export const admissionsTeamCode: TeamCode = unsafeStringToTeamCode("Admissions");

export const admissionsTeam: Team = {
  code: admissionsTeamCode,
  name: "Central Admissions",
  autopilot: false,
};

export function isAdmissionsTeamCode(code: TeamCode): boolean {
  return code === admissionsTeamCode;
}

export function isSchoolTeamCode(code: TeamCode): boolean {
  return code !== admissionsTeamCode;
}

export function schoolToTeamCode(school: Opt<SchoolCode>): TeamCode {
  return unsafeStringToTeamCode(school || admissionsTeamCode);
}

export function teamToSchoolCode(code: TeamCode): SchoolCode | null {
  return isAdmissionsTeamCode(code) ? null : unsafeRetag<"TeamCode", "SchoolCode">(code);
}

export function teamCodeToString(code: TeamCode): string {
  return code;
}

export function foldTeamCode<A>(teamCode: TeamCode, whenAdmissions: () => A, whenSchool: (schoolCode: SchoolCode) => A): A {
  const schoolCode = teamToSchoolCode(teamCode);
  return schoolCode == null ? whenAdmissions() : whenSchool(schoolCode);
}

export function schoolToTeam(school: School): Team {
  const { code, name, autopilot } = school;
  return { code: schoolToTeamCode(code), name: `${code} - ${name}`, autopilot };
}

export function schoolsToTeams(schools: School[]): Team[] {
  return [admissionsTeam, ...schools.map(schoolToTeam)];
}

export function isAdmissions(team: Team): boolean {
  return isAdmissionsTeamCode(team.code);
}

export function isSchool(team: Team): boolean {
  return isSchoolTeamCode(team.code);
}
