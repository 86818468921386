import { Label, Note } from "../../model/types";
import { Opt } from "../../utils";

export type MyState = {
  applicantId: Opt<number>;
  labels: Label[];
  notes: Note[];
  selectedNoteIndex: Opt<number>;
  selectedNoteOriginal: Opt<Note>;
};

export const initialState: MyState = {
  applicantId: null,
  labels: [],
  notes: [],
  selectedNoteIndex: null,
  selectedNoteOriginal: null,
};
