import React from "react";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { Location } from "history";
import { locationQueryParams } from "../utils/queryParams";

export type SorterProps = {
  sortBy: string;
  label: string;
  initialDir?: "asc" | "desc";
};

export type AllSorterProps = SorterProps & RouteComponentProps & { location: Location };

class Sorter extends React.Component<AllSorterProps> {
  render() {
    const { location, sortBy, label, initialDir } = this.props;
    const query = locationQueryParams(location);

    let icon;

    if (query.sortby === sortBy) {
      icon = query.sortdir === "desc" ? <Icon name="caret down" /> : <Icon name="caret up" />;
    } else {
      icon = null;
    }

    const sortdir = query.sortby === sortBy ? (query.sortdir === "asc" ? "desc" : "asc") : initialDir || "asc";

    const to = {
      ...location,
      query: {
        ...query,
        sortby: sortBy,
        sortdir,
      },
    };

    return (
      <Link to={to}>
        {label} {icon}
      </Link>
    );
  }
}

export default withRouter(Sorter);
