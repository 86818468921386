import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { ReportingGroupTypeEnum } from "../model/enums";
import { Messages, subErrors } from "../model/errors";
import { ProgrammeCode, programmeCodeToUcasCourseCode } from "../model/programme";
import { admissionsTeam, teamToSchoolCode } from "../model/team";
import { Programme, ReportingGroup, School, SchoolCode } from "../model/types";
import { withAllSchools } from "../reducers/teams";
import ErrorDisplay from "./ErrorDisplay";
import { DropdownField, DropdownOptions, FieldLabel, MultiDropdownField, NullableIntegerInput, NullableTextInput, RadioSelectField } from "./fields";

type Props = {
  value: ReportingGroup;
  messages: Messages;
  programmes: Programme[];
  onChange: (value: ReportingGroup) => void;
  allSchools: School[];
  readOnly: boolean;
};

export default withAllSchools(
  class ReportingGroupEditor extends React.Component<Props> {
    render() {
      const { value, messages, programmes, readOnly, onChange, allSchools } = this.props;

      const splitErrors = subErrors(messages, {
        code: true,
        name: true,
        schoolCode: true,
        ufAimHome: true,
        ufAimOverseas: true,
        groupType: true,
        programmeCodes: true,
      });

      const teamOptions = [
        {
          value: teamToSchoolCode(admissionsTeam.code),
          label: admissionsTeam.name,
        },
        ...allSchools.map(school => ({
          value: school.code,
          label: `${school.code} - ${school.name}`,
        })),
      ];

      const programmeOptions: DropdownOptions<ProgrammeCode> = programmes.map(prog => ({
        value: prog.code,
        label: `${prog.schoolCode} - ${programmeCodeToUcasCourseCode(prog.code)} - ${prog.name}`,
      }));

      return (
        <Form as="div">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={4} computer={4}>
                <FieldLabel label="Code">
                  <NullableTextInput value={value.code} readOnly={readOnly} onChange={code => onChange({ ...value, code: code ?? "" })} />
                  <ErrorDisplay attached messages={splitErrors.code} />
                </FieldLabel>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={12} computer={12}>
                <FieldLabel label="Name">
                  <NullableTextInput value={value.name} readOnly={readOnly} onChange={name => onChange({ ...value, name: name ?? "" })} />
                  <ErrorDisplay attached messages={splitErrors.name} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <FieldLabel label="School">
                  <DropdownField<SchoolCode | null>
                    fluid
                    value={value.schoolCode || null}
                    readOnly={readOnly}
                    placeholder="-- Choose a school --"
                    onChange={schoolCode => onChange({ ...value, schoolCode })}
                    options={teamOptions}
                  />
                  <ErrorDisplay attached messages={splitErrors.schoolCode} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel label="UF Aim Home">
                  <NullableIntegerInput
                    value={value.ufAimHome}
                    onChange={ufAimHome => onChange({ ...value, ufAimHome: ufAimHome ?? 0 })}
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.ufAimHome} />
                  {value.groupType == ReportingGroupTypeEnum.Control && value.ufAimHome <= 0 && (
                    <p>Warning: A zero value on a control group will mark all of its programmes as full to home applicants.</p>
                  )}
                </FieldLabel>

                <FieldLabel label="UF Aim Overseas">
                  <NullableIntegerInput
                    value={value.ufAimOverseas}
                    onChange={ufAimOverseas => onChange({ ...value, ufAimOverseas: ufAimOverseas ?? 0 })}
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.ufAimOverseas} />
                  {value.groupType == ReportingGroupTypeEnum.Control && value.ufAimOverseas <= 0 && (
                    <p>Warning: A zero value on a control group will mark all of its programmes as full to overseas applciants.</p>
                  )}
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel label="Group Type">
                  <RadioSelectField
                    value={value.groupType}
                    options={[
                      {
                        key: ReportingGroupTypeEnum.Reporting,
                        value: ReportingGroupTypeEnum.Reporting,
                        label: <label>Reporting - has no impact on programme availability </label>,
                      },
                      {
                        key: ReportingGroupTypeEnum.Control,
                        value: ReportingGroupTypeEnum.Control,
                        label: <label>Control - programmes in this group are marked as full once the UF Aim is reached</label>,
                      },
                    ]}
                    onChange={groupType => onChange({ ...value, groupType })}
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.groupType} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={16}>
                <FieldLabel label="Programmes">
                  <MultiDropdownField
                    fluid
                    search
                    value={value.programmeCodes}
                    readOnly={readOnly}
                    onChange={programmeCodes => onChange({ ...value, programmeCodes })}
                    placeholder="Select a entry point"
                    options={programmeOptions}
                  />
                  <ErrorDisplay attached messages={splitErrors.programmeCodes} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
            {splitErrors._rest_.length > 0 && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <ErrorDisplay messages={splitErrors._rest_} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Form>
      );
    }
  },
);
