import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { LabelTypeEnum } from "../model/enums";
import { Messages, subErrors } from "../model/errors";
import { Team, teamToSchoolCode } from "../model/team";
import { LabelPrototype } from "../model/types";
import { withAllTeams } from "../reducers/teams";
import ErrorDisplay from "./ErrorDisplay";
import { ColorField, DropdownField, FieldLabel, TextInput } from "./fields";

interface Props {
  value: LabelPrototype;
  messages: Messages;
  onChange: (value: LabelPrototype) => void;
  allTeams: Team[];
  readOnly: boolean;
}

export default withAllTeams(function LabelPrototypeEditor(props: Props) {
  const { value, messages, readOnly, onChange, allTeams } = props;

  const splitErrors = subErrors(messages, {
    schoolCode: true,
    text: true,
    color: true,
  });

  const teamOptions = allTeams.map(team => ({
    value: teamToSchoolCode(team.code),
    label: team.name,
  }));

  return (
    <Form as="div">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label="School">
              <DropdownField
                fluid
                value={value.schoolCode}
                readOnly={readOnly}
                placeholder="Central Admissions"
                onChange={schoolCode => onChange({ ...value, schoolCode })}
                options={teamOptions}
              />

              <ErrorDisplay attached messages={splitErrors.schoolCode} />
            </FieldLabel>

            <FieldLabel label="Label Type">
              <DropdownField
                fluid
                value={value.labelType}
                readOnly={readOnly}
                onChange={labelType => onChange({ ...value, labelType })}
                options={LabelTypeEnum.entries}
              />

              <ErrorDisplay attached messages={splitErrors.schoolCode} />
            </FieldLabel>

            <FieldLabel label={value.labelType === "fao" ? "Team Name" : "Label Text"}>
              <TextInput value={value.text} readOnly={readOnly} onChange={text => onChange({ ...value, text })} />

              <ErrorDisplay attached messages={splitErrors.text} />
            </FieldLabel>

            <FieldLabel label="Label Color">
              <ColorField value={value.color} readOnly={readOnly} onChange={color => onChange({ ...value, color })} />
              <ErrorDisplay attached messages={splitErrors.color} />
            </FieldLabel>

            {splitErrors._rest_.length > 0 && <ErrorDisplay messages={splitErrors._rest_} />}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
});
