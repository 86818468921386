import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as modal } from "redux-modal";
import { History } from "history";

import auth from "./auth";
import fetch from "./fetch";
import user from "./user";
import route, { routeDirtySelector } from "./route";
import { AppState } from "./state";
import { MyState as UserState } from "./user/state";

// anything unsaved?
export const dirtySelector = routeDirtySelector;

// const logActions = (state: State | void, action: any): State | null => {
//   console.debug('ACTION', action, state);
//   // If state is undefined, we apparently have to return null.
//   // Otherwise we get a big error on the page:
//   return state == null ? null : state;
// };

const rootReducer = (history: History) =>
  combineReducers({
    // logActions,
    router: connectRouter(history),
    route,
    modal,
    fetch,
    auth,
    user,
  });

export const userSelector = (state: AppState): UserState => state.user;

export default rootReducer;
