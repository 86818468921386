import { Qualification, QualificationType } from "@qmspringboard/shared/dist/model/qualifications";
import { qualificationHasSubject } from "@qmspringboard/shared/dist/validators";
import React, { ReactElement } from "react";
import { Button, Grid } from "semantic-ui-react";
import styled from "styled-components";
import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import { QualificationTypeEnum } from "../model/enums";
import { subErrors } from "../model/errors";
import ErrorDisplay from "./ErrorDisplay";
import { BaseFieldProps, DropdownField, DropdownFieldProps, DropdownOptions, NullableNumberInput, NullableTextInput } from "./fields";
import QualificationGradeField from "./QualificationGradeField";
import SubjectDropdown from "./SubjectDropdown";

export interface QualificationEditorProps extends BaseFieldProps<Qualification> {
  onRemove?: () => void;
}

function UnstyledQualificationTypeDropdown(props: DropdownFieldProps<string>) {
  return <DropdownField {...props} />;
}

const QualificationTypeDropdown = styled(UnstyledQualificationTypeDropdown)`
  .divider.text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const TightGrid = styled(Grid)`
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
`;

const TightGridColumn = styled(Grid.Column)`
  margin-bottom: 10px !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
`;

const RowContainerOuter = styled.div`
  display: flex;
  width: 100%;
  max-width: 100% !important;
  flex-wrap: nowrap;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const RowContainerInner = styled.div`
  flex: 1 1 auto;
  margin-right: 1.5rem;
`;

const RemoveButtonContainer = styled.div`
  flex: 0 0 auto;
`;

function qualificationTypeOption(value: QualificationType) {
  switch (value) {
    case "A2":
      return { value, label: "A-Level" };
    case "AS":
      return { value, label: "AS-Level" };
    case "EPQ":
      return { value, label: "Extended Project Qualification (EPQ)" };
    case "IBOverall":
      return { value, label: "IB Overall" };
    case "IBHigher":
      return { value, label: "IB Higher" };
    case "IBStandard":
      return { value, label: "IB Standard" };
    case "BtecExtendedRqf":
      return { value, label: "BTEC Extended (reformed 2019)" };
    case "BtecNationalRqf":
      return { value, label: "BTEC National (reformed 2019)" };
    case "BtecSubsidiaryRqf":
      return { value, label: "BTEC Subsidiary (reformed 2019)" };
    case "BtecExtendedQcf":
      return { value, label: "BTEC Extended (unreformed 2016)" };
    case "BtecNationalQcf":
      return { value, label: "BTEC National (unreformed 2016)" };
    case "BtecSubsidiaryQcf":
      return { value, label: "BTEC Subsidiary (unreformed 2016)" };
    case "FrenchBacOverall":
      return { value, label: "French Bac Overall" };
    case "FrenchBac":
      return { value, label: "French Bac Final Year" };
    case "IndianHsNatOverall":
      return { value, label: "Indian HS (Nat) Overall" };
    case "IndianHsNat":
      return { value, label: "Indian HS (Nat) Final Year" };
    case "IndianHsWbOverall":
      return { value, label: "Indian HS (WB) Overall" };
    case "IndianHsWb":
      return { value, label: "Indian HS (WB) Final Year" };
    case "IndianHsOtherOverall":
      return { value, label: "Indian HS (Other) Overall" };
    case "IndianHsOther":
      return { value, label: "Indian HS (Other) Final Year" };
    case "GermanAbiturOverall":
      return { value, label: "Abitur Overall" };
    case "GermanAbitur":
      return { value, label: "Abitur Higher" };
    case "PolishMatura":
      return { value, label: "Matura Extended" };
    case "SpanishBacOverall":
      return { value, label: "Spanish Bac Overall" };
    case "SpanishBac":
      return { value, label: "Spanish Bac Final Year" };
    case "ItalianDiplomaOverall":
      return { value, label: "Italian Diploma Overall" };
    case "ItalianDiploma":
      return { value, label: "Italian Diploma Final Year" };
    case "UsDiplomaOverall":
      return { value, label: "US Diploma GPA" };
    case "UsDiploma":
      return { value, label: "US Diploma Advanced Placement" };
    case "UCATScore":
      return { value, label: "UCAT Score" };
    case "UCATBand":
      return { value, label: "UCAT Band" };
    default:
      return checkExhausted(value);
  }
}

const qualificationTypeOptions: DropdownOptions<QualificationType> = QualificationTypeEnum.values.map(qualificationTypeOption);

export default function QualificationEditor(props: QualificationEditorProps): ReactElement {
  const { value, messages, readOnly, onChange, onRemove } = props;

  const errors = subErrors(messages, {
    grade: true,
    subject: true,
    yearObtained: true,
  });

  const [customQualification, setCustomQualification] = React.useState<boolean>(
    // we are not a known qualification. This value is always initially set to something
    !QualificationTypeEnum.isValue(value.tpe),
  );

  const onCustomRemove = () => {
    setCustomQualification(false);
    onChange?.({
      ...value,
      tpe: "A2",
    });
  };

  const qualificationField = customQualification ? (
    <NullableTextInput
      value={value.tpe}
      fluid
      readOnly={readOnly}
      placeholder="Type of qualification"
      onChange={val => {
        onChange?.({
          ...value,
          tpe: val || "",
        });
      }}
      action={readOnly ? undefined : { onClick: onCustomRemove, icon: "trash" }}
    />
  ) : (
    <QualificationTypeDropdown
      fluid={true}
      value={value.tpe}
      readOnly={readOnly}
      options={[...qualificationTypeOptions, { label: "Other", value: "_other" }]}
      onChange={(tpe: string) => {
        if (tpe === "_other") {
          setCustomQualification(true);
          onChange?.({
            ...value,
            tpe: "",
          });
        } else {
          onChange?.({
            ...value,
            tpe,
            subject: qualificationHasSubject(tpe) ? value.subject : undefined,
          });
        }
      }}
    />
  );

  return (
    <React.Fragment>
      <RowContainerOuter>
        <RowContainerInner>
          <TightGrid>
            <Grid.Row>
              <TightGridColumn mobile={16} tablet={10} computer={6}>
                {qualificationField}
              </TightGridColumn>
              <TightGridColumn mobile={16} tablet={6} computer={4}>
                <SubjectDropdown
                  readOnly={readOnly}
                  qualification={value.tpe}
                  value={value.subject}
                  onChange={subject => onChange?.({ ...value, subject })}
                  messages={errors.subject}
                />
              </TightGridColumn>
              <TightGridColumn mobile={16} tablet={10} computer={4}>
                <QualificationGradeField
                  type={value.tpe}
                  value={value.grade}
                  readOnly={readOnly}
                  onChange={grade => onChange?.({ ...value, grade })}
                  messages={errors.grade}
                />
              </TightGridColumn>
              <TightGridColumn mobile={16} tablet={6} computer={2} style={{ marginBottom: 10 }}>
                <NullableNumberInput
                  value={value.yearObtained}
                  fluid
                  maxLength={4}
                  messages={errors.yearObtained}
                  readOnly={readOnly}
                  onChange={yearObtained => onChange?.({ ...value, yearObtained })}
                  placeholder="Year"
                />
              </TightGridColumn>
            </Grid.Row>
          </TightGrid>
        </RowContainerInner>
        {readOnly ? null : (
          <RemoveButtonContainer>
            <Button icon="remove" onClick={onRemove} />
          </RemoveButtonContainer>
        )}
      </RowContainerOuter>
      {errors._rest_.length > 0 && (
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <ErrorDisplay messages={errors._rest_} />
          </Grid.Column>
        </Grid.Row>
      )}
    </React.Fragment>
  );
}
