import { push } from "connected-react-router";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { UnknownClientError } from "../../errors";
import { reportingGroupRule } from "../../model/reportingGroup";
import { ReportingGroup, Checked, Message } from "../../model/types";
import { AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { AppState } from "../state";
import * as teams from "../teams";
import { emptyReportingGroup, initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.route.reportingGroupUpdate;

export const group = (state: AppState): ReportingGroup => myState(state).group;

export const messages = (state: AppState): Message[] => myState(state).clientMessages.concat(myState(state).serverMessages);

export const fetching = (state: AppState): boolean => myState(state).fetching;

export const fetched = (state: AppState): boolean => myState(state).fetched;

/////////////
// Actions //
/////////////

const INITIALISE = "reportingGroupUpdate/INITIALISE";
const LOAD_START = "reportingGroupUpdate/LOAD_START";
const LOAD_SUCCESS = "reportingGroupUpdate/LOAD_SUCCESS";
const UPDATE = "reportingGroupUpdate/UPDATE";
const SAVE_START = "reportingGroupUpdate/SAVE_START";
const SAVE_SUCCESS = "reportingGroupUpdate/SAVE_SUCCESS";
const DELETE_START = "reportingGroupUpdate/DELETE_START";
const DELETE_SUCCESS = "reportingGroupUpdate/DELETE_SUCCESS";
const SET_SERVER_MESSAGES = "reportingGroupUpdate/SET_SERVER_MESSAGES";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  function clearServerMessages(state: MyState): MyState {
    return { ...state, serverMessages: [] };
  }

  function commitChanges(state: MyState) {
    return { ...state, originalGroup: state.group };
  }

  function updateAndValidate(state: MyState, group: ReportingGroup) {
    return { ...state, group, clientMessages: reportingGroupRule(group) };
  }

  switch (action.type) {
    case INITIALISE:
      return commitChanges(updateAndValidate({ ...state, fetching: false, serverMessages: [] }, emptyReportingGroup(action.schoolCode)));
    case LOAD_START:
      return { ...state, fetching: true };
    case LOAD_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.group));
    case UPDATE:
      return clearServerMessages(updateAndValidate(state, action.group));
    case SAVE_START:
      return { ...state, fetching: true };
    case SAVE_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.group));
    case DELETE_START:
      return { ...state, fetching: true };
    case DELETE_SUCCESS:
      return { ...state, fetching: false };
    case SET_SERVER_MESSAGES:
      return { ...state, fetching: false, serverMessages: action.messages };
    default:
      return state;
  }
};

export default reducer;

/////////////////////
// Action Creators //
/////////////////////

export const initialise = () => {
  return (dispatch: Function, getState: Function) => {
    dispatch({
      type: INITIALISE,
      schoolCode: teams.currentSchoolCode(getState()),
    });
  };
};

export function load(id: number) {
  return fetchWithMutex<ReportingGroup>({
    mutex: "reportingGroupLoad",

    request(dispatch, getState) {
      return api.fetchReportingGroup(api.fetchParams(getState()), id);
    },

    pending(dispatch, getState) {
      dispatch({ type: LOAD_START });
    },

    success(dispatch, getState, group) {
      dispatch({ type: LOAD_SUCCESS, group });
    },
  });
}

export const update = (group: ReportingGroup) => ({ type: UPDATE, group });

export function save(group: ReportingGroup) {
  return fetchWithMutex<Checked<ReportingGroup>>({
    mutex: "reportingGroupSave",

    request(dispatch, getState) {
      return group.id >= 0
        ? api.updateReportingGroup(api.fetchParams(getState()), group)
        : api.insertReportingGroup(api.fetchParams(getState()), group);
    },

    pending(dispatch, getState) {
      dispatch({ type: SAVE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState, checked) {
      checked.data && toast.success("Reporting group saved");
      checked.data && dispatch({ type: SAVE_SUCCESS, group: checked.data });
      checked.messages && dispatch({ type: SET_SERVER_MESSAGES, messages: checked.messages });
      checked.data && dispatch(push("/admin/reporting-group"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}

export function del(group: ReportingGroup) {
  return fetchWithMutex({
    mutex: "reportingGroupDelete",

    request(dispatch, getState) {
      return group.id >= 0
        ? api.deleteReportingGroup(api.fetchParams(getState()), group.id)
        : Promise.reject(new UnknownClientError("Cannot delete an unsaved group"));
    },

    pending(dispatch, getState) {
      dispatch({ type: DELETE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState) {
      toast.success("Reporting group deleted");
      dispatch({ type: DELETE_SUCCESS });
      dispatch(push("/admin/reporting-group"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}
