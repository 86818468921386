import { push } from "connected-react-router";
import { Location } from "history";
import { debounce } from "lodash";
import React, { ChangeEvent, Component, ComponentType, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Button, Icon, Input } from "semantic-ui-react";
import * as pot from "../api/pot";
import { PAGE_SIZE } from "../constants";
import { SearchParams } from "../model/search";
import { Team, teamToSchoolCode } from "../model/team";
import { SearchResults } from "../model/types";
import { withCurrentTeam } from "../reducers/teams";
import { Opt, ReactNodeLike, safeParseInt } from "../utils";
import { formatQueryString, locationQueryParams, numberQueryParam, updateLocation } from "../utils/queryParams";
import Pager from "./Pager";

interface SharedProps<A> {
  search: (school: Opt<string>, params: SearchParams) => Promise<SearchResults<A>>;
  reset: () => void;
  results: pot.Pot<SearchResults<A>>;
  currentTeam: Team;
}

// Props for the contained component:
export interface SearchProps<A> extends SharedProps<A> {
  q?: Opt<string>;
  searchComponent: ReactNode;
  pagerComponent: ReactNode;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  searchText: string;
  refetch: () => void;
}

// Props for the containing component:
interface Props<A> extends SharedProps<A>, RouteComponentProps {
  placeholder?: string;
  push: (location: Location) => void;
  count?: number;
  emptyByDefault?: boolean;
  searchBySchool?: boolean;
}

interface PublicProps<A> {
  search: (school: Opt<string>, params: SearchParams) => Promise<SearchResults<A>>;
  reset: () => void;
  results: pot.Pot<SearchResults<A>>;
}

interface State {
  search: string;
}

export function withSearch<A>(
  ComposedComponent: ComponentType<SearchProps<A> & RouteComponentProps>,
  defaultPageSize: number = PAGE_SIZE,
): ComponentType<PublicProps<A>> {
  return withRouter(
    withCurrentTeam(
      connect(null, { push })(
        class WithSearch extends Component<Props<A>, State> {
          handleSearch = debounce(() => {
            const { search } = this.state;
            const query = locationQueryParams(this.props.location);
            const len = search.length;
            const page = search === query.q ? numberQueryParam(query, "page") : undefined;
            if (len === 0) {
              this.reset();
            } else {
              this.props.push(
                updateLocation(this.props.location, {
                  search: formatQueryString({
                    ...query,
                    q: search,
                    page,
                  }),
                }),
              );
            }
          }, 500);

          handleChange = (e: ChangeEvent<HTMLInputElement>) => {
            const search = e.target.value;
            this.setState({ search }, this.handleSearch);
          };

          constructor(props: Props<A>) {
            super(props);
            this.state = {
              search: locationQueryParams(props.location).q ?? "",
            };
          }

          doSearch(props: Props<A>) {
            const { location, searchBySchool, currentTeam } = props;

            const { q, page: pageString, count: countString, sortby, sortdir } = locationQueryParams(location);

            if (this.props.emptyByDefault && !q) {
              this.props.reset();
            } else {
              const page = safeParseInt(pageString, 0);
              const count = safeParseInt(countString, undefined);
              const pageSize = count || this.props.count || PAGE_SIZE;

              const school = searchBySchool ? teamToSchoolCode(currentTeam.code) : undefined;

              const query = {
                q,
                start: (page ? page - 1 : 0) * pageSize,
                count: pageSize,
                sortby,
                sortdir,
              };

              this.props.search(school, query);
            }
          }

          componentDidMount() {
            this.props.reset();
            this.doSearch(this.props);
          }

          UNSAFE_componentWillReceiveProps(nextProps: Props<A>) {
            if (nextProps.location !== this.props.location || nextProps.currentTeam.code !== this.props.currentTeam.code) {
              this.doSearch(nextProps);
            }
          }

          reset = () => {
            this.setState({ search: "" }, () => {
              this.props.push(updateLocation(this.props.location, { search: "" }));
            });
          };

          sortComponent = (sortby: string, label: string, initialDir?: "asc" | "desc"): ReactNodeLike => {
            const query = locationQueryParams(this.props.location);

            let icon;
            if (query.sortby === sortby) {
              icon = query.sortdir === "desc" ? <Icon name="caret down" /> : <Icon name="caret up" />;
            } else {
              icon = null;
            }

            const sortdir = query.sortby === sortby ? (query.sortdir === "asc" ? "desc" : "asc") : initialDir || "asc";

            const location = updateLocation(this.props.location, {
              search: formatQueryString({
                ...query,
                sortby,
                sortdir,
              }),
            });

            return (
              <Link to={location}>
                {label} {icon}
              </Link>
            );
          };

          pageSize = () => {
            const { count: countString } = locationQueryParams(this.props.location);
            return safeParseInt(countString, defaultPageSize);
          };

          render() {
            const searchComponent = (
              <Input
                fluid
                action
                iconPosition="left"
                value={this.state.search}
                onChange={this.handleChange}
                placeholder={this.props.placeholder || "Search"}
                autoComplete="off"
              >
                <Icon name="search" />
                <input />
                {this.state.search.length !== 0 && <Button basic icon="close" onClick={this.reset} />}
                <Button onClick={this.handleSearch}>Search</Button>
              </Input>
            );

            return (
              <ComposedComponent
                {...this.props}
                refetch={() => {
                  this.doSearch(this.props);
                }}
                q={locationQueryParams(this.props.location).q}
                searchText={this.state.search}
                searchComponent={searchComponent}
                pagerComponent={<Pager results={this.props.results} pageSize={this.pageSize()} />}
                sortComponent={this.sortComponent}
              />
            );
          }
        },
      ),
    ),
  );
}
