import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import qhistory from "qhistory";
import { parse, stringify } from "qs";
import { applyMiddleware, compose, createStore } from "redux";
import * as storage from "redux-storage";
import debounce from "redux-storage-decorator-debounce";
import filter from "redux-storage-decorator-filter";
import createEngine from "redux-storage-engine-localstorage";
import thunk from "redux-thunk";
import reducer from "./reducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) ?? compose;

const engine = debounce(filter(createEngine("qmspringboard"), ["auth", "user"]), 1500);

const storageMiddleware = storage.createMiddleware(engine);

export const history = qhistory(createBrowserHistory(), stringify, parse);
const middlewares = [routerMiddleware(history), thunk, storageMiddleware];

// Create redux store
export const store = createStore(storage.reducer(reducer(history)), composeEnhancers(applyMiddleware(...middlewares)));

export default function configureStore(onComplete: () => void) {
  const load = storage.createLoader(engine);
  load(store)
    .then(newState => console.debug("Loaded state for:", newState))
    .then(onComplete)
    .then(undefined, e => console.trace(e));
  return store;
}

export type AppStore = ReturnType<typeof configureStore>;
