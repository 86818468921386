import { User } from "./types";
import * as rule from "./rules";

export const userRule: rule.Rule<User> = rule
  .create()
  .field("username", rule.nonEmpty(rule.error("You must enter a username")))
  .field("surname", rule.nonEmpty(rule.error("You must enter a surname")))
  .field("forenames", rule.nonEmpty(rule.error("You must enter some forenames")))
  .field("email", rule.nonEmpty(rule.error("You must enter an email address")))
  .finish();
