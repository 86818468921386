import * as pot from "../../api/pot";
import { ExpirySettings } from "@qmspringboard/shared/dist/model/types";

export interface MyState {
  settings: pot.Pot<ExpirySettings>;
}

export const initialState: MyState = {
  settings: pot.empty(),
};
