import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { show as showModal } from "redux-modal";
import { Button, Container, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { ReportingGroup, Message, Programme } from "../model/types";
import * as reportingGroupUpdate from "../reducers/reportingGroupUpdate";
import * as programmeList from "../reducers/programmeList";
import Title from "../components/Title";
import ReportingGroupEditor from "../components/ReportingGroupEditor";
import SubmitButton from "../components/SubmitButton";
import { Opt, safeParseInt } from "../utils";

interface Params {
  id: string;
}

interface Props {
  match: Match<Params>;
  fetching: boolean;
  fetched: boolean;
  group: Opt<ReportingGroup>;
  messages: Message[];
  programmes: Programme[];
  onInit: () => void;
  onLoad: (id: number) => void;
  onUpdate: (group: ReportingGroup) => void;
  onSave: (group: ReportingGroup) => void;
  onDelete: (group: ReportingGroup) => void;
  initProgrammes: () => void;
  showModal: Function;
}

class ReportingGroupEditorPage extends React.PureComponent<Props> {
  getId = (props: Props) => safeParseInt(props.match.params.id, null);

  componentDidMount() {
    const { onInit, onLoad, initProgrammes } = this.props;
    const id = this.getId(this.props);
    initProgrammes();
    if (id) {
      onLoad(id);
    } else {
      onInit();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { onInit, onLoad } = nextProps;
    const oldId = this.getId(this.props);
    const newId = this.getId(nextProps);
    if (oldId !== newId) {
      if (newId) {
        onLoad(newId);
      } else {
        onInit();
      }
    }
  }

  handleSave = () => {
    const { group, onSave } = this.props;
    group && onSave(group);
  };

  handleDelete = () => {
    const { group, onDelete } = this.props;

    this.props.showModal("confirm", {
      title: "Delete reporting group",
      content: "Are you sure?",
      onPrimaryClick: () => group && onDelete(group),
    });
  };

  render() {
    const { fetching, group, onUpdate, messages, programmes } = this.props;

    return (
      <Title title={group == null || group.id < 0 ? "New Reporting Group" : group.code}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            {group && <ReportingGroupEditor value={group} readOnly={false} messages={messages} programmes={programmes} onChange={onUpdate} />}

            <Divider hidden />

            <Segment basic vertical textAlign="right">
              {group && group.id >= 0 && (
                <Button color="red" onClick={this.handleDelete}>
                  Delete
                </Button>
              )}

              <SubmitButton onClick={this.handleSave} messages={messages}>
                Save
              </SubmitButton>
            </Segment>
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    group: reportingGroupUpdate.group(state),
    messages: reportingGroupUpdate.messages(state),
    fetching: reportingGroupUpdate.fetching(state),
    fetched: reportingGroupUpdate.fetched(state),
    programmes: programmeList.allProgrammes(state),
  }),
  {
    onInit: reportingGroupUpdate.initialise,
    onLoad: reportingGroupUpdate.load,
    onUpdate: reportingGroupUpdate.update,
    onSave: reportingGroupUpdate.save,
    onDelete: reportingGroupUpdate.del,
    initProgrammes: programmeList.initialise,
    showModal,
  },
)(ReportingGroupEditorPage);
