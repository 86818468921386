import React, { useContext } from "react";
import styled from "styled-components";
import { FeatureFlagsContext } from "@qmspringboard/app/src/FeatureFlagsProvider";

const WatermarkContainer = styled.div({
  position: "fixed",
  display: "flex",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  pointerEvents: "none",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  zIndex: 999999,
});

const WatermarkText = styled.div({
  flex: 1,
  textAlign: "center",
  fontSize: "15vw",
  transform: "rotate(-30deg)",
  color: "rgba(0,0,0,0.05)",
});

export function WatermarkIfFlagged(): React.ReactElement | null {
  const featureFlags = useContext(FeatureFlagsContext);

  if (featureFlags.showWatermark) {
    return (
      <WatermarkContainer>
        <WatermarkText>{featureFlags.environmentFriendlyName}</WatermarkText>
      </WatermarkContainer>
    );
  }
  return null;
}
