import React from "react";
import DocumentTitle from "react-document-title";

interface Props {
  title: string;
  children: React.ReactElement | number | string | null | undefined;
}

const Title = ({ title, children }: Props) => {
  const fullTitle = `${title} - Springboard`;
  return <DocumentTitle title={fullTitle}>{children}</DocumentTitle>;
};

export default Title;
