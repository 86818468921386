import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import useAppDispatch from "../hooks/useAppDispatch";
import { SelectorListApplicant } from "../hooks/useSelectorListActions";
import { Permissions } from "../model/permission";
import { Team, TeamCode, teamToSchoolCode } from "../model/team";
import { ApplicantDTO, Application, Label, Note, User } from "../model/types";
import * as auth from "../reducers/auth";
import * as selectorListSidebar from "../reducers/selectorListSidebar";
import * as teams from "../reducers/teams";
import ApplicantAuditTrail from "./ApplicantAuditTrail";
import ApplicantLabels from "./ApplicantLabels";
import ApplicantNotes from "./ApplicantNotes";
import TrackingNumberView from "./TrackingNumberView";

interface InnerSelectorListSidebarProps {
  applicant: ApplicantDTO;
  applications: Application[];
  fetch: (applicantId: number) => void;
  labels: Label[];
  notes: Note[];
  user: User;
  permissions: Permissions;
  onUpdateLabel: (oldLabel: Label, newLabel: Label) => void;
  onAddNote: (author: User, team: TeamCode) => void;
  onSaveNote?: (oldNote: Note, newNote: Note) => void;
  onUpdateNote: (oldNote: Note, newNote: Note) => void;
  onDeleteNote: (note: Note) => void;
  teamCode: TeamCode;
  allTeams: Team[];
}

interface SelectorListSidebarProps {
  selectedApplicant?: SelectorListApplicant;
  applications: Application[];
  teamCode: TeamCode;
  fetching: boolean;
}

function InnerSelectorListSidebar({
  applicant,
  applications,
  labels,
  notes,
  user,
  permissions,
  onUpdateLabel,
  onAddNote,
  onSaveNote,
  onUpdateNote,
  onDeleteNote,
  teamCode,
  allTeams,
  fetch,
}: InnerSelectorListSidebarProps) {
  useEffect(() => {
    fetch(applicant.id);
  }, [fetch, applicant.id]);

  return (
    <Segment secondary style={{ border: "none", boxShadow: "none" }}>
      <Segment basic vertical>
        <TrackingNumberView applicantId={applicant.id} />
      </Segment>

      <Segment basic vertical>
        <ApplicantLabels
          applicantId={applicant.id}
          labels={labels}
          onUpdateLabel={onUpdateLabel}
          applications={applications}
          permissions={permissions}
          teamCode={teamCode}
          allTeams={allTeams}
        />
      </Segment>

      {applicant.id >= 0 && (
        <Segment basic vertical>
          <ApplicantAuditTrail applicantId={applicant.id} />
        </Segment>
      )}

      <Segment basic vertical>
        <ApplicantNotes
          notes={notes}
          onAddNote={() => onAddNote(user, teamCode)}
          onSaveNote={onSaveNote}
          onUpdateNote={onUpdateNote}
          onDeleteNote={onDeleteNote}
          permissions={permissions}
          authorUserId={user.id}
          teamCode={teamCode}
        />
      </Segment>
    </Segment>
  );
}

export default function SelectorListSidebar({ selectedApplicant, applications, fetching, teamCode }: SelectorListSidebarProps) {
  const labels = useSelector(selectorListSidebar.labels);
  const notes = useSelector(selectorListSidebar.notes);
  const user = useSelector(auth.currentUser);
  const allTeams = useSelector(teams.allTeams);
  const permissions = useSelector(auth.permissions);

  const dispatch = useAppDispatch();

  const fetch = useCallback(
    (applicantId: number) => {
      dispatch(selectorListSidebar.fetch(applicantId));
    },
    [dispatch],
  );

  const onUpdateLabel = useCallback(
    (oldLabel: Label, newLabel: Label) => {
      dispatch(selectorListSidebar.saveLabel(oldLabel, newLabel));
    },
    [dispatch],
  );

  const onAddNote = useCallback(
    (author: User, teamCode: TeamCode) => {
      dispatch(selectorListSidebar.addNote(author, teamToSchoolCode(teamCode)));
    },
    [dispatch],
  );

  const onUpdateNote = useCallback(
    (oldNote: Note, newNote: Note) => {
      dispatch(selectorListSidebar.updateNote(oldNote, newNote));
    },
    [dispatch],
  );

  const onSaveNote = useCallback(
    (oldNote: Note, newNote: Note) => {
      dispatch(selectorListSidebar.saveNote(oldNote, newNote));
    },
    [dispatch],
  );

  const onDeleteNote = useCallback(
    (note: Note) => {
      dispatch(selectorListSidebar.deleteNote(note));
    },
    [dispatch],
  );
  if (!fetching && user != null && selectedApplicant != null && selectedApplicant.existingApplicant != null) {
    return (
      <InnerSelectorListSidebar
        applicant={selectedApplicant.existingApplicant}
        applications={applications}
        labels={labels}
        notes={notes}
        user={user}
        allTeams={allTeams}
        teamCode={teamCode}
        permissions={permissions}
        fetch={fetch}
        onUpdateLabel={onUpdateLabel}
        onAddNote={onAddNote}
        onUpdateNote={onUpdateNote}
        onSaveNote={onSaveNote}
        onDeleteNote={onDeleteNote}
      />
    );
  }

  return null;
}
