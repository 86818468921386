/**
 * @returns [additions, removals]
 */
export function diffArrays<A>(prev: A[], curr: A[], same: (x: A, y: A) => boolean = (x, y) => x === y): [A[], A[]] {
  return [curr.filter(curr => prev.find(prev => same(prev, curr)) == null), prev.filter(prev => curr.find(curr => same(prev, curr)) == null)];
}

export function joinEnglish(items: string[]): string {
  switch (items.length) {
    case 0:
      return "";
    case 1:
      return items[0];
    default:
      return items.slice(0, items.length - 1).join(", ") + " and " + items[items.length - 1];
  }
}
