import React, { ComponentType, ReactElement } from "react";
import { Permissions } from "../model/permission";
import { Team, TeamCode } from "../model/team";
import * as auth from "../reducers/auth";
import * as teams from "../reducers/teams";

interface Props {
  permissions: Permissions;
  permissionCheck: (permissions: Permissions, teamCode: TeamCode) => boolean;
  currentTeam: Team;
  children: ReactElement;
}

class RequirePermission extends React.Component<Props> {
  render() {
    const { permissions, permissionCheck, currentTeam, children } = this.props;
    if (permissionCheck(permissions, currentTeam.code)) {
      return <div>{children}</div>;
    } else {
      window.location.assign("/");
      return <span />;
    }
  }
}

const ConnectedRequirePermission = auth.withPermissions(teams.withCurrentTeam(RequirePermission));

export default function requirePermission<A>(
  ComposedComponent: ComponentType<A>,
  permissionCheck: (permissions: Permissions, teamCode: TeamCode) => boolean,
) {
  return function RequirePermission(props: A) {
    return (
      <ConnectedRequirePermission permissionCheck={permissionCheck}>
        <ComposedComponent {...props} />
      </ConnectedRequirePermission>
    );
  };
}
