import { Location } from "history";
import lodash from "lodash";
import qs from "qs";
import { Opt } from "../utils";

export interface QueryParams {
  [name: string]: Opt<string>;
}

export interface RelaxedQueryParams {
  [name: string]: Opt<string | number | boolean>;
}

export function locationQueryParams(loc: Location): QueryParams {
  const temp = qs.parse(loc.search, {
    ignoreQueryPrefix: true,
    parseArrays: false,
    allowDots: false,
  });

  const ans: QueryParams = {};

  for (const key in temp) {
    const val = temp[key];
    if (typeof val === "string") {
      ans[key] = val;
    }
  }

  return ans;
}

export function stringQueryParam(params: QueryParams, name: string): Opt<string> {
  const temp = params[name];
  return typeof temp === "string" ? temp : undefined;
}

export function numberQueryParam(params: QueryParams, name: string): Opt<number> {
  const str = stringQueryParam(params, name);
  if (str == null) return undefined;
  const num = parseInt(str, 10);
  return isNaN(num) ? undefined : num;
}

export function booleanQueryParam(params: QueryParams, name: string): Opt<boolean> {
  switch (stringQueryParam(params, name)?.toLowerCase()) {
    case "y":
    case "yes":
    case "t":
    case "true":
    case "1":
      return true;

    case "n":
    case "no":
    case "f":
    case "false":
    case "0":
      return false;

    default:
      return undefined;
  }
}

type Params = Record<string, Opt<string | number | boolean>>;

export function normalizeQueryParams(params: Params): QueryParams {
  const ans: QueryParams = {};

  for (const key in params) {
    const value = params[key];

    if (value != null) {
      ans[key] = `${value}`;
    }
  }

  return ans;
}

export function formatQueryString(query: RelaxedQueryParams): string {
  const queryString = qs.stringify(lodash.omitBy(query, value => value == null));
  return queryString === "" ? "" : `?${queryString}`;
}

export function updateLocation<S>(location: Location<S>, updates: Partial<Location<S>>): Location {
  return {
    pathname: updates.pathname ?? location.pathname,
    search: updates.search ?? location.search,
    state: updates.state ?? location.state,
    hash: updates.hash ?? location.hash,
    key: updates.key ?? location.key,
  };
}
