import { admissionsTeam, TeamCode, teamToSchoolCode } from "../../model/team";
import { Message, User } from "../../model/types";

export type MyState = {
  fetching: boolean;
  fetched: boolean;
  user: User;
  originalUser: User;
  clientMessages: Message[];
  serverMessages: Message[];
};

export const emptyUser = (team: TeamCode) => ({
  username: "",
  surname: "",
  forenames: "",
  email: "",
  roles: [],
  defaultSchoolCode: teamToSchoolCode(team),
  canLogin: true,
  id: -1,
});

export const initialState = (): MyState => {
  const user = emptyUser(admissionsTeam.code);

  return {
    fetching: false,
    fetched: false,
    user: user,
    originalUser: user,
    clientMessages: [],
    serverMessages: [],
  };
};
