import React, { Component } from "react";
import { DATE_TIME_FORMAT } from "../constants";
import formatInTimezone from "date-fns-tz/formatInTimeZone";
import parseISO from "date-fns/parseISO";

type Props = {
  iso: string;
  displayTZ?: string;
};

/**
 * The iso date string should include timezone information.
 *
 * If the displayTZ property is set to a valid timezone string then the date will be translated
 * to that timezone for display, otherwise the date will be translated to the Europe/London
 * timezone.
 */
export default class DateTime extends Component<Props> {
  render() {
    const displayTimezone = this.props.displayTZ || "Europe/London";
    const formattedDateTime = formatInTimezone(parseISO(this.props.iso), displayTimezone, DATE_TIME_FORMAT);

    return <span>{formattedDateTime}</span>;
  }
}
