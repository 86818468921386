import React, { Component, ReactNode, Ref, useCallback, useMemo } from "react";
import { Dropdown as SemanticDropdown, DropdownProps as SemanticDropdownProps } from "semantic-ui-react";
import { BaseFieldProps, SemanticPointing } from "./base";
import FieldWrapper from "./FieldWrapper";
import { TextInput } from "./TextInput";

type SemanticUiDropdownValue = boolean | number | string | (boolean | number | string)[] | undefined;

export interface DropdownOption<A> {
  value: A;
  key?: string | number;
  label: string;
  content?: ReactNode;
  disabled?: boolean;
  active?: boolean;
}

export type DropdownOptions<A> = DropdownOption<A>[];

export interface ExtraDropdownProps {
  placeholder?: string;
  fluid?: boolean;
  clearable?: boolean;
}

export interface DropdownFieldProps<A> extends BaseFieldProps<A>, ExtraDropdownProps {
  forwardRef?: Ref<Component<SemanticDropdownProps>>;
  className?: string;
  options: DropdownOptions<A>;
  search?: boolean | ((options: DropdownOptions<A>, query: string) => DropdownOptions<A>);
  errorPointing?: SemanticPointing;
  onBlur?: () => void;
}

export default function DropdownField<A>(props: DropdownFieldProps<A>) {
  const {
    forwardRef,
    className,
    value,
    options,
    onChange,
    onBlur,
    readOnly,
    disabled,
    messages,
    errorPointing,
    // ExtraDropdownProps
    placeholder,
    fluid,
    search,
    clearable,
  } = props;

  const selected = useMemo(() => options.find(opt => opt.value === value), [value, options]);

  const selectedLabel = selected == null ? "" : selected.label;

  const semanticOption = useCallback(
    (option: DropdownOption<A>) => ({
      key: `${option.value}-${option.label}`,
      value: option.label,
      text: option.label,
      content: option.content,
      active: option.active || false,
      disabled: option.disabled || false,
    }),
    [],
  );

  // We use option labels as the values in the options for SemanticUiDropdown.
  const semanticOptions = useMemo(() => options.map(semanticOption), [options, semanticOption]);

  const semanticSearch = useMemo(
    () =>
      search == null
        ? undefined
        : typeof search === "boolean"
          ? true
          : (_ignored: unknown, query: string) => search(options, query).map(semanticOption),
    [options, search, semanticOption],
  );

  // SemanticUiDropdown passes us an option label back.
  // This should always be a string but the types don't enforce that.
  const handleChange = useCallback(
    (value: SemanticUiDropdownValue) => {
      if (typeof value !== "string") {
        // eslint-disable-next-line no-console
        console.warn("Dropdown.handleChange: expected string, found", value);
      }

      const option = options.find(opt => opt.label === value);

      if (option != null) {
        onChange?.(option.value);
      }
    },
    [onChange, options],
  );

  return (
    <FieldWrapper readOnly={readOnly} messages={messages} errorPointing={errorPointing}>
      {readOnly ? (
        <TextInput value={selected?.label ?? ""} readOnly={true} placeholder={placeholder} fluid={fluid} disabled={disabled} />
      ) : (
        <SemanticDropdown
          ref={forwardRef}
          className={className}
          value={selectedLabel}
          onChange={(_, p) => handleChange(p.value)}
          onBlur={() => onBlur?.()}
          options={semanticOptions}
          search={semanticSearch}
          disabled={disabled}
          selection
          // ExtraDropdownProps
          placeholder={placeholder}
          fluid={fluid}
          clearable={clearable}
        />
      )}
    </FieldWrapper>
  );
}
