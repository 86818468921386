import * as React from "react";
import { useQueryErrorResetBoundary } from "react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ModalAlert } from "./components/ModalAlert";
import { useModalPortal } from "./hooks/useModalPortal";
import {
  AppError,
  UnauthorizedError,
  ConcurrentModificationError,
  NotFoundError,
  ForbiddenError,
  ValidationError,
  UnknownClientError,
  UnknownServerError,
  UnknownError,
} from "./errors";

export type ErrorModalProps = { onReset: () => void; error: unknown };

function DefaultErrorModal({ onReset, error }: ErrorModalProps) {
  const { Portal } = useModalPortal();
  let detail: React.ReactElement | string = "";
  let title = "Oops";
  if (error instanceof AppError) {
    if (error instanceof NotFoundError) {
      title = "Not Found";
      detail = error.allMessages();
    } else if (error instanceof ValidationError) {
      title = "Validation Error";
      detail = error.allMessages();
    } else if (error instanceof UnauthorizedError) {
      title = "Unauthorized";
      detail = error.allMessages();
    } else if (error instanceof ForbiddenError) {
      title = "Forbidden";
      detail = error.allMessages();
    } else if (error instanceof ConcurrentModificationError) {
      title = "Concurrent Modification";
      detail = error.allMessages();
    } else if (error instanceof UnknownClientError) {
      title = "Unknown Client Error";
      detail = error.allMessages();
    } else if (error instanceof UnknownServerError) {
      title = "Unknown Server Error";
      detail = error.allMessages();
    } else if (error instanceof UnknownError) {
      title = "Unknown Error";
      detail = error.allMessages();
    } else {
      detail = <pre>{JSON.stringify(error, null, 2)}</pre>;
    }
  } else {
    detail = <pre>{JSON.stringify(error, null, 2)}</pre>;
  }

  const content = (
    <div>
      <p>Something went wrong. Here&apos;s the error message:</p>
      <p style={{ textAlign: "center" }}>{detail}</p>
    </div>
  );

  return (
    <Portal>
      <ModalAlert
        show
        title={title}
        onPrimaryClick={onReset}
        primaryButtonText={"Clear Error"}
        handleHide={onReset}
        handleDestroy={onReset}
        content={content}
      />
    </Portal>
  );
}

export function ReactQueryErrorBoundary({
  children,
  ErrorModal = DefaultErrorModal,
}: React.PropsWithChildren<{
  ErrorModal?: (props: ErrorModalProps) => React.ReactElement;
}>) {
  const { reset } = useQueryErrorResetBoundary();
  return (
    <ErrorBoundary onReset={reset} fallbackRender={({ resetErrorBoundary, error }) => <ErrorModal onReset={resetErrorBoundary} error={error} />}>
      {children}
    </ErrorBoundary>
  );
}
