import React, { ReactNode } from "react";
import { Form, Radio } from "semantic-ui-react";
import { BaseFieldProps } from "./base";
import FieldWrapper from "./FieldWrapper";
import { ExtraRadioProps } from "./RadioField";

export interface RadioSelectOption<A = string> {
  key: string | number;
  value: A;
  label: ReactNode;
}

export type RadioSelectOptions<A = string> = Array<RadioSelectOption<A>>;

export interface RadioSelectFieldProps<A> extends BaseFieldProps<A>, ExtraRadioProps {
  options: RadioSelectOptions<A>;
}

function safeValuesEqual(value: unknown, option: unknown) {
  return value === option || (value === "" && option == null);
}

export default function RadioSelectField<A>(props: RadioSelectFieldProps<A>) {
  const { value, options, onChange, readOnly, disabled, messages, errorPointing, label, forwardRef } = props;

  return (
    <FieldWrapper readOnly={readOnly} messages={messages} errorPointing={errorPointing}>
      <Form.Group grouped>
        {label && <label>{label}</label>}
        {options.map(option => (
          <Form.Field key={option.key}>
            <Radio
              ref={forwardRef}
              checked={safeValuesEqual(value, option.value)}
              onChange={() => onChange?.(option.value)}
              disabled={disabled}
              readOnly={readOnly}
              label={option.label}
            />
          </Form.Field>
        ))}
      </Form.Group>
    </FieldWrapper>
  );
}
