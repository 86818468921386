import React, { useState } from "react";
import { Message, Modal, Header, Button, ModalProps, Form, Grid } from "semantic-ui-react";
import { Messages } from "../model/errors";
import ErrorDisplay from "./ErrorDisplay";
import { FieldLabel, NullableTextInput } from "./fields";
import { Opt } from "../utils";

interface Props extends ModalProps {
  messages: Messages;
  onPrimaryClick: (keywords: string) => void;
  onHide: () => void;
}

export default function ModalBulkEditEntryRequirements({ show, onPrimaryClick, messages, onHide }: Props) {
  const [keywords, setKeywords] = useState<Opt<string>>("");
  const handlePrimaryClick = () => {
    if (keywords) {
      onPrimaryClick(keywords);
    }
  };

  return (
    <Modal open={show} onClose={onHide} size="small">
      <Header content="Replace Keywords on Selected Programmes" />
      <Message warning content="The keywords you set here will replace any existing keywords on the selected programmes" />

      <Modal.Content>
        <Form as="div">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16}>
                <ErrorDisplay messages={messages} />
                <FieldLabel label="Keywords">
                  <NullableTextInput value={keywords} onChange={setKeywords} />
                  <p>Space separated, only single words are supported. Please do not use quotes to group words.</p>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handlePrimaryClick} disabled={!keywords}>
          Replace All
        </Button>
        <Button onClick={onHide}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
}
