import { feeCategoryLabel } from "@qmspringboard/shared/dist/model/feeCategory";
import {
  isClosedToFeeCategory,
  isFullToFeeCategory,
  isPartiallyClosed,
  isPartiallyFull,
  spacesDescription,
} from "@qmspringboard/shared/dist/model/programme";
import Opt from "@qmspringboard/shared/dist/utils/opt";
import React, { ReactElement, ReactNode, useMemo } from "react";
import { Header, Message, Segment } from "semantic-ui-react";
import { Programme } from "../model/types";
import { WrappedMessage } from "./RequirementsCheckView";
import { FeeCategory } from "../model/enums";

export interface ClosedProgrammeCheckViewProps {
  programme: Programme;
  initialProgramme?: Opt<Programme>;
  canMakeOffersOnClosedProgrammes: boolean;
  guessedFeeCategory: FeeCategory | null;
  readOnly: boolean;
}

export default function ClosedProgrammeCheckView(props: ClosedProgrammeCheckViewProps): ReactElement | null {
  const { programme, initialProgramme, canMakeOffersOnClosedProgrammes, guessedFeeCategory, readOnly } = props;

  const programmeChanged = useMemo(() => programme.code !== initialProgramme?.code, [programme, initialProgramme]);

  // Programme's general status:
  const partiallyClosed = isPartiallyClosed(programme);
  const partiallyFull = isPartiallyFull(programme);
  const completelyOpen = !partiallyClosed && !partiallyFull;

  // Programmes's status for this applicant:
  const closedToApplicant = isClosedToFeeCategory(programme, guessedFeeCategory);
  const fullToApplicant = isFullToFeeCategory(programme, guessedFeeCategory);
  const openToApplicant = !closedToApplicant && !fullToApplicant;

  // User's permission on closed programmes:
  const offersForbidden = !canMakeOffersOnClosedProgrammes;

  const addHeader = (content: ReactNode) => (
    <>
      <Header as="h4">Programme Status</Header>
      {content}
    </>
  );

  return programmeChanged && !readOnly
    ? addHeader(
        <WrappedMessage success={openToApplicant} warning={!completelyOpen} error={!openToApplicant}>
          <Message.Header>This programme is {spacesDescription(programme)}</Message.Header>
          {programmeChanged && !completelyOpen && (
            <Message.Content>
              <Segment vertical>
                <p>
                  {guessedFeeCategory ? (
                    <>
                      The applicant&apos;s fee status appears to be: <strong>{feeCategoryLabel(guessedFeeCategory)}</strong>
                    </>
                  ) : (
                    <>The applicant&apos;s fee status can&apos;t be determined automatically.</>
                  )}
                </p>
                <p>
                  {openToApplicant
                    ? "You can make an offer for this programme."
                    : offersForbidden
                      ? "You cannot make an offer for this programme."
                      : "You are advised not to make an offer for this programme."}
                </p>
              </Segment>
            </Message.Content>
          )}
        </WrappedMessage>,
      )
    : null;
}
