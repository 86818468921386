import React, { PureComponent } from "react";
import { Grid, Header } from "semantic-ui-react";
import { isFailedApplication } from "@qmspringboard/shared/dist/model/application";
import { addDays } from "date-fns";
import {
  ApplicationStatusEnum,
  ClearingDecisionEnum,
  ClearingResponseEnum,
  ConfirmationDecisionEnum,
  ConfirmationResponseEnum,
  InitialDecisionEnum,
  InitialResponseEnum,
  RejectionReasonEnum,
} from "../model/enums";
import { Messages, subErrors } from "../model/errors";
import { Permissions } from "../model/permission";
import { Application } from "../model/types";
import { Opt } from "../utils";
import { PredictionExpiryField } from "./ExpiryField";
import { DropdownField, FieldLabel, NullableIntegerInput, NullableTextInput } from "./fields";
import { DropdownOptions } from "./fields/DropdownField";

export type ShowFields = "INITIAL" | "CLEARING" | "CONFIRMATION" | "SELECTOR_LIST_KEY" | "REJECT_REASON";

interface Props {
  onChange: Function;
  readOnly: boolean;
  value: Application;
  showFields: ShowFields[];
  messages: Messages;
  permissions: Permissions;
  school: string;
}

const cocoOptions: DropdownOptions<boolean> = [
  { key: "N", value: false, label: "No" },
  { key: "Y", value: true, label: "Yes" },
];

export default class UcasStatusEditor extends PureComponent<Props> {
  render() {
    const { value, readOnly, onChange: underlyingOnChange, permissions, school, showFields } = this.props;

    const showInitial = showFields.includes("INITIAL");
    const showConfirmation = showFields.includes("CONFIRMATION");
    const showClearing = showFields.includes("CLEARING");
    const showRejectReason = showFields.includes("REJECT_REASON");
    const showSelectorListKey = showFields.includes("SELECTOR_LIST_KEY");

    const onChange = (appn: Application) => underlyingOnChange(isFailedApplication(appn) ? appn : { ...appn, rejectionReason: null });

    const splitErrors = subErrors(this.props.messages, {
      prediction: true,
      predictionExpiry: true,
    });

    const suggestExpiry = (prediction: Opt<number>): string | null => (prediction ? addDays(new Date(), 1).toISOString() : null);

    return (
      <Grid>
        {showInitial || showClearing || showConfirmation ? (
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h4">UCAS / SITS status</Header>
            </Grid.Column>

            {showInitial && (
              <Grid.Column width={8}>
                <FieldLabel label="Initial Decision">
                  <DropdownField
                    value={value.initialDecision}
                    readOnly={readOnly}
                    onChange={initialDecision => onChange({ ...value, initialDecision })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={InitialDecisionEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
            {showInitial && (
              <Grid.Column width={8}>
                <FieldLabel label="Initial Response">
                  <DropdownField
                    value={value.initialResponse}
                    readOnly={readOnly}
                    onChange={initialResponse => onChange({ ...value, initialResponse })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={InitialResponseEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
            {showClearing && (
              <Grid.Column width={8}>
                <FieldLabel label="Clearing Decision">
                  <DropdownField
                    value={value.clearingDecision}
                    readOnly={readOnly}
                    onChange={clearingDecision => onChange({ ...value, clearingDecision })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={ClearingDecisionEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
            {showClearing && (
              <Grid.Column width={8}>
                <FieldLabel label="Clearing Response">
                  <DropdownField
                    value={value.clearingResponse}
                    readOnly={readOnly}
                    onChange={clearingResponse => onChange({ ...value, clearingResponse })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={ClearingResponseEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
            {showConfirmation && (
              <Grid.Column width={8}>
                <FieldLabel label="Confirmation Decision">
                  <DropdownField
                    value={value.confirmationDecision}
                    readOnly={readOnly}
                    onChange={confirmationDecision => onChange({ ...value, confirmationDecision })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={ConfirmationDecisionEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
            {showConfirmation && (
              <Grid.Column width={8}>
                <FieldLabel label="Confirmation Response">
                  <DropdownField
                    value={value.confirmationResponse}
                    readOnly={readOnly}
                    onChange={confirmationResponse => onChange({ ...value, confirmationResponse })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={ConfirmationResponseEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}

            {(showClearing || showInitial) && (
              <Grid.Column width={8}>
                <FieldLabel label="Application Status">
                  <DropdownField
                    value={value.applicationStatus}
                    readOnly={readOnly}
                    onChange={applicationStatus => onChange({ ...value, applicationStatus })}
                    placeholder="-- Blank --"
                    disabled={!permissions.canUpdateUcasSitsFields(school)}
                    fluid
                    options={ApplicationStatusEnum.dropdownOptionsWithBlank()}
                  />
                </FieldLabel>
              </Grid.Column>
            )}
          </Grid.Row>
        ) : null}

        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label="Change of Course Offer (COCO)?">
              <DropdownField
                value={value.coco}
                readOnly={readOnly}
                onChange={coco => onChange({ ...value, coco })}
                fluid
                disabled={!readOnly && !permissions.canUpdateCocoStatuses(school)}
                options={cocoOptions}
              />
            </FieldLabel>
          </Grid.Column>
          {(showClearing || showRejectReason) && (
            <Grid.Column width={8}>
              <FieldLabel label="Rejection Reason">
                <DropdownField
                  value={value.rejectionReason}
                  readOnly={readOnly}
                  onChange={rejectionReason => onChange({ ...value, rejectionReason })}
                  placeholder="-- Blank --"
                  fluid
                  disabled={!readOnly && !isFailedApplication(value)}
                  options={RejectionReasonEnum.dropdownOptionsWithBlank()}
                />
              </FieldLabel>
            </Grid.Column>
          )}
        </Grid.Row>

        {(showClearing || showInitial) && (
          <Grid.Row>
            <Grid.Column width={16}>
              <FieldLabel label="Override Prediction">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <NullableIntegerInput
                        value={value.prediction ?? null}
                        readOnly={readOnly}
                        disabled={!readOnly && !permissions.canUpdatePredictionOverrides(school)}
                        onChange={prediction =>
                          onChange({
                            ...value,
                            prediction,
                            predictionExpiry: prediction == null ? null : value.predictionExpiry || suggestExpiry(prediction),
                          })
                        }
                        fluid
                        labelPosition="right"
                        label="%"
                        messages={splitErrors.prediction.concat(splitErrors.predictionExpiry)}
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <PredictionExpiryField
                        value={value.predictionExpiry}
                        readOnly={readOnly}
                        disabled={!readOnly && !permissions.canUpdatePredictionOverrides(school)}
                        onChange={predictionExpiry => onChange({ ...value, predictionExpiry })}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>
        )}

        {showSelectorListKey && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Header as="h4">Selector List</Header>
            </Grid.Column>
            <Grid.Column width={8}>
              <FieldLabel label="Selector List Application ID">
                <NullableTextInput
                  onChange={selectorListKey => onChange({ ...value, selectorListKey })}
                  fluid
                  placeholder="Enter a 15-digit selector list ID"
                  readOnly={readOnly}
                  value={value.selectorListKey}
                />
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}
