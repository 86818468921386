import { Location } from "history";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Dropdown, Grid, Segment } from "semantic-ui-react";
import * as pot from "../api/pot";
import ApplicationList from "../components/ApplicationList";
import { FieldLabel } from "../components/fields";
import Pager from "../components/Pager";
import SearchField from "../components/SearchField";
import SearchHelp from "../components/SearchHelp";
import Title from "../components/Title";
import { ApplicationSearchKeywordEnum } from "../model/enums";
import { Permissions } from "../model/permission";
import { emptySearchResults, SearchParams } from "../model/search";
import { ApplicationSummary, SearchResults } from "../model/types";
import * as applicationSearch from "../reducers/applicationSearch";
import * as auth from "../reducers/auth";
import { locationQueryParams } from "../utils/queryParams";
import { Opt, safeParseInt } from "../utils";
import LabelDropdown from "../components/LabelDropdown";
import { PAGE_SIZE } from "../constants";

type ApplicationListPageProps = {};

type AllApplicationListPageProps = ApplicationListPageProps & {
  location: Location;
  search: (school: Opt<string>, params: SearchParams) => Promise<SearchResults<ApplicationSummary>>;
  reset: () => void;
  results: pot.Pot<SearchResults<ApplicationSummary>>;
  downloadCsvUrl: (query: applicationSearch.DownloadCsvUrlOptions) => string;
  permissions: Permissions;
};

interface PageIndicatorProps<A> {
  items: A[];
  start: number;
  total: number;
}

function PageIndicator<A>({ items, start, total }: PageIndicatorProps<A>) {
  return (
    <Segment basic vertical textAlign="center">
      {items.length > 0 ? `Showing results ${start + 1} to ${start + items.length} of ${total}` : "No results"}
    </Segment>
  );
}

class ApplicationListPage extends React.Component<AllApplicationListPageProps> {
  handleDownloadClick = (options: applicationSearch.DownloadCsvUrlOptions) => {
    const { q, sortby, sortdir } = locationQueryParams(this.props.location);
    window.location.href = this.props.downloadCsvUrl({
      q,
      sortby,
      sortdir,
      ...options,
    });
  };

  pageSize = () => {
    const { count: countString } = locationQueryParams(this.props.location);
    return safeParseInt(countString, PAGE_SIZE);
  };

  render = () => {
    const { permissions, search, reset } = this.props;

    const results = pot.getOrElse(this.props.results, emptySearchResults());
    const fetching = pot.fetching(this.props.results);
    const fetchError = pot.error(this.props.results);

    const canDownloadCsv = true;
    const canCreateAppn = permissions.canCreateApplicants();

    return (
      <Title title="Applications">
        <Container fluid>
          <Segment padded basic>
            <Grid stackable columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <FieldLabel>
                    <SearchField searchBySchool search={search} reset={reset} permissions={permissions} />
                  </FieldLabel>
                </Grid.Column>
                <Grid.Column>
                  <Grid columns="equal">
                    <Grid.Row>
                      <Grid.Column>
                        <LabelDropdown />
                      </Grid.Column>
                      <Grid.Column>
                        {canDownloadCsv && (
                          <FieldLabel>
                            <Dropdown className="icon" button fluid icon={null} text="Download CSV" style={{ textAlign: "center" }}>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  text={"Without Personal Data"}
                                  onClick={() =>
                                    this.handleDownloadClick({
                                      personal: false,
                                      notes: false,
                                    })
                                  }
                                />
                                <Dropdown.Item
                                  text={"Including Personal Data"}
                                  onClick={() =>
                                    this.handleDownloadClick({
                                      personal: true,
                                      notes: false,
                                    })
                                  }
                                />
                                <Dropdown.Item
                                  text={"Including Personal Data and Notes"}
                                  onClick={() =>
                                    this.handleDownloadClick({
                                      personal: true,
                                      notes: true,
                                    })
                                  }
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </FieldLabel>
                        )}
                      </Grid.Column>
                      {canCreateAppn && (
                        <Grid.Column>
                          <FieldLabel>
                            <Button fluid primary as={Link} to="/applicant/search">
                              Take a call
                            </Button>
                          </FieldLabel>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <SearchHelp keywords={ApplicationSearchKeywordEnum.entries} />

            {results.items.length > 0 && <PageIndicator items={results.items} start={results.start} total={results.total} />}

            <ApplicationList results={results} fetching={fetching} fetchError={fetchError} />

            <Pager results={this.props.results} pageSize={this.pageSize()} />
          </Segment>
        </Container>
      </Title>
    );
  };
}

export default connect(
  state => ({
    results: applicationSearch.results(state),
    fetching: applicationSearch.fetching(state),
    downloadCsvUrl: applicationSearch.downloadCsvUrl(state),
    permissions: auth.permissions(state),
  }),
  {
    search: applicationSearch.search,
    reset: applicationSearch.reset,
  },
)(ApplicationListPage);
