import React from "react";

import { Button, Dropdown, Icon, Table } from "semantic-ui-react";

import { School, SchoolCode, Role, UserRole, isGlobalPermissionType } from "../model/types";

import SchoolCheckButtons from "./SchoolCheckButtons";

interface MaybeRoleAndSchools {
  role: Role | null; // During editing, a role may not have been set
  schools: SchoolCode[];
}

interface State {
  userRoles: MaybeRoleAndSchools[];
}

interface Props {
  allSchools: School[];
  allRoles: Role[];
  userRoles: UserRole[]; // All the roles the user has when loaded will def. have a Role
  defaultSchoolCode: SchoolCode | undefined | null; // What school to pre-select when adding a new role?
  onChange: (userRoles: MaybeRoleAndSchools[]) => void;
}

/**
 * A table that allows the user to edit the roles and associated schools of a user.
 */
export default class UserRoleEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    const state: State = {
      userRoles: this.props.userRoles,
    };
    this.setState(state);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const state: State = {
      userRoles: nextProps.userRoles,
    };
    this.setState(state);
  }

  newRoleRow = () => {
    this.setState({
      userRoles: [
        ...this.state.userRoles,
        {
          role: null,
          schools: this.props.defaultSchoolCode ? [this.props.defaultSchoolCode] : [],
        },
      ],
    });
  };

  addSchooldToUserRole = (index: number, school: School) => {
    this.setState(
      // Update local component state, triggering a re-render:
      {
        userRoles: [...this.state.userRoles].map((userRole, i) =>
          i === index
            ? {
                role: userRole.role,
                schools: [...userRole.schools, school.code],
              }
            : userRole,
        ),
      },
      // And after state is updated, call the parent component's onChange function.
      //
      // This will also trigger a re-render of the parent component because props.userRoles will change.
      //
      // We could do away with the local state in this component, if UserEditor could handle
      // a null Role on UserRole[] AND still update the user.roles entry with any changing schools.
      // However, when we save the user, we MUST have a non-null role assigned,
      // and making role optional on user seems equally unpleasant.
      //
      // NB: that when adding a new role row (previous function) we don't call the parent onChange.
      // That will get called anyway when roles or schools are added.
      () => (this.state.userRoles[index].role ? this.props.onChange(this.state.userRoles) : {}),
    );
  };

  removeSchoolFromUserRole = (index: number, school: School) => {
    this.setState(
      {
        userRoles: [...this.state.userRoles].map((userRole, i) =>
          i === index
            ? {
                role: userRole.role,
                schools: userRole.schools.filter(sc => sc !== school.code),
              }
            : userRole,
        ),
      },
      () => (this.state.userRoles[index].role ? this.props.onChange(this.state.userRoles) : {}),
    );
  };

  setRole = (index: number, roleId: number) => {
    this.setState(
      {
        userRoles: [...this.state.userRoles].map((userRole, i) =>
          i === index
            ? {
                role: this.props.allRoles.find(r => r.id === roleId) || null,
                schools: userRole.schools,
              }
            : userRole,
        ),
      },
      () => this.props.onChange(this.state.userRoles),
    );
  };

  deleteRoleRow = (index: number) => () => {
    this.props.onChange(this.state.userRoles.filter((_userRole, i) => i !== index));
  };

  render() {
    const { allSchools, allRoles } = this.props;
    const { userRoles } = this.state;

    const usedRole = (role: Role) => userRoles.find(ur => ur.role?.id === role.id);

    const allRolesUsed: boolean = allRoles.every(usedRole);

    const containsOnlyNonSchoolPermissions = (role: Role) => role.permissions.every(isGlobalPermissionType);

    return (
      <Table verticalAlign="top">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Role</Table.HeaderCell>
            <Table.HeaderCell width={8}>Schools</Table.HeaderCell>
            <Table.HeaderCell width={2}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userRoles.map((userRole, index) => (
            <Table.Row verticalAlign="top" key={index}>
              <Table.Cell>
                <Dropdown
                  fluid
                  placeholder="Select Role"
                  value={userRole.role ? userRole.role.id : -1}
                  options={allRoles
                    .filter(role => !usedRole(role) || role.id === userRole.role?.id)
                    .map((role, index) => ({
                      key: index,
                      value: role.id,
                      text: role.name,
                    }))}
                  onChange={(_event, { value }) => this.setRole(index, value as number)}
                />
              </Table.Cell>
              <Table.Cell textAlign="left">
                {userRole.role && containsOnlyNonSchoolPermissions(userRole.role) ? (
                  <span>No school selection required: role contains no school-specific permissions</span>
                ) : (
                  <SchoolCheckButtons
                    readOnly={!userRole.role || userRole.role.id < 0}
                    allSchools={allSchools}
                    selectedSchools={userRole.schools}
                    onExclude={(school: School) => this.removeSchoolFromUserRole(index, school)}
                    onInclude={(school: School) => this.addSchooldToUserRole(index, school)}
                  />
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button basic icon onClick={this.deleteRoleRow(index)}>
                  <Icon name="delete" />
                </Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell>
              <Button basic icon onClick={this.newRoleRow} disabled={allRolesUsed}>
                <Icon name="plus" />
              </Button>
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}
