import React, { Component, MouseEvent } from "react";
import { connectModal } from "redux-modal";
import { Modal, Header, Button } from "semantic-ui-react";
import { ConnectModalProps } from "../utils/modal";
import { ProgrammeCode } from "../model/programme";

interface Props extends ConnectModalProps {
  onPrimaryClick: (e: MouseEvent) => void;
  onSecondaryClick?: (e: MouseEvent) => void;
  oldProgrammeCode: ProgrammeCode;
  newProgrammeCode: ProgrammeCode;
}

export default connectModal({ name: "changeProgrammeConfirm" })(
  class ModalConfirmChangeOfProgramme extends Component<Props> {
    onCancelClick = (_e: MouseEvent) => {
      this.props.handleHide();
    };

    onPrimaryClick = (e: MouseEvent) => {
      this.props.onPrimaryClick(e);
      this.props.handleHide();
    };

    onSecondaryClick = (e: MouseEvent) => {
      this.props.onSecondaryClick && this.props.onSecondaryClick(e);
      this.props.handleHide();
    };

    render() {
      const { show, oldProgrammeCode, newProgrammeCode } = this.props;

      return (
        <Modal open={show} onClose={this.props.handleHide} size="small">
          <Header icon="info circle" content="Change of programme" />
          <Modal.Content>
            <p>Has the applicant already selected {oldProgrammeCode} on UCAS track?</p>

            <p>
              If so, the School will need to accept them onto {oldProgrammeCode} and then file a change of programme form to get them onto{" "}
              {newProgrammeCode}.
            </p>

            <p>
              The School Office will know how to handle this, but you should inform the applicant that UCAS Track will show that they have been
              accepted onto {oldProgrammeCode} first before they are transferred to {newProgrammeCode}.
            </p>

            <p>If the applicant has not selected {oldProgrammeCode} on UCAS Track, no change of programme form will be required.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onCancelClick}>Cancel</Button>
            <Button primary onClick={this.onPrimaryClick}>
              Yes they have (saves the application)
            </Button>
            <Button primary onClick={this.onSecondaryClick}>
              No they have not
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
  },
);
