import { Message } from "@qmspringboard/shared/dist/model/core.generated";
import { ALevelEquivalence, ALevelEquivalenceEnum } from "@qmspringboard/shared/dist/model/enums";
import {
  Condition,
  DEFAULT_A2GRADE,
  DEFAULT_CONDITION,
  DEFAULT_IBGRADE,
  DEFAULT_REQUIRED_SUBJECT_NO_GRADE,
  DEFAULT_REQUIREMENTS_CASE,
  OverallRequirement,
  RequiredSubject,
  Requirements,
  RequirementsAlternative,
} from "@qmspringboard/shared/dist/model/requirements";
import update from "immutability-helper";
import range from "lodash/range";
import React from "react";
import { Button, Form, Grid, Icon, Segment, SemanticICONS, Table } from "semantic-ui-react";
import { indexedErrors, subErrors } from "../model/errors";
import { assertNever } from "../utils";
import { EnglishRequirementsEditor } from "./EnglishRequirementsEditor";
import ErrorDisplay from "./ErrorDisplay";
import { BooleanRadioField, DropdownField, DropdownOptions, FieldLabel, NullableNumberInput, NullableTextArea } from "./fields";
import { ALevelGradeEditor, ALevelGradesEditor, BtecGradesEditor, IBGradeEditor, IBGradesEditor } from "./RequirementsGrades";
import SubjectDropdown from "./SubjectDropdown";

export interface RequirementsEditorProps {
  readOnly: boolean;
  value: Requirements;
  messages: Message[];
  onChange: (newValue: Requirements) => void;
}

interface NodeProps<NodeType> {
  readOnly: boolean;
  path: string;
  value: NodeType;
  depth: number;
  messages: Message[];
  onChange: (newValue: NodeType) => void;
}

const equivalenceOptions: DropdownOptions<ALevelEquivalence> = [
  {
    key: "Strict",
    value: ALevelEquivalenceEnum.Strict,
    label: "Strict",
    content: (
      <div>
        <h4>Strict Equivalence</h4>
        <p>Strict equivalence for overall A2 checks (e.g. BBB grades would not meet ABC requirements)</p>
      </div>
    ),
  },
  {
    key: "Rough",
    value: ALevelEquivalenceEnum.Rough,
    label: "Rough",
    content: (
      <div>
        <h4>Rough Equivalence</h4>
        <p>Rough Rquivalence for overall A2 checks (e.g. BBB grades would meet ABC requirements)</p>
      </div>
    ),
  },
];

const numberOfSubjectOptions: (max: number) => DropdownOptions<number> = max =>
  range(max).map(i => ({
    value: i + 1,
    label: `Must have ${i + 1} of the following subjects`,
  }));

const IconButton = ({ icon = "remove", ...passProps }: { icon?: SemanticICONS; disabled?: boolean; onClick?: () => void }) => (
  <Button basic icon {...passProps}>
    <Icon name={icon} />
  </Button>
);

function OverallRequirementEditor({ value, onChange, messages, ...passProps }: NodeProps<OverallRequirement>) {
  const splitErrors = subErrors(messages, {
    a2Grades: true,
    btecGrades: true,
    ibGrades: true,
  });

  return (
    <Table verticalAlign="top">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={4}></Table.HeaderCell>
          <Table.HeaderCell width={2}>A2s</Table.HeaderCell>
          <Table.HeaderCell width={2}>IB Higher</Table.HeaderCell>
          <Table.HeaderCell width={2}>IB Total</Table.HeaderCell>
          <Table.HeaderCell width={2}>BTEC</Table.HeaderCell>
          <Table.HeaderCell width={2}>Equivalence</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row verticalAlign="top">
          <Table.Cell width={4}>Minimum Grades</Table.Cell>
          <Table.Cell width={2}>
            <ALevelGradesEditor
              {...passProps}
              value={value.a2Grades}
              messages={splitErrors.a2Grades}
              onChange={a2Grades => onChange({ ...value, a2Grades })}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            <IBGradesEditor
              {...passProps}
              value={value.ibGrades}
              messages={splitErrors.ibGrades}
              onChange={ibGrades => onChange({ ...value, ibGrades })}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            <NullableNumberInput
              fluid
              value={value.ibPoints}
              placeholder="eg 32"
              onChange={ibPoints => {
                onChange({ ...value, ibPoints: ibPoints ?? 0 });
              }}
            />
          </Table.Cell>
          <Table.Cell width={2}>
            {value.btecGrades === null ? (
              <Button fluid size="medium" onClick={() => onChange({ ...value, btecGrades: [] })}>
                Add
              </Button>
            ) : null}
            {value.btecGrades ? (
              <BtecGradesEditor
                {...passProps}
                value={value.btecGrades}
                messages={splitErrors.btecGrades}
                onRemove={() => onChange({ ...value, btecGrades: null })}
                onChange={btecGrades => onChange({ ...value, btecGrades })}
              />
            ) : null}
          </Table.Cell>
          <Table.Cell width={4}>
            <DropdownField
              fluid
              value={value.aLevelEquivalence}
              options={equivalenceOptions}
              onChange={aLevelEquivalence => {
                onChange({ ...value, aLevelEquivalence });
              }}
            />
            <ErrorDisplay messages={splitErrors._rest_} />
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function ConditionEditor({
  value,
  onRemove,
  messages,
  path,
  onChange,
  ...passProps
}: NodeProps<Condition> & {
  onRemove: () => void;
  path: string;
}) {
  const { numberRequired, subjects } = value;

  const splitErrors = subErrors(messages, {
    conditions: true,
  });

  const conditionSplitErrors = indexedErrors(splitErrors.conditions, subjects.length);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ fontWeight: "normal" }}>
                  <DropdownField
                    value={numberRequired}
                    options={numberOfSubjectOptions(subjects.length)}
                    onChange={numberRequired => onChange({ ...value, numberRequired })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>A2</Table.HeaderCell>
                <Table.HeaderCell>IB</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  <Button basic icon onClick={onRemove}>
                    <Icon name="delete" />
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {subjects.map((subject, i) => (
                <Table.Row key={`${path}-${i}`} verticalAlign="top">
                  <RequiredSubjectEditor
                    {...passProps}
                    path={`${path}-${i}`}
                    value={subject}
                    messages={conditionSplitErrors[i]}
                    onChange={v => {
                      onChange(
                        update(value, {
                          subjects: { [i]: { $set: v } },
                        }),
                      );
                    }}
                  />
                  <Table.Cell textAlign="right">
                    <IconButton
                      icon="minus"
                      disabled={i === 0 && subjects.length === 1}
                      onClick={() => {
                        onChange(
                          update(value, {
                            numberRequired: {
                              $set: numberRequired > subjects.length - 1 ? numberRequired - 1 : numberRequired,
                            },
                            subjects: { $splice: [[i, 1]] },
                          }),
                        );
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            onClick={() => {
              onChange(
                update(value, {
                  subjects: { $push: [DEFAULT_REQUIRED_SUBJECT_NO_GRADE] },
                }),
              );
            }}
          >
            Add Another Subject
          </Button>
          <ErrorDisplay messages={splitErrors._rest_} />
          {/* <Button
                onClick={() => {
                  onChange(
                    update(value, {
                      conditions: { $push: [DEFAULT_REQUIRED_SUBJECT] },
                    }),
                  );
                }}
              >
                Add a Group of Subjects
              </Button> */}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function RequiredSubjectEditor({ value, messages, onChange, ...passProps }: NodeProps<RequiredSubject>) {
  switch (value.type) {
    case "RequiredSubjectNoGrade": {
      const handleAddGrade = () => {
        onChange({
          type: "RequiredSubjectAtGrade",
          a2Grade: DEFAULT_A2GRADE,
          ibGrade: DEFAULT_IBGRADE,
          subject: value.subject,
        });
      };
      return (
        <>
          <Table.Cell>
            <SubjectDropdown
              qualification={"Other"}
              value={value.subject}
              onChange={name =>
                onChange({
                  ...value,
                  subject: name ?? "",
                })
              }
            />
            <ErrorDisplay attached messages={messages} />
          </Table.Cell>
          <Table.Cell colSpan={2}>
            <Button fluid size="medium" onClick={handleAddGrade}>
              Add Min Grades
            </Button>
          </Table.Cell>
        </>
      );
    }
    case "RequiredSubjectAtGrade": {
      // downgrade us to RequiredSubject
      const handleRemove = () => {
        onChange({
          type: "RequiredSubjectNoGrade",
          subject: value.subject,
        });
      };
      const splitErrors = subErrors(messages, {
        grade: true,
      });
      return (
        <>
          <Table.Cell>
            <SubjectDropdown
              qualification="Other"
              value={value.subject}
              onChange={name =>
                onChange({
                  ...value,
                  subject: name ?? "",
                })
              }
            />
            <ErrorDisplay messages={splitErrors._rest_} />
          </Table.Cell>
          <Table.Cell>
            <ALevelGradeEditor
              {...passProps}
              onRemove={handleRemove}
              messages={splitErrors.grade}
              value={value.a2Grade}
              onChange={a2Grade => onChange({ ...value, a2Grade })}
            />
          </Table.Cell>
          <Table.Cell>
            <IBGradeEditor
              {...passProps}
              onRemove={handleRemove}
              messages={splitErrors.grade}
              value={value.ibGrade}
              onChange={ibGrade => onChange({ ...value, ibGrade })}
            />
          </Table.Cell>
        </>
      );
    }
    default: {
      assertNever(value);
    }
  }
}

function RootContainer({
  onRemove,
  actions,
  header,
  children,
}: React.PropsWithChildren<{
  onRemove?: () => void;
  header?: React.ReactNode;
  actions?: React.ReactNode;
}>) {
  return (
    <Segment>
      <Grid>
        {onRemove || header ? (
          <Grid.Row>
            {header ? <Grid.Column width={onRemove ? 14 : 16}>{header}</Grid.Column> : null}
            {onRemove ? (
              <Grid.Column width={header ? 2 : 16} textAlign="right">
                <IconButton onClick={onRemove} />
              </Grid.Column>
            ) : null}
          </Grid.Row>
        ) : null}
        {children ? (
          <Grid.Row>
            <Grid.Column width={16}>{children}</Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row>{actions}</Grid.Row>
      </Grid>
    </Segment>
  );
}

function RequirementsAlternativeEditor({
  value,
  onChange,
  onRemove,
  messages,
  path,
  depth,
  ...passProps
}: NodeProps<RequirementsAlternative> & {
  onRemove?: () => void;
}) {
  const { overall, conditions } = value;

  const splitErrors = subErrors(messages, {
    overall: true,
    conditions: true,
  });

  const conditionErrors = indexedErrors(splitErrors.conditions, conditions.length);

  const actions = (
    <Grid.Column>
      <Button
        onClick={() =>
          onChange(
            update(value, {
              conditions: { $push: [DEFAULT_CONDITION] },
            }),
          )
        }
      >
        Add a Condition
      </Button>
    </Grid.Column>
  );

  const header = (
    <OverallRequirementEditor
      {...passProps}
      depth={depth + 1}
      path={`${path}-grades`}
      value={overall}
      messages={splitErrors.overall}
      onChange={overall => onChange({ ...value, overall })}
    />
  );
  return (
    <>
      <RootContainer onRemove={onRemove} actions={actions} header={header}>
        {conditions.map((condition, index) => (
          <ConditionEditor
            key={index}
            {...passProps}
            depth={depth + 1}
            path={`${path}-condition`}
            onRemove={() => onChange(update(value, { conditions: { $splice: [[index, 1]] } }))}
            messages={conditionErrors[index]}
            value={condition}
            onChange={condition => onChange(update(value, { conditions: { [index]: { $set: condition } } }))}
          />
        ))}
      </RootContainer>
      <ErrorDisplay messages={splitErrors._rest_} />
    </>
  );
}

function RequirementsAlternativesEditor({ value, onChange, messages, ...passProps }: NodeProps<RequirementsAlternative[]>) {
  const splitErrors = indexedErrors(messages, value.length);

  return (
    <Grid>
      {value.map((alt, index) => (
        <Grid.Row key={index}>
          <Grid.Column width={16}>
            <RequirementsAlternativeEditor
              value={alt}
              onChange={alt => onChange(update(value, { [index]: { $set: alt } }))}
              onRemove={() => onChange(update(value, { $splice: [[index, 1]] }))}
              messages={splitErrors[index]}
              {...passProps}
            />
          </Grid.Column>
        </Grid.Row>
      ))}
      <Grid.Row>
        <Grid.Column width={16}>
          <Button onClick={() => onChange(update(value, { $push: [DEFAULT_REQUIREMENTS_CASE] }))}>Add Alternative Set of Requirements</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

function EntryRequirementsExtra({ value, readOnly, messages, onChange }: NodeProps<Requirements>) {
  const splitErrors = subErrors(messages, {
    english: true,
    maths: true,
  });

  return (
    <>
      <Grid.Row>
        <Grid.Column width={16}>
          <FieldLabel label="English language requirements">
            <EnglishRequirementsEditor value={value.english} onChange={english => onChange({ ...value, english })} />
            <ErrorDisplay attached messages={splitErrors.english} />
          </FieldLabel>
        </Grid.Column>
        <Grid.Column width={16}>
          <FieldLabel label="Mathematics requirements">
            <BooleanRadioField
              value={value.maths}
              readOnly={readOnly}
              onChange={maths => onChange({ ...value, maths })}
              trueLabel="GCSE Maths grade B/5 or equivalent"
              falseLabel="No requirement"
            />
            <ErrorDisplay attached messages={splitErrors.maths} />
          </FieldLabel>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Form as="div">
            <FieldLabel label="Internal Notes">
              <NullableTextArea
                rows={10}
                value={value.internalNotes}
                disabled={readOnly}
                onChange={internalNotes => onChange({ ...value, internalNotes: internalNotes ?? null })}
              />
              <p>These notes are for office use and don&apos;t appear in other Springboard web pages or emails.</p>
            </FieldLabel>
          </Form>
          <ErrorDisplay messages={splitErrors._rest_} />
        </Grid.Column>

        <Grid.Column width={16}>
          <Form as="div">
            <FieldLabel label="Guidance Notes">
              <NullableTextArea
                rows={5}
                value={value.guidanceNotes}
                disabled={readOnly}
                onChange={guidanceNotes => onChange({ ...value, guidanceNotes: guidanceNotes ?? null })}
              />
              <p>These appear next to the on-screen requirements when reporting on an applicant&apos;s eligibility.</p>
            </FieldLabel>
          </Form>
          <ErrorDisplay messages={splitErrors._rest_} />
        </Grid.Column>
      </Grid.Row>
    </>
  );
}

export default function RequirementsEditor({ value, readOnly, messages, onChange }: RequirementsEditorProps) {
  const splitErrors = subErrors(messages, {
    alternatives: true,
  });

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <RequirementsAlternativesEditor
            depth={0}
            path="gradeRequirements"
            messages={splitErrors.alternatives}
            readOnly={readOnly}
            value={value.alternatives}
            onChange={alternatives => onChange({ ...value, alternatives })}
          />
        </Grid.Column>
      </Grid.Row>
      <EntryRequirementsExtra depth={0} path="extra" messages={splitErrors._rest_} readOnly={readOnly} value={value} onChange={onChange} />
    </Grid>
  );
}
