import { addDays, addHours, getMinutes, getSeconds, setMinutes, setSeconds } from "date-fns";
import { formatIso, setEuropeLondonHours } from "../utils/date";
import Opt from "../utils/opt";
import { applicationChoiceToMethod } from "./application";
import { ApplicationChoice, OfferExpiry, OfferStatus, SchoolExpirySettings, HourAndMinute } from "./types";

function roundMins(date: Date): Date {
  const mins = getMinutes(date);

  if (mins === 0 || mins === 15 || mins === 30 || mins === 45) {
    return setSeconds(date, 0);
  } else if (mins < 15) {
    return setSeconds(setMinutes(date, 15), 0);
  } else if (mins < 30) {
    return setSeconds(setMinutes(date, 30), 0);
  } else if (mins < 45) {
    return setSeconds(setMinutes(date, 45), 0);
  } else {
    return setSeconds(setMinutes(addHours(date, 1), 0), 0);
  }
}

function roundHours(date: Date): Date {
  const mins = getMinutes(date);
  const secs = getSeconds(date);

  if (mins === 0 && secs === 0) {
    return date;
  } else {
    return setSeconds(setMinutes(addHours(date, 1), 0), 0);
  }
}

export function calcExpiry(value: OfferExpiry, startOfDay: HourAndMinute, endOfDay: HourAndMinute, now: Date): Date {
  switch (value) {
    case "HOUR1":
      return roundMins(addHours(now, 1));

    case "HOUR2":
      return roundMins(addHours(now, 2));

    case "HOUR3":
      return roundMins(addHours(now, 3));

    case "HOUR4":
      return roundMins(addHours(now, 4));

    case "HOUR6":
      return roundMins(addHours(now, 6));

    case "DAY1":
      return roundHours(addDays(now, 1));

    case "DAY2":
      return roundHours(addDays(now, 2));

    case "DAY3":
      return roundHours(addDays(now, 3));

    case "DAY5":
      return roundHours(addDays(now, 5));

    case "DAY7":
      return roundHours(addDays(now, 7));

    case "EOD":
      return setMinutes(setEuropeLondonHours(now, endOfDay[0]), endOfDay[1]);

    case "SOD":
      return setMinutes(setEuropeLondonHours(addDays(now, 1), startOfDay[0]), startOfDay[1]);
  }
}

function clearingStandardOfferExpiry(value: OfferExpiry, settings: SchoolExpirySettings, now: Date): Date {
  return calcExpiry(value, settings.clearingStandardOfferStartOfDay, settings.clearingStandardOfferEndOfDay, now);
}

function clearingPreReleaseOfferExpiry(value: OfferExpiry, settings: SchoolExpirySettings, now: Date): Date {
  return calcExpiry(value, settings.clearingPreReleaseOfferStartOfDay, settings.clearingPreReleaseOfferEndOfDay, now);
}

export function defaultOfferExpiry(
  choice: ApplicationChoice,
  offerStatus: Opt<OfferStatus>,
  settings: SchoolExpirySettings,
  now: Date = new Date(),
): string | undefined {
  const method = applicationChoiceToMethod(choice);

  switch (method) {
    case "C":
      switch (offerStatus) {
        case "P":
          return formatIso(clearingPreReleaseOfferExpiry(settings.clearingPreReleaseDefaultExpiry, settings, now));

        case "M":
          return formatIso(clearingStandardOfferExpiry(settings.clearingStandardDefaultExpiry, settings, now));

        default:
          return undefined;
      }

    case "U":
      return undefined;
  }
}

export function customOfferExpiry(
  value: OfferExpiry,
  choice: ApplicationChoice,
  offerStatus: Opt<OfferStatus>,
  settings: SchoolExpirySettings,
  now: Date = new Date(),
): string | undefined {
  const method = applicationChoiceToMethod(choice);

  switch (method) {
    case "C":
      switch (offerStatus) {
        case "P":
          return formatIso(clearingPreReleaseOfferExpiry(value, settings, now));

        case "M":
          return formatIso(clearingStandardOfferExpiry(value, settings, now));

        default:
          return undefined;
      }

    case "U":
      return undefined;
  }
}
