import React from "react";
import { Container, Divider, Header, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { ApplicationChoiceEnum, OfferStatus, OfferStatusEnum } from "../model/enums";
import { subErrors } from "../model/errors";
import ClearingOfferStatusEditor from "./ClearingOfferStatusEditor";
import ErrorDisplay from "./ErrorDisplay";
import ProgrammeSelectionEditor from "./ProgrammeSelectionEditor";
import { ApplicationEditorProps } from "./UcasApplicationEditor";
import UCASStatusEditor, { ShowFields } from "./UcasStatusEditor";
import EntryPointEditor from "@qmspringboard/app/src/components/EntryPointEditor";

const PaddedHeader = styled(Header)`
  padding-top: 8px !important;
`;

export default function ClearingApplicationEditor(props: ApplicationEditorProps) {
  const {
    editorTitle,
    allProgrammes,
    permissions,
    qualifications,
    guessedFeeCategory,
    value,
    initialValue,
    readOnly,
    onChange,
    canRemove,
    handleRemove,
    messages,
    emailMessages,
    startProgrammeChange,
    completeProgrammeChange,
    cancelProgrammeChange,
    school,
  } = props;

  const applicationSaved = value.id > 0; // use some other indicator here maybe?

  let statusesToShow: OfferStatus[] = [];
  let statusFieldsToShow: ShowFields[] = [];
  switch (value.choice) {
    case ApplicationChoiceEnum.Clearing:
      statusesToShow = applicationSaved
        ? [
            OfferStatusEnum.Made,
            OfferStatusEnum.MadePreRelease,
            OfferStatusEnum.Interview,
            OfferStatusEnum.Accepted,
            OfferStatusEnum.Confirmed,
            OfferStatusEnum.SelfReferral,
            OfferStatusEnum.OnlineReferral,
            OfferStatusEnum.OnHold,
            OfferStatusEnum.WaitingList,
            OfferStatusEnum.Declined,
            OfferStatusEnum.Rejected,
          ]
        : [
            OfferStatusEnum.Made,
            OfferStatusEnum.MadePreRelease,
            OfferStatusEnum.Interview,
            OfferStatusEnum.OnHold,
            OfferStatusEnum.WaitingList,
            OfferStatusEnum.Declined,
            OfferStatusEnum.Rejected,
          ];
      statusFieldsToShow = applicationSaved ? ["CLEARING"] : ["REJECT_REASON"];
      break;
    default:
    // whistles
  }

  if (applicationSaved) {
    statusFieldsToShow.push("SELECTOR_LIST_KEY");
  }

  const canEditSitsFields = permissions.canUpdateUcasSitsFields(school);

  const splitErrors = subErrors(messages, {
    programmeCode: true,
    offerStatus: true,
    offerExpiry: true,
  });

  return (
    <div className="ui form">
      {canRemove && (
        <Container textAlign="right">
          <Icon link size="large" name="remove" onClick={handleRemove(value)} />
        </Container>
      )}
      <PaddedHeader>{editorTitle}</PaddedHeader>
      <Divider hidden />
      <ErrorDisplay messages={splitErrors._rest_} />
      <ErrorDisplay messages={emailMessages} />
      <ProgrammeSelectionEditor
        value={value}
        initialValue={initialValue}
        readOnly={readOnly}
        onChange={onChange}
        allProgrammes={allProgrammes}
        qualifications={qualifications}
        guessedFeeCategory={guessedFeeCategory}
        messages={splitErrors.programmeCode}
        startProgrammeChange={startProgrammeChange}
        completeProgrammeChange={completeProgrammeChange}
        cancelProgrammeChange={cancelProgrammeChange}
      />
      <ClearingOfferStatusEditor
        value={value}
        initialValue={initialValue}
        readOnly={readOnly}
        allProgrammes={allProgrammes}
        permissions={permissions}
        onChange={onChange}
        statusesToShow={statusesToShow}
        messages={splitErrors.offerStatus.concat(splitErrors.offerExpiry)}
        guessedFeeCategory={guessedFeeCategory}
      />
      <br />
      <EntryPointEditor value={value} readOnly={readOnly || !canEditSitsFields} onChange={onChange} />
      {statusFieldsToShow.length > 0 && (
        <UCASStatusEditor
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          showFields={statusFieldsToShow}
          messages={splitErrors._rest_}
          school={school}
          permissions={permissions}
        />
      )}
    </div>
  );
}
