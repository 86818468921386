import { push } from "connected-react-router";
import { Location } from "history";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import useInterval from "react-useinterval";
import { Dimmer, Loader, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import Title from "../../components/Title";
import { TeamCode } from "../../model/team";
import type { PredictionDTO, School } from "../../model/types";
import * as predictions from "../../reducers/predictions";
import { PredictionParams } from "../../reducers/predictions/state";
import * as teams from "../../reducers/teams";
import { Opt } from "../../utils";
import { updateLocation } from "../../utils/queryParams";
import ColumnsToolbar from "./ColumnsToolbar";
import DataRow from "./DataRow";
import HeaderRows from "./HeaderRows";
import ParamsToolbar from "./ParamsToolbar";
import { formatQueryStringState, queryStringState } from "./qss";
import { tableRows } from "./row";
import { QueryStringState, Row } from "./type";

const REFRESH_TTL = 60; // seconds

interface PredictionsPageProps {
  dto: Opt<PredictionDTO>;
  fetching: boolean;
  fetch: (params: PredictionParams) => void;
  location: Location;
  push: (loc: Location) => void;
  schools: School[];
  currentTeam: Opt<TeamCode>;
}

const ResponsiveTable = styled.div`
  font-size: 75%;
  margin-bottom: 2em;
  overflow-x: auto;
`;

function PredictionsPage(props: PredictionsPageProps): ReactElement | null {
  const { dto, fetching, fetch, location, push, schools, currentTeam } = props;

  const qss = useMemo(() => queryStringState(location, currentTeam), [location, currentTeam]);

  const [ttl, setTtl] = useState(REFRESH_TTL); // seconds

  useInterval(() => {
    if (ttl <= 0) {
      fetch(queryStringState(location, currentTeam));
      setTtl(REFRESH_TTL);
    } else {
      setTtl(ttl - 1);
    }
  }, 1000);

  useEffect(() => {
    fetch(queryStringState(location, currentTeam));
  }, [fetch, location, currentTeam]);

  const pushQss = useCallback(
    (state: QueryStringState) => {
      push(
        updateLocation(location, {
          search: formatQueryStringState(location, state),
        }),
      );
    },
    [push, location],
  );

  const { fees } = qss;

  const bodyRows: Row[] = useMemo(() => (dto ? tableRows(fees, dto.rows) : []), [dto, fees]);

  const totalRows: Row[] = useMemo(() => (dto && dto.total ? tableRows(fees, [dto.total]) : []), [dto, fees]);

  return (
    <Title title="Predictions">
      <Segment basic>
        <ParamsToolbar qss={qss} pushQss={pushQss} schools={schools} />
        <ColumnsToolbar qss={qss} pushQss={pushQss} />

        <Dimmer.Dimmable dimmed={fetching}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          {dto && (
            // Instead of embedding <DataRow> components in the render tree,
            // we call DataRow() as a function.
            // This speeds up the page because React doesn't have to diff every component.
            <ResponsiveTable>
              <Table striped celled unstackable>
                <Table.Header>
                  <HeaderRows qss={qss} />
                  {totalRows.map(row => DataRow({ as: "th", qss, row }))}
                </Table.Header>
                {bodyRows.length > 0 && <Table.Body>{bodyRows.map((row, i) => DataRow({ key: i, as: "td", qss, row }))}</Table.Body>}
                {totalRows.length > 0 && <Table.Footer>{totalRows.map((row, i) => DataRow({ key: i, as: "th", qss, row }))}</Table.Footer>}
              </Table>
            </ResponsiveTable>
          )}
        </Dimmer.Dimmable>
        <Segment basic textAlign="center">
          <small>Page will refresh in {ttl} seconds.</small>
        </Segment>
      </Segment>
    </Title>
  );
}

export default connect(
  state => ({
    dto: predictions.data(state),
    fetching: predictions.fetching(state),
    schools: teams.allSchools(state),
    currentTeam: teams.currentTeam(state).code,
  }),
  {
    fetch: predictions.fetch,
    push,
  },
)(PredictionsPage);
