import { Message } from "@qmspringboard/shared/dist/model/core.generated";
import { A2Grade, BtecGrade, GcseGrade, IBGrade, isA2Grade, isGcseGrade, isIBGrade } from "@qmspringboard/shared/dist/model/qualifications";
import { splitA2Grades, splitBtecGrades, splitIBGrades } from "@qmspringboard/shared/dist/model/requirements";
import { Opt } from "@qmspringboard/shared/dist/utils";
import React from "react";
import { NullableTextInput } from "./fields";

type GradeProps<GradeType> = {
  label?: string;
  readOnly: boolean;
  value: GradeType;
  messages: Message[];
  onChange: (newValue: GradeType) => void;
  placeholder?: string;
  onRemove?: () => void;
};

function GradeEditor(props: GradeProps<Opt<string>>) {
  const { value, onChange, onRemove, label, placeholder, messages, readOnly } = props;

  return (
    <NullableTextInput
      value={value}
      readOnly={readOnly}
      onChange={onChange}
      fluid
      label={label}
      labelPosition={label ? "right" : undefined}
      action={onRemove ? { onClick: onRemove, icon: "trash" } : undefined}
      placeholder={placeholder}
      messages={messages}
    />
  );
}

export function GCSEGradeEditor({ onChange, value, ...passProps }: GradeProps<GcseGrade>) {
  const handleChange = (v: Opt<string>) => {
    if (v) {
      const cased = v.toUpperCase();
      if (isGcseGrade(cased)) {
        onChange(cased);
      } else {
        console.log(`not a valid gcse ${cased}`);
      }
    }
  };
  return <GradeEditor {...passProps} onChange={handleChange} value={value} />;
}

export function ALevelGradeEditor({ onChange, value, ...passProps }: GradeProps<A2Grade>) {
  const handleChange = (v: Opt<string>) => {
    if (v) {
      const cased = v.toUpperCase();
      if (isA2Grade(cased)) onChange(cased);
    }
  };
  return <GradeEditor {...passProps} onChange={handleChange} value={value} />;
}

export function IBGradeEditor({ onChange, value, ...passProps }: GradeProps<IBGrade>) {
  const handleChange = (v: Opt<string>) => {
    if (v) {
      if (isIBGrade(v)) onChange(v);
    }
  };
  return <GradeEditor {...passProps} onChange={handleChange} value={value} />;
}

export function ALevelGradesEditor({ onChange, value, ...passProps }: GradeProps<A2Grade[]>) {
  const handleChange = (newGrades: Opt<string>) => {
    onChange(splitA2Grades(newGrades || ""));
  };
  return <GradeEditor {...passProps} value={value.join("")} placeholder="eg AAB" onChange={handleChange} />;
}

export function IBGradesEditor({ onChange, value, ...passProps }: GradeProps<IBGrade[]>) {
  const handleChange = (newGrades: Opt<string>) => {
    onChange(splitIBGrades(newGrades || ""));
  };
  return <GradeEditor {...passProps} value={value.join("")} placeholder="eg 665" onChange={handleChange} />;
}

export function BtecGradesEditor({ onChange, value, ...passProps }: GradeProps<BtecGrade[]>) {
  const handleChange = (newGrades: Opt<string>) => {
    onChange(splitBtecGrades(newGrades || ""));
  };
  return <GradeEditor {...passProps} value={value.join("")} placeholder="eg DDM" onChange={handleChange} />;
}
