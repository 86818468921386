import Opt from "@qmspringboard/shared/dist/utils/opt";
import React, { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import {
  Dimmer,
  Divider,
  Grid,
  Header,
  Loader,
  Segment,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import Title from "../components/Title";
import { A2Grade, InternationalEquiv } from "../model/types";
import * as ie from "../reducers/internationalEquivs";

interface IePageProps {
  dto: Opt<InternationalEquiv[]>;
  fetching: boolean;
  fetch: () => void;
}

function InternationalEquivsPage(props: IePageProps): ReactElement | null {
  const { dto: data, fetching, fetch } = props;

  useEffect(() => {
    fetch();
  }, [fetch]);

  const byBoardName = (a: InternationalEquiv, b: InternationalEquiv) => a.boardName.localeCompare(b.boardName);

  const overallTable = (keyPrefix: string, overall: [A2Grade[], string][]) => (
    <>
      <Header size="tiny">Overall grades</Header>
      <Table celled compact collapsing size="small">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>A2 Grades</TableHeaderCell>
            <TableHeaderCell>Equivalent</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {overall.map(([grades, equiv]) => (
            <TableRow key={keyPrefix + "overall" + grades.join("")}>
              <TableCell>{grades.join(" ")}</TableCell>
              <TableCell>{equiv}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );

  const subjectTable = (keyPrefix: string, overall: [A2Grade, string][]) => (
    <>
      <Header size="tiny">Subject grades</Header>
      <Table celled compact collapsing size="small">
        <TableHeader>
          <TableRow>
            <TableHeaderCell>A2</TableHeaderCell>
            <TableHeaderCell>Equivalent</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {overall.map(([grade, equiv]) => (
            <TableRow key={keyPrefix + "subject" + grade}>
              <TableCell>{grade}</TableCell>
              <TableCell>{equiv}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );

  return (
    <Title title="International Equivalences">
      <Segment basic>
        <Dimmer.Dimmable dimmed={fetching}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          {data &&
            data.sort(byBoardName).map(ie => (
              <>
                <Header key={ie.boardName}>{ie.boardName}</Header>
                <Grid columns={2} key={ie.boardName + "grid"}>
                  <Grid.Column width={4} key={ie.boardName + "subjectsColumn"}>
                    {subjectTable(ie.boardName, ie.subjects)}
                  </Grid.Column>
                  <Grid.Column width={4} key={ie.boardName + "overallColumn"}>
                    {ie.overall && overallTable(ie.boardName, ie.overall)}
                  </Grid.Column>
                </Grid>
                <Divider />
              </>
            ))}
        </Dimmer.Dimmable>
      </Segment>
    </Title>
  );
}

export default connect(
  state => ({
    dto: ie.data(state),
    fetching: ie.fetching(state),
  }),
  {
    fetch: ie.fetch,
  },
)(InternationalEquivsPage);
