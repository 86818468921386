import { initialState as initialApplicationSearchState, MyState as ApplicationSearchState } from "../applicationSearch/state";
import { initialState as initialReportingGroupSearchState, MyState as ReportingGroupSearchState } from "../reportingGroupSearch/state";
import { initialState as initialLabelPrototypeSearchState, MyState as LabelPrototypeSearchState } from "../labelPrototypeSearch/state";
import { initialState as initialProgrammeListState, MyState as ProgrammeListState } from "../programmeList/state";
import { initialState as initialProgrammeSearchState, MyState as ProgrammeSearchState } from "../programmeSearch/state";
import { initialState as initialSavedSearchesState, MyState as SavedSearches } from "../savedSearches/state";
import { initialState as initialSchoolConfigState, MyState as SchoolConfigState } from "../schoolConfig/state";

export interface MyState {
  schoolConfig: SchoolConfigState;
  programmeList: ProgrammeListState;
  programmeSearch: ProgrammeSearchState;
  applicationSearch: ApplicationSearchState;
  reportingGroupSearch: ReportingGroupSearchState;
  labelPrototypeSearch: LabelPrototypeSearchState;
  savedSearches: SavedSearches;
}

export function initialState(): MyState {
  return {
    applicationSearch: initialApplicationSearchState,
    reportingGroupSearch: initialReportingGroupSearchState,
    labelPrototypeSearch: initialLabelPrototypeSearchState,
    programmeList: initialProgrammeListState,
    programmeSearch: initialProgrammeSearchState,
    savedSearches: initialSavedSearchesState,
    schoolConfig: initialSchoolConfigState,
  };
}
