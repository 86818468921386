import { RefObject, useCallback, useEffect } from "react";

// Taken from this SO post:
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
//
// Demo:
// https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js:862-872

export default function useClickOutside(ref: RefObject<HTMLElement>, callback: () => void) {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (e.target != null && !ref.current?.contains(e.target as Node)) {
        callback();
      }
    },
    [ref, callback],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handleClickOutside]);

  return handleClickOutside;
}
