import React from "react";
import { Form, Grid } from "semantic-ui-react";
import { Messages, subErrors } from "../model/errors";
import { withAllTeams } from "../reducers/teams";
import ErrorDisplay from "./ErrorDisplay";
import { FieldLabel, TextInput } from "./fields";
import { Role } from "../model/types.generated";

interface Props {
  value: Role;
  messages: Messages;
  onChange: (value: Role) => void;
  readOnly: boolean;
}

export default withAllTeams(function LabelPrototypeEditor(props: Props) {
  const { value, messages, readOnly, onChange } = props;

  const splitErrors = subErrors(messages, {
    name: true,
  });

  return (
    <Form as="div">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label={"Role Name"}>
              <TextInput value={value.name} readOnly={readOnly} onChange={name => onChange({ ...value, name })} />

              <ErrorDisplay attached messages={splitErrors.name} />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
});
