import { raise } from "@qmspringboard/shared/dist/utils/raise";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { programmeCodeToString, programmeRule } from "../../model/programme";
import { Checked, Message, Programme, ProgrammeEditorDTO, unsafeTag } from "../../model/types";
import { AppDispatch, AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { showModal } from "../modal";
import { AppState, GetState } from "../state";
import * as teams from "../teams";
import { emptyProgramme, initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.route.programmeUpdate;

export const programme = (state: AppState): ProgrammeEditorDTO => myState(state).programmeDTO;

export const isNew = (state: AppState): boolean => myState(state).creating;

export const messages = (state: AppState): Message[] => myState(state).clientMessages.concat(myState(state).serverMessages);

export const fetching = (state: AppState): boolean => myState(state).fetching;

export const fetched = (state: AppState): boolean => myState(state).fetched;

/////////////
// Actions //
/////////////

const INITIALISE = "programmeUpdate/INITIALISE";
const LOAD_START = "programmeUpdate/LOAD_START";
const LOAD_SUCCESS = "programmeUpdate/LOAD_SUCCESS";
const DELETE_START = "programmeUpdate/DELETE_START";
const DELETE_SUCCESS = "programmeUpdate/DELETE_SUCCESS";
const UPDATE = "programmeUpdate/UPDATE";
const SAVE_START = "programmeUpdate/SAVE_START";
const SAVE_SUCCESS = "programmeUpdate/SAVE_SUCCESS";
const SET_SERVER_MESSAGES = "programmeUpdate/SET_SERVER_MESSAGES";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  function clearServerMessages(state: MyState): MyState {
    return { ...state, serverMessages: [] };
  }

  function commitChanges(state: MyState) {
    return { ...state, originalProgramme: state.programmeDTO.programme };
  }

  function updateAndValidate(state: MyState, programmeDTO: ProgrammeEditorDTO): MyState {
    const clientMessages = programmeRule(programmeDTO.programme);
    return {
      ...state,
      programmeDTO: programmeDTO,
      clientMessages: clientMessages,
    };
  }

  switch (action.type) {
    case INITIALISE:
      return commitChanges(updateAndValidate({ ...state, fetching: false, creating: true, serverMessages: [] }, emptyProgramme(action.schoolCode)));
    case LOAD_START:
      return { ...state, fetching: true };
    case LOAD_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false, fetched: true, creating: false }, action.programmeDTO));
    case UPDATE:
      return clearServerMessages(
        updateAndValidate(state, {
          programme: action.programme,
          hasApplications: false,
        }),
      );
    case SAVE_START:
      return { ...state, fetching: true };
    case SAVE_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.programmeDTO));
    case SET_SERVER_MESSAGES:
      return { ...state, fetching: false, serverMessages: action.messages };
    default:
      return state;
  }
};
export default reducer;

/////////////////////
// Action Creators //
/////////////////////

export const initialise = () => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({
      type: INITIALISE,
      schoolCode: teams.currentSchoolCode(getState()) ?? unsafeTag<"SchoolCode">(""),
    });
  };
};

export function load(programmeCode: string) {
  return fetchWithMutex<ProgrammeEditorDTO>({
    mutex: "programmeLoad",

    request(dispatch, getState) {
      return api.fetchProgramme(api.fetchParams(getState()), programmeCode);
    },

    pending(dispatch, getState) {
      dispatch({ type: LOAD_START });
    },

    success(dispatch, getState, programmeDTO) {
      dispatch({ type: LOAD_SUCCESS, programmeDTO: programmeDTO });
    },
  });
}

export function del(programmeCode: string) {
  return fetchWithMutex({
    mutex: "programmeDelete",

    request(dispatch, getState) {
      return api.deleteProgramme(api.fetchParams(getState()), programmeCode);
    },

    pending(dispatch, getState) {
      dispatch({ type: DELETE_START, code: programmeCode });
    },

    success(dispatch, getState) {
      dispatch({ type: DELETE_SUCCESS, code: programmeCode });
      dispatch(push("/admin/programme"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}

export const update = (programme: Programme) => ({ type: UPDATE, programme });

function saveInternal(programmeDTO: ProgrammeEditorDTO, closeWaitingList: boolean) {
  return fetchWithMutex<Checked<Programme>>({
    mutex: "programmeSave",

    request(dispatch, getState) {
      const original = myState(getState()).originalProgramme;

      return programmeCodeToString(original.code).length > 0
        ? api.updateProgramme(api.fetchParams(getState()), programmeCodeToString(original.code), programmeDTO.programme, closeWaitingList)
        : api.insertProgramme(api.fetchParams(getState()), programmeDTO.programme);
    },

    pending(dispatch, getState) {
      dispatch({ type: SAVE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState, checked) {
      checked.data && toast.success("Programme saved");
      checked.data &&
        dispatch({
          type: SAVE_SUCCESS,
          programmeDTO: { ...programmeDTO, programme: checked.data },
        });
      checked.messages && dispatch({ type: SET_SERVER_MESSAGES, messages: checked.messages });
      checked.data && dispatch(push("/admin/programme"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}

export const save = (programmeDTO: ProgrammeEditorDTO) => {
  return (dispatch: AppDispatch, getState: GetState) => {
    const original = myState(getState()).originalProgramme;

    const closingHome = programmeDTO.programme.isClosedHome && !original.isClosedHome;
    const closingOverseas = programmeDTO.programme.isClosedOverseas && !original.isClosedOverseas;

    const closingAny = closingHome || closingOverseas;
    const closingAll = closingHome && closingOverseas;

    if (closingAny) {
      const applicantType = closingAll
        ? "all applicants"
        : closingHome
          ? "home applicants"
          : closingOverseas
            ? "overseas applicants"
            : raise(new Error("Can't determine what kind of waiting list we're closing"));

      dispatch(
        showModal("confirm", {
          title: `Close the waiting list to ${applicantType}?`,
          content: `
          Would you like to close the waiting list for ${applicantType} to this programme?
          Doing so will reject ${applicantType} on the waiting list and send each a confirmation email.
          The process is irreversible.
        `,

          primaryButtonText: "Yes - send rejection emails",
          secondaryButtonText: "No - just prevent new offers",
          onPrimaryClick: () => dispatch(saveInternal(programmeDTO, true)),
          onSecondaryClick: () => dispatch(saveInternal(programmeDTO, false)),
        }),
      );
    } else if (
      (original.isClosedHome && !programmeDTO.programme.isClosedHome) ||
      (original.isClosedOverseas && !programmeDTO.programme.isClosedOverseas)
    ) {
      dispatch(
        showModal("confirm", {
          title: "Re-open this programme?",
          content: `
          Reopening the programme will allow regular users to make offers again.
          However, any waiting list applicants who have already been rejected will remain rejected.
        `,
          onPrimaryClick: () => dispatch(saveInternal(programmeDTO, false)),
        }),
      );
    } else {
      dispatch(saveInternal(programmeDTO, false));
    }
  };
};

export const withProgramme = connect((state: AppState) => ({ programmeDTO: programme(state) }), { load });
