import { LOCATION_CHANGE } from "connected-react-router";
import { Location } from "history";
import { startsWith } from "lodash/fp";
import { combineReducers, Reducer } from "redux";
import { AppAction } from "../actions";
import applicantUpdate, { anythingDirty as applicantUpdateDirty } from "../applicantUpdate";
import reportingGroupUpdate from "../reportingGroupUpdate";
import labelPrototypeUpdate from "../labelPrototypeUpdate";
import selectorListSidebar from "../selectorListSidebar";
import offerExpirySettings from "../offerExpirySettings";
import predictions from "../predictions";
import snc from "../snc";
import internationalEquivs from "../internationalEquivs";
import programmeUpdate from "../programmeUpdate";
import school, { schoolDirtySelector } from "../school";
import { AppState } from "../state";
import userSearch from "../userSearch";
import userUpdate from "../userUpdate";
import roleSearch from "../roleSearch";
import roleUpdate from "../roleUpdate";
import { initialLastRouteState, initialState, LastRouteState, MyState } from "./state";

const contexts = ["/admin/settings", "/admin/selector-list"];

export function lastRoute(state: LastRouteState = initialLastRouteState, action: AppAction) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return { ...state, from: state.to, to: action.payload };

    default:
      return state;
  }
}

export const isSameContext = (oldLocation: Location, newLocation: Location): boolean => {
  if (oldLocation.pathname === newLocation.pathname) {
    return true;
  }
  return !!contexts.find(c => {
    const samePath = startsWith(c);
    return samePath(oldLocation.pathname) && samePath(newLocation.pathname);
  });
};

const routeReducer: Reducer<MyState, AppAction> = combineReducers({
  applicantUpdate,
  predictions,
  snc,
  internationalEquivs,
  userSearch,
  userUpdate,
  roleSearch,
  roleUpdate,
  programmeUpdate,
  reportingGroupUpdate,
  labelPrototypeUpdate,
  school,
  lastRoute,
  offerExpirySettings,
  selectorListSidebar,
});

export default (state: MyState = initialState, action: AppAction) => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const { lastRoute } = state;
      if (lastRoute.to == null || !isSameContext(lastRoute.to.location, action.payload.location)) {
        window.scrollTo(0, 0);
        return routeReducer({ ...initialState, lastRoute }, action);
      } else {
        return routeReducer(state, action);
      }
    }

    default:
      return routeReducer(state, action);
  }
};

export const rootSelector = (state: AppState): MyState => state.route;

export const routeDirtySelector = (state: AppState) => () => {
  return applicantUpdateDirty(state)() || schoolDirtySelector(state);
};
