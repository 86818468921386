import React from "react";
import { Button, Card, Label } from "semantic-ui-react";
import { AnnouncementDTO } from "../model/announcements.generated";
import DateTime from "./DateTime";

type Props = {
  className?: string;
  messages: AnnouncementDTO[];
  maxToPreview?: number;
  onAcknowledge?: () => void;
};

function Notification({ messages, onAcknowledge, className, maxToPreview = Infinity }: Props): React.ReactElement | null {
  const visible = messages.slice(0, maxToPreview);
  if (visible.length === 0) {
    return null;
  }
  const more = messages.length - maxToPreview;
  return (
    <Card fluid className={className}>
      {visible.map(({ authorName, headline, id, detail, timestamp }) => (
        <Card.Content key={id}>
          <Card.Header>{headline}</Card.Header>
          <Card.Description>{detail}</Card.Description>
          <Card.Meta>
            {authorName} at <DateTime iso={timestamp} />
          </Card.Meta>
        </Card.Content>
      ))}

      {more > 0 && (
        <Card.Content>
          <Card.Description>
            <Label size="medium" circular>
              + {more}
            </Label>{" "}
            more
          </Card.Description>
        </Card.Content>
      )}
      <Card.Content extra textAlign="right">
        {more > 0 ? (
          <Button as="a" href="/announcements" basic color="green">
            View All
          </Button>
        ) : (
          <Button onClick={onAcknowledge} basic color="green">
            {messages.length === 1 ? "Mark as Read" : "Mark all as Read"}
          </Button>
        )}
      </Card.Content>
    </Card>
  );
}

export default Notification;
