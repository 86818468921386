import React from "react";
import { Form, Radio } from "semantic-ui-react";
import { ReactNodeLike } from "../../utils";
import { BaseFieldProps, SemanticPointing } from "./base";
import FieldWrapper from "./FieldWrapper";

export interface BooleanRadioFieldProps extends BaseFieldProps<boolean> {
  trueLabel: ReactNodeLike;
  falseLabel: ReactNodeLike;
  errorPointing?: SemanticPointing;
  tabIndex?: number;
}

export default function BooleanRadioField(props: BooleanRadioFieldProps) {
  const { value, onChange, readOnly, disabled, messages, errorPointing, trueLabel, falseLabel } = props;

  return (
    <FieldWrapper readOnly={readOnly} messages={messages} errorPointing={errorPointing}>
      <Form.Field>
        <Radio checked={value !== true} label={falseLabel} disabled={readOnly || disabled} onChange={() => onChange?.(false)} />
      </Form.Field>
      <Form.Field>
        <Radio checked={value === true} label={trueLabel} disabled={readOnly || disabled} onChange={() => onChange?.(true)} />
      </Form.Field>
    </FieldWrapper>
  );
}
