import "codemirror/lib/codemirror.css";
import "codemirror/mode/handlebars/handlebars";
import "codemirror/mode/ruby/ruby";
import { debounce } from "lodash";
import React, { createRef, RefObject } from "react";
import RCM from "react-codemirror";
import CodeMirror from "react-codemirror";
import styled from "styled-components";
import { Messages } from "../../model/errors";
import FieldLabel from "./FieldLabel";
import FieldWrapper from "./FieldWrapper";
import "./RichTextArea.css";

const insertAtCursorLocation = (cursor: CodeMirror.Position, value: string, toInsert: string) => {
  const lines = value.split(/\r*\n/);
  const insertAtPos = lines.slice(0, cursor.line).reduce((a, l) => (a += l.length), 0) + cursor.ch + cursor.line;
  return value.slice(0, insertAtPos) + toInsert + value.slice(insertAtPos);
};

export interface RichTextAreaProps {
  value: string;
  onChange: (value: string) => void;
  config?: CodeMirror.EditorConfiguration;
  className?: string;
  label?: string;
  messages?: Messages;
}

const defaultEditorConfig: CodeMirror.EditorConfiguration = {
  lineWrapping: true,
  smartIndent: false,
  mode: "handlebars",
};

export class UnstyledRichTextArea extends React.Component<RichTextAreaProps> {
  cm: RefObject<RCM>;

  constructor(props: RichTextAreaProps) {
    super(props);
    this.cm = createRef();
  }

  insertAtCursor = (value: string) => {
    if (this.cm.current) {
      const codeMirror = this.cm.current.getCodeMirror();
      const cursor = codeMirror.getDoc().getCursor();
      const newValue = insertAtCursorLocation(cursor, this.props.value, value);
      this.props.onChange(newValue);
      codeMirror.getDoc().setValue(newValue);
    }
  };

  handleChange = debounce((content: string) => this.props.onChange(content), 500);

  render() {
    const { label, className, value, config, messages } = this.props;

    const editor = (
      <CodeMirror className={className} ref={this.cm} value={value} onChange={this.handleChange} options={config ?? defaultEditorConfig} />
    );

    return label == null ? (
      editor
    ) : (
      <FieldLabel label={label}>
        <FieldWrapper messages={messages} errorPointing="above">
          {editor}
        </FieldWrapper>
      </FieldLabel>
    );
  }
}

const RichTextArea = styled(UnstyledRichTextArea)`
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
`;

export default RichTextArea;
