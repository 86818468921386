import React, { KeyboardEvent, useCallback, useEffect, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { DOWN_ARROW_KEY, ENTER_KEY, UP_ARROW_KEY } from "../../constants";
import { Opt } from "../../utils";
import { BaseFieldProps, SemanticInputSize, SemanticPointing } from "./base";
import { DropdownOptions } from "./DropdownField";
import NullableTextInput from "./NullableTextInput";
import classNames from "classnames";

export interface AutocompleteFieldProps extends BaseFieldProps<Opt<string>> {
  errorPointing?: SemanticPointing;
  placeholder?: string;
  loading?: boolean;
  size?: SemanticInputSize;
  createMenuOptions: (str: Opt<string>) => DropdownOptions<string>;
}

export default function AutocompleteField(props: AutocompleteFieldProps) {
  const { id, value, onChange, readOnly, disabled, messages, errorPointing, placeholder, size, loading, createMenuOptions } = props;

  const [menuOptions, setMenuOptions] = useState<DropdownOptions<string>>([]);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuSelected, setMenuSelected] = useState(-1);

  useEffect(() => {
    const menuOptions = createMenuOptions(value);
    setMenuOptions(menuOptions);
    setMenuVisible(menuVisible && menuOptions.length > 0);
  }, [value, menuVisible, createMenuOptions]);

  useEffect(() => {
    if (readOnly || disabled) {
      setMenuVisible(false);
    }
  }, [readOnly, disabled]);

  const handleChange = useCallback(
    (value: Opt<string>) => {
      setMenuVisible(menuVisible ? true : value ? value.length > 0 : false);
      setMenuSelected(menuVisible ? menuSelected : -1);
      onChange?.(value);
    },
    [onChange, menuVisible, menuSelected],
  );

  const handleSelect = useCallback(
    (index: number) => {
      const selected = menuOptions.length > index ? menuOptions[index] : null;

      if (selected != null) {
        setMenuVisible(false);
        setMenuSelected(index);
        onChange?.(selected.value);
      }
    },
    [menuOptions, onChange],
  );

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      switch (evt.keyCode) {
        case ENTER_KEY: {
          setMenuVisible(false);
          setMenuSelected(0);

          const selectedOption = menuVisible && menuSelected >= 0 && menuSelected < menuOptions.length ? menuOptions[menuSelected] : null;

          if (selectedOption != null) {
            onChange?.(selectedOption.value);
          }

          break;
        }

        case UP_ARROW_KEY: {
          evt.stopPropagation();

          setMenuVisible(menuSelected > 0);
          setMenuSelected(Math.max(menuSelected - 1, 0));

          break;
        }

        case DOWN_ARROW_KEY: {
          evt.stopPropagation();

          setMenuVisible(true);
          setMenuSelected(Math.min(menuSelected + 1, menuOptions.length - 1));

          break;
        }

        default: {
          // Do nothing
        }
      }
    },
    [onChange, menuOptions, menuVisible, menuSelected],
  );

  const clickOutsideRef = useRef<HTMLDivElement>(null);

  useClickOutside(clickOutsideRef, () => {
    setMenuVisible(false);
    setMenuSelected(0);
  });

  return (
    <div
      ref={clickOutsideRef}
      role="combobox"
      aria-expanded={menuVisible}
      aria-controls="menu"
      className={classNames("ui visible fluid dropdown autocomplete", {
        loading,
      })}
    >
      <NullableTextInput
        id={id}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        readOnly={readOnly}
        disabled={disabled}
        messages={messages}
        errorPointing={errorPointing}
        placeholder={placeholder}
        fluid
        size={size}
        updateWhenFocused_EXPERIMENTAL={true}
      />
      <div role="listbox" className={classNames("menu transition", menuVisible ? "visible" : "hidden")} style={{ width: "100%" }}>
        {menuOptions.map((option, index) => (
          <div
            role="option"
            tabIndex={index}
            aria-selected={index === menuSelected}
            key={option.key}
            className={classNames("item", {
              "active selected": index === menuSelected,
            })}
            onClick={() => handleSelect(index)}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
}
