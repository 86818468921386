import React, { ReactNode, MouseEvent, useMemo } from "react";
import { Button } from "semantic-ui-react";
import { Messages } from "../model/errors";
import { ToggleableMessagesDisplay } from "./ErrorDisplay";
import { SemanticCOLORS } from "semantic-ui-react";

type Props = {
  messages: Messages;
  onClick: (evt: MouseEvent) => void;
  disabled?: boolean;
  color?: SemanticCOLORS;
  basic?: boolean;
  children: ReactNode;
};

export default function SubmitButton({ messages, onClick, disabled, basic, color, children }: Props) {
  const errors = useMemo(() => messages.filter(e => e.level === "error"), [messages]);

  return (
    <ToggleableMessagesDisplay messages={errors}>
      <Button color={color || "blue"} basic={basic || false} onClick={onClick} disabled={errors.length > 0 || disabled}>
        {children}
      </Button>
    </ToggleableMessagesDisplay>
  );
}
