import { groupBy, sortBy, keys } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button, Container, Form, Grid, Header, List, Segment, Table } from "semantic-ui-react";
import type { Permission, PermissionType, User, UserRole } from "../model/types";
import { GlobalPermissionTypeEnum } from "../model/enums";
import * as auth from "../reducers/auth";
import Title from "../components/Title";
import { FieldLabel, NullableTextInput } from "../components/fields";
import RolesView from "../components/RolesView";
import { Opt } from "../utils";

type Props = {
  loggedInUser: Opt<User>;
  actingAsUser: Opt<User>;
  actAs: (username: string) => void;
  unactAs: () => void;
  refresh: () => void;
};

type MyState = {
  username: Opt<string>;
};

class MyAccountPage extends React.Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = { username: null };
  }

  setUsername = (username: Opt<string>) => {
    this.setState({ username });
  };

  handleActAsClick = () => {
    const { username } = this.state;
    if (username) {
      this.props.actAs(username);
    }
  };

  handleUnactAsClick = () => {
    this.props.unactAs();
  };

  render() {
    return (
      <Title title="My Account">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width="8">{this.renderRefreshForm()}</Grid.Column>
              <Grid.Column width="8">{this.renderActAsForm()}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="8">{this.renderLoggedInUser()}</Grid.Column>
              <Grid.Column width="8">{this.renderActingAsUser()}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Title>
    );
  }

  renderLoggedInUser = () => {
    const { loggedInUser } = this.props;

    return (
      loggedInUser && (
        <Container>
          <Header as="h3">Logged in as</Header>
          {this.renderUser(loggedInUser)}
        </Container>
      )
    );
  };

  renderActingAsUser = () => {
    const { actingAsUser } = this.props;

    return (
      actingAsUser && (
        <Container>
          <Header as="h3">Acting as</Header>
          {this.renderUser(actingAsUser)}
        </Container>
      )
    );
  };

  renderUser = (user: User) => {
    return (
      <Table columns="2" definition>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">Name</Table.Cell>
            <Table.Cell verticalAlign="top">
              {user.forenames} {user.surname}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell verticalAlign="top">Username</Table.Cell>
            <Table.Cell verticalAlign="top">{user.username}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell verticalAlign="top">Email</Table.Cell>
            <Table.Cell verticalAlign="top">{user.email}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell verticalAlign="top">Roles</Table.Cell>
            <Table.Cell verticalAlign="top">
              <RolesView roles={user.roles} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  renderPermissions = (perms: Permission[]) => {
    const grouped = groupBy(
      sortBy(perms, perm => perm.school),
      perm => perm.school || "AAAAA",
    );
    const schools = sortBy(keys(grouped));
    return schools.map(school => {
      const perms = sortBy(grouped[school], perm => perm.type);
      return (
        <Segment basic vertical key={school}>
          <Header as="h4">{school === "AAAAA" ? "Central Admissions" : school}</Header>
          <List>
            {perms.map((perm, index) => (
              <List.Item key={index}>
                <List.Content>
                  <List.Description>{perm.type}</List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      );
    });
  };

  renderRefreshForm = () => {
    const { loggedInUser, refresh } = this.props;

    return (
      loggedInUser && (
        <Container>
          <Header as="h3">Reload permissions</Header>

          <p>Click the button below if you need to reload your permissions after they have been changed by an administrator.</p>

          <Button onClick={() => refresh()}>Reload permissions</Button>
        </Container>
      )
    );
  };

  renderActAsForm = () => {
    const { loggedInUser, actingAsUser } = this.props;
    const { username } = this.state;

    const canActAs: boolean = loggedInUser
      ? loggedInUser.roles.find(
          (userRole: UserRole) =>
            userRole.role.permissions.find(
              (perm: PermissionType) => perm === GlobalPermissionTypeEnum.SuperUser || perm === GlobalPermissionTypeEnum.CanActAsOtherUsers,
            ) != null,
        ) != null
      : false;

    return (
      canActAs && (
        <Container>
          <Header as="h3">Act as another user</Header>

          {!actingAsUser && (
            <Form as="div" onSubmit={this.handleActAsClick}>
              <FieldLabel label="Enter a username...">
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <NullableTextInput value={username} onChange={this.setUsername} fluid />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Button fluid onClick={this.handleActAsClick}>
                        Change
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FieldLabel>
            </Form>
          )}

          {actingAsUser && (
            <Container>
              <p>
                You are acting as {actingAsUser.forenames} {actingAsUser.surname} (<strong>{actingAsUser.username}</strong>
                ).
              </p>

              <Button onClick={this.handleUnactAsClick}>Restore</Button>
            </Container>
          )}
        </Container>
      )
    );
  };
}

export default connect(
  state => ({
    loggedInUser: auth.loggedInUser(state),
    actingAsUser: auth.actingAsUser(state),
  }),
  {
    actAs: auth.actAs,
    unactAs: auth.unactAs,
    refresh: auth.refresh,
  },
)(MyAccountPage);
