import type { Permission } from "./model/types";

import { AppError } from "@qmspringboard/shared/dist/errors";
export {
  AppError,
  NotFoundError,
  ConcurrentModificationError,
  ValidationError,
  UnauthorizedError,
  UnknownClientError,
  UnknownError,
  UnknownServerError,
} from "@qmspringboard/shared/dist/errors";

export class ForbiddenError extends AppError {
  missingPermissions?: Permission[];
  failedCheck?: unknown;

  constructor(missingPermissions: Permission[] | undefined, failedCheck: unknown | undefined) {
    super(`Permission denied`);
    this.missingPermissions = missingPermissions;
    this.failedCheck = failedCheck;
  }
}
