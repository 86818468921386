import { Just, Maybe } from "purify-ts/Maybe";
import React from "react";
import { Input as SemanticInput } from "semantic-ui-react";
import { BaseFieldProps } from "./base";
import { BaseInput, ExtraInputProps } from "./BaseInput";

export interface TextInputProps extends BaseFieldProps<string>, ExtraInputProps<SemanticInput> {}

function format(str: string): string {
  return str;
}

function validate(str: string): Maybe<string> {
  return Just(str);
}

export function TextInput(props: TextInputProps) {
  return <BaseInput format={format} validate={validate} {...props} />;
}
