import React, { useContext } from "react";
import { Container, Header } from "semantic-ui-react";
import Title from "../components/Title";
import { FeatureFlagsContext } from "@qmspringboard/app/src/FeatureFlagsProvider";

export default function AppConfigPage() {
  const featureFlags = useContext(FeatureFlagsContext);

  return (
    <Title title="Developer Settings">
      <Container>
        <div>
          <Header as="h2">Feature flags</Header>
          <pre>{JSON.stringify(featureFlags, null, 2)}</pre>
        </div>
      </Container>
    </Title>
  );
}
