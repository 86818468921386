import { EntryPointEnum } from "../../model/enums";
import { unsafeStringToProgrammeCode } from "../../model/programme";
import { Message, Programme, ProgrammeEditorDTO, SchoolCode, unsafeTag } from "../../model/types";
import { emptyRequirements } from "../requirementsUpdate";

export type MyState = {
  fetching: boolean;
  fetched: boolean;
  creating: boolean;
  programmeDTO: ProgrammeEditorDTO;
  originalProgramme: Programme;
  clientMessages: Message[];
  serverMessages: Message[];
};

export const emptyProgramme = (schoolCode: SchoolCode = unsafeTag<"SchoolCode">("")): ProgrammeEditorDTO => ({
  programme: {
    code: unsafeStringToProgrammeCode(""),
    name: "",
    schoolCode,
    entryRequirements: emptyRequirements,
    defaultEntryPoint: EntryPointEnum.First,
    jointSchoolCode: null,
    allowHotlineClearingOffers: false,
    isVisibleOnPortal: true,
    isVisibleOnCourseFinder: true,
    isClosedHome: false,
    isClosedOverseas: false,
    isFullHome: false,
    isFullOverseas: false,
  },
  hasApplications: false,
});

export const initialState = (): MyState => {
  const prog: ProgrammeEditorDTO = emptyProgramme();

  return {
    fetching: false,
    fetched: false,
    creating: false,
    programmeDTO: prog,
    originalProgramme: prog.programme,
    clientMessages: [],
    serverMessages: [],
  };
};
