import React from "react";
import { Dimmer, Loader, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import { AppError } from "../errors";
import { Role, SearchResults } from "../model/types";
import { Opt, ReactNodeLike } from "../utils";
import { withModal, WithModalProps } from "../utils/modal";
import ClickableCell from "./ClickableCell";

type Props = {
  results: SearchResults<Role>;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  fetching: boolean;
  fetchError: Opt<AppError>;
  refetch: Function;
};

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const NoItems = ({ fetchError }: { fetchError: AppError | null }) => (
  <Table.Row key="noitems">
    <Table.Cell colSpan={5} textAlign="center">
      <Segment basic>{fetchError ? `Could not load results. ${fetchError.allMessages()}` : "Nothing to show"}</Segment>
    </Table.Cell>
  </Table.Row>
);

const RoleItem = ({ role }: { role: Role }) => {
  const url = `/admin/role/${role.id}`;

  return (
    <Table.Row key={role.id}>
      <ClickableCell to={url}>{role.name}</ClickableCell>
    </Table.Row>
  );
};

const RoleList = ({ results: { items }, fetching, fetchError, sortComponent }: Props & WithModalProps) => {
  const [busy, _setBusy] = React.useState<boolean>(false);

  return (
    <Dimmer.Dimmable dimmed={busy || fetching}>
      <Dimmer active={busy || fetching} inverted>
        <Loader>Loading</Loader>
      </Dimmer>

      <ResponsiveTable>
        <Table selectable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{sortComponent("name", "Role Name")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items.length === 0 ? <NoItems fetchError={fetchError || null} /> : items.map(role => <RoleItem key={role.id} role={role} />)}
          </Table.Body>
        </Table>
      </ResponsiveTable>
    </Dimmer.Dimmable>
  );
};

export default withModal(RoleList);
