import { createSelector } from "reselect";
import * as api from "../../api";
import * as pot from "../../api/pot";
import { Programme, SearchResults } from "../../model/types";
import { AppReducer } from "../actions";
import { fetchWithMutex } from "../fetch";
import { AppState } from "../state";
import { emptyResults, initialState, MyState, Results } from "./state";

///////////////
// Selectors //
///////////////

// Cyclic dependency. This replicates the behaviour of:
// - routeSelector from route.js
// - schoolSelector from school.js
const rootSelector: (state: AppState) => MyState = (state: AppState) => state.route.school.programmeList;

const allResults: (state: AppState) => Results = createSelector(rootSelector, state => pot.getOrElse(state, emptyResults));

export const allProgrammes: (state: AppState) => Programme[] = createSelector(allResults, state => state.items);

export function findProgramme(code: string): (state: AppState) => Programme | undefined {
  return createSelector(allProgrammes, progs => progs.find(prog => prog.code === code));
}

/////////////
// Actions //
/////////////

const REQUEST = "programmeList/REQUEST";
const SUCCESS = "programmeList/SUCCESS";
const FAILURE = "programmeList/FAILURE";

export function initialise() {
  return fetchWithMutex<SearchResults<Programme>>({
    mutex: "programmeList",

    request(dispatch, getState) {
      return api.searchProgrammes(api.fetchParams(getState()), undefined, {
        count: 99999,
      });
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: REQUEST, requestId });
    },

    success(dispatch, getState, resource) {
      dispatch({ type: SUCCESS, resource });
    },

    error(dispatch, getState, error) {
      dispatch({ type: FAILURE, error });
    },
  });
}

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return pot.pending(action.requestId);
    case SUCCESS:
      return pot.ready(action.resource);
    case FAILURE:
      return pot.failed(action.error);
    default:
      return state;
  }
};

export default reducer;
