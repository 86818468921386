import React, { FormEvent, useCallback, KeyboardEvent, SyntheticEvent, Ref } from "react";
import { TextArea as SemanticTextArea, TextAreaProps as SemanticTextAreaProps } from "semantic-ui-react";
import ErrorDisplay from "../ErrorDisplay";
import { BaseFieldProps, MappedFieldProps, SemanticPointing } from "./base";
import useInputDebounce from "./useInputDebounce";
import TextareaAutosize from "react-textarea-autosize";

export interface ExtraTextAreaProps<I = SemanticTextArea> {
  forwardRef?: Ref<I>;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onKeyPress?: (e: KeyboardEvent) => void;
  placeholder?: string;
  // Settings for debouncing keypresses (defaults to ):
  debounce?: number;
  // Error message to display if the local value is invalid:
  localError?: string;
  // Where to display the pointer on the error message speech bubble:
  errorPointing?: SemanticPointing;
  rows?: number;
  cols?: number;
  autoHeight?: boolean;
  tabIndex?: number;
}

export interface BaseTextAreaProps<A> extends BaseFieldProps<A>, MappedFieldProps<A>, ExtraTextAreaProps {}

export function BaseTextArea<A>(props: BaseTextAreaProps<A>) {
  const {
    value,
    format,
    validate,
    onChange,
    readOnly,
    disabled,
    debounce,
    messages,
    localError,
    // ExtraTextAreaProps:
    forwardRef,
    onFocus,
    onBlur,
    onKeyUp,
    onKeyDown,
    onKeyPress,
    placeholder,
    errorPointing,
    rows,
    cols,
    autoHeight,
    tabIndex,
  } = props;

  const { textValue, handleFocus, handleBlur, handleChange, localMessages } = useInputDebounce({
    value,
    format,
    validate,
    onChange,
    onFocus,
    onBlur,
    debounce,
    messages,
    localError,
  });

  const localHandleChange = useCallback(
    (evt: FormEvent<HTMLTextAreaElement>, data: SemanticTextAreaProps) => {
      switch (typeof data.value) {
        case "string":
          handleChange(data.value);
          break;
        case "number":
          handleChange(String(data.value));
          break;
        default:
          handleChange("");
          break;
      }
    },
    [handleChange],
  );

  return (
    <span>
      <SemanticTextArea
        ref={forwardRef}
        as={autoHeight ? TextareaAutosize : undefined}
        value={textValue}
        onChange={localHandleChange}
        disabled={readOnly || disabled}
        // ExtraTextAreaProps:
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        tabIndex={tabIndex}
      />
      {!readOnly && <ErrorDisplay pointing={errorPointing} attached messages={localMessages} />}
    </span>
  );
}
