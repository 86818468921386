import { requirementsCheckResultsFold, requirementsCheckResultsItems } from "@qmspringboard/shared/dist/model/checking";
import { QualificationBoardEnum, RequirementsCheckStatusEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import React, { ReactNode, useMemo } from "react";
import { Header, Icon, Label, List, Message, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { useRequirementsCheckDTO } from "../hooks/useRequirementsChecks";
import { Qualifications } from "../model/applicant";
import { ProgrammeCode, Requirements, RequirementsCheckDTO, RequirementsCheckItem, RequirementsCheckResults } from "../model/types";
import { Opt } from "../utils";

interface RequirementsCheckViewProps {
  qualifications: Qualifications;
  programmeCode: ProgrammeCode;
  requirements: Requirements;
}

function ResultsList({ results }: { results: RequirementsCheckResults }) {
  function formatResult(result: RequirementsCheckItem) {
    const icon = result.status === "Passed" ? "checkmark" : result.status === "Failed" ? "times" : "question";
    return (
      <List.Item key={result.label}>
        <Label
          style={{
            background: "rgba(0, 0, 0, 0.05)",
            color: "inherit",
          }}
        >
          <Icon name={icon} />
          {result.label}
        </Label>
      </List.Item>
    );
  }

  const items: RequirementsCheckItem[] = useMemo(() => requirementsCheckResultsItems(results), [results]);

  return <List>{items.map(formatResult)}</List>;
}

export interface CommentsViewProps {
  label: string;
  comments?: Opt<string>;
}

export function CommentsView({ label, comments }: CommentsViewProps) {
  return comments == null ? null : (
    <Segment vertical>
      <strong>{label}:</strong> {comments}
    </Segment>
  );
}

// HACK: There's a CSS rule in Semantic UI React that
// hides warning messages when the form isn't flagged with class="warning".
// This style attribute overrides this behaviour.
//
// We get away with this above because
// we're using "positive" and "negative" messages
// instead of "success" and "warning" ones.
export const WrappedMessage = styled(Message)`
  display: block !important;
`;

export default function RequirementsCheckView({ programmeCode, requirements }: RequirementsCheckViewProps) {
  const dto: RequirementsCheckDTO | undefined = useRequirementsCheckDTO(programmeCode);

  if (dto == null) {
    return null;
  } else {
    const results = dto.results;

    const skipReason = requirementsCheckResultsFold(
      results,
      inc => inc.reason,
      _ => undefined,
    );
    const items = requirementsCheckResultsItems(results);
    const description = results.requirementsDescription;
    const qualType = requirementsCheckResultsFold(
      results,
      _ => undefined,
      req => QualificationBoardEnum.labelOf(req.board),
    );

    // let items = results == null ? [] : requirementsCheckResultsItems(results);

    const allPassed = items.every(item => item.status === RequirementsCheckStatusEnum.Passed);

    const anyFailed = items.some(item => item.status === RequirementsCheckStatusEnum.Failed);

    const guidanceNotes: Opt<string> = requirements.guidanceNotes;

    const addHeader = (content: ReactNode) => (
      <>
        <Header as="h4">Entry Requirements</Header>
        {content}
      </>
    );

    if (skipReason != null || results == null) {
      return addHeader(
        <WrappedMessage warning>
          <Message.Header>We can&apos;t check whether the applicant meets the entry requirements</Message.Header>
          <Message.Content>
            <CommentsView label="Entry requirements" comments={description} />
            <CommentsView label="Additional guidance notes" comments={guidanceNotes} />
            <CommentsView label="Reason we couldn't check" comments={skipReason} />
          </Message.Content>
        </WrappedMessage>,
      );
    } else if (allPassed) {
      return addHeader(
        <WrappedMessage positive>
          <Message.Header>The applicant meets the entry requirements</Message.Header>
          <Message.Content>
            <CommentsView label="Entry requirements" comments={description} />
            <CommentsView label="Additional guidance notes" comments={guidanceNotes} />
            <CommentsView label="Applicant's qualifications identified as" comments={qualType} />
            <ResultsList results={results} />
          </Message.Content>
        </WrappedMessage>,
      );
    } else if (anyFailed) {
      return addHeader(
        <WrappedMessage negative>
          <Message.Header>
            The applicant <em>may not</em> meet the entry requirements
          </Message.Header>
          <Message.Content>
            <CommentsView label="Entry requirements" comments={description} />
            <CommentsView label="Additional guidance notes" comments={guidanceNotes} />
            <CommentsView label="Applicant's qualifications identified as" comments={qualType} />
            <ResultsList results={results} />
          </Message.Content>
        </WrappedMessage>,
      );
    } else {
      return addHeader(
        <WrappedMessage warning>
          <Message.Header>We can&apos;t check whether the applicant meets the entry requirements</Message.Header>
          <Message.Content>
            <CommentsView label="Entry requirements" comments={description} />
            <CommentsView label="Additional guidance notes" comments={guidanceNotes} />
            <CommentsView label="Applicant's qualifications identified as" comments={qualType} />
            <ResultsList results={results} />
          </Message.Content>
        </WrappedMessage>,
      );
    }
  }
}
