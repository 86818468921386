import React from "react";
import { connectModal } from "redux-modal";
import { Modal, Button } from "semantic-ui-react";
import { TelephoneScript } from "../model/types";
import ApplicationScript from "./ApplicationScript";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  scripts?: TelephoneScript[];
}

export default connectModal({ name: "applicationScript" })(function ModalApplicationScript(props: Props) {
  const { show, handleHide, scripts } = props;

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Modal.Content>{scripts?.map((script, i: number) => <ApplicationScript key={i} script={script} />)}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleHide}>OK</Button>
      </Modal.Actions>
    </Modal>
  );
});
