import { useProgrammeAuditEvents } from "@qmspringboard/app/src/api";
import { ProgrammeCode } from "@qmspringboard/shared/src/model/core.generated";
import { Button, Dimmer, Divider, Grid, Header, Label, Loader, Modal, Segment, Table } from "semantic-ui-react";
import React, { useState } from "react";
import { ddmmhhmm, formatLondon, parseIso } from "@qmspringboard/shared/src/utils/date";
import { Programme } from "@qmspringboard/app/src/model/programmes.generated";
import { ProgrammeAuditEvent } from "@qmspringboard/app/src/model/programmeAudit.generated";
import styled from "styled-components";
import {
  Condition,
  EnglishRequirement,
  IeltsEnglishRequirement,
  OverallRequirement,
  RequiredSubject,
  RequirementsAlternative,
  ToeflEnglishRequirement,
} from "@qmspringboard/shared/src/model/requirements.generated";
import { FieldLabel } from "@qmspringboard/app/src/components/fields";
import { assertNever } from "@qmspringboard/app/src/utils";

interface Props {
  programmeCode: ProgrammeCode;
}

export const ProgrammeAuditModal = (props: Props) => {
  const [open, setOpen] = useState(false);

  const { programmeCode } = props;
  const { data } = useProgrammeAuditEvents(programmeCode);
  const events = data ?? null;

  return (
    <Modal open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} trigger={<Button>Show History</Button>} size="small">
      <Header icon="info circle" content={`Change History for ${programmeCode}`} />
      <Modal.Content>
        <Dimmer active={events == null} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        {events && events.changes.length === 0 && (
          <Segment basic textAlign="center">
            No events - the history is empty!
          </Segment>
        )}

        {events &&
          events.changes.length > 0 &&
          events.changes.map((event, index) => (
            <Segment basic vertical key={index}>
              <EventView event={event} />
            </Segment>
          ))}
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setOpen(false)}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const EventView = ({ event }: { event: ProgrammeAuditEvent }) => {
  const actionType = event.action;
  switch (actionType) {
    case "Added":
      return <ProgrammeAddedEventView event={event} />;
    case "DetailsChanged":
      return <ProgrammeDetailsChangedEventView event={event} />;
    case "RequirementsChanged":
      return <ProgrammeRequirementsChangedEventView event={event} />;
    case "Removed":
      return <ProgrammeRemovedEventView event={event} />;
    default:
      return <div>unknown action type: {actionType}</div>;
  }
};

const ProgrammeAddedEventView = ({ event }: { event: ProgrammeAuditEvent }) => (
  <Segment>
    <ProgrammeAuditEventTitle title="Programme Added" event={event} divider />
    <ProgrammeDetailsView programme={event.programme} />
    <ProgrammeRequirementsView programme={event.programme} />
  </Segment>
);

const ProgrammeDetailsChangedEventView = ({ event }: { event: ProgrammeAuditEvent }) => (
  <Segment>
    <ProgrammeAuditEventTitle title="Programme Details Changed" event={event} divider />
    <ProgrammeDetailsView programme={event.programme} />
  </Segment>
);

const ProgrammeRequirementsChangedEventView = ({ event }: { event: ProgrammeAuditEvent }) => (
  <Segment>
    <ProgrammeAuditEventTitle title="Programme Entry Requirements Changed" event={event} divider />
    <ProgrammeRequirementsView programme={event.programme} />
  </Segment>
);

const ProgrammeRemovedEventView = ({ event }: { event: ProgrammeAuditEvent }) => (
  <Segment>
    <ProgrammeAuditEventTitle title="Programme Removed" event={event} divider />
  </Segment>
);

type ProgrammeAuditEventTitleProps = {
  title: string;
  event: ProgrammeAuditEvent;
  divider?: boolean;
};

const DefinitionTable = ({ ...props }) => <Table columns="4" definition {...props} />;

const DetailsTable = styled(DefinitionTable)`
  background: transparent !important;

  td:first-child {
    background: rgba(0, 0, 0, 0.03) !important;
  }

  td {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const ProgrammeDetailsView = ({ programme }: { programme: Programme }) => {
  return (
    <DetailsTable>
      <Table.Body>
        <Table.Row>
          <Table.Cell>Code</Table.Cell>
          <Table.Cell>{programme.code}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Name</Table.Cell>
          <Table.Cell>{programme.name}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>School Code</Table.Cell>
          <Table.Cell>{programme.schoolCode}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Default Entry Point</Table.Cell>
          <Table.Cell>{programme.defaultEntryPoint}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Joint School Code</Table.Cell>
          <Table.Cell>{programme.jointSchoolCode}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Allow Hotline Clearing Offers</Table.Cell>
          <Table.Cell>{programme.allowHotlineClearingOffers}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Closed to Home</Table.Cell>
          <Table.Cell>{programme.isClosedHome ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Closed to Overseas</Table.Cell>
          <Table.Cell>{programme.isClosedOverseas ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Full for Home</Table.Cell>
          <Table.Cell>{programme.isFullHome ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Full for Overseas</Table.Cell>
          <Table.Cell>{programme.isFullOverseas ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Show on Portal</Table.Cell>
          <Table.Cell>{programme.isVisibleOnPortal ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Show on Course Finder</Table.Cell>
          <Table.Cell>{programme.isVisibleOnCourseFinder ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>Keywords</Table.Cell>
          <Table.Cell>{programme.keywords ? "Yes" : "No"}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </DetailsTable>
  );
};

const ProgrammeRequirementsView = ({ programme }: { programme: Programme }) => {
  const requirements = programme.entryRequirements;
  return (
    <>
      <DetailsTable>
        <Table.Body>
          {requirements.english ? (
            <EnglishRequirementView requirement={requirements.english} />
          ) : (
            <Table.Row>
              <Table.Cell>English Requirement</Table.Cell>
              <Table.Cell>None</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </DetailsTable>
      <DetailsTable>
        <Table.Body>
          {requirements.alternatives.map((alternative, index) => (
            <>
              <Table.Row>
                <Table.HeaderCell>Alternative {index + 1}</Table.HeaderCell>
              </Table.Row>
              <RequirementsAlternativeView alternative={alternative} key={index} />
            </>
          ))}
        </Table.Body>
      </DetailsTable>
    </>
  );
};

const RequirementsAlternativeView = ({ alternative }: { alternative: RequirementsAlternative }) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>Minimum Grades</Table.Cell>
        <Table.Cell>
          <OverallOptions overall={alternative.overall}></OverallOptions>
        </Table.Cell>
      </Table.Row>
      {alternative.conditions.map((condition, index) => (
        <ConditionView condition={condition} key={index} />
      ))}
    </>
  );
};

const ConditionView = ({ condition }: { condition: Condition }) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>Must have {condition.numberRequired} of the following subjects:</Table.Cell>
        <Table.Cell>
          <Grid columns="equal">
            <Grid.Row>
              <Grid.Column>A2</Grid.Column>
              <Grid.Column>IB</Grid.Column>
            </Grid.Row>
          </Grid>
        </Table.Cell>
      </Table.Row>
      {condition.subjects.map((subject, index) => (
        <Table.Row key={index}>
          <RequiredSubjectView subject={subject} />
        </Table.Row>
      ))}
    </>
  );
};

const RequiredSubjectView = ({ subject }: { subject: RequiredSubject }) => {
  switch (subject.type) {
    case "RequiredSubjectNoGrade": {
      return <Table.Cell>{subject.subject}</Table.Cell>;
    }

    case "RequiredSubjectAtGrade": {
      return (
        <>
          <Table.Cell>{subject.subject}</Table.Cell>
          <Table.Cell>
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>{subject.a2Grade}</Grid.Column>
                <Grid.Column>{subject.ibGrade}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Table.Cell>
        </>
      );
    }
    default: {
      assertNever(subject);
    }
  }
};

const OverallOptions = ({ overall }: { overall: OverallRequirement }) => {
  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FieldLabel label="A2s">{overall.a2Grades}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="IB Higher">{overall.ibGrades}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="IB Total">{overall.ibPoints}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="BTEC">{overall.btecGrades}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Equivalence">{overall.aLevelEquivalence}</FieldLabel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const EnglishRequirementView = ({ requirement }: { requirement: EnglishRequirement }) => {
  return (
    <>
      <Table.Row>
        <Table.Cell>English Requirements</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>GCSE</Table.Cell>
        <Table.Cell>{requirement.gcse}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>IELTS</Table.Cell>
        <Table.Cell>
          <IELTSOptions ielts={requirement.ielts} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>TOEFL</Table.Cell>
        <Table.Cell>
          <TOEFLOptions toefl={requirement.toefl} />
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>French Bac</Table.Cell>
        <Table.Cell>{requirement.frenchBac}%</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Indian HS</Table.Cell>
        <Table.Cell>{requirement.indianHs}%</Table.Cell>
      </Table.Row>
    </>
  );
};

const IELTSOptions = ({ ielts }: { ielts: IeltsEnglishRequirement }) => {
  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FieldLabel label="Overall">{ielts.overall}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Listening">{ielts.listening}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Reading">{ielts.reading}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Writing">{ielts.writing}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Speaking">{ielts.speaking}</FieldLabel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const TOEFLOptions = ({ toefl }: { toefl: ToeflEnglishRequirement }) => {
  return (
    <Grid columns="equal">
      <Grid.Row>
        <Grid.Column>
          <FieldLabel label="Overall">{toefl.overall}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Listening">{toefl.listening}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Reading">{toefl.reading}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Writing">{toefl.writing}</FieldLabel>
        </Grid.Column>
        <Grid.Column>
          <FieldLabel label="Speaking">{toefl.speaking}</FieldLabel>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const ProgrammeAuditEventTitle = ({ title, event, divider }: ProgrammeAuditEventTitleProps) => (
  <div>
    <Label ribbon>{formatLondon(parseIso(event.timestamp), ddmmhhmm)}</Label>
    <strong>
      {title} by {event.username}
    </strong>
    {divider && <Divider hidden />}
  </div>
);
