import { Location } from "history";
import { startsWith } from "lodash";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, Icon, Menu } from "semantic-ui-react";
import { Permissions } from "../model/permission";
import { isAdmissions, isSchool, Team, TeamCode, teamCodeToString } from "../model/team";
import { User } from "../model/types";
import * as auth from "../reducers/auth";
import * as teams from "../reducers/teams";
import { Opt } from "../utils";
import { FeatureFlagsContext } from "@qmspringboard/app/src/FeatureFlagsProvider";

interface Props {
  loggedInUser: Opt<User>;
  actingAsUser: Opt<User>;
  currentTeam: Team;
  allTeams: Team[];
  setCurrentTeam: (teamCode: TeamCode) => void;
  location: Location;
  permissions: Permissions;
  logout: (params?: auth.LogoutParams) => void;
}

const Navbar = (props: Props) => {
  const isActive = (prefix: string, exact: boolean = false): boolean => {
    return exact ? props.location.pathname === prefix : startsWith(props.location.pathname, prefix);
  };

  function menuItem(href: string, label: string) {
    return (
      <Dropdown.Item as={Link} to={href} active={isActive(href)}>
        {label}
      </Dropdown.Item>
    );
  }

  const handleItemClick = () => {
    // Do nothing
  };

  const { loggedInUser, actingAsUser } = props;
  const featureFlags = useContext(FeatureFlagsContext);

  return (
    <div style={{ height: 80 }}>
      <Menu fixed="top" style={{ zIndex: 10000 }}>
        <Menu.Menu name="title">{renderTeamMenu()}</Menu.Menu>

        <Menu.Item name="applications" to="/" as={Link} active={isActive("/", true)} onClick={handleItemClick}>
          Applications
        </Menu.Item>

        <Menu.Item name="newApplicant" to="/applicant/search" as={Link} active={isActive("/applicant/search", true)} onClick={handleItemClick}>
          Take a Call
        </Menu.Item>

        <Menu.Item name="predictions" as={Link} active={isActive("/predictions")} to="/predictions">
          Predictions
        </Menu.Item>

        <Menu.Item name="status" as={Link} active={isActive("/status")} to="/status">
          Programme Status
        </Menu.Item>

        <Menu.Menu
          name="admin"
          style={{
            background: isActive("/admin") ? "rgba(0,0,0,.05)" : "transparent",
          }}
        >
          {renderAdminMenu()}
        </Menu.Menu>

        {loggedInUser && (
          <Menu.Menu position="right">
            {featureFlags && featureFlags.environmentFriendlyName && featureFlags.environmentFriendlyName !== "LIVE" && (
              <Menu.Item name="env" as="span" style={{ background: "#FFCC77" }}>
                {featureFlags.environmentFriendlyName.toLowerCase()} site
              </Menu.Item>
            )}

            <Menu.Item name="weblink" as="a" href="https://weblink.ucas.com/ucaslinkauth/login.do" target="_blank">
              <Icon name="external" />
              UCAS Web-Link
            </Menu.Item>

            {renderUserMenu(loggedInUser, actingAsUser)}
          </Menu.Menu>
        )}
      </Menu>
    </div>
  );

  function renderTeamMenu() {
    const { allTeams, currentTeam, setCurrentTeam } = props;
    return (
      <Dropdown text={currentTeam.code} pointing className="link item">
        <Dropdown.Menu>
          {allTeams.map(team => (
            <Dropdown.Item key={teamCodeToString(team.code)} active={team.code === currentTeam.code} onClick={() => setCurrentTeam(team.code)}>
              {team.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  function renderAdminMenu(): JSX.Element {
    const { permissions, currentTeam } = props;

    const showAddAnnouncements = permissions.canAddAnnouncements();
    const showUsers = permissions.canUpdateUsers(currentTeam.code);
    const showRoles = permissions.canUpdateUsers(currentTeam.code); // Assumption: User managers can also manage roles.
    const showProgrammes = permissions.canUpdateProgrammes(currentTeam.code);
    const showLabelPrototypes = permissions.canUpdateLabelPrototypes(currentTeam.code);
    const showReportingGroups =
      (isSchool(currentTeam) && permissions.canUpdateReportingGroups(currentTeam.code)) ||
      (isAdmissions(currentTeam) && permissions.canUpdateAdmissionsTargets());
    const showSchoolConfig =
      isSchool(currentTeam) &&
      (permissions.canUpdateSchoolConfig(currentTeam.code) ||
        permissions.canUpdateSchoolDeadlines(currentTeam.code) ||
        permissions.canUpdateTelephoneScripts(currentTeam.code) ||
        permissions.canUpdateEmailTemplates(currentTeam.code) ||
        permissions.canUpdateClassifierRules(currentTeam.code));
    const showSelectorList = isAdmissions(currentTeam) && permissions.canSyncSelectorList(currentTeam.code);
    const showAppConfig = permissions.canViewAppConfig(currentTeam.code);
    const showInternationalEquivs = permissions.canViewInternationalEquivsTable();
    const showImportClearingPlusApplicants = permissions.canImportUcasClearingPlusApplicants();

    const showAdminMenu =
      showRoles ||
      showUsers ||
      showProgrammes ||
      showLabelPrototypes ||
      showReportingGroups ||
      showSchoolConfig ||
      showSelectorList ||
      showAppConfig ||
      showInternationalEquivs ||
      showImportClearingPlusApplicants;

    if (showAdminMenu) {
      return (
        <Dropdown text="Admin" pointing className="link item">
          <Dropdown.Menu>
            {showRoles && menuItem("/admin/role", "Roles")}
            {showUsers && menuItem("/admin/user", "Users")}
            {showProgrammes && menuItem("/admin/programme", "Programmes")}
            {showLabelPrototypes && menuItem("/admin/label", "Labels")}
            {showReportingGroups && menuItem("/admin/reporting-group", "Reporting Groups")}
            {showSchoolConfig && menuItem("/admin/settings", "School Settings")}
            {showSelectorList && menuItem("/admin/selector-list", "Selector List Sync")}
            {showAppConfig && menuItem("/admin/app-config", "Developer Settings")}
            {showInternationalEquivs && menuItem("/admin/international-equivs", "International Equivalents")}
            {showAddAnnouncements && menuItem("/admin/create-announcement", "Announcements")}
            {showImportClearingPlusApplicants && menuItem("/admin/bulk-upload-applicants", "UCAS Clearing Plus Import")}
          </Dropdown.Menu>
        </Dropdown>
      );
    } else {
      return <span />;
    }
  }

  function renderUserMenu(loggedInUser: User, actingAsUser: Opt<User>) {
    let whoamiText: string = `${loggedInUser.forenames} ${loggedInUser.surname}`;

    if (actingAsUser) {
      whoamiText += ` as ${actingAsUser.forenames} ${actingAsUser.surname}`;
    }

    return (
      <Dropdown text={whoamiText} pointing className="link item">
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to="/account" active={isActive("/account")}>
            My account
          </Dropdown.Item>
          <Dropdown.Item
            name="Documentation"
            as="a"
            href="https://clearingplus.notion.site/Springboard-User-Manual-a5f68208eee64501833ea502f254422d"
            target="_blank"
          >
            What&apos;s new &amp; help
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/announcements" active={isActive("/announcements")}>
            Announcements
          </Dropdown.Item>
          <Dropdown.Item onClick={() => props.logout({ clearRedirect: true })}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
};

export default connect(
  state => ({
    allTeams: teams.allTeams(state),
    currentTeam: teams.currentTeam(state),
    currentUser: auth.currentUser(state),
    loggedInUser: auth.loggedInUser(state),
    actingAsUser: auth.actingAsUser(state),
    authError: auth.authError(state),
    permissions: auth.permissions(state),
  }),
  {
    setCurrentTeam: teams.setCurrentTeam,
    logout: auth.logout,
  },
)(Navbar);
