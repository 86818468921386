import React from "react";
import { Header, List, Segment } from "semantic-ui-react";
import { UserRole } from "../model/types";

type Props = {
  roles: UserRole[];
};

const RolesView = ({ roles }: Props) => {
  return (
    <div>
      {roles.map(userRole => {
        return (
          <Segment basic vertical key={userRole.role.name}>
            <Header as="h4">{userRole.role.name}</Header>
            <List>
              {userRole.schools.map((school, index) => (
                <List.Item key={index}>
                  <List.Content>
                    <List.Description>{school}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        );
      })}
    </div>
  );
};

export default RolesView;
