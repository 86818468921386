import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { Button, Container, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import RoleEditor from "../components/RoleEditor";
import * as roleUpdate from "../reducers/roleUpdate/index";
import SubmitButton from "../components/SubmitButton";
import Title from "../components/Title";
import { Opt, safeParseInt } from "../utils";
import { withModal, WithModalProps } from "../utils/modal";
import { Role } from "../model/types.generated";
import { Message } from "../model/types";
import PermissionsEditor from "../components/PermissionsEditor";
import { Permissions } from "../model/permission";

interface Params {
  id: string;
}

interface Props extends WithModalProps {
  match: Match<Params>;
  fetching: boolean;
  fetched: boolean;
  role: Opt<Role>;
  messages: Message[];
  onInit: () => void;
  onLoad: (id: number) => void;
  onUpdate: (role: Role) => void;
  onSave: (role: Role) => void;
  onDelete: (role: Role) => void;
  onClose: () => void;
  permissionsList: Permissions | null;
}

class RoleEditorPage extends React.PureComponent<Props> {
  getId = (props: Props) => safeParseInt(props.match.params.id, null);

  componentDidMount() {
    const { onInit, onLoad } = this.props;
    const id = this.getId(this.props);
    if (id) {
      onLoad(id);
    } else {
      onInit();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { onInit, onLoad } = nextProps;
    const oldId = this.getId(this.props);
    const newId = this.getId(nextProps);
    if (oldId !== newId) {
      if (newId) {
        onLoad(newId);
      } else {
        onInit();
      }
    }
  }

  handleSubmit = () => {
    const { role, onSave } = this.props;
    role && onSave(role);
  };

  handleDelete = () => {
    const { role, onDelete } = this.props;

    this.props.showModal("confirm", {
      title: "Delete role",
      content: "Are you sure?",
      onPrimaryClick: () => role && onDelete(role),
    });
  };

  render() {
    const { fetching, role, onUpdate, messages, permissionsList } = this.props;

    return (
      <Title title={role == null || role.id < 0 ? "New Role" : role.name}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            {role && <RoleEditor value={role} readOnly={false} messages={messages} onChange={onUpdate} />}

            <Divider hidden />

            {role && permissionsList && (
              <PermissionsEditor
                value={role.permissions}
                readOnly={false}
                permissions={permissionsList}
                onChange={permissions => onUpdate({ ...role, permissions })}
              />
            )}

            <Divider hidden />

            <div>
              <Segment basic vertical textAlign="right">
                {role && role.id >= 0 && (
                  <Button color="red" onClick={this.handleDelete}>
                    Delete
                  </Button>
                )}

                <SubmitButton onClick={this.handleSubmit} messages={messages}>
                  Save
                </SubmitButton>
              </Segment>
            </div>
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default withModal(
  connect(
    state => ({
      role: roleUpdate.role(state),
      messages: roleUpdate.messages(state),
      fetching: roleUpdate.fetching(state),
      fetched: roleUpdate.fetched(state),
      permissionsList: roleUpdate.permissionsList(state),
    }),
    {
      onInit: roleUpdate.initialise,
      onLoad: roleUpdate.load,
      onUpdate: roleUpdate.update,
      onSave: roleUpdate.save,
      onDelete: roleUpdate.del,
      onClose: roleUpdate.cancel,
    },
  )(RoleEditorPage),
);
