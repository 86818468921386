import "react-app-polyfill/stable";

import { ThemeProvider } from "@qmspringboard/shared/dist/ui";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import configureStore, { AppStore, history } from "./configureStore";
import { connect, Provider } from "react-redux";
import { Prompt, Route, withRouter } from "react-router";
import { ConnectedRouter } from "connected-react-router";
import { dirtySelector } from "./reducers";
import ModalContainer from "./components/ModalContainer";
import { Location } from "history";
import { isSameContext } from "./reducers/route";
import AuthenticateOrLogin from "./components/AuthenticateOrLogin";
import Notifications from "react-notify-toast";
import AnnouncementNotificationContainer from "./components/AnnouncementNotificationContainer";
import { QueryClientProvider } from "react-query";
import { QueryParamProvider } from "use-query-params";

import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { queryClient } from "./api";
import { WatermarkIfFlagged } from "./WatermarkIfFlagged";
import { ReactQueryErrorBoundary } from "./ReactQueryErrorBoundary";
import theme from "./theme";
import { FeatureFlagsProvider } from "@qmspringboard/app/src/FeatureFlagsProvider";
import { InternalMessagesContextProvider } from "@qmspringboard/app/src/components/InternalMessageContext";

const handleNewLocation =
  (location: Location) =>
  (newLocation: Location): string | true => {
    return isSameContext(location, newLocation) ? true : "You have unsaved changes, are you sure you want to leave this page";
  };

const AnythingDirty = withRouter(
  connect(state => ({
    isDirty: dirtySelector(state),
  }))(({ isDirty, location }: { isDirty: () => boolean; location: Location }) => <Prompt when={isDirty()} message={handleNewLocation(location)} />),
);

interface Props {}

interface State {
  isLoading: boolean;
  store: AppStore;
}

class App extends Component<Props, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      isLoading: true,
      store: configureStore(() => {
        // In 2024, we migrated users from having "permissions" to "roles".
        // This led to existing local storage being invalid (permissions key wasn't parsed into Pot).
        // We invalidate old state here, and new state is now in "qmspringboard" key.
        if (window.localStorage.getItem("clearing")) {
          window.localStorage.removeItem("clearing");
        }

        this.setState({ isLoading: false });
      }),
    };
  }

  render() {
    if (this.state.isLoading) return null;

    return (
      <InternalMessagesContextProvider>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Provider store={this.state.store}>
              <FeatureFlagsProvider>
                <ConnectedRouter history={history}>
                  <ReactQueryErrorBoundary>
                    <QueryParamProvider ReactRouterRoute={Route}>
                      <Notifications />
                      <AnnouncementNotificationContainer />
                      <ModalContainer />
                      <AnythingDirty />
                      <AuthenticateOrLogin />
                    </QueryParamProvider>
                  </ReactQueryErrorBoundary>
                  <WatermarkIfFlagged />
                </ConnectedRouter>
              </FeatureFlagsProvider>
            </Provider>
          </QueryClientProvider>
        </ThemeProvider>
      </InternalMessagesContextProvider>
    );
  }
}

const root = document.getElementById("root");
root && ReactDOM.render(<App />, root);
