import * as pot from "../../api/pot";
import { admissionsTeam, schoolsToTeams, Team } from "../../model/team";
import { School } from "../../model/types";

export interface LoadedState {
  allSchools: School[];
  allTeams: Team[];
  currentTeam: Team;
}

export interface MyState {
  pot: pot.Pot<LoadedState>;
}

export const emptyState: LoadedState = {
  allSchools: [],
  allTeams: schoolsToTeams([]),
  currentTeam: admissionsTeam,
};

export const initialState: MyState = {
  pot: pot.empty<LoadedState>(),
};
