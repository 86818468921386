import _ from "lodash";

const regex = /QM(-[0-9]+)/i;

export function format(id: number): string {
  return id < 0 ? "Unknown" : "QM" + _.padStart(`${id}`, 5, "0");
}

export function parse(num: string): number | null {
  const match = num.match(regex);
  return match == null ? null : parseInt(match[1], 10);
}
