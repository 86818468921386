import { PredictionGroupingEnum } from "@qmspringboard/shared/dist/model/enums";
import React from "react";
import { Segment } from "semantic-ui-react";
import styled from "styled-components";
import { DropdownField, DropdownOptions } from "../../components/fields";
import { PredictionGrouping, PredictionOrganisation, School } from "../../model/types";
import { PredictionFees } from "../../reducers/predictions/state";
import { admissionsOrg, allOrg, QueryStringState, unsafeSchoolOrg } from "./type";

export interface ParamsToolbarProps {
  qss: QueryStringState;
  pushQss: (qss: QueryStringState) => void;
  schools: School[];
}

export const ToolbarSegment = styled(Segment)`
  .ui.dropdown {
    margin-left: 1ex;
    margin-right: 1ex;
    min-width: auto !important;
  }

  .ui.dropdown.organisation .text {
    min-width: 9em;
  }

  .ui.dropdown.grouping .text {
    min-width: 8em;
  }

  .ui.dropdown.fees .text {
    min-width: 14em;
  }
`;

// Return type for adjustPredictionGroupings() below:
interface AdjustedPredictionGroupings {
  grouping: PredictionGrouping;
  groupingOptions: DropdownOptions<PredictionGrouping>;
}

// Certain groupings can only be combined with certain organisations.
// This function returns a valid current grouping
// and a valid set of grouping dropdown options
// given a requested organisation and grouping.
function adjustPredictionGroupings(org: PredictionOrganisation, requestedGrouping: PredictionGrouping): AdjustedPredictionGroupings {
  let validGroupings: PredictionGrouping[];
  switch (org) {
    case allOrg: {
      validGroupings = [PredictionGroupingEnum.Group, PredictionGroupingEnum.School];
      break;
    }

    case admissionsOrg: {
      validGroupings = [PredictionGroupingEnum.Group, PredictionGroupingEnum.School];
      break;
    }

    default: {
      validGroupings = [PredictionGroupingEnum.Group, PredictionGroupingEnum.Programme];
      break;
    }
  }

  const grouping: PredictionGrouping = validGroupings.includes(requestedGrouping) ? requestedGrouping : validGroupings[0];

  const groupingOptions: DropdownOptions<PredictionGrouping> = PredictionGroupingEnum.dropdownOptions(false).filter(opt =>
    validGroupings.includes(opt.value),
  );

  return { grouping, groupingOptions };
}

export default function ParamsToolbar(props: ParamsToolbarProps) {
  const { qss, pushQss, schools } = props;

  const { org, grouping: requestedGrouping, fees } = qss;

  const orgOptions: DropdownOptions<PredictionOrganisation> = [
    { value: allOrg, label: "the whole college" },
    { value: admissionsOrg, label: "Admissions" },
    ...schools.map(school => ({
      value: unsafeSchoolOrg(school.code),
      label: school.code,
    })),
  ];

  const { grouping, groupingOptions } = adjustPredictionGroupings(org, requestedGrouping);

  const feesOptions: DropdownOptions<PredictionFees> = [
    {
      key: "overall",
      value: "overall",
      label: "home and overseas applicants",
    },
    { key: "home", value: "home", label: "home applicants only" },
    { key: "overseas", value: "overseas", label: "overseas applicants only" },
  ];

  return (
    <ToolbarSegment basic vertical>
      {" Show "}
      {" predictions for "}
      <DropdownField
        value={org}
        options={orgOptions}
        className="organisation"
        onChange={org =>
          pushQss({
            ...qss,
            org,
            grouping: adjustPredictionGroupings(org, grouping).grouping,
          })
        }
      />
      {" grouped by "}
      <DropdownField
        value={grouping}
        options={groupingOptions}
        className="grouping"
        onChange={grouping =>
          pushQss({
            ...qss,
            grouping: adjustPredictionGroupings(org, grouping).grouping,
          })
        }
      />
      <span>
        {" showing "}
        <DropdownField
          value={fees}
          options={feesOptions}
          className="fees"
          onChange={fees =>
            pushQss({
              ...qss,
              fees,
              grouping: adjustPredictionGroupings(org, grouping).grouping,
            })
          }
        />
      </span>
    </ToolbarSegment>
  );
}
