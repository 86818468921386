import { Messages } from "../../model/errors";
import { fromApplicantEditorDTO } from "../../model/applicant";
import { MyState as AttachmentState, initialState as initialAttachmentState } from "../applicantAttachments/state";
import { ApplicantEditorDTO } from "@qmspringboard/shared/src/model/applications.generated";

export const defaultResource: ApplicantEditorDTO = {
  applicant: {
    id: -1,
    createdAt: "",
    details: {
      ucasPersonalId: null,
      studentId: null,
      surname: null,
      forenames: null,
      telephone1: null,
      telephone2: null,
      email: null,
      sitsHomeEmail: null,
      dateOfBirth: null,
      version: 0,
    },
    qualifications: {
      list: [],
      english: null,
      secondaryEducation: null,
      version: 0,
    },
  },
  applications: [],
  labels: [],
  notes: [],
};

export type Sections = {
  details: boolean;
  transfers: boolean;
  qualifications: boolean;
  application: boolean;
  note: boolean;
};

export const emptySections: Sections = {
  details: false,
  transfers: false,
  qualifications: false,
  application: false,
  note: false,
};

export const initialState = {
  fetching: false,
  fetched: false,
  busy: false,
  dirty: false,
  selectedApplicationIndex: null as number | null,
  selectedNoteIndex: null as number | null,
  saving: emptySections,
  editing: emptySections,
  clientMessages: [] as Messages,
  serverMessages: [] as Messages,
  dto: fromApplicantEditorDTO(defaultResource),
  dtoInitial: fromApplicantEditorDTO(defaultResource),
};

export type MyState = typeof initialState;

export type CombinedState = {
  applicant: MyState;
  applicantAttachments: AttachmentState;
};

export const initialCombinedState: CombinedState = {
  applicant: initialState,
  applicantAttachments: initialAttachmentState,
};
