import { sortBy, without } from "lodash";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { ValidationError } from "../../errors";
import { Label, Checked, Note, SearchResults, User } from "../../model/types";
import { Opt, replace } from "../../utils";
import { AppDispatch } from "../actions";
import { AppState, GetState } from "../state";
import { defaultErrorHandler, fetchWithMutex } from "../fetch";
import { MyState, initialState } from "./state";
import { MyAction } from "./actions";
import { AppReducer } from "../actions";
import { rootSelector as routeSelector } from "../route";

const myState = (state: AppState): MyState => routeSelector(state).selectorListSidebar;

export const applicantId = (state: AppState): Opt<number> => myState(state).applicantId;

export const notes = (state: AppState): Note[] => myState(state).notes;

export const labels = (state: AppState): Label[] => myState(state).labels;

export const selectedNoteIndex = (state: AppState): Opt<number> => myState(state).selectedNoteIndex;

export const selectedNoteOriginal = (state: AppState): Opt<Note> => myState(state).selectedNoteOriginal;

/////////////
// ACTIONS //
/////////////

const FETCH_START = "selectorListSidebar/FETCH_START";
const FETCH_SUCCESS = "selectorListSidebar/FETCH_SUCCESS";

const LABEL_UPDATE = "selectorListSidebar/LABEL_UPDATE";
const LABEL_SAVE_START = "selectorListSidebar/LABEL_SAVE_START";
const LABEL_SAVE_SUCCESS = "selectorListSidebar/LABEL_SAVE_SUCCESS";

const NOTE_ADD_START = "selectorListSidebar/NOTE_ADD_START";
const NOTE_ADD_SUCCESS = "selectorListSidebar/NOTE_ADD_SUCCESS";
const NOTE_UPDATE = "selectorListSidebar/NOTE_UPDATE";
const NOTE_EDIT_START = "selectorListSidebar/NOTE_EDIT_START";
const NOTE_EDIT_CANCEL = "selectorListSidebar/NOTE_EDIT_CANCEL";
const NOTE_SAVE_START = "selectorListSidebar/NOTE_SAVE_START";
const NOTE_SAVE_SUCCESS = "selectorListSidebar/NOTE_SAVE_SUCCESS";
const NOTE_DELETE_START = "selectorListSidebar/NOTE_DELETE_START";
const NOTE_DELETE_SUCCESS = "selectorListSidebar/NOTE_DELETE_SUCCESS";

//////////////
/// REDUCER //
//////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  function updateAndValidateLabels(state: MyState, labels: Label[]): MyState {
    const sorted = sortBy(labels, label => label.text.toLowerCase());
    return { ...state, labels: sorted };
  }

  function updateAndValidateNotes(state: MyState, notes: Note[]): MyState {
    return { ...state, notes };
  }

  function selectNote(state: MyState, note: Note): MyState {
    const index = state.notes.indexOf(note);
    return {
      ...state,
      selectedNoteIndex: index >= 0 ? index : null,
      selectedNoteOriginal: index >= 0 ? note : null,
    };
  }

  function revertNote(state: MyState): MyState {
    const { selectedNoteIndex: index, selectedNoteOriginal: original } = state;

    if (index != null && original != null) {
      return {
        ...state,
        notes: state.notes.map((note, i) => (i === index ? original : note)),
        selectedNoteIndex: null,
        selectedNoteOriginal: null,
      };
    } else {
      return state;
    }
  }

  function deselectNote(state: MyState): MyState {
    return {
      ...state,
      selectedNoteIndex: null,
      selectedNoteOriginal: null,
    };
  }

  switch (action.type) {
    case FETCH_START:
      return { ...state, applicantId: action.applicantId };

    case FETCH_SUCCESS:
      return updateAndValidateNotes(updateAndValidateLabels(state, action.labels), action.notes);

    case LABEL_UPDATE:
      return updateAndValidateLabels(state, replace(state.labels, action.oldLabel, action.newLabel));

    case LABEL_SAVE_START:
      return state;

    case LABEL_SAVE_SUCCESS:
      return updateAndValidateLabels(state, replace(state.labels, action.oldLabel, action.newLabel));

    case NOTE_EDIT_START:
      return selectNote(state, action.note);

    case NOTE_EDIT_CANCEL:
      return revertNote(state);

    case NOTE_UPDATE:
      return updateAndValidateNotes(state, replace(state.notes, action.oldNote, action.newNote));

    case NOTE_ADD_START:
      return state;

    case NOTE_ADD_SUCCESS:
      return selectNote(updateAndValidateNotes(state, [action.note].concat(state.notes)), action.note);

    case NOTE_SAVE_START:
      return state;

    case NOTE_SAVE_SUCCESS:
      return deselectNote(updateAndValidateNotes(state, replace(state.notes, action.oldNote, action.newNote)));

    case NOTE_DELETE_START:
      return state;

    case NOTE_DELETE_SUCCESS:
      return deselectNote(updateAndValidateNotes(state, without(state.notes, action.note)));

    default:
      return state;
  }
};

export default reducer;

/////////////////////
// ACTION CREATORS //
/////////////////////

export function fetch(applicantId: number) {
  return fetchWithMutex<[Label[], SearchResults<Note>]>({
    mutex: "fetchLabels",

    request(dispatch, getState) {
      return Promise.all([
        api.fetchLabelsForApplicant(api.fetchParams(getState()), applicantId),
        api.fetchNotesForApplicant(api.fetchParams(getState()), applicantId),
      ]);
    },

    pending(dispatch, getState, _requestId) {
      dispatch({ type: FETCH_START, applicantId });
    },

    success(dispatch, getState, [labels, notes]) {
      dispatch({ type: FETCH_SUCCESS, labels, notes: notes.items });
    },

    error: defaultErrorHandler,
  });
}

export function saveLabel(oldLabel: Label, newLabel: Label) {
  return fetchWithMutex<Label>({
    mutex: "saveLabel",

    request(dispatch, getState) {
      return api.saveLabel(api.fetchParams(getState()), newLabel);
    },

    success(dispatch, getState, newLabel) {
      toast.success("Label updated");
      dispatch({ type: LABEL_SAVE_SUCCESS, oldLabel, newLabel });
    },

    error: defaultErrorHandler,
  });
}

export function addNote(author: User, schoolCode: Opt<string>) {
  return function (dispatch: AppDispatch, getState: GetState) {
    dispatch({
      type: NOTE_ADD_SUCCESS,
      note: {
        applicantId: applicantId(getState()),
        authorUserId: author.id,
        authorName: `${author.forenames} ${author.surname}`,
        authorSchoolCode: author.defaultSchoolCode,
        schoolCode: schoolCode,
        text: "",
        timestamp: new Date().toISOString(),
        id: -1,
      },
    } as MyAction);
  };
}

export function updateNote(oldNote: Note, newNote: Note): MyAction {
  return { type: NOTE_UPDATE, oldNote, newNote };
}

export function saveNote(oldNote: Note, newNote: Note) {
  return fetchWithMutex<Checked<Note>>({
    mutex: "saveNote",

    request(dispatch, getState) {
      const apptId = applicantId(getState());

      return apptId == null
        ? Promise.reject("Badness")
        : oldNote.id === -1
          ? api.addNote(api.fetchParams(getState()), apptId, newNote)
          : api.updateNote(api.fetchParams(getState()), newNote);
    },

    success(dispatch, getState, checked) {
      if (checked.data) {
        toast.success("Note saved");
        dispatch({
          type: NOTE_SAVE_SUCCESS,
          oldNote,
          newNote: checked.data,
        });
      }

      if (checked.messages) {
        // TODO: Handle validation errors!
        throw new ValidationError(checked.messages);
      }
    },

    error: defaultErrorHandler,
  });
}

export function deleteNote(note: Note) {
  return fetchWithMutex({
    mutex: "deleteNote",

    request(dispatch, getState) {
      if (note.id < 0) {
        return Promise.resolve();
      }
      return api.deleteNote(api.fetchParams(getState()), note.id);
    },

    pending(dispatch, getState) {
      dispatch({ type: NOTE_DELETE_START });
    },

    success(dispatch, getState, _result) {
      toast.success("Note deleted");
      dispatch({ type: NOTE_DELETE_SUCCESS, note });
    },
  });
}
