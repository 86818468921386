import { ReportingGroup, Message, SchoolCode, unsafeTag } from "../../model/types";
import { ReportingGroupTypeEnum } from "@qmspringboard/shared/dist/model/enums";

export interface MyState {
  fetching: boolean;
  fetched: boolean;
  group: ReportingGroup;
  originalGroup: ReportingGroup;
  clientMessages: Message[];
  serverMessages: Message[];
}

export function emptyReportingGroup(schoolCode: SchoolCode = unsafeTag<"SchoolCode">("")): ReportingGroup {
  return {
    code: "",
    name: "",
    schoolCode,
    ufAimHome: 0,
    ufAimOverseas: 0,
    groupType: ReportingGroupTypeEnum.Reporting,
    programmeCodes: [],
    id: -1,
  };
}

export function initialState(): MyState {
  const group = emptyReportingGroup();

  return {
    fetching: false,
    fetched: false,
    group: group,
    originalGroup: group,
    clientMessages: [],
    serverMessages: [],
  };
}
