import { Button, Modal, Segment } from "semantic-ui-react";
import { AppError, ConcurrentModificationError, ForbiddenError } from "../errors";
import PermissionsView from "./PermissionsView";
import { isEqual, keys } from "lodash";
import { loginUrl } from "../api";

import React from "react";

// There is duplication here with dialog text from from src/reduces/fetch/errors.tsx
// but here we return a Modal component instead of dispatching an action (via a reducer).

const reloadPage = () => window.location.reload();

export const forbiddenModal = (error: ForbiddenError, onOk: () => void) => {
  return (
    <Modal open={true} onClose={onOk} size="small">
      <Modal.Content>
        <div>
          <p>You didn&apos;t have all the necessary permissions for that.</p>
          {error.missingPermissions && error.missingPermissions.length > 0 && (
            <Segment>
              <p>
                <strong>Missing permissions:</strong>
              </p>
              <PermissionsView permissions={error.missingPermissions} />
            </Segment>
          )}
          {error.failedCheck && (
            <Segment>
              <p>
                <strong>Failed permission check:</strong>
              </p>
              <pre>{JSON.stringify(error.failedCheck, null, 2)}</pre>
            </Segment>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onOk}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const concurrentModificationModal = (error: ConcurrentModificationError, onOk: () => void) => {
  return (
    <Modal open={true} size="small">
      <Modal.Content>
        <div>
          <p>Someone else modified that record since you loaded it!</p>
          <Segment basic textAlign="center">
            Record <strong>{error.item}</strong> of type <strong>{error.itemType}</strong>
          </Segment>
          <p>
            Your changes have <em>not</em> been saved. Reload the page and try again on the updated record.
          </p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onOk}>
          Keep going and hope things clear up
        </Button>
        <Button primary onClick={reloadPage}>
          Reload the page
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const defaultModal = (error: AppError) => {
  return (
    <Modal open={true} size="small">
      <Modal.Content>
        <div>
          <p>Something went wrong. Here&apos;s the error message:</p>
          <p style={{ textAlign: "center" }}>
            <strong>{error.message}</strong>
          </p>
          {!isEqual(keys(error), ["message"]) && (
            <div>
              <p>Here&apos;s some extra detail that should help the development team:</p>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={reloadPage}>
          Reoload the page
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const youNeedToLoginDialog = () => {
  const logout = () => (window.location.href = loginUrl((window.location.pathname || "/") + (window.location.search || "")));
  return (
    <Modal open={true} onClose={logout} size="small">
      <Modal.Content>
        <div>
          <p>You need to be logged in</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={logout}>
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
