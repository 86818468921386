import { AppError } from "../errors";

export type Empty = {
  state: "empty";
};

export type Pending = {
  state: "pending";
  requestId: string;
};

export type Ready<A> = {
  state: "ready";
  value: A;
};

export type Failed = {
  state: "failed";
  error: AppError;
};

export type Pot<A> = Empty | Pending | Ready<A> | Failed;

// Construtors ----------------------------------

export function empty<A>(): Pot<A> {
  return { state: "empty" };
}

export function pending<A>(requestId: string): Pot<A> {
  return { state: "pending", requestId };
}

export function ready<A>(value: A): Pot<A> {
  return { state: "ready", value };
}

export function failed<A>(error: AppError): Pot<A> {
  return { state: "failed", error };
}

// Accessors ------------------------------------

export function fold<A, B>(pot: Pot<A>, ifEmpty: () => B, ifFull: (a: A) => B): B {
  switch (pot.state) {
    case "ready":
      return ifFull(pot.value);
    default:
      return ifEmpty();
  }
}

export function hasValue<A>(pot: Pot<A>): boolean {
  switch (pot.state) {
    case "ready":
      return true;
    default:
      return false;
  }
}

export function get<A>(pot: Pot<A>): A | null {
  switch (pot.state) {
    case "ready":
      return pot.value;
    default:
      return null;
  }
}

export function getUnsafe<A>(pot: Pot<A>): A {
  switch (pot.state) {
    case "ready":
      return pot.value;
    default:
      throw new Error("Invalid attempt to read from pot: " + JSON.stringify(pot));
  }
}

export function orElse<A>(pot1: Pot<A>, pot2: Pot<A>): Pot<A> {
  switch (pot1.state) {
    case "ready":
      return pot1;
    default:
      return pot2;
  }
}

export function getOrElse<A>(pot: Pot<A>, orElse: A): A {
  switch (pot.state) {
    case "ready":
      return pot.value;
    default:
      return orElse;
  }
}

export function fetching<A>(pot: Pot<A>): boolean {
  switch (pot.state) {
    case "pending":
      return true;
    default:
      return false;
  }
}

export function isEmpty<A>(pot: Pot<A>): boolean {
  switch (pot.state) {
    case "empty":
      return true;
    default:
      return false;
  }
}

export function requestId<A>(pot: Pot<A>): string | null {
  switch (pot.state) {
    case "pending":
      return pot.requestId;
    default:
      return null;
  }
}

export function hasError<A>(pot: Pot<A>): boolean {
  switch (pot.state) {
    case "failed":
      return true;
    default:
      return false;
  }
}

export function error<A>(pot: Pot<A>): AppError | null {
  switch (pot.state) {
    case "failed":
      return pot.error;
    default:
      return null;
  }
}

// Other combinators ----------------------------

export function map<A, B>(pot: Pot<A>, func: (a: A) => B): Pot<B> {
  switch (pot.state) {
    case "ready":
      return ready(func(pot.value));
    default:
      return pot;
  }
}
