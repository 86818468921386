import { ApplicantEditorDTO } from "@qmspringboard/shared/src/model/applications.generated";
import { Button, Header, Modal } from "semantic-ui-react";
import React from "react";

interface SaveModalProps {
  dto: ApplicantEditorDTO;
  onCancel: () => void;
  onSaveWithEmail: () => void;
  onSaveWithoutEmail: () => void;
}

export const SaveModal = ({ dto, onCancel, onSaveWithEmail, onSaveWithoutEmail }: SaveModalProps) => {
  if (dto.applicant.details.email) {
    return <WithEmailModal onCancel={onCancel} onSaveWithEmail={onSaveWithEmail} onSaveWithoutEmail={onSaveWithoutEmail} />;
  } else {
    return <NoEmailModal onCancel={onCancel} onSave={onSaveWithoutEmail}></NoEmailModal>;
  }
};

interface WithEmailProps {
  onCancel: () => void;
  onSaveWithEmail: () => void;
  onSaveWithoutEmail: () => void;
}

const WithEmailModal = ({ onCancel, onSaveWithEmail, onSaveWithoutEmail }: WithEmailProps) => {
  return (
    <Modal size="small" open={true} onClose={onCancel}>
      <Header icon="info circle" content="Send email confirmation?" />
      <Modal.Content>
        <p>Do you want to send email confirmation to the applicant?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button secondary onClick={onSaveWithoutEmail}>
          No - just save the application
        </Button>
        <Button primary onClick={onSaveWithEmail}>
          Yes - send the email
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

interface NoEmailProps {
  onCancel: () => void;
  onSave: () => void;
}

const NoEmailModal = ({ onCancel, onSave }: NoEmailProps) => {
  return (
    <Modal size="small" open={true} onClose={onCancel}>
      <Header icon="info circle" content="Cannot send email" />
      <Modal.Content>
        <p>The applicant has no email address. We cannot send them an email confirmation.</p>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={onSave}>
          Confirm
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
