import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { Button, Container, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import LabelPrototypeEditor from "../components/LabelPrototypeEditor";
import SubmitButton from "../components/SubmitButton";
import Title from "../components/Title";
import { LabelPrototype, Message } from "../model/types";
import * as labelPrototypeUpdate from "../reducers/labelPrototypeUpdate";
import { Opt, safeParseInt } from "../utils";
import { withModal, WithModalProps } from "../utils/modal";

interface Params {
  id: string;
}

interface Props extends WithModalProps {
  match: Match<Params>;
  fetching: boolean;
  fetched: boolean;
  proto: Opt<LabelPrototype>;
  messages: Message[];
  onInit: () => void;
  onLoad: (id: number) => void;
  onUpdate: (proto: LabelPrototype) => void;
  onSave: (proto: LabelPrototype) => void;
  onDelete: (proto: LabelPrototype) => void;
  onClose: () => void;
}

class LabelPrototypeEditorPage extends React.PureComponent<Props> {
  getId = (props: Props) => safeParseInt(props.match.params.id, null);

  componentDidMount() {
    const { onInit, onLoad } = this.props;
    const id = this.getId(this.props);
    if (id) {
      onLoad(id);
    } else {
      onInit();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { onInit, onLoad } = nextProps;
    const oldId = this.getId(this.props);
    const newId = this.getId(nextProps);
    if (oldId !== newId) {
      if (newId) {
        onLoad(newId);
      } else {
        onInit();
      }
    }
  }

  handleSubmit = () => {
    const { proto, onSave, onClose } = this.props;
    proto && (proto.isSystem ? onClose() : onSave(proto));
  };

  handleDelete = () => {
    const { proto, onDelete } = this.props;

    this.props.showModal("confirm", {
      title: "Delete label",
      content: "Are you sure?",
      onPrimaryClick: () => proto && onDelete(proto),
    });
  };

  render() {
    const { fetching, proto, onUpdate, messages } = this.props;

    return (
      <Title title={proto == null || proto.id < 0 ? "New Label" : proto.text}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            {proto && <LabelPrototypeEditor value={proto} readOnly={proto.isSystem} messages={messages} onChange={onUpdate} />}

            <Divider hidden />

            <div>
              {proto && proto.isSystem && (
                <Segment basic vertical floated="left">
                  This is a system label: it cannot be changed.
                </Segment>
              )}

              <Segment basic vertical textAlign="right">
                {proto && !proto.isSystem && proto.id >= 0 && (
                  <Button color="red" onClick={this.handleDelete}>
                    Delete
                  </Button>
                )}

                <SubmitButton onClick={this.handleSubmit} messages={messages}>
                  {proto && proto.isSystem ? "Close" : "Save"}
                </SubmitButton>
              </Segment>
            </div>
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    proto: labelPrototypeUpdate.proto(state),
    messages: labelPrototypeUpdate.messages(state),
    fetching: labelPrototypeUpdate.fetching(state),
    fetched: labelPrototypeUpdate.fetched(state),
  }),
  {
    onInit: labelPrototypeUpdate.initialise,
    onLoad: labelPrototypeUpdate.load,
    onUpdate: labelPrototypeUpdate.update,
    onSave: labelPrototypeUpdate.save,
    onDelete: labelPrototypeUpdate.del,
    onClose: labelPrototypeUpdate.cancel,
  },
)(withModal(LabelPrototypeEditorPage));
