import { groupBy, sortBy, keys } from "lodash";
import React from "react";
import { Header, List, Segment } from "semantic-ui-react";
import { Permission } from "../model/types";

type Props = {
  permissions: Permission[];
};

const PermissionsView = ({ permissions }: Props) => {
  const grouped = groupBy(
    sortBy(permissions, perm => perm.school),
    perm => perm.school || "AAAAA",
  );
  const schools = sortBy(keys(grouped));
  return (
    <div>
      {schools.map(school => {
        const perms = sortBy(grouped[school], perm => perm.type);
        return (
          <Segment basic vertical key={school}>
            <Header as="h4">{school === "AAAAA" ? "Global" : school} Permissions</Header>
            <List>
              {perms.map((perm, index) => (
                <List.Item key={index}>
                  <List.Content>
                    <List.Description>{perm.type}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        );
      })}
    </div>
  );
};

export default PermissionsView;
