import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Loader } from "semantic-ui-react";
import { withRouter, Route, Switch, Redirect, RouteComponentProps } from "react-router";
import authRoutes from "../routes/authenticated";

import { User, SearchResults, School, TeamCode } from "../model/types";
import * as teams from "../reducers/teams";
import * as auth from "../reducers/auth";
import { Opt } from "../utils";
import { AppState } from "../reducers/state";
import { schoolToTeamCode } from "../model/team";

type Props = {
  bearerToken: Opt<string>;
  currentUser: Opt<User>;
  fetchTeams: (defaultTeamCode?: TeamCode) => Promise<SearchResults<School>>;
  fetching: boolean;
  checkAuthentication: () => void;
};

type AllProps = Props & RouteComponentProps;

type State = {
  firstInit: boolean;
};

class AuthenticateOrLogin extends Component<AllProps, State> {
  state = {
    firstInit: false,
  };

  componentDidMount() {
    this.checkAuth(this.props);
    if (this.props.currentUser) {
      this.props.fetchTeams();
    }
    this.setState({ firstInit: true });
  }

  UNSAFE_componentWillReceiveProps(nextProps: AllProps) {
    if (nextProps.bearerToken !== this.props.bearerToken) {
      this.checkAuth(nextProps);
    }
    if (nextProps.currentUser !== this.props.currentUser) {
      if (nextProps.currentUser) {
        if (nextProps.currentUser.defaultSchoolCode) {
          const team = schoolToTeamCode(nextProps.currentUser.defaultSchoolCode);
          this.props.fetchTeams(team);
        } else {
          this.props.fetchTeams();
        }
      }
    }
  }

  checkAuth(props: AllProps) {
    if (props.currentUser == null) {
      props.checkAuthentication();
    }
  }

  renderBusy() {
    return (
      <Modal basic dimmer="inverted" closeOnEscape={false} closeOnDocumentClick={false} defaultOpen={true}>
        <Modal.Content>
          <Loader>Reticulating splines...</Loader>
        </Modal.Content>
      </Modal>
    );
  }

  render() {
    const { fetching, currentUser } = this.props;

    // this is more complicated than it first seems
    // on first render we are firstInit false + not fetching *or* fetched
    // we then go to fetching as we get the user
    // we can't check fetched state as logging out sets us back to empty pot
    if (fetching || !this.state.firstInit) {
      return this.renderBusy();
    }

    const allowedRoutes = currentUser ? authRoutes : [];

    return (
      <Switch>
        {allowedRoutes.map(({ component, path, exact }, i) => (
          <Route key={i} component={component} path={path} exact={exact ?? false} />
        ))}
        {currentUser && <Redirect to="/" />}
      </Switch>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  bearerToken: auth.bearerToken(state),
  currentUser: auth.currentUser(state),
  authError: auth.authError(state),
  fetching: teams.fetchingTeams(state) || auth.fetching(state),
});

const mapActionsToProps = {
  checkAuthentication: auth.checkAuthentication,
  fetchTeams: teams.initialise,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AuthenticateOrLogin));
