import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Container, Header, Icon, Segment } from "semantic-ui-react";
import styled from "styled-components";
import { isFailedApplication } from "@qmspringboard/shared/dist/model/application";
import { ApplicationChoiceEnum } from "../model/enums";
import { Permissions } from "../model/permission";
import { ProgrammeCode, programmeCodeToUcasCourseCode } from "../model/programme";
import { Application, Programme } from "../model/types";
import * as programmeList from "../reducers/programmeList";
import { AppState } from "../reducers/state";
import ApplicationStatusIndicator from "./ApplicationStatusIndicator";
import { withModal } from "../utils/modal";

type Props = {
  applicantId: number;
  applications: Application[];
  findProgramme: (code: ProgrammeCode) => Programme | undefined;
  onSetHandover: (appn: Application, handover: boolean) => void;
  showModal: Function;
  permissions: Permissions;
  anythingEditing?: boolean;
};

const LabelContainer = styled.div`
  margin: 0.5em 0;
`;

class ApplicantHandover extends React.PureComponent<Props> {
  handleApplicationClick = (appn: Application) => {
    this.props.onSetHandover(appn, !appn.handover);
  };

  applicationLabel = (appn: Application): ReactElement => {
    const style = { marginTop: ".25em", marginBottom: ".25em" };
    return (
      <label style={style}>
        {ApplicationChoiceEnum.labelOf(appn.choice) || appn.choice}
        {", "}
        {programmeCodeToUcasCourseCode(appn.programmeCode)}
        {", "}
        <ApplicationStatusIndicator statuses={appn} />
      </label>
    );
  };

  userHasHandoverPermission = (appn: Application): boolean => {
    const prog: Programme | undefined = this.props.findProgramme(appn.programmeCode);

    return prog != null && this.props.permissions.canHandoverApplications(prog.schoolCode);
  };

  canRevertHandover = (appn: Application): boolean => this.userHasHandoverPermission(appn);

  canHandover = (appn: Application): boolean => !isFailedApplication(appn) && this.userHasHandoverPermission(appn);

  render() {
    const { applications, anythingEditing } = this.props;

    const handedOver = applications.find(appn => appn.handover);

    const canHandoverAny = !handedOver && applications.find(this.canHandover) != null;

    return (
      <Container>
        <Header as="h3" dividing>
          Handover
        </Header>

        {!handedOver && (
          <Segment basic>
            <p style={{ textAlign: "center" }}>
              This applicant has not been handed over to admissions.
              {canHandoverAny && " Click an application below to start:"}
            </p>

            {canHandoverAny &&
              applications.map((appn, index) => {
                return (
                  <LabelContainer key={index}>
                    <Checkbox
                      checked={appn.handover}
                      disabled={!this.canHandover(appn) || anythingEditing}
                      label={this.applicationLabel(appn)}
                      onChange={() => this.handleApplicationClick(appn)}
                    />
                  </LabelContainer>
                );
              })}
          </Segment>
        )}

        {handedOver && (
          <Segment color="green" style={{ background: "#eeffee" }}>
            <p>This application has been handed over to admissions:</p>

            <div style={{ textAlign: "center", marginBottom: "1em" }}>{this.applicationLabel(handedOver)}</div>

            {this.canRevertHandover(handedOver) && (
              <div>
                <Button basic fluid onClick={() => this.handleApplicationClick(handedOver)} style={{ background: "#fff" }} disabled={anythingEditing}>
                  <Icon name="warning sign" />
                  Revert Handover
                </Button>
              </div>
            )}
          </Segment>
        )}
      </Container>
    );
  }
}

export default connect(
  (state: AppState) => ({
    findProgramme: (code: ProgrammeCode) => programmeList.findProgramme(code)(state),
  }),
  {},
)(withModal(ApplicantHandover));
