import React, { useState } from "react";
import { Container, Grid, Icon, List, Segment } from "semantic-ui-react";
import { EnumEntry } from "../model/enums";

interface Props {
  keywords: EnumEntry<string>[];
}

export default function SearchHelp(props: Props) {
  const { keywords } = props;

  const [show, setShow] = useState(false);

  return (
    <Container fluid>
      {!show && (
        <Segment basic vertical>
          {/* eslint-disable-next-line */}
          <a onClick={() => setShow(!show)} style={{ cursor: "pointer" }}>
            <Icon name="caret down" />
            Show search help
          </a>
        </Segment>
      )}

      {show && (
        <Segment basic vertical>
          {/* eslint-disable-next-line */}
          <a onClick={() => setShow(!show)} style={{ cursor: "pointer" }}>
            <Icon name="caret up" />
            Hide search help
          </a>
        </Segment>
      )}

      {show && (
        <Segment style={{ margin: 0 }}>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <p>
                  <strong>Basic searches</strong>
                </p>

                <p>
                  Enter single words search for <em>all</em> of them in the same record. For example, search for <code>smith defer</code> to find all
                  applications containing the words &quot;smith&quot; and &quot;defer&quot;.
                </p>

                <p>
                  Surround multi-word search terms with &quot;double quotes&quot;. For example, search for <code>&quot;john smith&quot;</code> to find
                  &quot;John Smith&quot; without finding any other Johns or any other Smiths.
                </p>

                <p>
                  <strong>Boolean searches</strong>
                </p>

                <p>
                  The words <em>and</em>, <em>or</em>, and <em>not</em> have special meaning. For example, you can search for{" "}
                  <code>smith or jones</code>
                  to find all applications containing either &quot;smith&quot; or &quot;jones&quot;. You can use brackets to group terms, for example{" "}
                  <code>smith or (jones and not fred)</code>.
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={10} computer={10}>
                <p>
                  <strong>Keyword searches</strong>
                </p>

                <p>
                  You can use the keywords below to target specific fields in your searches. For example, search for <code>prog:c100</code> to find
                  all applicants applying to <em>C100 Biology</em>:
                </p>

                <List>
                  {keywords.map((keyword, index) => (
                    <List.Item key={index}>
                      <List.Content>
                        <List.Header>{keyword.value}</List.Header>
                        <List.Description>{keyword.label}</List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}
    </Container>
  );
}
