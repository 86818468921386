import React, { createContext } from "react";
import { useFeatureFlags } from "@qmspringboard/app/src/api";
import { FeatureFlags } from "@qmspringboard/app/src/model/types.generated";

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
}

export const FeatureFlagsContext = createContext<FeatureFlags>({
  environmentFriendlyName: "LOADING",
  showWatermark: true,
  clickToCallEnabled: false,
});

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({ children }) => {
  const { data } = useFeatureFlags();

  if (data) {
    return <FeatureFlagsContext.Provider value={data}>{children}</FeatureFlagsContext.Provider>;
  }

  return null;
};
