import React, { Component, MouseEvent } from "react";
import { connectModal } from "redux-modal";
import { Modal, Header, Button } from "semantic-ui-react";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  onPrimaryClick: (e: MouseEvent) => void;
  onSecondaryClick?: (e: MouseEvent) => void;
}

export default connectModal({ name: "changeProgramme" })(
  class ModalChangeProgramme extends Component<Props> {
    onPrimaryClick = (e: MouseEvent) => {
      this.props.onPrimaryClick(e);
      this.props.handleHide();
    };

    onSecondaryClick = (e: MouseEvent) => {
      this.props.onSecondaryClick?.(e);
      this.props.handleHide();
    };

    render() {
      const { show } = this.props;

      return (
        <Modal open={show} onClose={this.props.handleHide} size="small">
          <Header icon="info circle" content="Change of programme" />
          <Modal.Content>
            <p>A change of programme form must be filled in and submitted to the College.</p>

            <p>Click OK to save the application immediately and flag it as changing, so an administrator can follow this up.</p>

            <p>Once this is started, an adminsitrator will have to confirm or cancel the change before this field can be modified again.</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onSecondaryClick}>Cancel</Button>
            <Button primary onClick={this.onPrimaryClick}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
  },
);
