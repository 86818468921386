import {
  ApplicationChoice,
  ApplicationChoiceEnum,
  ApplicationMethod,
  ApplicationMethodEnum,
  ApplicationStatus,
  ApplicationStatusEnum,
  ClearingDecisionEnum,
  ClearingResponseEnum,
  ConfirmationDecisionEnum,
  ConfirmationResponseEnum,
  InitialDecisionEnum,
  InitialResponseEnum,
  OfferStatus,
  OfferStatusEnum,
} from "./enums";
import { Application } from "./types";
import { Opt } from "../utils";

export function applicationChoiceToMethod(choice: ApplicationChoice): ApplicationMethod {
  switch (choice) {
    case ApplicationChoiceEnum.Clearing:
      return ApplicationMethodEnum.Clearing;
    case ApplicationChoiceEnum.First:
    case ApplicationChoiceEnum.Second:
    case ApplicationChoiceEnum.Third:
    case ApplicationChoiceEnum.Fourth:
    case ApplicationChoiceEnum.Fifth:
    case ApplicationChoiceEnum.Extra:
    default:
      return ApplicationMethodEnum.Ucas;
  }
}

export function offerStatusCanExpire(status: Opt<OfferStatus>): boolean {
  switch (status) {
    case OfferStatusEnum.Made:
    case OfferStatusEnum.MadePreRelease:
      return true;
    default:
      return false;
  }
}

export const isEmailOfferStatus = (offerStatus: Opt<OfferStatus>): boolean => {
  switch (offerStatus) {
    case OfferStatusEnum.Made:
    case OfferStatusEnum.MadePreRelease:
    case OfferStatusEnum.WaitingList:
    case OfferStatusEnum.Interview:
    case OfferStatusEnum.Rejected:
      return true;
    default:
      return false;
  }
};

export const applicationRequiresConfirmationEmail = (currAppn: Application, origAppn?: Application | null): boolean =>
  origAppn ? isEmailOfferStatus(currAppn.offerStatus) && origAppn.offerStatus !== currAppn.offerStatus : isEmailOfferStatus(currAppn.offerStatus);

export const isSuccessfulOfferStatus = (status: Opt<OfferStatus>): boolean => status === OfferStatusEnum.Confirmed;

export const isSuccessfulApplicationStatus = (status: Opt<ApplicationStatus>): boolean => status === ApplicationStatusEnum.Transferred;

export const isFailedOfferStatus = (status: Opt<OfferStatus>): boolean => status === OfferStatusEnum.Declined || status === OfferStatusEnum.Rejected;

export const isFailedApplicationStatus = (status: Opt<ApplicationStatus>): boolean =>
  status === ApplicationStatusEnum.Withdrawn ||
  status === ApplicationStatusEnum.OtherInstitution ||
  status === ApplicationStatusEnum.OtherProgramme ||
  status === ApplicationStatusEnum.Released ||
  status === ApplicationStatusEnum.ReverseTransfer ||
  status === ApplicationStatusEnum.Deferred ||
  status === ApplicationStatusEnum.Cancelled ||
  status === ApplicationStatusEnum.Miscoded;

export const isSuccessfulApplication = (appn: Application): boolean =>
  isSuccessfulOfferStatus(appn.offerStatus) ||
  isSuccessfulApplicationStatus(appn.applicationStatus) ||
  appn.confirmationResponse === ConfirmationResponseEnum.Firm;

export const isFailedApplication = (appn: Application): boolean =>
  isFailedOfferStatus(appn.offerStatus) ||
  isFailedApplicationStatus(appn.applicationStatus) ||
  appn.clearingDecision === ClearingDecisionEnum.Rejected ||
  appn.clearingResponse === ClearingResponseEnum.Declined ||
  appn.confirmationDecision === ConfirmationDecisionEnum.Rejected ||
  appn.confirmationResponse === ConfirmationResponseEnum.Declined ||
  appn.initialDecision === InitialDecisionEnum.Reject ||
  appn.initialResponse === InitialResponseEnum.Declined;

export const isLiveApplication = (appn: Application): boolean => !isSuccessfulApplication(appn) && !isFailedApplication(appn);

export const isAcceptedOrConfirmed = (appn: Application): boolean =>
  appn.offerStatus === OfferStatusEnum.Accepted || appn.offerStatus === OfferStatusEnum.Confirmed;
