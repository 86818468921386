import React, { ReactNode } from "react";
import { Button, ButtonGroup, ButtonOr, Segment } from "semantic-ui-react";
import { ApplicantDTO, Application, SelectorAction, SelectorListRowActions, SelectorListDataRow } from "../model/types";
import { ApplicationChoiceEnum } from "../model/enums";
import { programmeCodeToR900Label } from "../model/programme";
import { Opt } from "../utils";
import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import { SelectorListApplicant } from "../hooks/useSelectorListActions";

type Props = {
  data: SelectorListApplicant;
  row: SelectorListRowActions;
  action: SelectorAction;
  applyAction: (action: SelectorAction) => void;
  ignoreAction: (action: SelectorAction) => void;
  unignoreAction: (action: SelectorAction) => void;
};

export function SelectorListAction({ row, action, applyAction: handleAction, ignoreAction, unignoreAction }: Props) {
  switch (action.type) {
    case "ImportApplication":
      return (
        <ImportApplicationView
          action={action}
          existingApplicant={row.existingApplicant}
          selectorRow={row.selector}
          onAction={handleAction}
          onIgnore={ignoreAction}
          onUnignore={unignoreAction}
        />
      );

    case "LinkApplication":
      return (
        <LinkApplicationView
          action={action}
          application={action.application}
          markAsOfferAccepted={action.markAsOfferAccepted}
          onAction={handleAction}
          onIgnore={ignoreAction}
          onUnignore={unignoreAction}
        />
      );

    case "NameChange": {
      const { forenames, surname } = action;
      const name = forenames ? (surname ? `${forenames} ${surname}` : forenames) : surname ? surname : null;
      return (
        <ChangeActionView
          label="name"
          value={name}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );
    }

    case "TelephoneChange": {
      const { telephone } = action;
      return (
        <ChangeActionView
          label="contact telephone"
          value={telephone}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );
    }

    case "NationalityChange": {
      const { nationality } = action;
      return (
        <ChangeActionView
          label="nationality"
          value={nationality}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );
    }

    case "DateOfBirthChange": {
      const { dateOfBirth } = action;
      return (
        <ChangeActionView
          label="date of birth"
          value={dateOfBirth}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );
    }

    case "SitsHomeEmailChange": {
      const { sitsHomeEmail } = action;
      return (
        <ChangeActionView
          label="home email address"
          value={sitsHomeEmail}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );
    }

    case "StudentIdChange":
      return (
        <ChangeActionView
          label="student ID"
          value={action.studentId}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ProgrammeChange":
      return (
        <ChangeActionView
          label="programme"
          value={programmeCodeToR900Label(action.programmeCode)}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "InitialDecisionChange":
      return (
        <ChangeActionView
          label="initial decision"
          value={action.initialDecision}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "InitialResponseChange":
      return (
        <ChangeActionView
          label="initial response"
          value={action.initialResponse}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ConfirmationDecisionChange":
      return (
        <ChangeActionView
          label="confirmation decision"
          value={action.confirmationDecision}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ConfirmationResponseChange":
      return (
        <ChangeActionView
          label="confirmation response"
          value={action.confirmationResponse}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ClearingDecisionChange":
      return (
        <ChangeActionView
          label="clearing decision"
          value={action.clearingDecision}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ClearingResponseChange":
      return (
        <ChangeActionView
          label="clearing response"
          value={action.clearingResponse}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ApplicationStatusChange":
      return (
        <ChangeActionView
          label="application status"
          value={action.applicationStatus}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "FeeStatusChange":
      return (
        <ChangeActionView
          label="fee status"
          value={action.feeStatus}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "EntryPointChange":
      return (
        <ChangeActionView
          label="entry point"
          value={action.entryPoint}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "CocoChange":
      return (
        <ChangeActionView
          label="COCO status"
          value={action.coco ? "Y" : "N"}
          action={action}
          onAction={handleAction}
          ignoreAction={ignoreAction}
          unignoreAction={unignoreAction}
        />
      );

    case "ErrorAction":
      return <ErrorActionView action={action} />;

    default:
      checkExhausted(action);
  }
}
type ImportActionProps = {
  existingApplicant: Opt<ApplicantDTO>;
  selectorRow: SelectorListDataRow;
  action: SelectorAction;
  onAction: (action: SelectorAction) => void;
  onIgnore: (action: SelectorAction) => void;
  onUnignore: (action: SelectorAction) => void;
};

const ImportApplicationView = ({ existingApplicant, selectorRow, action, onAction, onIgnore, onUnignore }: ImportActionProps) => {
  const possibleSelfReferral: boolean = selectorRow.choice === ApplicationChoiceEnum.Clearing;

  const buttonLabel = possibleSelfReferral ? "Import as a possible self-referral" : "Import as a new application";

  const selfReferralText = possibleSelfReferral ? (
    <p>
      This could represent a self-referral on UCAS Track. We will mark it with an Offer Status of <strong>Self-Referral (S)</strong>.
    </p>
  ) : (
    ""
  );

  const buttons = (
    <ButtonGroup widths="2">
      <ActionButton action={action} onAction={onAction}>
        {buttonLabel}
      </ActionButton>
      <ButtonOr />
      <IgnoreSelectorListAction action={action} onAction={action.ignored ? onUnignore : onIgnore}>
        {action.ignored ? "Unignore this change" : "Ignore this change"}
      </IgnoreSelectorListAction>
    </ButtonGroup>
  );

  return existingApplicant != null ? (
    <div>
      <Segment basic vertical>
        You can import this record as a new application attached to applicant {existingApplicant.details.ucasPersonalId}:{selfReferralText}
      </Segment>
      {buttons}
    </div>
  ) : (
    <div>
      <Segment basic vertical>
        <p>You can import this record as a new applicant and application.</p>
        {selfReferralText}
      </Segment>
      {buttons}
    </div>
  );
};

type LinkActionProps = {
  action: SelectorAction;
  application: Application;
  markAsOfferAccepted: boolean;
  onAction: (action: SelectorAction) => void;
  onIgnore: (action: SelectorAction) => void;
  onUnignore: (action: SelectorAction) => void;
};

const LinkApplicationView = ({ action, application, markAsOfferAccepted, onAction, onIgnore, onUnignore }: LinkActionProps) => {
  const { choice, programmeCode, oldProgrammeCode, offerStatus } = application;

  const actionButton = (
    <ActionButton action={action} onAction={onAction}>
      {markAsOfferAccepted ? "Link and mark as accepted:" : "Link to existing application:"}
      <br />
      {ApplicationChoiceEnum.labelOf(choice) || choice}, {programmeCodeToR900Label(programmeCode)}{" "}
      {oldProgrammeCode ? <span>&lArr; {programmeCodeToR900Label(oldProgrammeCode)}</span> : ""}
      {offerStatus && (markAsOfferAccepted ? `, old offer status ${offerStatus}` : `, offer status ${offerStatus}`)}
    </ActionButton>
  );

  return (
    <div>
      <Segment basic vertical>
        This looks like an existing Springboard application. You can <em>link</em> the records to indicate that they refer to the same application:
      </Segment>

      <ButtonGroup widths="2">
        {actionButton}
        <ButtonOr />
        <IgnoreSelectorListAction action={action} onAction={action.ignored ? onUnignore : onIgnore}>
          {action.ignored ? "Unignore this change" : "Ignore this change"}
        </IgnoreSelectorListAction>
      </ButtonGroup>
    </div>
  );
};

type ChangeActionProps = {
  label: string;
  value: Opt<string>;
  action: SelectorAction;
  onAction: (action: SelectorAction) => void;
  ignoreAction: (action: SelectorAction) => void;
  unignoreAction: (action: SelectorAction) => void;
};

const ChangeActionView = ({ label, value, action, onAction, ignoreAction, unignoreAction }: ChangeActionProps) => (
  <div>
    <Segment basic vertical>
      The {label} in SITS/UCAS is different to the one in Springboard.
    </Segment>
    <ButtonGroup widths="2">
      <ActionButton action={action} onAction={onAction}>
        Import {label}: {value || "blank"}
      </ActionButton>
      <ButtonOr />
      <IgnoreSelectorListAction action={action} onAction={action.ignored ? unignoreAction : ignoreAction}>
        {action.ignored ? "Unignore this change" : "Ignore this change"}
      </IgnoreSelectorListAction>
    </ButtonGroup>
  </div>
);

type ErrorActionProps = {
  action: SelectorAction;
};

const ErrorActionView = ({ action }: ErrorActionProps) => (
  <div>
    <Segment basic vertical>
      We could not parse the information in the selector list:
    </Segment>

    <Segment>
      <pre>{JSON.stringify(action, null, 2)}</pre>
    </Segment>
  </div>
);

type ActionButtonProps = {
  action: SelectorAction;
  onAction: (action: SelectorAction) => void;
  children: ReactNode;
};

const ActionButton = ({ action, onAction, children, ...props }: ActionButtonProps) => {
  const color = action.recommended ? "green" : undefined;

  return (
    <Button color={color} onClick={() => onAction(action)} {...props}>
      {children}
    </Button>
  );
};

function IgnoreSelectorListAction({ action, children, onAction, ...props }: ActionButtonProps) {
  return (
    <Button basic={action.ignored} color="blue" onClick={() => onAction(action)} {...props}>
      {children}
    </Button>
  );
}
