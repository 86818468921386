import { Just, Maybe, Nothing } from "purify-ts/Maybe";
import React from "react";
import { BaseInput, ExtraInputProps } from "./BaseInput";
import { BaseFieldProps } from "./base";
import { Opt } from "../../utils";

export interface NullableIntegerInputProps extends BaseFieldProps<Opt<number>>, ExtraInputProps {}

function format(num: Opt<number>): string {
  return num == null ? "" : num.toFixed(0);
}

const regex = /^-?\d+$/;

function validate(str: string): Maybe<Opt<number>> {
  const trimmed = str.trim();

  if (trimmed === "") {
    return Just(null);
  } else if (regex.test(trimmed)) {
    return Just(Number(trimmed));
  } else {
    return Nothing;
  }
}

export default function NullableIntegerInput(props: NullableIntegerInputProps) {
  return <BaseInput format={format} validate={validate} {...props} />;
}
