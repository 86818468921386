import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
// import { withRouter } from "react-router";
import { Container, Dimmer, Header, Loader, Segment } from "semantic-ui-react";

import { ApplicantEditorState } from "../model/applicant";
import { format as formatTrackingNumber } from "../model/trackingNumber";
import * as applicantUpdate from "../reducers/applicantUpdate";
import Title from "../components/Title";
import { ApplicantDetailsView, QualificationsView, ApplicationView, LabelsView, NoteView } from "../components/DetailsView";
import { Opt, safeParseInt } from "../utils";

interface Params {
  applicantId: string;
}

interface Props {
  match: Match<Params>;
  fetching: boolean;
  fetched: boolean;
  dto: ApplicantEditorState;
  onLoad: (apptId: number, appnId?: Opt<number>) => void;
}

class ApplicantPrintPage extends React.Component<Props> {
  componentDidMount() {
    const { applicantId: applicantIdString } = this.props.match.params;
    const applicantId = safeParseInt(applicantIdString, null);
    if (applicantId != null) {
      this.props.onLoad(applicantId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { applicantId: oldApplicantIdString } = this.props.match.params;
    const { applicantId: newApplicantIdString } = nextProps.match.params;
    const oldApplicantId = safeParseInt(oldApplicantIdString, null);
    const newApplicantId = safeParseInt(newApplicantIdString, null);
    if (newApplicantId != null && oldApplicantId !== newApplicantId) {
      this.props.onLoad(newApplicantId);
    }
  }

  render() {
    const { fetching, dto } = this.props;

    return (
      <Title title={`${formatTrackingNumber(dto.applicant.id)} Print Preview`}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            {dto && (
              <Segment basic vertical>
                <Header as="h3">Applicant Details</Header>
                <ApplicantDetailsView details={dto.applicant.details} />
              </Segment>
            )}

            {dto && (
              <Segment basic vertical>
                <Header as="h3">Qualifications</Header>
                <QualificationsView qualifications={dto.applicant.qualifications} />
              </Segment>
            )}

            {dto && (
              <Segment basic vertical>
                <Header as="h3">Applications</Header>
                {dto.applications.map(appn => (
                  <ApplicationView key={appn.id} application={appn} />
                ))}
              </Segment>
            )}

            {dto && (
              <Segment basic vertical>
                <Header as="h3">Labels</Header>
                {dto.labels.length === 0 && (
                  <Segment padded textAlign="center">
                    No labels.
                  </Segment>
                )}
                {dto.labels.length > 0 && <LabelsView labels={dto.labels} />}
              </Segment>
            )}

            {dto && (
              <Segment basic vertical>
                <Header as="h3">Notes</Header>
                {dto.notes.length === 0 && (
                  <Segment padded textAlign="center">
                    No notes.
                  </Segment>
                )}
                {dto.notes.map((note, index) => (
                  <NoteView key={index} note={note} />
                ))}
              </Segment>
            )}
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    dto: applicantUpdate.dto(state),
    fetching: applicantUpdate.fetching(state),
    fetched: applicantUpdate.fetched(state),
  }),
  {
    onLoad: applicantUpdate.load,
  },
)(ApplicantPrintPage);
