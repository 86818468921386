import * as pot from "../../api/pot";
import { BearerAuthentication, User } from "../../model/types";

export type MyState = {
  bearer: pot.Pot<BearerAuthentication>;
  loggedInUser: pot.Pot<User>;
  actingAsUser: pot.Pot<User>;
};

export const initialState: MyState = {
  bearer: pot.empty(),
  loggedInUser: pot.empty(),
  actingAsUser: pot.empty(),
};
