import React, { useCallback } from "react";
import { Message, Path } from "./model/types";

// Substitute for ?A in Flow:
export type Opt<A> = A | null | undefined;

export type ReactNodeLike = React.ReactNode;

export function assertNever(value: never): never {
  throw new Error("assertNever failed: " + value);
}

export function prefixMessages(messages: Message[], prefix: Path): Message[] {
  return messages.map(({ level, text, path }: Message) => {
    switch (level) {
      case "error":
        return { level: "error", text, path: prefix.concat(path) };
      case "warning":
        return { level: "warning", text, path: prefix.concat(path) };
      default:
        return { level: "error", text, path: prefix.concat(path) };
    }
  });
}

export function replace<A>(array: A[], oldItem: A, ...newItems: A[]): A[] {
  const index = array.indexOf(oldItem);
  if (index >= 0) {
    const copy = [...array];
    copy.splice(index, 1, ...newItems);
    return copy;
  } else {
    return array;
  }
}

export function intoArray<V>(a: Array<V>, i: number, v: V): Array<V> {
  return [...a.slice(0, i), v, ...a.slice(i + 1)];
}

export const regexSearch =
  (...regexes: RegExp[]) =>
  (str: string): string | null =>
    regexes.reduce((ans: string | null, regex: RegExp) => {
      if (ans != null) {
        return ans;
      }

      const match = str.match(regex);
      return match && match.length > 1 ? match[1] : ans;
    }, null);

export function safeParseInt<A>(str: Opt<string>, orElse: A): number | A {
  const ans = str == null ? Number.NaN : parseInt(str, 10);
  return isNaN(ans) ? orElse : ans;
}

export const useInputField = <V>(values: V, onChange: (v: V) => void) => {
  return useCallback(
    (
      path: keyof V,
    ): {
      id: keyof V;
      value: string;
      onChange: (v: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    } => {
      const v = values[path];
      return {
        id: path,
        onChange: e => {
          onChange({ ...values, [path]: e.currentTarget.value });
        },
        value: typeof v === "string" ? v : "",
      };
    },
    [values, onChange],
  );
};
