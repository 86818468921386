import React from "react";
import { createPortal } from "react-dom";

export type Props = {
  id: string;
  className?: string;
  children: React.ReactNode;
};

const Portal = ({ id, children, className }: Props) => {
  const el = React.useRef(document.getElementById(id));
  if (!el || !el.current) {
    throw new Error("trying to create portal on element that does not exist");
  }

  if (className) {
    el.current.className = className;
  }

  return createPortal(children, el.current);
};

export default React.memo(Portal);
