import {
  CountryOfDomicileEnum,
  FeeStatus,
  UkResidencyEnum,
  UkImmigrationStatusEnum,
  FeeStatusEnum,
  NationalityEnum,
} from "@qmspringboard/shared/dist/model/enums.generated";
import React from "react";
import { Form, Grid, Header } from "semantic-ui-react";
import styled from "styled-components";
import { ApplicantDetails } from "../model/applicant";
import { subErrors } from "../model/errors";
import { BaseFieldProps, DropdownField, DropdownOptions, FieldLabel } from "./fields";
import { Opt } from "@qmspringboard/app/src/utils";
import { initialFeeStatus, isConfirmed } from "@qmspringboard/shared/src/model/feeCategory";
import { appendResidencyLabelWithDateHint } from "@qmspringboard/shared/dist/model/residencyDate";

const PaddedHeader = styled(Header)`
  padding-top: 5px !important;
`;

// The user can only set non-confirmed fee statuses, as the confirmed status comes from SITS
const feeStatusOptions: DropdownOptions<Opt<FeeStatus>> = [
  { value: null, label: "-- Blank --" },
  // { value: "HHC", label: "HHC - Home" },
  { value: "HHP", label: "HHP - Home (provisional)" },
  { value: "HCP", label: "HCP - Channel Islands (provisional)" },
  { value: "HEP", label: "HEP - EU (provisional)" },
  { value: "OEP", label: "OEP - Overseas EU (provisional)" },
  {
    value: "OHP",
    label: "OHP - Overseas (but charged Home fees) (provisional)",
  },
  { value: "OOP", label: "OOP - Overseas (provisional)" },
  { value: "QQP", label: "QQP - Query" },
];

export default function FeeCategoryEditor(props: BaseFieldProps<ApplicantDetails>) {
  const { messages, onChange, value, readOnly } = props;

  const splitErrors = subErrors(messages, {
    countryOfDomicile: true,
    feeStatus: true,
    ukResidency: true,
    ukImmigrationStatus: true,
    nationality: true,
  });

  // If the fee status is confirmed, then we only want to show that option:
  const feeStatusList: DropdownOptions<Opt<FeeStatus>> =
    value.feeStatus && isConfirmed(value.feeStatus)
      ? [
          {
            value: value.feeStatus,
            label: FeeStatusEnum.labelOf(value.feeStatus) || "unknown",
          },
        ]
      : feeStatusOptions;

  return (
    <Form as="div">
      <PaddedHeader>Applicant Fee Status</PaddedHeader>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label={"Country of Domicile"}>
              <DropdownField
                search={true}
                value={value.countryOfDomicile}
                readOnly={readOnly}
                onChange={countryOfDomicile => {
                  onChange?.({ ...value, countryOfDomicile });
                }}
                placeholder="Select"
                fluid
                options={CountryOfDomicileEnum.dropdownOptions(false)}
                messages={splitErrors.countryOfDomicile}
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label={"Nationality"}>
              <DropdownField
                value={value.nationality}
                readOnly={readOnly}
                search={true}
                onChange={nationality => {
                  onChange?.({ ...value, nationality });
                }}
                placeholder="Select"
                fluid
                options={NationalityEnum.dropdownOptions(false)}
                messages={splitErrors.nationality}
              />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label={"How long have you been ordinarily resident in the UK?"}>
              <DropdownField
                value={value.ukResidency}
                readOnly={readOnly}
                onChange={ukResidency => {
                  const feeStatus = initialFeeStatus(value.feeStatus, ukResidency, value.ukImmigrationStatus);
                  onChange?.({ ...value, ukResidency, feeStatus });
                }}
                placeholder="Select"
                fluid
                options={UkResidencyEnum.dropdownOptions(true).map(option => {
                  return {
                    ...option,
                    label: appendResidencyLabelWithDateHint(option.value, option.label),
                  };
                })}
                messages={splitErrors.ukResidency}
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label={"What is your current immigration status in the UK?"}>
              <DropdownField
                value={value.ukImmigrationStatus}
                readOnly={readOnly}
                onChange={ukImmigrationStatus => {
                  const feeStatus = initialFeeStatus(value.feeStatus, value.ukResidency, ukImmigrationStatus);
                  onChange?.({ ...value, ukImmigrationStatus, feeStatus });
                }}
                placeholder="Select"
                fluid
                options={UkImmigrationStatusEnum.dropdownOptions(true)}
                messages={splitErrors.ukImmigrationStatus}
              />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label={"Fee Status" + (isConfirmed(value.feeStatus) ? " (already confirmed)" : "")}>
              <DropdownField
                value={value.feeStatus}
                readOnly={readOnly || isConfirmed(value.feeStatus)}
                onChange={feeStatus => onChange?.({ ...value, feeStatus })}
                placeholder="Select a fee status"
                fluid
                options={feeStatusList}
                messages={splitErrors.feeStatus}
              />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}
