import { Message, Segment, Table, Grid, Container, Divider, Dimmer, Loader, Button } from "semantic-ui-react";
import Papa from "papaparse";
import { SchoolCode, UserRole } from "../model/types";
import { FieldLabel, DropdownField } from "../components/fields";
import { User } from "../model/types";
import { admissionsTeam, teamToSchoolCode } from "../model/team";
import useAllSchools from "../hooks/useAllSchools";
import { RouteComponentProps } from "react-router";
import Title from "../components/Title";
import SubmitButton from "../components/SubmitButton";
import { useAddUsers, PartialSuccess } from "../api";
import { userRule } from "../model/user";
import { Messages, indexedErrors, subErrors } from "../model/errors";
import React from "react";
import { ValidationError } from "../errors";
import ErrorDisplay from "../components/ErrorDisplay";
import FileUploadZone from "../components/FileUploadZone";
import { Stringified } from "type-fest";
import UserRoleEditor from "../components/UserRoleEditor";
import useAllRoles from "../hooks/useAllRoles";
import PermissionsDetails from "../components/PermissionsDetails";

const subErrorKeys = {
  username: true,
  email: true,
  first_name: true,
  last_name: true,
};

const requiredFields = Object.keys(subErrorKeys) as Array<keyof typeof subErrorKeys>;

type CheckedData = Stringified<typeof subErrorKeys>;

const MAX_ROWS_TO_SHOW = 10;

const validate = (users: User[]): Messages => {
  const messages: Messages = [];
  for (const [i, user] of users.entries()) {
    messages.push(...userRule(user).map(m => ({ ...m, path: [i, ...m.path] })));
  }

  return messages;
};

function BulkUserEditorPage({ history }: RouteComponentProps) {
  const allRoles = useAllRoles();
  const allSchools = useAllSchools();
  const {
    mutate: saveUsers,
    status,
    error: serverMessages,
    reset,
  } = useAddUsers({
    onSuccess: () => {
      // go to user list
      history.push("/admin/user");
    },
  });

  const [errors, setErrors] = React.useState<Messages>([]);
  const [showRows, setShowRows] = React.useState(MAX_ROWS_TO_SHOW);
  const [result, setResult] = React.useState<CheckedData[]>([]);
  const [userRoles, setUserRoles] = React.useState<UserRole[]>([]);
  const [school, setSchool] = React.useState<SchoolCode | null>(null);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const valid = requiredFields.every(f => results.meta.fields?.includes(f) ?? false);
          if (valid) {
            setResult(results.data as CheckedData[]);
          } else {
            setErrors([
              {
                level: "error",
                path: [],
                text: `CSV must contain a header with the following fields ${requiredFields.join(",")}`,
              },
            ]);
          }
        },
      });
    }
  }, []);

  const users = React.useMemo(() => {
    // reset any server errors
    reset();
    // combine all our values into a 'User' type
    const users: User[] = result.map(r => ({
      id: -1,
      email: r.email,
      forenames: r.first_name,
      surname: r.last_name,
      username: r.username,
      roles: userRoles,
      canLogin: true,
      defaultSchoolCode: school,
    }));
    return users;
  }, [userRoles, reset, result, school]);

  const messages = serverMessages instanceof PartialSuccess ? serverMessages.data : validate(users);

  const teamOptions = React.useMemo(
    () => [
      {
        value: teamToSchoolCode(admissionsTeam.code),
        label: admissionsTeam.name,
      },
      ...allSchools.map(school => ({
        value: school.code,
        label: `${school.code} - ${school.name}`,
      })),
    ],
    [allSchools],
  );

  const visibleErrors = indexedErrors(messages, Number.isFinite(showRows) ? showRows : result.length);

  return (
    <Title title="Bulk Add Users">
      <Dimmer.Dimmable as={Container}>
        <Dimmer active={status === "loading"} inverted>
          <Loader>Saving</Loader>
        </Dimmer>

        {status === "error" && serverMessages instanceof ValidationError && <ErrorDisplay messages={serverMessages.messages} />}

        {status === "error" && serverMessages instanceof PartialSuccess && (
          <Message negative>
            <Message.Header>Some users couldn&apos;t be uploaded</Message.Header>
            <p>Users outlined in red below could not be uploaded. Other users were successfully added.</p>
          </Message>
        )}

        <Grid>
          <Grid.Row>
            {result.length > 0 ? (
              <Grid.Column width={16}>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      {requiredFields.map(f => (
                        <Table.HeaderCell key={f}>{f}</Table.HeaderCell>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {result.slice(0, showRows).map((row, i) => {
                      const rowErrors = subErrors(visibleErrors[i], subErrorKeys);
                      const isErrorRow = visibleErrors[i].length > 0;
                      return (
                        <Table.Row key={i}>
                          {requiredFields.map(f => {
                            let thisFieldIsError = rowErrors[f] && rowErrors[f].length > 0;
                            return (
                              <Table.Cell error={isErrorRow} key={`${i}.${f}`}>
                                {thisFieldIsError ? (
                                  <span>
                                    {row[f]} - {rowErrors[f][0].text}
                                  </span>
                                ) : (
                                  row[f]
                                )}
                              </Table.Cell>
                            );
                          })}
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
                <div>
                  {result.length > showRows ? (
                    <Button onClick={() => setShowRows(Infinity)}>Show {result.length - showRows} more row(s)</Button>
                  ) : null}
                  <Button onClick={() => setResult([])}>Clear Upload</Button>
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column width={16}>
                <FileUploadZone messages={errors} onDrop={onDrop} onClear={() => setResult([])} requiredFields={requiredFields} />
              </Grid.Column>
            )}
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel label="Home School">
                <DropdownField fluid value={school} readOnly={false} placeholder="Central Admissions" onChange={setSchool} options={teamOptions} />
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <FieldLabel label="Roles">
                <UserRoleEditor
                  allSchools={allSchools}
                  allRoles={allRoles}
                  userRoles={userRoles}
                  defaultSchoolCode={school}
                  onChange={maybeRoles => setUserRoles(maybeRoles.flatMap(mr => (mr.role ? [{ ...mr, role: mr.role }] : [])))}
                />
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider hidden />

        {users && users.length > 0 && <PermissionsDetails user={users[0]} />}

        <Divider hidden />

        <Segment basic vertical textAlign="right">
          <SubmitButton disabled={result.length === 0} onClick={() => saveUsers(users)} messages={messages}>
            Add Users
          </SubmitButton>
        </Segment>
      </Dimmer.Dimmable>
    </Title>
  );
}

export default BulkUserEditorPage;
