import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Dropdown, Dimmer, Loader } from "semantic-ui-react";
import * as pot from "../api/pot";
import { FieldLabel } from "../components/fields";
import { SearchProps, withSearch } from "../components/SearchHOC";
import Title from "../components/Title";
import UserList from "../components/UserList";
import { emptySearchResults } from "../model/search";
import { User } from "../model/types";
import * as teams from "../reducers/teams";
import * as userSearch from "../reducers/userSearch";
import { RouteComponentProps } from "react-router";

interface Props extends SearchProps<User>, RouteComponentProps {
  //
}

class UserListPage extends Component<Props> {
  render() {
    const { pagerComponent, searchComponent, sortComponent, refetch, history } = this.props;
    const results = pot.getOrElse(this.props.results, emptySearchResults());
    const fetching = pot.fetching(this.props.results);
    const fetchError = pot.error(this.props.results);

    return (
      <Title title="Users">
        <Dimmer.Dimmable as={Container}>
          <Dimmer active={status === "loading"} inverted>
            <Loader>Saving</Loader>
          </Dimmer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={12}>
                <FieldLabel>{searchComponent}</FieldLabel>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={6} computer={4}>
                <FieldLabel>
                  <Button.Group primary>
                    <Button fluid primary as={Link} to="/admin/user/new">
                      Add User
                    </Button>
                    <Dropdown
                      className="button icon"
                      floating
                      options={[
                        {
                          text: "Add Multiple Users",
                          value: "bulk",
                          onClick: () => {
                            history.push("/admin/add-user-bulk");
                          },
                        },
                      ]}
                      trigger={<React.Fragment />}
                    />
                  </Button.Group>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <UserList refetch={refetch} results={results} fetching={fetching} fetchError={fetchError} sortComponent={sortComponent} />
          {pagerComponent}
        </Dimmer.Dimmable>
      </Title>
    );
  }
}

const ConnectedUserListPage: ComponentType<{}> = connect(
  state => ({
    results: userSearch.results(state),
    fetching: userSearch.fetching(state),
    currentTeam: teams.currentTeam(state),
    searchBySchool: true,
  }),
  {
    search: userSearch.search,
    reset: userSearch.reset,
  },
)(withSearch(UserListPage));

export default ConnectedUserListPage;
