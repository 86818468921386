import React, { KeyboardEvent, SyntheticEvent } from "react";
import { connectModal } from "redux-modal";
import { Button, Header, Modal } from "semantic-ui-react";
import useKey from "use-key-hook";
import { ReactNodeLike } from "../utils";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  primaryButtonText?: ReactNodeLike;
  onPrimaryClick?: (e: SyntheticEvent) => void;
  title?: string;
  content: ReactNodeLike;
}

export function ModalAlert({ show, title, onPrimaryClick, handleHide, primaryButtonText, content }: Props) {
  const handlePrimaryClick = (e: SyntheticEvent) => {
    onPrimaryClick?.(e);
    handleHide();
  };

  useKey(
    (_pressedKey: string | number, e: KeyboardEvent) => {
      handlePrimaryClick(e);
    },
    {
      detectKeys: [13],
    },
  );

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Header icon="warning circle" content={title || "Alert"} />
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button primary onClick={handlePrimaryClick}>
          {primaryButtonText || "OK"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default connectModal({ name: "alert" })(ModalAlert);
