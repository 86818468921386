import React from "react";
import { Header, Segment } from "semantic-ui-react";

const SelectorListNoSelection = () => (
  <Segment>
    <Segment basic padded vertical textAlign="center">
      <Header as="h1">No applicant selected</Header>
    </Segment>
    <Segment basic padded vertical textAlign="center" size="large">
      Select an applicant on the left to sync changes from UCAS and SITS.
    </Segment>
    <Segment basic padded vertical textAlign="center" size="large">
      Use the dropdown menus above to filter the applicants on the left.
    </Segment>
  </Segment>
);

export default SelectorListNoSelection;
