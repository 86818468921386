import { sortBy } from "lodash";
import React from "react";
import { Dimmer, Label, Loader, Table } from "semantic-ui-react";
import styled from "styled-components";
import { programmeCodeToR900Label, programmeCodeToString } from "../model/programme";
import { ReportingGroup, SearchResults } from "../model/types";
import { ReactNodeLike } from "../utils";
import ClickableCell from "./ClickableCell";
import { ReportingGroupTypeEnum } from "../model/enums";

type Props = {
  results: SearchResults<ReportingGroup>;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  fetching: boolean;
};

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default class ReportingGroupList extends React.Component<Props> {
  render() {
    const {
      results: { items },
      fetching,
    } = this.props;

    return (
      <Dimmer.Dimmable dimmed={fetching}>
        <Dimmer active={fetching} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <ResponsiveTable>
          <Table selectable unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>School</Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Group Type</Table.HeaderCell>
                <Table.HeaderCell>UF Aim Home</Table.HeaderCell>
                <Table.HeaderCell>UF Aim Overseas</Table.HeaderCell>
                <Table.HeaderCell>Programmes</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{items.length === 0 ? this.renderNoItems() : items.map(this.renderItem)}</Table.Body>
          </Table>
        </ResponsiveTable>
      </Dimmer.Dimmable>
    );
  }

  renderNoItems = () => {
    return (
      <Table.Row key="noitems">
        <Table.Cell colSpan={11} textAlign="center">
          <div style={{ padding: 10 }}>Nothing to show</div>
        </Table.Cell>
      </Table.Row>
    );
  };

  url = (item: ReportingGroup) => {
    return `/admin/reporting-group/${item.id}`;
  };

  truncatedLongList = (items: Array<JSX.Element>) => {
    if (items.length > 160) {
      return <span>{items.length} programmes</span>;
    } else {
      return items;
    }
  };

  renderItem = (group: ReportingGroup) => {
    const url = this.url(group);

    return (
      <Table.Row key={group.id}>
        <ClickableCell to={url}>{group.schoolCode || "Central Admissions"}</ClickableCell>
        <ClickableCell to={url}>{group.code}</ClickableCell>
        <ClickableCell to={url}>{group.name}</ClickableCell>
        <ClickableCell to={url}>{ReportingGroupTypeEnum.labelOf(group.groupType)}</ClickableCell>
        <ClickableCell to={url}>{group.ufAimHome}</ClickableCell>
        <ClickableCell to={url}>{group.ufAimOverseas}</ClickableCell>
        <ClickableCell to={url}>
          {this.truncatedLongList(
            sortBy(group.programmeCodes, x => x).map(code => <Label key={programmeCodeToString(code)}>{programmeCodeToR900Label(code)}</Label>),
          )}
        </ClickableCell>
      </Table.Row>
    );
  };
}
