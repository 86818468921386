import React from "react";
import { Checkbox, Form, Grid, Header, Icon } from "semantic-ui-react";
import { EntryPointEnum } from "../model/enums";
import { Messages, subErrors } from "../model/errors";
import { unsafeStringToProgrammeCode } from "../model/programme";
import { Programme, School, SchoolCode } from "../model/types";
import { withAllSchools } from "../reducers/teams";
import { Opt } from "../utils";
import RequirementsEditor from "./RequirementsEditor";
import ErrorDisplay from "./ErrorDisplay";
import { DropdownField, DropdownOptions, FieldLabel, NullableTextArea, NullableTextInput, RadioSelectField, TextInput } from "./fields";

interface Props {
  programme: Programme;
  messages: Messages;
  onChange: (value: Programme) => void;
  readOnly: boolean;
  allSchools: School[];
}

export default withAllSchools(
  class ProgrammeEditor extends React.Component<Props> {
    render() {
      const { programme, messages, readOnly, onChange, allSchools } = this.props;

      const splitErrors = subErrors(messages, {
        code: true,
        name: true,
        schoolCode: true,
        jointSchoolCode: true,
        entryRequirements: true,
        defaultEntryPoint: true,
        homeFteAlloc: true,
        jointFteAlloc: true,
        allowHotlineClearingOffers: true,
        isClosedHome: true,
        isClosedOverseas: true,
        keywords: true,
      });

      const schoolOptions: DropdownOptions<SchoolCode> = allSchools.map(school => ({
        value: school.code,
        label: `${school.code} - ${school.name}`,
      }));

      const nullableSchoolOptions: DropdownOptions<Opt<SchoolCode>> = [null, ...allSchools].map(optSchool => ({
        value: optSchool == null ? null : optSchool.code,
        label: optSchool == null ? "No school" : `${optSchool.code} - ${optSchool.name}`,
      }));

      return (
        <Form as="div">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <FieldLabel label="Code">
                  <NullableTextInput
                    value={programme.code}
                    readOnly={readOnly}
                    onChange={code =>
                      onChange({
                        ...programme,
                        code: unsafeStringToProgrammeCode(code ?? ""),
                      })
                    }
                  />
                  <ErrorDisplay attached messages={splitErrors.code} />
                  <p style={{ marginTop: ".5em" }}>
                    Use the format <code>UUUU-RRRR</code> where <code>UUUU</code> is the UCAS Course Code and <code>RRRR</code> is the SITS route
                    code.
                  </p>
                </FieldLabel>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <FieldLabel label="Name">
                  <TextInput value={programme.name} readOnly={readOnly} onChange={name => onChange({ ...programme, name })} />
                  <ErrorDisplay attached messages={splitErrors.name} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <FieldLabel label="Home School">
                  <DropdownField<SchoolCode>
                    fluid
                    value={programme.schoolCode}
                    readOnly={readOnly}
                    placeholder="-- Choose a school --"
                    onChange={schoolCode => onChange({ ...programme, schoolCode })}
                    options={schoolOptions}
                  />
                  <ErrorDisplay attached messages={splitErrors.schoolCode} />
                </FieldLabel>
              </Grid.Column>

              <Grid.Column mobile={16} tablet={8} computer={8}>
                <FieldLabel label="Joint School">
                  <DropdownField<Opt<SchoolCode>>
                    fluid
                    value={programme.jointSchoolCode}
                    readOnly={readOnly}
                    placeholder="-- Choose a school --"
                    onChange={jointSchoolCode => onChange({ ...programme, jointSchoolCode })}
                    options={nullableSchoolOptions}
                  />
                  <ErrorDisplay attached messages={splitErrors.jointSchoolCode} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={8}>
                <FieldLabel label="Default Entry Point">
                  <DropdownField
                    fluid
                    value={programme.defaultEntryPoint}
                    readOnly={readOnly}
                    onChange={defaultEntryPoint => onChange({ ...programme, defaultEntryPoint })}
                    placeholder="-- Blank --"
                    options={EntryPointEnum.dropdownOptions()}
                  />
                  <ErrorDisplay attached messages={splitErrors.defaultEntryPoint} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Header>Searching for Programmes</Header>

            <Grid.Row>
              <Grid.Column width={16}>
                <FieldLabel label="Keywords">
                  <NullableTextArea
                    value={programme.keywords}
                    rows={2}
                    readOnly={readOnly}
                    onChange={keywords => onChange({ ...programme, keywords })}
                  />
                  <ErrorDisplay attached messages={splitErrors.keywords} />
                  <p>These provide additional search terms when searching for a programme.</p>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Header>Publication Status</Header>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel label="Allow Hotline Offers">
                  <RadioSelectField
                    value={programme.allowHotlineClearingOffers}
                    options={[
                      {
                        key: "false",
                        value: false,
                        label: <label>Disable&mdash;only school operators can make Clearing offers on this programme.</label>,
                      },
                      {
                        key: "true",
                        value: true,
                        label: <label>Enable&mdash;hotline operators can also make Clearing offers on this programme.</label>,
                      },
                    ]}
                    onChange={allowHotlineClearingOffers => onChange({ ...programme, allowHotlineClearingOffers })}
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.allowHotlineClearingOffers} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel
                  label={
                    <>
                      Open / Closed Status <Icon name="mail" />
                    </>
                  }
                >
                  <Checkbox
                    checked={programme.isClosedHome}
                    label="Closed to home applicants&mdash;only users with elevated privileges will be able to make new offers to home applicants."
                    onChange={() =>
                      onChange({
                        ...programme,
                        isClosedHome: !programme.isClosedHome,
                      })
                    }
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.isClosedHome} />
                  <Checkbox
                    checked={programme.isClosedOverseas}
                    label="Closed to overseas applicants&mdash;only users with elevated privileges will be able to make new offers to overseas applicants."
                    onChange={() =>
                      onChange({
                        ...programme,
                        isClosedOverseas: !programme.isClosedOverseas,
                      })
                    }
                    readOnly={readOnly}
                  />
                  <ErrorDisplay attached messages={splitErrors.isClosedOverseas} />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <FieldLabel>
                  <Checkbox
                    checked={programme.isVisibleOnPortal}
                    onChange={(e, { checked: isVisibleOnPortal }) =>
                      onChange({
                        ...programme,
                        isVisibleOnPortal: isVisibleOnPortal ?? false,
                      })
                    }
                    label="Show this Programme on the Applicant Portal"
                  />
                  <br />
                  <Checkbox
                    checked={programme.isVisibleOnCourseFinder}
                    onChange={(e, { checked: isVisibleOnCourseFinder }) =>
                      onChange({
                        ...programme,
                        isVisibleOnCourseFinder: isVisibleOnCourseFinder ?? false,
                      })
                    }
                    label="Show this Programme on the Course Finder"
                  />
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>

            <Header>Entry Requirements</Header>

            <Grid.Row>
              <Grid.Column width={16}>
                <RequirementsEditor
                  value={programme.entryRequirements}
                  messages={splitErrors.entryRequirements}
                  readOnly={readOnly}
                  onChange={entryRequirements => onChange({ ...programme, entryRequirements })}
                />
              </Grid.Column>
            </Grid.Row>

            {splitErrors._rest_.length > 0 && (
              <Grid.Row>
                <Grid.Column width={16}>
                  <ErrorDisplay messages={splitErrors._rest_} />
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Form>
      );
    }
  },
);
