import { initialState as attachmentUploadInitialState, IState as LocalAttachmentState } from "@qmspringboard/shared/dist/reducers/uploads";
import * as pot from "../../api/pot";
import { AttachmentRequest, FileInfo } from "../../model/types";

export type MyState = {
  requestedAttachments: pot.Pot<AttachmentRequest[]>;
  savedAttachments: pot.Pot<FileInfo[]>;
  localAttachments: LocalAttachmentState;
};

export const initialState: MyState = {
  requestedAttachments: pot.empty(),
  savedAttachments: pot.empty(),
  localAttachments: attachmentUploadInitialState,
};
