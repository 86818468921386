import { ddmmhhmm, formatLondon, parseIso } from "@qmspringboard/shared/dist/utils/date";
import { isAfter } from "date-fns";
import React, { Component } from "react";
import { Dimmer, Header, Icon, Label, Loader, Popup, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import { QualificationBoard, qualificationBoardShortLabel } from "@qmspringboard/shared/dist/model/qualifications";
import { AppError } from "../errors";
import { ApplicationMethodEnum, QualificationTypeEnum } from "../model/enums";
import { formatNameWithInitials } from "../model/name";
import { ProgrammeCode, programmeCodeToString, programmeCodeToUcasCourseCode } from "../model/programme";
import { format as formatTrackingNumber } from "../model/trackingNumber";
import { ApplicantDTO, Application, ApplicationSummary, ProgrammeEditorDTO, RequirementsCheckStatus, SearchResults } from "../model/types";
import { withProgramme } from "../reducers/programmeUpdate";
import { Opt } from "../utils";
import ApplicationStatusIndicator from "./ApplicationStatusIndicator";
import ClickableCell from "./ClickableCell";
import DateTime from "./DateTime";
import Sorter from "./Sorter";

const ProgrammeInfo = withProgramme(
  ({ load, programmeDTO, programmeCode }: { load: Function; programmeDTO: ProgrammeEditorDTO; programmeCode: ProgrammeCode }) => {
    React.useEffect(() => {
      load(programmeCode);
    }, [load, programmeCode]);

    return (
      <div>
        <Header>{programmeDTO.programme.name}</Header>
        {programmeCodeToString(programmeCode)}
      </div>
    );
  },
);

type Props = {
  results: SearchResults<ApplicationSummary>;
  fetching: boolean;
  fetchError: Opt<AppError>;
};

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const NowrapLabel = styled(Label)`
  white-space: nowrap;
`;

export default class ApplicationList extends Component<Props> {
  render() {
    const {
      results: { items },
      fetching,
      fetchError,
    } = this.props;

    return (
      <Dimmer.Dimmable dimmed={fetching}>
        <Dimmer active={fetching} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <ResponsiveTable>
          <Table selectable unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Sorter sortBy="tracking" label="Tracking" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Sorter sortBy="ucas" label="UCAS" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Sorter sortBy="studentid" label="Student ID" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Sorter sortBy="name" label="Name" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="fees" label="Fee Status" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <Sorter sortBy="school" label="School" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="programme" label="Prog" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="qualifications" label="Quals" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="method" label="Method" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status Codes</Table.HeaderCell>
                <Table.HeaderCell>
                  <Sorter sortBy="statusLabel" label="Status" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="expires" label="Expires" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="prediction" label="Predict" />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <Sorter sortBy="updated" label="Updated" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{items.length === 0 ? this.renderNoItems(fetchError) : items.map(this.renderItem)}</Table.Body>
          </Table>
        </ResponsiveTable>
      </Dimmer.Dimmable>
    );
  }

  renderNoItems = (fetchError: Opt<AppError>) => {
    return (
      <Table.Row key="noitems">
        <Table.Cell colSpan={14} textAlign="center">
          <Segment basic>{fetchError ? `Could not load results. ${fetchError.allMessages()}` : "Nothing to show"}</Segment>
        </Table.Cell>
      </Table.Row>
    );
  };

  applicationUrl = (appt: ApplicantDTO, appn: Opt<Application>) => {
    const queryString = appn == null ? "" : `?application=${appn.id}`;
    return `/applicant/${appt.id}${queryString}`;
  };

  renderItem = (summ: ApplicationSummary) => {
    const { applicant: appt, application: appn, classification: clsn } = summ;

    const url = this.applicationUrl(appt, appn);

    return (
      <Table.Row key={`${appt.id}-${appn ? appn.id : "empty"}-${summ.schoolCode ? summ.schoolCode : "none"}`}>
        <ClickableCell to={url}>{formatTrackingNumber(appt.id)}</ClickableCell>
        <ClickableCell to={url}>{appt.details.ucasPersonalId || "-"}</ClickableCell>
        <ClickableCell to={url}>{appt.details.studentId || "-"}</ClickableCell>
        <ClickableCell to={url}>{formatNameWithInitials(appt.details.surname, appt.details.forenames)}</ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {appt.details.feeStatus}
        </ClickableCell>
        <ClickableCell to={url}>{summ.schoolCode ? summ.schoolCode : "-"}</ClickableCell>
        <Popup
          on="hover"
          hideOnScroll
          position="bottom right"
          trigger={
            <ClickableCell to={url} textAlign="center">
              {appn ? programmeCodeToUcasCourseCode(appn.programmeCode) : "-"}
              {appn && appn.oldProgrammeCode && (
                /* left hand double arrow */
                <small>{` \u21d0 ${programmeCodeToUcasCourseCode(appn.oldProgrammeCode)}`}</small>
              )}
            </ClickableCell>
          }
          wide
        >
          {appn && appn.programmeCode ? <ProgrammeInfo programmeCode={appn.programmeCode} /> : "-"}
        </Popup>
        <ClickableCell to={url} textAlign="center">
          {clsn ? this.renderQualifications(clsn.qualificationType, clsn.qualificationTotal, clsn.requirementsCheckStatus) : "-"}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {clsn ? ApplicationMethodEnum.labelOf(clsn.method) : "-"}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {appn && <ApplicationStatusIndicator statuses={appn} style={{ position: "relative", zIndex: 1 }} />}
        </ClickableCell>
        <ClickableCell to={url}>{appn ? appn.statusLabel : "-"}</ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {appn && appn.offerExpiry ? <DateTime iso={appn.offerExpiry} /> : "-"}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {appn && clsn ? this.renderPrediction(clsn.probability, clsn.classifierRuleLine, appn.prediction, appn.predictionExpiry) : "-"}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {appn && appn.lastUpdated ? <DateTime iso={appn.lastUpdated} /> : appt && appt.createdAt ? <DateTime iso={appt.createdAt} /> : "-"}
        </ClickableCell>
      </Table.Row>
    );
  };

  renderQualifications = (board: Opt<QualificationBoard>, qTotal: Opt<number>, status: Opt<RequirementsCheckStatus>) => {
    const color = status === "Passed" ? "green" : status === "Failed" ? "red" : undefined;

    if (board == null) {
      return (
        <NowrapLabel color={color} size="small">
          {qTotal ?? "??"}
        </NowrapLabel>
      );
    } else {
      const boardLabel = qualificationBoardShortLabel(board);
      const boardTitle = QualificationTypeEnum.labelOf(board);

      return (
        <NowrapLabel color={color} title={boardTitle} size="small">
          {qTotal && (
            <>
              {qTotal} <br />
            </>
          )}
          <small>{boardLabel}</small>
        </NowrapLabel>
      );
    }
  };

  renderPrediction = (pred: Opt<number>, line: Opt<number>, override: Opt<number>, overrideExpiry: Opt<string>) => {
    const percent = pred && Math.round(pred * 100);

    function overrideLabel(override: number, overrideExpiry: string) {
      const overrideDate = parseIso(overrideExpiry);
      const overridden = isAfter(overrideDate, new Date());
      const overrideLabel = formatLondon(overrideDate, ddmmhhmm);

      return overridden ? (
        <div style={{ position: "relative", zIndex: 1 }}>
          <Popup
            size="mini"
            trigger={
              <span>
                {override}%{" "}
                <small>
                  <Icon name="pencil" color="grey" />
                </small>
              </span>
            }
            content={`Overridden prediction expires ${overrideLabel}`}
          />
        </div>
      ) : null;
    }

    function regularLabel() {
      return (
        <span>
          {percent != null ? `${Math.round(percent / 100)}%` : "???"} <small>{line ? `L${line}` : ""}</small>
        </span>
      );
    }

    return override != null && overrideExpiry != null ? overrideLabel(override, overrideExpiry) || regularLabel() : regularLabel();
  };
}
