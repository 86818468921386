import React, { createRef, PureComponent, RefObject } from "react";
import { Button, Container, Form, Segment, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import { Note } from "../model/types";
import { Opt } from "../utils";
import DateTime from "./DateTime";
import { NullableTextArea } from "./fields";
import SidebarIconButton from "./SidebarIconButton";

interface Props {
  note: Note;
  readOnly: boolean;
  onSave?: (oldNote: Note, newNote: Note) => void;
  onUpdate: (oldNote: Note, newNote: Note) => void;
  onDelete: (note: Note) => void;
}

interface State {
  editedNote: Note;
  editing: boolean;
}

const NoteWrapper = styled(Container)`
  margin-top: 2em !important;
  font-size: 0.8rem !important;
`;

const EditWrapper = styled(Segment)`
  margin-top: 0.5em !important;
  padding: 0.5em !important;

  textarea {
    font-size: 0.8rem !important;
    padding: 0.5em !important;
  }
`;

const ReadOnlyViewWrapper = styled(Segment)`
  margin-top: 0.5em !important;
  overflow-x: hidden;
  box-shadow: none !important;
  font-size: 0.8rem !important;
`;

const EditableViewWrapper = styled(Segment)`
  margin-top: 0.5em !important;
  cursor: pointer;
  overflow-x: hidden;
  font-size: 0.8rem !important;

  &:hover {
    border-color: rgba(34, 36, 38, 0.25) !important;
  }
`;

function formatNote(note: string): string[] {
  return note.split(/\n *\n/g);
}

export default class ApplicantNote extends PureComponent<Props, State> {
  area: RefObject<TextArea>;

  constructor(props: Props) {
    super(props);
    this.area = createRef();
  }

  UNSAFE_componentWillMount() {
    const state: State = {
      ...this.state,
      editedNote: this.props.note,
      editing: this.props.onSave == null,
    };
    this.setState(state);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.note !== this.props.note) {
      const state: State = {
        ...this.state,
        editedNote: nextProps.note,
      };
      this.setState(state);
    }
  }

  weAreOnCreatePage = () => {
    return this.props.onSave == null;
  };

  weAreOnUpdatePage = () => {
    return this.props.onSave != null;
  };

  handleTextChange = (value: Opt<string>) => {
    const { onUpdate, note } = this.props;

    if (this.weAreOnCreatePage()) {
      // We DON'T have save/cancel buttons on the applicant create page,
      // so we immediately send changes to the redux reducer:
      onUpdate(note, { ...note, text: value ?? "" });
      this.setState({
        ...this.state,
        editedNote: { ...this.state.editedNote, text: value ?? "" },
      });
    } else {
      // We DO have save/cancel buttons on the applicant update page,
      // so we don't commit changes until we click "save":
      this.setState({
        ...this.state,
        editedNote: { ...this.state.editedNote, text: value ?? "" },
      });
    }
  };

  handleEditClick = () => {
    this.setState({ ...this.state, editing: true }, () => {
      this.area.current?.focus();
    });
  };

  handleSaveClick = () => {
    const { onSave, note } = this.props;
    const { editedNote: editingNote } = this.state;

    if (onSave) {
      onSave(note, editingNote);
      this.setState({ ...this.state, editing: false });
    } else {
      console.error("Saving without editing a note?");
    }
  };

  handleCancelClick = () => {
    const { note } = this.props;
    this.setState({ ...this.state, editedNote: note, editing: false });
  };

  handleDeleteClick = () => {
    const { onDelete, note } = this.props;
    onDelete(note);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevState.editing && this.state.editing && this.area) {
      this.area.current?.focus();
    }
  }

  render() {
    const { note, readOnly } = this.props;
    const { editing, editedNote } = this.state;

    return (
      <Form as="div">
        {this.renderHeader(note, editing, readOnly)}
        {editing && !readOnly ? this.renderEditing(editedNote) : this.renderViewing(editedNote, readOnly)}
      </Form>
    );
  }

  renderAuthor = (note: Note) => {
    const dept = note.authorSchoolCode || "Central";
    return `${note.authorName || ""} (${dept})`;
  };

  renderHeader = (note: Note, editing: boolean, readOnly: boolean) => {
    return (
      <NoteWrapper>
        {!readOnly && (
          <SidebarIconButton onClick={this.handleDeleteClick} disabled={this.weAreOnUpdatePage() && editing} icon="close" title="Delete note" />
        )}
        {this.weAreOnUpdatePage() && !readOnly && (
          <SidebarIconButton onClick={this.handleEditClick} disabled={editing} icon="pencil" title="Edit note" />
        )}
        {this.renderAuthor(note)}
        <br />
        <DateTime iso={note.timestamp} />
      </NoteWrapper>
    );
  };

  renderViewing = (note: Note, readOnly: boolean) => {
    const ViewWrapper = readOnly ? ReadOnlyViewWrapper : EditableViewWrapper;
    return (
      <ViewWrapper onClick={this.handleEditClick}>
        {formatNote(note.text ?? "").map((para, index) => (
          <p key={index}>{para}</p>
        ))}
      </ViewWrapper>
    );
  };

  renderEditing = (note: Note) => {
    return (
      <EditWrapper>
        <NullableTextArea forwardRef={this.area} value={note.text} onChange={this.handleTextChange} autoHeight />
        {this.weAreOnUpdatePage() && (
          <Container textAlign="right" style={{ marginTop: ".5em" }}>
            <Button size="mini" onClick={this.handleCancelClick}>
              Cancel
            </Button>
            <Button size="mini" onClick={this.handleSaveClick} primary>
              Save
            </Button>
          </Container>
        )}
      </EditWrapper>
    );
  };
}
