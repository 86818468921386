import { useSelector, useDispatch } from "react-redux";
import { Messages } from "../model/errors";
import { User } from "../model/types";
import * as userUpdate from "../reducers/userUpdate";
import { safeParseInt } from "../utils";
import React from "react";
type Status = "idle" | "loading" | "success" | "error";

export const useUser = (rid: string): [User, Status, Messages, (resource: User) => void, (resource: User) => void] => {
  const dispatch = useDispatch();
  const [status, setStatus] = React.useState<Status>("idle");
  const id = safeParseInt(rid, null);

  const user = useSelector(userUpdate.user);
  const messages = useSelector(userUpdate.messages);
  const fetching = useSelector(userUpdate.fetching);
  const fetched = useSelector(userUpdate.fetched);

  const onCommit = React.useCallback((user: User) => dispatch(userUpdate.save(user)), [dispatch]);

  const onChange = React.useCallback(
    (user: User) => {
      dispatch(userUpdate.update(user));
    },
    [dispatch],
  );

  React.useEffect(() => {
    if (fetching) {
      setStatus("loading");
    }
  }, [fetching]);

  React.useEffect(() => {
    if (fetched) {
      setStatus("success");
    }
  }, [fetched]);

  // try to load straight away
  React.useEffect(() => {
    setStatus("loading");
    if (id) {
      dispatch(userUpdate.load(id));
    } else {
      dispatch(userUpdate.initialise());
      setStatus("success");
    }
  }, [dispatch, id]);

  return [user, status, messages, onChange, onCommit];
};

export default useUser;
