import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Button, Container, Dimmer, Grid, Header, Loader, Segment } from "semantic-ui-react";
import { AppDispatch, AppThunkAction } from "../reducers/actions";
import { GetState } from "../reducers/state";
import { fetchTransferScript, fetchParams } from "../api/index";
import { ApplicantEditorState } from "../model/applicant";
import * as applicantUpdate from "../reducers/applicantUpdate";
import { Opt, safeParseInt } from "../utils";
import { match as Match } from "react-router";
import { TelephoneScript } from "../model/types";
import { Location } from "history";
import { locationQueryParams } from "../utils/queryParams";
import { AppError } from "../errors";

interface Params {
  applicantId: string;
}

interface Props {
  doFetch: (applicationId: number, groupId: number) => Promise<TelephoneScript>;
  location: Location;
  match: Match<Params>;
  dto: ApplicantEditorState;
  onLoad: Function;
}

interface State {
  applicantId: Opt<number>;
  groupId: Opt<number>;
  fetching: boolean;
  error: Opt<AppError>;
  title: Opt<string>;
  content: Opt<string>;
}

const doFetch =
  (applicationId: number, groupId: number): AppThunkAction<Promise<TelephoneScript>> =>
  (dispatch: AppDispatch, getState: GetState) =>
    fetchTransferScript(fetchParams(getState()), applicationId, groupId);

class ApplicantTransferCompletePage extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      applicantId: null,
      groupId: null,
      fetching: false,
      error: null,
      title: null,
      content: null,
    };
  }

  fetchScript = () => {
    const { doFetch, location, match } = this.props;

    this.setState(
      {
        ...this.state,
        fetching: true,
        applicantId: safeParseInt(match.params.applicantId, undefined),
        groupId: safeParseInt(locationQueryParams(location).group, undefined),
      },
      () => {
        const { applicantId, groupId } = this.state;

        if (applicantId != null && groupId != null) {
          doFetch(applicantId, groupId).then(
            ({ title, content }) => this.setState({ ...this.state, title, content, fetching: false }),
            error => this.setState({ ...this.state, error, fetching: false }),
          );
        }
      },
    );
  };

  componentDidMount() {
    const { applicantId } = this.props.match.params;
    this.props.onLoad(applicantId);
    this.fetchScript();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { applicantId } = nextProps.match.params;
    if (this.props.match.params.applicantId !== applicantId) {
      this.props.onLoad(this.props.match.params.applicantId);
    }
  }

  render() {
    return (
      <Dimmer.Dimmable dimmed={this.state.fetching}>
        <Container>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={12}>
                <Dimmer active={this.state.fetching} inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
                <Header as="h3">
                  <div dangerouslySetInnerHTML={{ __html: this.state.title ?? "" }} />
                </Header>
                {this.state.content && <Segment basic padded dangerouslySetInnerHTML={{ __html: this.state.content }} />}
                {!this.state.content && (
                  <Segment basic padded textAlign="center">
                    <p>There are no transfer notes for the selected school.</p>
                  </Segment>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={12} textAlign="right">
                {this.state.applicantId && (
                  <Button as="a" href={`/applicant/${this.state.applicantId}`}>
                    Return to the applicant
                  </Button>
                )}
                <Button as="a" href="/dashboard">
                  Return to the applicant list
                </Button>
                <Button as="a" primary href="/applicant/search">
                  Take another call
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Dimmer.Dimmable>
    );
  }
}

export default connect(
  state => ({
    dto: applicantUpdate.dto(state),
  }),
  {
    doFetch,
    onLoad: applicantUpdate.load,
  },
)(ApplicantTransferCompletePage);
