import { connect } from "react-redux";
import { showModal } from "../reducers/modal";
import { InjectedProps } from "redux-modal";

export type ShowModalFunc = typeof showModal;

// The props required by withModal:
export interface WithModalProps {
  showModal: ShowModalFunc;
}

export const withModal = connect(null, { showModal });

// The props required by connectModal:
//
// Use these like this:
//
// interface MyProps extends ConnectModalProps { ... }
// class MyComponent extends Component<MyProps> { ... }
// const MyConnected = connectModal({ name: "..." })(MyComponent)
export type ConnectModalProps = InjectedProps;
