import { checkExhausted } from "../utils";
import { SchoolCode } from "./types";
import { FeeCategory } from "@qmspringboard/shared/src/model/enums.generated";

export interface ProgrammeLike {
  schoolCode: SchoolCode;
  isClosedHome: boolean;
  isClosedOverseas: boolean;
  isFullHome: boolean;
  isFullOverseas: boolean;
}

export function isCompletelyClosed(prog: ProgrammeLike): boolean {
  return prog.isClosedHome && prog.isClosedOverseas;
}

export function isCompletelyFull(prog: ProgrammeLike): boolean {
  return prog.isFullHome && prog.isFullOverseas;
}

export function isPartiallyClosed(prog: ProgrammeLike): boolean {
  return prog.isClosedHome || prog.isClosedOverseas;
}

export function isPartiallyFull(prog: ProgrammeLike): boolean {
  return prog.isFullHome || prog.isFullOverseas;
}

export function isAcceptingOffers(prog: ProgrammeLike): boolean {
  return !isCompletelyClosed(prog) && !isCompletelyFull(prog);
}

export function isClosedToFeeCategory(prog: ProgrammeLike, fees: FeeCategory | null): boolean {
  switch (fees) {
    case "Home":
      return prog.isClosedHome;
    case "Eu":
    case "Overseas":
      return prog.isClosedOverseas;
    case null:
      return isCompletelyClosed(prog);
    default:
      return checkExhausted(fees);
  }
}

export function isFullToFeeCategory(prog: ProgrammeLike, fees: FeeCategory | null): boolean {
  switch (fees) {
    case "Home":
      return prog.isFullHome;
    case "Eu":
    case "Overseas":
      return prog.isFullOverseas;
    case null:
      return isCompletelyFull(prog);
    default:
      return checkExhausted(fees);
  }
}

export function spacesDescription(prog: ProgrammeLike): string {
  if (isCompletelyClosed(prog)) {
    return "completely closed";
  } else if (isCompletelyFull(prog)) {
    return "completely full";
  } else if (isPartiallyClosed(prog) || isPartiallyFull(prog)) {
    const homeDescription = prog.isClosedHome
      ? "closed to home applicants"
      : prog.isFullHome
        ? "full to home applicants"
        : "available to home applicants";

    const overseasDescription = prog.isClosedOverseas
      ? "closed to overseas applicants"
      : prog.isFullOverseas
        ? "full to overseas applicants"
        : "available to overseas applicants";

    const homeAvailable = !prog.isClosedHome && !prog.isFullHome;
    const overseasAvailable = !prog.isClosedOverseas && !prog.isFullOverseas;
    const joiningWord = homeAvailable === overseasAvailable ? " and " : " but ";

    return homeDescription + joiningWord + overseasDescription;
  } else {
    return "open with spaces available";
  }
}
