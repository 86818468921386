import React, { Component } from "react";
import { PermissionType, SchoolCode, User, isGlobalPermissionType } from "../model/types";
import { Table } from "semantic-ui-react";
import { union } from "lodash";
import { abbreviate } from "./SchoolCheckButtons";
import { GlobalPermissionTypeEnum, SchoolPermissionTypeEnum } from "../model/enums";

type Props = {
  user: User;
};

type PermissionsAndASchools = {
  permission: PermissionType;
  schools: SchoolCode[];
};

export default class PermissionsDetails extends Component<Props> {
  permissionsTable(permissions: PermissionsAndASchools[]) {
    const sortedPermissions = permissions.sort((a, b) => a.permission.localeCompare(b.permission));

    const label = (type: PermissionType) => GlobalPermissionTypeEnum.labelOf(type) || SchoolPermissionTypeEnum.labelOf(type);

    const schools = (p: PermissionsAndASchools) => (isGlobalPermissionType(p.permission) ? "N/A" : p.schools.map(abbreviate).sort().join(", "));

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Permission</Table.HeaderCell>
            <Table.HeaderCell>Schools</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedPermissions.map(p => (
            <Table.Row key={p.permission}>
              <Table.Cell>{label(p.permission)}</Table.Cell>
              <Table.Cell>{schools(p)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const { user } = this.props;

    // A single permission type could exist in multiple roles (potentially with different schools).
    // This gives "all permissions" for a user:
    const allPermissionsAndSchools: PermissionsAndASchools[] = user.roles.flatMap(ur =>
      ur.role.permissions.map(perm => ({
        permission: perm,
        schools: ur.schools,
      })),
    );

    // We now combine the schools for each permission, to give a single permission entry:
    const permissionsAndSchools: PermissionsAndASchools[] = allPermissionsAndSchools.reduce(
      (acc: PermissionsAndASchools[], curr: PermissionsAndASchools) => {
        const existing = acc.find(p => p.permission === curr.permission);
        if (existing) {
          existing.schools = union([...existing.schools, ...curr.schools]);
        } else {
          acc.push(curr);
        }
        return acc;
      },
      [],
    );

    return (
      <details>
        <summary>Show permissions</summary>
        {this.permissionsTable(permissionsAndSchools)}
      </details>
    );
  }
}
