import lodash from "lodash";
import { useEffect, useRef } from "react";

export default function useDeepEqualEffect(func: () => void, deps: unknown[]) {
  const oldDeps = useRef<unknown[]>();

  useEffect(() => {
    if (!lodash.isEqual(deps, oldDeps.current)) {
      oldDeps.current = deps;
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
