import { PredictionOrganisation, PredictionGrouping } from "../../model/types";
import { PredictionGroupingEnum } from "@qmspringboard/shared/dist/model/enums";
import Opt from "@qmspringboard/shared/dist/utils/opt";
import { Location } from "history";
import { admissionsTeamCode, TeamCode } from "../../model/team";
import { PredictionFees } from "../../reducers/predictions/state";
import { formatQueryString, locationQueryParams, normalizeQueryParams } from "../../utils/queryParams";
import { admissionsOrg, allOrg, QueryStringState, unsafeSchoolOrg, teamOrg } from "./type";

function parseFees(str: Opt<string>): PredictionFees {
  switch (str) {
    case "home":
      return "home";
    case "overseas":
      return "overseas";
    case "overall":
      return "overall";
    default:
      return "overall";
  }
}

function parseBoolean(str: Opt<string>): boolean {
  return str === "y" || str === "yes" || str === "t" || str === "true" || str === undefined;
}

function parseOrg(str: Opt<string>): Opt<PredictionOrganisation> {
  if (str == null) {
    return null;
  } else if (str === "all") {
    return allOrg;
  } else if (str === admissionsTeamCode) {
    return admissionsOrg;
  } else {
    return unsafeSchoolOrg(str);
  }
}

export function queryStringState(location: Location, currentTeam: Opt<TeamCode>): QueryStringState {
  const query = locationQueryParams(location);

  const org: PredictionOrganisation = parseOrg(query.org) ?? (currentTeam == null ? allOrg : teamOrg(currentTeam));

  const grouping: PredictionGrouping = PredictionGroupingEnum.isValue(query.grouping) ? query.grouping : PredictionGroupingEnum.Group;

  const fees = parseFees(query.fees) || "overall";

  return {
    org,
    grouping,
    fees,
    showCharts: parseBoolean(query.showCharts),
    showOfferStatusSummary: parseBoolean(query.showOfferStatusSummary),
    showOverall: parseBoolean(query.showOverall),
    showUcas: parseBoolean(query.showUcas),
    showClearing: parseBoolean(query.showClearing),
  };
}

export function formatQueryStringState(location: Location, state: QueryStringState): string {
  return formatQueryString({
    ...locationQueryParams(location),
    ...normalizeQueryParams(state as unknown as Record<string, Opt<string | number | boolean>>),
  });
}
