import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Grid } from "semantic-ui-react";
import * as pot from "../api/pot";
import ReportingGroupList from "../components/ReportingGroupList";
import { FieldLabel } from "../components/fields";
import { SearchProps, withSearch } from "../components/SearchHOC";
import Title from "../components/Title";
import { emptySearchResults } from "../model/search";
import { ReportingGroup } from "../model/types";
import * as reportingGroupSearch from "../reducers/reportingGroupSearch";
import * as teams from "../reducers/teams";
import { RouteComponentProps } from "react-router";

interface Props extends SearchProps<ReportingGroup>, RouteComponentProps {
  //
}

class ReportingGroupListPage extends Component<Props> {
  render() {
    const { pagerComponent, /*searchComponent,*/ sortComponent } = this.props;
    const results = pot.getOrElse(this.props.results, emptySearchResults());
    const fetching = pot.fetching(this.props.results);

    return (
      <Title title="Reporting Groups">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={12}>
                <FieldLabel>{/*searchComponent*/}</FieldLabel>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={6} computer={4}>
                <FieldLabel>
                  <Button fluid primary as={Link} to="/admin/reporting-group/new">
                    Add a reporting group
                  </Button>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ReportingGroupList results={results} fetching={fetching} sortComponent={sortComponent} />
          {pagerComponent}
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    results: reportingGroupSearch.results(state),
    fetching: reportingGroupSearch.fetching(state),
    currentTeam: teams.currentTeam(state),
    searchBySchool: true,
  }),
  {
    search: reportingGroupSearch.search,
    reset: reportingGroupSearch.reset,
  },
)(withSearch(ReportingGroupListPage));
