import { isClosedToFeeCategory, isFullToFeeCategory } from "@qmspringboard/shared/dist/model/programme";
import { some } from "lodash";
import { Opt } from "../utils";
import {
  ApplicationMethodEnum,
  FeeCategory,
  GlobalPermissionType,
  GlobalPermissionTypeEnum,
  OfferStatus,
  OfferStatusEnum,
  SchoolPermissionType,
  SchoolPermissionTypeEnum,
} from "./enums";
import { foldTeamCode, TeamCode } from "./team";
import { ApplicationMethod, isGlobalPermissionType, Permission, Programme, User, UserRole } from "./types";

type SchoolCode = string;

export type Permissions = {
  canCreateBlankApplication(method: ApplicationMethod): boolean;
  canUpdateCocoStatuses(school: SchoolCode): boolean;
  canUpdateUcasSitsFields(school: SchoolCode): boolean;
  canTransferApplicants(): boolean;
  canCreateApplicants(): boolean;
  canInviteToInterview(school: SchoolCode): boolean;
  canUpdateApplicantDetails(): boolean;
  canUpdateQualifications(): boolean;
  canUpdateNonALevelQualifications(): boolean;
  canViewAppConfig(teamCode: TeamCode): boolean;
  canUpdateLabels(teamCode: TeamCode): boolean;
  canAddNotes(teamCode: TeamCode): boolean;
  canUpdateNotes(teamCode: TeamCode): boolean;
  canUpdatePredictionOverrides(school: SchoolCode): boolean;
  canHandoverApplications(school: SchoolCode): boolean;
  canSeeProgrammeInProgrammeSelect(method: ApplicationMethod, prog: Programme): boolean;
  canUpdateApplication(method: ApplicationMethod, prog: Programme): boolean;
  canChangeOfferStatusTo(params: {
    method: ApplicationMethod;
    programme: Programme;
    oldOfferStatus: Opt<OfferStatus>;
    newOfferStatus: Opt<OfferStatus>;
    guessedFeeCategory: FeeCategory | null;
  }): boolean;
  // HACK: Replace this in 2022 with a flag Programme.visibleToHotlineUsers.
  // This is the only R900 programme that should be visible to hotline users.
  // We filter out other programmes so the user can't see/select them.
  canMakeOffersOnAllR900Routes(): boolean;
  canMakeOffersOnClosedProgrammes(school: SchoolCode): boolean;
  canMakeInterSchoolChangesOfProgramme(): boolean;
  canMakeInterSchoolChangeOfProgramme(params: { method: ApplicationMethod; oldSchoolCode: SchoolCode; newSchoolCode: SchoolCode }): boolean;
  canUpdateUsers(teamCode: TeamCode): boolean;
  canUpdateProgrammes(teamCode: TeamCode): boolean;
  canUpdateReportingGroups(teamCode: TeamCode): boolean;
  canUpdateLabelPrototypes(teamCode: TeamCode): boolean;
  canUpdateAdmissionsTargets(): boolean;
  canUpdateSchoolConfig(teamCode: TeamCode): boolean;
  canUpdateSchoolDeadlines(teamCode: TeamCode): boolean;
  canUpdateClassifierRules(teamCode: TeamCode): boolean;
  canUpdateTelephoneScripts(teamCode: TeamCode): boolean;
  canUpdateEmailTemplates(teamCode: TeamCode): boolean;
  canSyncSelectorList(teamCode: TeamCode): boolean;
  canUpdateSavedSearches(teamCode: TeamCode): boolean;
  canUpdateDeveloperPermissions(): boolean;
  isHotlineUser(): boolean;
  canAddAnnouncements(): boolean;
  canImportUcasClearingPlusApplicants(): boolean;
  canViewInternationalEquivsTable(): boolean;
};

const Global = GlobalPermissionTypeEnum;
const School = SchoolPermissionTypeEnum;

function hasGlobal(permissions: Permission[], type: GlobalPermissionType): boolean {
  return some(permissions, perm => {
    return perm.type === "SuperUser" || perm.type === type;
  });
}

function hasSchool(permissions: Permission[], type: SchoolPermissionType, school: SchoolCode): boolean {
  return some(permissions, perm => {
    return perm.type === "SuperUser" || (perm.type === type && perm.school === school);
  });
}

function hasAnySchool(permissions: Permission[], type: SchoolPermissionType): boolean {
  return some(permissions, perm => perm.type === type);
}

export const noopPermissions: Permissions = {
  canCreateBlankApplication(_method: ApplicationMethod) {
    return false;
  },
  canUpdateCocoStatuses(_school: SchoolCode) {
    return false;
  },
  canUpdateUcasSitsFields(_school: SchoolCode) {
    return false;
  },
  canTransferApplicants() {
    return false;
  },
  canCreateApplicants() {
    return false;
  },
  canInviteToInterview(_school: SchoolCode) {
    return false;
  },
  canUpdateApplicantDetails() {
    return false;
  },
  canUpdateQualifications() {
    return false;
  },
  canUpdateNonALevelQualifications() {
    return false;
  },
  canViewAppConfig(_teamCode: TeamCode) {
    return false;
  },
  canUpdateProgrammes(_teamCode: TeamCode) {
    return false;
  },
  canUpdateLabels(_teamCode: TeamCode) {
    return false;
  },
  canAddNotes(_teamCode: TeamCode) {
    return false;
  },
  canUpdateNotes(_teamCode: TeamCode) {
    return false;
  },
  canUpdatePredictionOverrides(_school: SchoolCode) {
    return false;
  },
  canHandoverApplications(_school: SchoolCode) {
    return false;
  },
  canUpdateAdmissionsTargets() {
    return false;
  },
  canSeeProgrammeInProgrammeSelect(_method: ApplicationMethod, _prog: Programme): boolean {
    return false;
  },
  canUpdateApplication(_method: ApplicationMethod, _prog: Programme): boolean {
    return false;
  },
  canChangeOfferStatusTo(_params: {
    method: ApplicationMethod;
    programme: Programme;
    oldOfferStatus: Opt<OfferStatus>;
    newOfferStatus: Opt<OfferStatus>;
    guessedFeeCategory: FeeCategory | null;
  }): boolean {
    return false;
  },
  canMakeInterSchoolChangesOfProgramme(): boolean {
    return false;
  },
  canMakeInterSchoolChangeOfProgramme(_params: { method: ApplicationMethod; oldSchoolCode: SchoolCode; newSchoolCode: SchoolCode }): boolean {
    return false;
  },
  canMakeOffersOnAllR900Routes(): boolean {
    return false;
  },
  canMakeOffersOnClosedProgrammes(_school: SchoolCode): boolean {
    return false;
  },
  canUpdateUsers(_teamCode: TeamCode) {
    return false;
  },
  canUpdateReportingGroups(_teamCode: TeamCode) {
    return false;
  },
  canUpdateLabelPrototypes(_teamCode: TeamCode) {
    return false;
  },
  canUpdateSchoolConfig(_teamCode: TeamCode) {
    return false;
  },
  canUpdateSchoolDeadlines(_teamCode: TeamCode) {
    return false;
  },
  canUpdateClassifierRules(_teamCode: TeamCode) {
    return false;
  },
  canUpdateTelephoneScripts(_teamCode: TeamCode) {
    return false;
  },
  canUpdateEmailTemplates(_teamCode: TeamCode) {
    return false;
  },
  canSyncSelectorList(_teamCode: TeamCode) {
    return false;
  },
  canUpdateSavedSearches(_teamCode: TeamCode) {
    return false;
  },
  canUpdateDeveloperPermissions() {
    return false;
  },
  canAddAnnouncements() {
    return false;
  },
  isHotlineUser() {
    return false;
  },
  canImportUcasClearingPlusApplicants() {
    return false;
  },
  canViewInternationalEquivsTable() {
    return false;
  },
};

function roleToPermissions(userRole: UserRole): Permission[] {
  const permissions = userRole.role.permissions.map(permissionType => {
    if (isGlobalPermissionType(permissionType)) {
      return [{ type: permissionType, school: null }];
    } else {
      return userRole.schools.map(school => {
        return { type: permissionType, school };
      });
    }
  });

  return permissions.flat();
}

export function userPermissions(user: User): Permission[] {
  return user.roles.flatMap(roleToPermissions);
}

export function createPermissions(permissions: Permission[]): Permissions {
  return {
    canCreateBlankApplication(method: ApplicationMethod) {
      switch (method) {
        case ApplicationMethodEnum.Ucas:
          return hasAnySchool(permissions, School.CanUpdateUcasApplications);
        case ApplicationMethodEnum.Clearing:
          return (
            hasGlobal(permissions, Global.CanMakeHotlineClearingOffers) ||
            hasAnySchool(permissions, School.CanPutOnClearingWaitingList) ||
            hasAnySchool(permissions, School.CanMakeClearingOffers) ||
            hasAnySchool(permissions, School.CanRejectClearingApplications) ||
            hasAnySchool(permissions, School.CanAcceptClearingApplications)
          );
        default:
          return false;
      }
    },

    canUpdateCocoStatuses(school: SchoolCode) {
      return hasSchool(permissions, School.CanUpdateCocoStatuses, school);
    },

    canUpdateUcasSitsFields(school: SchoolCode) {
      return hasSchool(permissions, School.CanUpdateUcasSitsFields, school);
    },

    canTransferApplicants() {
      return hasGlobal(permissions, Global.CanTransferApplicants);
    },

    canUpdateAdmissionsTargets() {
      return hasGlobal(permissions, Global.CanUpdateAdmissionsTargets);
    },

    canCreateApplicants() {
      return hasGlobal(permissions, Global.CanCreateApplicants);
    },

    canInviteToInterview(school: SchoolCode) {
      return hasSchool(permissions, School.CanInterviewApplications, school);
    },

    canUpdateApplicantDetails() {
      return hasGlobal(permissions, Global.CanUpdateApplicantDetails);
    },

    canUpdateQualifications() {
      return hasGlobal(permissions, Global.CanUpdateQualifications);
    },

    isHotlineUser() {
      return hasGlobal(permissions, Global.CanMakeHotlineClearingOffers);
    },

    canUpdateNonALevelQualifications() {
      return hasGlobal(permissions, Global.CanUpdateNonALevelQualifications);
    },

    canViewAppConfig() {
      return hasGlobal(permissions, Global.SuperUser);
    },

    canUpdateProgrammes() {
      return hasGlobal(permissions, Global.CanUpdateProgrammes);
    },

    canUpdateLabels(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => hasGlobal(permissions, Global.CanUpdateAdmissionsLabels),
        school => hasSchool(permissions, School.CanUpdateSchoolLabels, school),
      );
    },

    canAddNotes(_teamCode: TeamCode) {
      return true;
    },

    canUpdateNotes(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => hasGlobal(permissions, Global.CanUpdateAdmissionsNotes),
        school => hasSchool(permissions, School.CanUpdateSchoolNotes, school),
      );
    },

    canUpdatePredictionOverrides(school: SchoolCode) {
      return hasSchool(permissions, School.CanUpdatePredictionOverrides, school);
    },

    canHandoverApplications(school: SchoolCode) {
      return hasSchool(permissions, School.CanHandoverApplications, school);
    },

    canMakeOffersOnAllR900Routes() {
      return hasSchool(permissions, School.CanMakeClearingOffers, "SLLF");
    },

    canMakeOffersOnClosedProgrammes(school: SchoolCode) {
      return hasSchool(permissions, School.CanMakeOffersOnClosedProgrammes, school);
    },

    canSeeProgrammeInProgrammeSelect(method: ApplicationMethod, prog: Programme): boolean {
      switch (method) {
        case ApplicationMethodEnum.Ucas:
          return hasSchool(permissions, School.CanUpdateUcasApplications, prog.schoolCode);

        case ApplicationMethodEnum.Clearing:
          return (
            hasSchool(permissions, School.CanPutOnClearingWaitingList, prog.schoolCode) ||
            hasSchool(permissions, School.CanMakeClearingOffers, prog.schoolCode) ||
            hasGlobal(permissions, Global.CanMakeHotlineClearingOffers) ||
            hasSchool(permissions, School.CanRejectClearingApplications, prog.schoolCode) ||
            hasSchool(permissions, School.CanAcceptClearingApplications, prog.schoolCode)
          );

        default:
          throw new Error("Should not get here!");
      }
    },

    canUpdateApplication(method: ApplicationMethod, prog: Programme): boolean {
      switch (method) {
        case ApplicationMethodEnum.Ucas:
          return hasSchool(permissions, School.CanUpdateUcasApplications, prog.schoolCode);

        case ApplicationMethodEnum.Clearing:
          return (
            hasSchool(permissions, School.CanPutOnClearingWaitingList, prog.schoolCode) ||
            hasSchool(permissions, School.CanMakeClearingOffers, prog.schoolCode) ||
            (prog.allowHotlineClearingOffers && hasGlobal(permissions, Global.CanMakeHotlineClearingOffers)) ||
            hasSchool(permissions, School.CanRejectClearingApplications, prog.schoolCode) ||
            hasSchool(permissions, School.CanAcceptClearingApplications, prog.schoolCode)
          );

        default:
          throw new Error("Should not get here!");
      }
    },

    /** Can the user change the offer status of a `method` applicant
     * in `schoolCode` from `oldOfferStatus` to `newOfferStatus`?
     *
     * This method does not handle inter-school changes of programme.
     *
     * Adjustment and UCAS changes are handled by a single permission.
     * Clearing changes follow the chart below.
     *
     * This function has an equivalent in Scala on the server.
     * "Clause" numbers below are to map behaviour between the two.
     *
     * ```
     *           | To
     *           | None   H   I    L    S    O    P    M    D    R    A    C
     * ----------+-----------------------------------------------------------
     * From None | ID   MAKE INVT WAIT MAKE MAKE MAKE MAKE MAKE REJ  ACC  ACC
     *      H    | ERR  ID   INVT WAIT MAKE MAKE MAKE MAKE MAKE REJ  ACC  ACC
     *      I    | ERR  WAIT  ID  WAIT MAKE MAKE MAKE MAKE MAKE REJ  ACC  ACC
     *      L    | ERR  WAIT INVT ID   MAKE MAKE MAKE MAKE MAKE REJ  ACC  ACC
     *      S    | ERR  MAKE INVT MAKE ID   MAKE MAKE MAKE MAKE REJ  ACC  ACC
     *      O    | ERR  MAKE INVT MAKE MAKE ID   MAKE MAKE MAKE REJ  ACC  ACC
     *      P    | ERR  MAKE INVT MAKE MAKE MAKE ID   MAKE MAKE REJ  ACC  ACC
     *      M    | ERR  MAKE INVT MAKE MAKE MAKE MAKE ID   MAKE REJ  ACC  ACC
     *      D    | ERR  MAKE INVT MAKE MAKE MAKE MAKE MAKE ID   REJ  ACC  ACC
     *      R    | ERR  REJ  INVT REJ  REJ  REJ  REJ  REJ  REJ  ID   ACC  ACC
     *      A    | ERR  ACC  INVT ACC  ACC  ACC  ACC  ACC  ACC  ACC  ID   ACC
     *      C    | ERR  ACC  INVT ACC  ACC  ACC  ACC  ACC  ACC  ACC  ACC  ID
     * ```
     */
    canChangeOfferStatusTo(params: {
      method: ApplicationMethod;
      programme: Programme;
      oldOfferStatus: Opt<OfferStatus>;
      newOfferStatus: Opt<OfferStatus>;
      guessedFeeCategory: FeeCategory | null;
    }): boolean {
      const { method, programme, oldOfferStatus, newOfferStatus, guessedFeeCategory } = params;

      const { schoolCode, allowHotlineClearingOffers } = programme;

      // Clause 0
      if (hasGlobal(permissions, Global.SuperUser)) {
        return true;
      }

      switch (method) {
        case ApplicationMethodEnum.Ucas:
          // Clause 1
          return newOfferStatus == null ? hasSchool(permissions, School.CanUpdateUcasApplications, schoolCode) : false;

        case ApplicationMethodEnum.Clearing:
          // Clause 3 - ID
          if (oldOfferStatus === newOfferStatus) {
            return (
              hasSchool(permissions, School.CanPutOnClearingWaitingList, schoolCode) ||
              hasSchool(permissions, School.CanMakeClearingOffers, schoolCode) ||
              (allowHotlineClearingOffers && hasGlobal(permissions, Global.CanMakeHotlineClearingOffers)) ||
              hasSchool(permissions, School.CanRejectClearingApplications, schoolCode) ||
              hasSchool(permissions, School.CanAcceptClearingApplications, schoolCode)
            );
          }

          // Clause 3b - INVT
          if (newOfferStatus === OfferStatusEnum.Interview) {
            return hasSchool(permissions, School.CanInterviewApplications, schoolCode);
          }

          // Clause 4 - ERR
          if (newOfferStatus == null) {
            return false;
          }

          // Clause 5 - ACC
          if (
            oldOfferStatus === OfferStatusEnum.Accepted ||
            oldOfferStatus === OfferStatusEnum.Confirmed ||
            newOfferStatus === OfferStatusEnum.Accepted ||
            newOfferStatus === OfferStatusEnum.Confirmed
          ) {
            return hasSchool(permissions, School.CanAcceptClearingApplications, schoolCode);
          }

          // Clause 6 - REJ
          if (oldOfferStatus === OfferStatusEnum.Rejected || newOfferStatus === OfferStatusEnum.Rejected) {
            return (
              hasSchool(permissions, School.CanRejectClearingApplications, schoolCode) ||
              (allowHotlineClearingOffers && hasGlobal(permissions, Global.CanMakeHotlineClearingOffers))
            );
          }

          // Clause 7 - MAKE
          if (
            oldOfferStatus === OfferStatusEnum.SelfReferral ||
            oldOfferStatus === OfferStatusEnum.OnlineReferral ||
            oldOfferStatus === OfferStatusEnum.MadePreRelease ||
            oldOfferStatus === OfferStatusEnum.Made ||
            oldOfferStatus === OfferStatusEnum.Declined ||
            newOfferStatus === OfferStatusEnum.SelfReferral ||
            newOfferStatus === OfferStatusEnum.OnlineReferral ||
            newOfferStatus === OfferStatusEnum.MadePreRelease ||
            newOfferStatus === OfferStatusEnum.Made ||
            newOfferStatus === OfferStatusEnum.Declined
          ) {
            const canMakeWhenOpen = () =>
              hasSchool(permissions, School.CanMakeClearingOffers, schoolCode) ||
              (allowHotlineClearingOffers && hasGlobal(permissions, Global.CanMakeHotlineClearingOffers));

            const canMakeWhenClosed = () => hasSchool(permissions, School.CanMakeOffersOnClosedProgrammes, schoolCode);

            if (isClosedToFeeCategory(programme, guessedFeeCategory) || isFullToFeeCategory(programme, guessedFeeCategory)) {
              return canMakeWhenOpen() && canMakeWhenClosed();
            } else {
              return canMakeWhenOpen();
            }
          }

          // Clause 8
          if (oldOfferStatus === OfferStatusEnum.WaitingList || newOfferStatus === OfferStatusEnum.WaitingList) {
            return hasSchool(permissions, School.CanPutOnClearingWaitingList, schoolCode);
          }

          // Clause 9
          if (newOfferStatus === OfferStatusEnum.OnHold) {
            return (
              hasSchool(permissions, School.CanPutOnClearingWaitingList, schoolCode) ||
              hasSchool(permissions, School.CanMakeClearingOffers, schoolCode) ||
              hasGlobal(permissions, Global.CanMakeHotlineClearingOffers)
            );
          }

          throw new Error("Should never get here!");

        default:
          throw new Error("Should not get here!");
      }
    },

    canMakeInterSchoolChangesOfProgramme(): boolean {
      return hasGlobal(permissions, Global.CanMakeInterSchoolChangesOfProgramme);
    },

    canMakeInterSchoolChangeOfProgramme(params: { method: ApplicationMethod; oldSchoolCode: string; newSchoolCode: string }): boolean {
      const { method, oldSchoolCode, newSchoolCode } = params;

      switch (method) {
        case ApplicationMethodEnum.Ucas:
          return (
            hasGlobal(permissions, Global.CanMakeInterSchoolChangesOfProgramme) &&
            (hasSchool(permissions, School.CanUpdateUcasApplications, oldSchoolCode) ||
              hasSchool(permissions, School.CanUpdateUcasApplications, newSchoolCode))
          );

        case ApplicationMethodEnum.Clearing:
          return (
            hasGlobal(permissions, Global.CanMakeInterSchoolChangesOfProgramme) &&
            (hasSchool(permissions, School.CanPutOnClearingWaitingList, oldSchoolCode) ||
              hasSchool(permissions, School.CanPutOnClearingWaitingList, newSchoolCode) ||
              hasSchool(permissions, School.CanMakeClearingOffers, oldSchoolCode) ||
              hasSchool(permissions, School.CanMakeClearingOffers, newSchoolCode) ||
              hasSchool(permissions, School.CanRejectClearingApplications, oldSchoolCode) ||
              hasSchool(permissions, School.CanRejectClearingApplications, newSchoolCode) ||
              hasSchool(permissions, School.CanAcceptClearingApplications, oldSchoolCode) ||
              hasSchool(permissions, School.CanAcceptClearingApplications, newSchoolCode))
          );

        default:
          throw new Error("Should not get here!");
      }
    },

    canUpdateUsers(_teamCode: TeamCode) {
      return hasGlobal(permissions, Global.CanUpdateUsers);
    },

    canUpdateReportingGroups(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => hasGlobal(permissions, Global.CanUpdateAdmissionsTargets),
        school => hasSchool(permissions, School.CanUpdateReportingGroups, school),
      );
    },

    canUpdateLabelPrototypes(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => hasGlobal(permissions, Global.CanUpdateAdmissionsLabelPrototypes),
        school => hasSchool(permissions, School.CanUpdateSchoolLabelPrototypes, school),
      );
    },

    canUpdateSchoolConfig(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => false,
        school => hasSchool(permissions, School.CanUpdateSchoolConfig, school),
      );
    },
    canUpdateSchoolDeadlines(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => false,
        school => hasSchool(permissions, School.CanUpdateSchoolDeadlines, school),
      );
    },
    canUpdateClassifierRules(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => false,
        school => hasSchool(permissions, School.CanUpdateClassifierRules, school),
      );
    },
    canUpdateTelephoneScripts(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => false,
        school => hasSchool(permissions, School.CanUpdateTelephoneScripts, school),
      );
    },
    canUpdateEmailTemplates(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => false,
        school => hasSchool(permissions, School.CanUpdateEmailTemplates, school),
      );
    },

    canSyncSelectorList() {
      return hasGlobal(permissions, Global.CanSyncSelectorList);
    },
    canUpdateSavedSearches(teamCode: TeamCode) {
      return foldTeamCode(
        teamCode,
        () => hasGlobal(permissions, Global.CanUpdateAdmissionsSavedSearches),
        school => hasSchool(permissions, School.CanUpdateSchoolSavedSearches, school),
      );
    },

    canUpdateDeveloperPermissions() {
      return hasGlobal(permissions, Global.SuperUser);
    },

    canAddAnnouncements() {
      return hasGlobal(permissions, Global.CanAddAnnouncements);
    },
    canImportUcasClearingPlusApplicants() {
      return hasGlobal(permissions, Global.CanImportUcasClearingPlusApplicants);
    },
    canViewInternationalEquivsTable() {
      return hasGlobal(permissions, Global.CanViewInternationalEquivsTable);
    },
  };
}
