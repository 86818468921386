import { Just, Maybe } from "purify-ts/Maybe";
import React from "react";
import { BaseFieldProps } from "./base";
import { BaseInput, ExtraInputProps } from "./BaseInput";

export interface NumberInputProps extends BaseFieldProps<number>, ExtraInputProps {
  decimalPlaces?: number;
}

export function formatDecimalPlaces(num: number, decimalPlaces?: number): string {
  if (decimalPlaces == null) {
    return String(num);
  } else if (Number.isInteger(num * Math.pow(10, decimalPlaces))) {
    // If the number has less than N decimal places, don't *add* any decimal places to it:
    return String(num);
  } else {
    // If the number has more than N decimal places, truncate it:
    return num.toFixed(decimalPlaces);
  }
}

export function validateDecimalPlaces(str: string, decimalPlaces?: number): Maybe<number> {
  const trimmed = str.trim();
  const answer = Just(Number(trimmed)).filter(isFinite);

  if (decimalPlaces == null) {
    return answer;
  } else {
    const power = Math.pow(10, decimalPlaces);
    return answer.map(num => Math.round(num * power) / power);
  }
}

export default function NumberInput(props: NumberInputProps) {
  const { decimalPlaces, ...rest } = props;

  function format(num: number): string {
    return formatDecimalPlaces(num, decimalPlaces);
  }

  function validate(str: string): Maybe<number> {
    return validateDecimalPlaces(str, decimalPlaces);
  }

  return <BaseInput format={format} validate={validate} {...rest} />;
}
