import { AppError, ConcurrentModificationError, ForbiddenError, NotFoundError, UnauthorizedError, ValidationError } from "../../errors";

export const SET_MUTEX = "fetch/SET_MUTEX";
export const CLEAR_MUTEX = "fetch/CLEAR_MUTEX";
export const ERROR = "fetch/ERROR";
export const NOT_FOUND_ERROR = "fetch/NOT_FOUND_ERROR";
export const VALIDATION_ERROR = "fetch/VALIDATION_ERROR";
export const UNAUTHORIZED_ERROR = "fetch/UNAUTHORIZED_ERROR";
export const FORBIDDEN_ERROR = "fetch/FORBIDDEN_ERROR";
export const CONCURRENT_MODIFICATION_ERROR = "fetch/CONCURRENT_MODIFICATION_ERROR";
export const UNKNOWN_ERROR = "fetch/UNKNOWN_ERROR";

export type MyAction =
  | { type: "fetch/SET_MUTEX"; mutex: string; value: string }
  | { type: "fetch/CLEAR_MUTEX"; mutex: string }
  | { type: "fetch/ERROR"; error: AppError }
  | { type: "fetch/NOT_FOUND_ERROR"; error: NotFoundError }
  | { type: "fetch/VALIDATION_ERROR"; error: ValidationError }
  | { type: "fetch/UNAUTHORIZED_ERROR"; error: UnauthorizedError }
  | { type: "fetch/FORBIDDEN_ERROR"; error: ForbiddenError }
  | {
      type: "fetch/CONCURRENT_MODIFICATION_ERROR";
      error: ConcurrentModificationError;
    }
  | { type: "fetch/UNKNOWN_ERROR"; error: AppError };
