import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Grid } from "semantic-ui-react";
import * as pot from "../api/pot";
import { FieldLabel } from "../components/fields";
import ProgrammeList from "../components/ProgrammeList";
import { SearchProps, withSearch } from "../components/SearchHOC";
import Title from "../components/Title";
import { emptySearchResults } from "../model/search";
import { Programme } from "../model/types";
import * as programmeSearch from "../reducers/programmeSearch";
import * as teams from "../reducers/teams";
import { RouteComponentProps } from "react-router";

interface Props extends SearchProps<Programme>, RouteComponentProps {
  //
}

class ProgrammeListPage extends Component<Props> {
  render() {
    const { refetch, pagerComponent, searchComponent, sortComponent } = this.props;
    const results = pot.getOrElse(this.props.results, emptySearchResults());
    const fetching = pot.fetching(this.props.results);
    const fetchError = pot.error(this.props.results);

    return (
      <Title title="Programmes">
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={12}>
                <FieldLabel>{searchComponent}</FieldLabel>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={6} computer={4}>
                <FieldLabel>
                  <Button fluid primary as={Link} to="/admin/programme/new">
                    Add a programme
                  </Button>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <ProgrammeList results={results} refetch={refetch} fetching={fetching} fetchError={fetchError} sortComponent={sortComponent} />
          {pagerComponent}
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    results: programmeSearch.results(state),
    fetching: programmeSearch.fetching(state),
    currentTeam: teams.currentTeam(state),
    searchBySchool: true,
  }),
  {
    search: programmeSearch.search,
    reset: programmeSearch.reset,
  },
)(withSearch(ProgrammeListPage));
