import React, { Component } from "react";
import { Header, Segment } from "semantic-ui-react";
import { TelephoneScript } from "../model/types";

interface Props {
  script: TelephoneScript;
}

export default class ApplicationScript extends Component<Props> {
  render() {
    return (
      <div>
        <Header as="h3">
          <div dangerouslySetInnerHTML={{ __html: this.props.script.title }} />
        </Header>
        <Segment basic vertical dangerouslySetInnerHTML={{ __html: this.props.script.content }} />
      </div>
    );
  }
}
