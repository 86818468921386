import { isEqual, keys } from "lodash";
import React from "react";
import { Segment } from "semantic-ui-react";
import PermissionsView from "../../components/PermissionsView";
import { AppError, ConcurrentModificationError, ForbiddenError, NotFoundError, UnauthorizedError, ValidationError } from "../../errors";
import { AppDispatch, AppAction } from "../actions";
import { logout } from "../auth";
import { showModal } from "../modal";
import { GetState } from "../state";
import {
  MyAction,
  ERROR,
  NOT_FOUND_ERROR,
  VALIDATION_ERROR,
  UNAUTHORIZED_ERROR,
  FORBIDDEN_ERROR,
  CONCURRENT_MODIFICATION_ERROR,
  UNKNOWN_ERROR,
} from "./actions";
import { Messages } from "../../model/errors";
import { Path } from "../../model/types";

export type ErrorHandler<A> = (dispatch: AppDispatch, getState: GetState, error: A) => unknown;

export type ErrorHandlers = {
  notFound?: ErrorHandler<NotFoundError>;
  validation?: ErrorHandler<ValidationError>;
  unauthorized?: ErrorHandler<UnauthorizedError>;
  concurrentModification?: ErrorHandler<ConcurrentModificationError>;
  forbidden?: ErrorHandler<ForbiddenError>;
  unknown?: ErrorHandler<AppError>;
};

const showErrorModal = (dispatch: AppDispatch, getState: GetState, error: AppError) => {
  dispatch(
    showModal("alert", {
      title: "Oops!",
      content: (
        <div>
          <p>Something went wrong. Here&apos;s the error message:</p>
          <p style={{ textAlign: "center" }}>
            <strong>{error.message}</strong>
          </p>
          {!isEqual(keys(error), ["message"]) && (
            <div>
              <p>Here&apos;s some extra detail that should help the development team:</p>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </div>
          )}
        </div>
      ),
      primaryButtonText: "Reload the page",
      onPrimaryClick: () => window.location.reload(),
    }),
  );
};

const showForbiddenModal = (dispatch: AppDispatch, _getState: GetState, error: ForbiddenError) => {
  dispatch(
    showModal("alert", {
      title: "Permission denied",
      content: (
        <div>
          <p>You didn&apos;t have all the necessary permissions for that.</p>
          {error.missingPermissions && error.missingPermissions.length > 0 && (
            <Segment>
              <p>
                <strong>Missing permissions:</strong>
              </p>
              <PermissionsView permissions={error.missingPermissions} />
            </Segment>
          )}
          {error.failedCheck && (
            <Segment>
              <p>
                <strong>Failed permission check:</strong>
              </p>
              <pre>{JSON.stringify(error.failedCheck, null, 2)}</pre>
            </Segment>
          )}
        </div>
      ),
      primaryButtonText: "Reload the page",
      onPrimaryClick: () => window.location.reload(),
    }),
  );
};

const redirectToLogin = (dispatch: AppDispatch, _getState: GetState, _error: AppError) => dispatch(logout({ showToast: false }));

const showConcurrentModificationModal = (dispatch: AppDispatch, getState: GetState, error: ConcurrentModificationError) => {
  dispatch(
    showModal("alert", {
      title: "Concurrent modification",
      content: (
        <div>
          <p>Someone else modified that record since you loaded it!</p>
          <Segment basic textAlign="center">
            Record <strong>{error.item}</strong> of type <strong>{error.itemType}</strong>
          </Segment>
          <p>
            Your changes have <em>not</em> been saved. Reload the page and try again on the updated record.
          </p>
        </div>
      ),
      primaryButtonText: "Reload the Page",
      onPrimaryClick: () => window.location.reload(),
      secondaryButtonText: "Keep going and hope things clear up",
    }),
  );
};

export function errorHandler({ notFound, validation, unauthorized, concurrentModification, forbidden, unknown }: ErrorHandlers) {
  const notFoundHandler = notFound || showErrorModal;
  const validationHandler = validation || showErrorModal;
  const unauthorizedHandler = unauthorized || redirectToLogin;
  const concurrentModificationHandler = concurrentModification || showConcurrentModificationModal;
  const forbiddenHandler = forbidden || showForbiddenModal;
  const unknownHandler = unknown || showErrorModal;

  return (dispatch: AppDispatch, getState: GetState, error: unknown) => {
    if (error instanceof AppError) {
      dispatch({ type: ERROR, error });
      if (error instanceof NotFoundError) {
        dispatch({ type: NOT_FOUND_ERROR, error } as MyAction);
        notFoundHandler(dispatch, getState, error);
      } else if (error instanceof ValidationError) {
        dispatch({ type: VALIDATION_ERROR, error } as MyAction);
        validationHandler(dispatch, getState, error);
      } else if (error instanceof UnauthorizedError) {
        dispatch({ type: UNAUTHORIZED_ERROR, error } as MyAction);
        unauthorizedHandler(dispatch, getState, error);
      } else if (error instanceof ForbiddenError) {
        dispatch({ type: FORBIDDEN_ERROR, error } as MyAction);
        forbiddenHandler(dispatch, getState, error);
      } else if (error instanceof ConcurrentModificationError) {
        dispatch({ type: CONCURRENT_MODIFICATION_ERROR, error } as MyAction);
        concurrentModificationHandler(dispatch, getState, error);
      } else {
        dispatch({ type: UNKNOWN_ERROR, error } as MyAction);
        unknownHandler(dispatch, getState, error);
      }
    } else {
      console.info("expected error to inherit from AppError");
      throw error;
    }
  };
}

export const defaultErrorHandler = errorHandler({});

export function validationErrorHandler(action: (error: AppError, messages: Messages) => AppAction, prefix: Path = [], defaults: ErrorHandlers = {}) {
  return errorHandler({
    notFound: defaults.notFound,
    validation(dispatch, getState, error) {
      const messages = error.messages.map(msg => ({
        ...msg,
        path: prefix.concat(msg.path),
      }));
      dispatch(action(error, messages));
    },
    unauthorized: defaults.unauthorized,
    forbidden: defaults.forbidden,
    unknown: defaults.unknown,
  });
}
