import React, { ReactElement, useState } from "react";
import useKey from "use-key-hook";
import { connectModal } from "redux-modal";
import { Modal, Input, Header, Button } from "semantic-ui-react";
import { ChangeEvent } from "react";
import { ReactNodeLike } from "../utils";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  primaryButtonText?: ReactNodeLike;
  primaryButtonEnabled?: (str: string) => boolean;
  onPrimaryClick: (value: string) => void;
  secondaryButtonText?: ReactNodeLike;
  onSecondaryClick?: () => void;
  title?: string;
  renderContent: (elem: ReactElement) => ReactNodeLike;
  placeholder?: string;
}

function ModalInput({
  show,
  title,
  primaryButtonText,
  onPrimaryClick,
  onSecondaryClick,
  secondaryButtonText,
  renderContent,
  handleHide,
  placeholder,
}: Props) {
  const [value, setValue] = useState("");

  const primaryButtonDisabled = value.length === 0;

  const handlePrimaryClick = () => {
    if (!primaryButtonDisabled) {
      handleHide();
      onPrimaryClick(value);
    }
  };

  const handleSecondaryClick = () => {
    handleHide();
    onSecondaryClick && onSecondaryClick();
  };

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  useKey(
    (_pressedKey: string | number) => {
      handlePrimaryClick();
    },
    {
      detectKeys: [13],
    },
    { dependencies: [primaryButtonDisabled] },
  );

  const input = <Input fluid focus value={value} onChange={handleChange} placeholder={placeholder || ""} autoComplete="off" />;

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Header icon="info circle" content={title || "Input"} />
      <Modal.Content>{renderContent && renderContent(input)}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleSecondaryClick}>{secondaryButtonText || "Cancel"}</Button>
        <Button primary onClick={handlePrimaryClick} disabled={primaryButtonDisabled}>
          {primaryButtonText || "Confirm"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default connectModal({ name: "input" })(ModalInput);
