import React, { PureComponent } from "react";
import { Application } from "../model/types";
import { EntryPoint } from "../model/enums";
import { DropdownOptions } from "./fields";
import { Grid, Header } from "semantic-ui-react";
import { FieldLabel, DropdownField } from "./fields";
import { Opt } from "../utils";

type Props = {
  onChange: Function;
  readOnly: boolean;
  value: Application;
};

const entryPointOptions: DropdownOptions<Opt<EntryPoint>> = [
  { value: null, label: "-- Blank --" },
  { value: "01", label: "01 - Foundation year" },
  { value: "11", label: "11 - First year" },
  { value: "21", label: "21 - Second year" },
  { value: "31", label: "31 - Third year" },
  { value: "41", label: "41 - Fourth year" },
];

export default class EntryPointEditor extends PureComponent<Props> {
  render() {
    const { value, onChange, readOnly } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h4">Entry Point &mdash; Specialist Admissions Team</Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <FieldLabel label="Entry Point">
              <DropdownField
                value={value.entryPoint}
                readOnly={readOnly}
                onChange={entryPoint => onChange({ ...value, entryPoint })}
                placeholder="Select an entry point"
                fluid
                options={entryPointOptions}
              />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
