import React from "react";
import { Container, Header } from "semantic-ui-react";
import { format } from "../model/trackingNumber";
import { Opt } from "../utils";

export type TrackingNumberViewProps = {
  applicantId: Opt<number>;
};

const TrackingNumberView = ({ applicantId }: TrackingNumberViewProps) => (
  <Container>
    <Header as="h3" dividing>
      Tracking Number
    </Header>

    <p style={{ textAlign: "center", fontSize: "140%" }}>{applicantId && applicantId > 0 ? format(applicantId) : "Unassigned"}</p>
  </Container>
);

export default TrackingNumberView;
