import { QueryClient, useMutation, UseMutationOptions, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useStore } from "react-redux";
import type { Subjects } from "@qmspringboard/shared/dist/model/subjects";
import {
  AppError,
  ConcurrentModificationError,
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
  UnknownClientError,
  UnknownError,
  UnknownServerError,
  ValidationError,
} from "../errors";
import { AnnouncementDTO } from "../model/announcements.generated";
import { Applicant } from "../model/applicant";
import { Messages } from "../model/errors";
import { SearchParams, searchResults } from "../model/search";
import { teamToSchoolCode } from "../model/team";
import { EmailParts } from "../model/templateParts";
import type {
  ActionResult,
  ApplicantDetails,
  ApplicantDTO,
  ApplicantEditorDTO,
  Application,
  ApplicationChoice,
  ApplicationSummary,
  AttachmentRequest,
  AttachmentRequestResponseBody,
  AttachmentType,
  AuditEvent,
  BearerAuthentication,
  Checked,
  DownloadLink,
  EmailEvent,
  ExpirySettings,
  FeatureFlags,
  FileId,
  FileInfo,
  InternationalEquiv,
  Label,
  LabelPrototype,
  Note,
  PredictionDTO,
  PredictionGrouping,
  PredictionOrganisation,
  Previews,
  Programme,
  ProgrammeAuditEvents,
  ProgrammeCode,
  ProgrammeEditorDTO,
  Qualifications,
  ReportingGroup,
  RequirementsCheckDTO,
  Role,
  SavedSearch,
  School,
  SchoolCode,
  SearchResults,
  SelectorAction,
  SelectorListRowActions,
  SncProgrammeSummary,
  TeamCode,
  TelephoneScript,
  UploadLink,
  User,
} from "../model/types";
import { PhoneCallRequest, PhoneCallResult } from "../model/clickToCall.generated";
import { actingAsUser, bearerToken } from "../reducers/auth";
import { AppState } from "../reducers/state";
import { Opt } from "../utils";
import { formatQueryString, QueryParams, RelaxedQueryParams } from "../utils/queryParams";

// If we're running in a node server in development mode that means we're on a local development
// machine, in which case we want api calls to be directed to the development server running locally.
// Otherwise, assume we're running in production where the pre-built React app is being served up statically
// by the api server. That means the hostname for the api is the same as the hostname for the front-end
// and api calls can just use browser-relative urls.
const developmentApiRoot = process.env.REACT_APP_API_BASE || "http://localhost:8080/api";
export const API_ROOT = process.env.NODE_ENV === "development" ? developmentApiRoot : "/api";

type MethodType = "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "OPTIONS";

export const queryClient = new QueryClient();

export type FetchParams = {
  token?: Opt<string>;
  actingAs?: Opt<string>;
};

// always return same object if our token hasn't changed
// helps with hooks that rely on shallow comparisons
let memoizeParams: FetchParams | null = null;
export const fetchParams = (state: AppState): FetchParams => {
  const token = bearerToken(state);
  const actingAs = actingAsUser(state)?.username;
  if (memoizeParams != null && memoizeParams.token === token && memoizeParams.actingAs === actingAs) {
    return memoizeParams;
  }
  memoizeParams = { token, actingAs };
  return memoizeParams;
};

function searchParamsToQueryParams(params: SearchParams): QueryParams {
  const ans: QueryParams = {};
  if (params.q != null) ans.q = params.q;
  if (params.start != null) ans.start = String(params.start);
  if (params.count != null) ans.count = String(params.count);
  if (params.sortby != null) ans.sortby = params.sortby;
  if (params.sortdir != null) ans.sortdir = params.sortdir;
  return ans;
}

function statusAndJson(promise: Promise<Response>): Promise<{ status: number; json: unknown }> {
  return promise.then(
    response =>
      response.json().then(
        json => ({ status: response.status, json }),
        _err => ({ status: response.status, json: null }),
      ),
    _err => ({ status: 0, json: null }),
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleErrors({ status, json }: { status: number; json: any }): any {
  function logged(error: AppError) {
    console.error(error);
    return error;
  }

  if (status >= 200 && status < 300) {
    return json;
  } else if (status === 401) {
    throw logged(new UnauthorizedError(json == null ? `Unauthorized` : json.message || `Unauthorized: ${JSON.stringify(json)}`));
  } else if (status === 403) {
    throw logged(new ForbiddenError(json?.missingPermissions ?? [], json?.failedCheck ?? undefined));
  } else if (status === 404) {
    throw logged(new NotFoundError(json && json.itemType ? json.itemType : "unknown", json && json.item ? json.item : "unknown"));
  } else if (status === 409) {
    throw logged(new ConcurrentModificationError(json && json.itemType ? json.itemType : "unknown", json && json.item ? json.item : "unknown"));
  } else if (status === 422 && json.messages) {
    throw logged(new ValidationError(json.messages));
  } else if (status === 400 && json.messages) {
    throw logged(new ValidationError(json.messages));
  } else if (status >= 400 && status < 500) {
    throw logged(new UnknownClientError(json == null ? `Unknown client error` : json.message || `Unknown client error: ${JSON.stringify(json)}`));
  } else if (status >= 500 || status === 0) {
    throw logged(
      new UnknownServerError(
        json === null
          ? `Could not contact the Springboard servers. Check your connection and try again!`
          : json.message || `Unknown network or server error: ${JSON.stringify(json)}`,
      ),
    );
  } else {
    throw logged(new UnknownError(json == null ? `Unknown error` : json.message || `Unknown error: ${JSON.stringify(json)}`));
  }
}

export const createHeaders = ({ token, actingAs }: FetchParams): Record<string, string> => {
  const authHeader: { Authorization: string } | {} = token == null ? {} : { Authorization: `Bearer ${token}` };

  const actingAsHeader: { "X-Acting-As": string } | {} = actingAs == null ? {} : { "X-Acting-As": actingAs };

  return {
    ...authHeader,
    ...actingAsHeader,
    Accept: "application/json",
    "Content-Type": "application/json",
  };
};

export const fetchWithAuth = <A>(
  fetchParams: FetchParams,
  method: MethodType,
  path: string,
  query: RelaxedQueryParams,
  body: unknown,
): Promise<A> => {
  const headers = createHeaders(fetchParams);
  const queryString = formatQueryString(query);
  const finalUrl = API_ROOT + path + queryString;
  let finalBody;
  if (body instanceof File) {
    const formData = new FormData();
    formData.append("data", body);
    // delete our content type so fetch can add a boundary
    delete headers["Content-Type"];
    finalBody = formData;
  } else {
    finalBody = body == null ? null : JSON.stringify(body);
  }
  const finalOptions = {
    method,
    body: finalBody,
    headers,
    credentials: "include" as const, // submit cookies
  };

  console.debug(">>", method, finalUrl, finalOptions);

  return statusAndJson(fetch(finalUrl, finalOptions))
    .then(handleErrors)
    .then(
      response => {
        console.debug("<<", method, finalUrl, response);
        return response;
      },
      response => {
        console.warn("!!", method, finalUrl, response);
        return Promise.reject(response);
      },
    );
};

export const get = (params: FetchParams, path: string, query: RelaxedQueryParams = {}): Promise<unknown> =>
  fetchWithAuth(params, "GET", path, query, null);

export function patch<A>(params: FetchParams, path: string, query: RelaxedQueryParams, body: unknown): Promise<A> {
  return fetchWithAuth(params, "PATCH", path, query, body);
}

export function post<A, B = unknown>(params: FetchParams, path: string, query: RelaxedQueryParams, body: B): Promise<A> {
  return fetchWithAuth(params, "POST", path, query, body);
}

export const put = (params: FetchParams, path: string, query: RelaxedQueryParams, body: unknown): Promise<unknown> =>
  fetchWithAuth(params, "PUT", path, query, body);

export const del = (params: FetchParams, path: string, query: RelaxedQueryParams = {}): Promise<unknown> =>
  fetchWithAuth(params, "DELETE", path, query, null);

export const loginUrl = (redirectTo: string = window.location.pathname): string => `/login?redirectTo=${encodeURIComponent(redirectTo)}`;

export const searchApplications = (params: FetchParams, school: Opt<string>, query: SearchParams): Promise<SearchResults<ApplicationSummary>> =>
  get(params, `/applications`, {
    ...searchParamsToQueryParams(query),
    school,
  }).then(obj => obj as SearchResults<ApplicationSummary>);

export const applicationsCsvUrl = (params: FetchParams, query: RelaxedQueryParams): string =>
  API_ROOT +
  `/applications/export${formatQueryString({
    ...query,
    bearer: params.token,
  })}`;

export const searchApplicants = (params: FetchParams, query: SearchParams): Promise<SearchResults<Applicant>> =>
  get(params, `/applicants`, searchParamsToQueryParams(query)).then(obj => obj as SearchResults<ApplicantDTO>);

export const blankApplication = (params: FetchParams, choice: ApplicationChoice): Promise<Application> =>
  get(params, `/applications/blank`, { choice }).then(obj => obj as Application);

export const fetchBearerAuthentication = (params: FetchParams) => get(params, "/bearer-token").then(obj => obj as BearerAuthentication);

export const fetchTransferScript = (params: FetchParams, id: number, group: number): Promise<TelephoneScript> =>
  get(params, `/applicant/${id}/script/transfer`, { group }).then(obj => obj as TelephoneScript);

export const fetchOfferScript = (params: FetchParams, id: number) =>
  get(params, `/applications/${id}/script/offer`).then(obj => obj as TelephoneScript);

export const searchRoles = (params: FetchParams, query: SearchParams): Promise<SearchResults<Role>> =>
  get(params, `/roles`, { ...searchParamsToQueryParams(query) }).then(obj => obj as SearchResults<Role>);

export const fetchRole = (params: FetchParams, id: number): Promise<Role> => get(params, `/roles/${id}`).then(obj => obj as Role);

export const insertRole = (params: FetchParams, role: Role) => post(params, `/roles`, {}, role).then(obj => obj as Checked<Role>);

export const updateRole = (params: FetchParams, role: Role) => put(params, `/roles/${role.id}`, {}, role).then(obj => obj as Checked<Role>);

export const deleteRole = (params: FetchParams, id: number) => del(params, `/roles/${id}`).then(_ => undefined);

export const searchUsers = (params: FetchParams, school: Opt<string>, query: SearchParams): Promise<SearchResults<User>> =>
  get(params, `/users`, { ...searchParamsToQueryParams(query), school }).then(obj => obj as SearchResults<User>);

export const fetchUser = (params: FetchParams, id: number): Promise<User> => get(params, `/users/${id}`).then(obj => obj as User);

export const fetchUserByUsername = (params: FetchParams, username: string): Promise<User> =>
  get(params, `/users/username/${username}`).then(obj => obj as User);

export const insertUser = (params: FetchParams, user: User): Promise<Checked<User>> =>
  post(params, `/users`, {}, user).then(obj => obj as Checked<User>);

export const updateUser = (params: FetchParams, user: User): Promise<Checked<User>> =>
  put(params, `/users/${user.id}`, {}, user).then(obj => obj as Checked<User>);

export const searchProgrammes = (params: FetchParams, school: Opt<string>, query: SearchParams): Promise<SearchResults<Programme>> =>
  get(params, `/programmes`, {
    ...searchParamsToQueryParams(query),
    school,
  }).then(obj => obj as SearchResults<Programme>);

export const fetchProgramme = (params: FetchParams, code: string): Promise<ProgrammeEditorDTO> =>
  get(params, `/programmes/${code}`, {}).then(obj => {
    return obj as ProgrammeEditorDTO;
  });

export const fetchProgrammeAuditEvents = (params: FetchParams, code: ProgrammeCode): Promise<ProgrammeAuditEvents> =>
  get(params, `/programmes/${code}/audit/events`, {}).then(obj => {
    return obj as ProgrammeAuditEvents;
  });

export const insertProgramme = (params: FetchParams, prog: Programme): Promise<Checked<Programme>> =>
  post(params, `/programmes`, {}, prog).then(obj => obj as Checked<Programme>);

export const updateProgramme = (params: FetchParams, code: string, prog: Programme, closeWaitingList: boolean) =>
  put(params, `/programmes/${code}`, { closeWaitingList }, prog).then(obj => obj as Checked<Programme>);

export const deleteProgramme = (params: FetchParams, code: string) => del(params, `/programmes/${code}`, {});

// NB: calling delete on a programme that has applications returns a 409
// which is normally converted into a "Concurrent Modification" dialog in the UI.
// In this case, we want to NOT do that as it's not someone modifying a record
// under us in this case.
export const deleteManyProgrammes = (params: FetchParams, programmeCodes: ProgrammeCode[]) =>
  Promise.all(
    programmeCodes.map(code =>
      deleteProgramme(params, code).catch((e: ConcurrentModificationError) => {
        e;
      }),
    ),
  );

export const searchReportingGroups = (params: FetchParams, school: Opt<string>, query: SearchParams) =>
  get(params, `/reporting-groups`, {
    ...searchParamsToQueryParams(query),
    school,
  }).then(obj => obj as SearchResults<ReportingGroup>);

export const fetchReportingGroup = (params: FetchParams, id: number) => get(params, `/reporting-groups/${id}`, {}).then(obj => obj as ReportingGroup);

export const insertReportingGroup = (params: FetchParams, group: ReportingGroup) =>
  post(params, `/reporting-groups`, {}, group).then(obj => obj as Checked<ReportingGroup>);

export const updateReportingGroup = (params: FetchParams, group: ReportingGroup) =>
  put(params, `/reporting-groups/${group.id}`, {}, group).then(obj => obj as Checked<ReportingGroup>);

export const deleteReportingGroup = (params: FetchParams, id: number) => del(params, `/reporting-groups/${id}`, {});

// Fetch label prototypes for a particular team:
export const searchLabelPrototypesByTeam = (params: FetchParams, team: TeamCode) =>
  get(params, `/label-prototypes`, { school: teamToSchoolCode(team) }).then(obj => obj as SearchResults<LabelPrototype>);

// Fetch all label prototypes:
export const allLabelPrototypes = (params: FetchParams) =>
  get(params, `/label-prototypes/all`)
    .then(obj => obj as LabelPrototype[])
    .then(searchResults);

export const fetchLabelPrototype = (params: FetchParams, id: number) => get(params, `/label-prototypes/${id}`, {}).then(obj => obj as LabelPrototype);

export const insertLabelPrototype = (params: FetchParams, proto: LabelPrototype) =>
  post(params, `/label-prototypes`, {}, proto).then(obj => obj as Checked<LabelPrototype>);

export const updateLabelPrototype = (params: FetchParams, proto: LabelPrototype) =>
  put(params, `/label-prototypes/${proto.id}`, {}, proto).then(obj => obj as Checked<LabelPrototype>);

export const deleteLabelPrototype = (params: FetchParams, id: number) => del(params, `/label-prototypes/${id}`, {});

export const fetchApplicant = (params: FetchParams, id: number) => get(params, `/applicants/${id}`).then(obj => obj as ApplicantEditorDTO);

export const blankApplicant = (params: FetchParams) => get(params, "/applicants/blank").then(obj => obj as ApplicantEditorDTO);

export const createApplicant = (
  params: FetchParams,
  dto: ApplicantEditorDTO,
  email: boolean,
  school?: string,
): Promise<Checked<ApplicantEditorDTO>> => post(params, "/applicants", { email, school }, dto).then(obj => obj as Checked<ApplicantEditorDTO>);

export const updateApplicantDetails = (params: FetchParams, id: number, details: ApplicantDetails): Promise<Checked<ApplicantDetails>> =>
  put(params, `/applicants/${id}/details`, {}, details).then(obj => obj as Checked<ApplicantDetails>);

export const updateQualifications = (params: FetchParams, id: number, quals: Qualifications): Promise<Checked<Qualifications>> =>
  put(params, `/applicants/${id}/qualifications`, {}, quals).then(obj => obj as Checked<Qualifications>);

export const createApplication = (params: FetchParams, apptId: number, appn: Application, email: boolean) =>
  post(params, `/applicants/${apptId}/application`, { email }, appn).then(obj => obj as Checked<Application>);

export const updateApplication = (params: FetchParams, appn: Application, email: boolean): Promise<Checked<Application>> =>
  put(params, `/applications/${appn.id}`, { email }, appn).then(obj => obj as Checked<Application>);

export const resendApplicationEmail = (params: FetchParams, id: number): Promise<boolean> =>
  post(params, `/applications/${id}/resend-email`, {}, {}).then(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj => !!(obj as any).success as boolean,
  );

export const fetchLabelsForApplicant = (params: FetchParams, apptId: number): Promise<Label[]> =>
  get(params, `/labels?applicant=${apptId}`).then(obj => obj as Label[]);

export const saveLabel = (params: FetchParams, label: Label) => {
  if (label.selected) {
    return put(
      params,
      `/labels/${label.prototypeId}?applicant=${label.applicantId}`,
      {},
      // Technically we only need checked for todo labels.
      // However, the server will safely ignore it if it's not needed.
      { checked: label.checked },
    ).then(obj => obj as Label);
  } else {
    return del(params, `/labels/${label.prototypeId}?applicant=${label.applicantId}`, {}).then(obj => obj as Label);
  }
};

export const setHandover = (params: FetchParams, id: number, handover: boolean, email: boolean) =>
  put(params, `/applications/${id}/handover`, { email }, { handover }).then(obj => obj as Application);

export const fetchNotesForApplicant = (params: FetchParams, apptId: number): Promise<SearchResults<Note>> =>
  get(params, `/notes?applicant=${apptId}`).then(obj => obj as SearchResults<Note>);

export const addNote = (params: FetchParams, apptId: number, note: Note) =>
  post(params, `/notes?applicant=${apptId}`, {}, note).then(obj => obj as Checked<Note>);

export const updateNote = (params: FetchParams, note: Note) => put(params, `/notes/${note.id}`, {}, note).then(obj => obj as Checked<Note>);

export const deleteNote = (params: FetchParams, id: number) => del(params, `/notes/${id}`);

export const fetchPredictions = (params: FetchParams, org: PredictionOrganisation, grouping: PredictionGrouping) =>
  get(params, `/predictions`, { org, grouping }).then(obj => obj as PredictionDTO);

export const fetchSnc = (params: FetchParams) => get(params, `/snc/all`).then(obj => obj as SncProgrammeSummary[]);

export const fetchInternationlEquivs = (params: FetchParams) =>
  get(params, `/qualifications/international-equivs`).then(obj => obj as InternationalEquiv[]);

// Common type for functions to fetch email/script previews from the server.
// Referenced in TemplateEditor.
export type ApiPreviewFetchFunc<A> = (params: FetchParams, template: A, { school }: { school: string }) => Promise<Previews<A>>;

export const fetchEmailPreviews: ApiPreviewFetchFunc<EmailParts> = (params, template, { school }) =>
  post(params, `/admin/preview/email`, { school }, template).then(obj => obj as Previews<EmailParts>);

export const fetchTransferScriptPreviews: ApiPreviewFetchFunc<TelephoneScript> = (params, template, { school }) =>
  post(params, `/admin/preview/script/transfer`, { school }, template).then(obj => obj as Previews<TelephoneScript>);

export const fetchOfferScriptPreviews: ApiPreviewFetchFunc<TelephoneScript> = (params, template, { school }) =>
  post(params, `/admin/preview/script/offer`, { school }, template).then(obj => obj as Previews<TelephoneScript>);

export const fetchSchoolConfig = (params: FetchParams, school: string) =>
  get(params, `/admin/config/${school}`).then(obj => obj as Record<string, unknown>);

export const saveSchoolConfig = (params: FetchParams, schoolCode: SchoolCode, config: Record<string, unknown>) =>
  post(params, `/admin/config/${schoolCode}`, {}, config).then(obj => obj as Checked<Record<string, unknown>>);

export const fetchFeatureFlags = (params: FetchParams) => get(params, `/admin/features`).then(obj => obj as FeatureFlags);

export const fetchSelectorList = (params: FetchParams, { schoolCode }: { schoolCode: string | null }) => {
  const query = schoolCode ? formatQueryString({ schoolCode }) : "";
  return get(params, `/selector/action${query}`).then(obj => obj as SelectorListRowActions[]);
};

export const applySelectorListAction = (params: FetchParams, action: SelectorAction) =>
  post(params, `/selector/action/apply`, {}, action).then(obj => obj as ActionResult);

export const ignoreSelectorListAction = (params: FetchParams, action: SelectorAction) =>
  post(params, `/selector/action/ignore`, {}, action).then(obj => obj as ActionResult);

export const unignoreSelectorListAction = (params: FetchParams, action: SelectorAction) =>
  post(params, `/selector/action/unignore`, {}, action).then(obj => obj as ActionResult);

export const applySelectorListActions = (params: FetchParams, actions: SelectorAction[]): Promise<void> =>
  post(params, `/selector/action/apply-many`, {}, actions).then(_ => undefined);

export const fetchUsers = (params: FetchParams) => get(params, `/user`).then(obj => obj as SearchResults<User>);

export const fetchSchools = (params: FetchParams) => get(params, `/schools`).then(obj => obj as SearchResults<School>);

export const fetchAuditEvents = (params: FetchParams, id: number) => get(params, `/audit/${id}`, {}).then(obj => obj as (AuditEvent | EmailEvent)[]);

export const fetchSavedSearches = (params: FetchParams, school?: Opt<string>) =>
  get(params, `/saved-searches`, { school }).then(obj => obj as SavedSearch[]);

export const createSavedSearch = (params: FetchParams, savedSearch: SavedSearch) => post<SavedSearch>(params, `/saved-searches`, {}, savedSearch);

export const deleteSavedSearch = (params: FetchParams, id: number) => del(params, `/saved-searches/${id}`).then(_ => undefined);

export const fetchExpirySettings = (params: FetchParams) => get(params, `/applications/expirySettings`).then(obj => obj as ExpirySettings);

export const getUploadLink = (params: FetchParams, applicantId: number, name: string, type: string): Promise<UploadLink> =>
  get(params, `/attachments/upload-link?applicantId=${applicantId}&name=${name}&attachmentType=${type}`, {}).then(response => response as UploadLink);

const uploadFileToSignedURL = (url: string, file: File) =>
  fetch(url, {
    method: "PUT",
    body: file,
  });

export const uploadFile = (params: FetchParams, applicantId: number, file: File, type: string): Promise<string> => {
  return getUploadLink(params, applicantId, file.name, type).then(result => {
    return uploadFileToSignedURL(result.url, file).then(r2 => {
      if (r2.status >= 400) {
        throw new Error("Error uploading");
      } else {
        return result.url;
      }
    });
  });
};

export function getAttachments(params: FetchParams, applicantId: number): Promise<FileInfo[]> {
  return get(params, `/attachments?applicantId=${applicantId}`).then(obj => obj as FileInfo[]);
}

export function bulkLockoutUser(params: FetchParams, users: User[]) {
  return post(params, `/users/lockout`, {}, users);
}

export function attachmentRequest(
  params: FetchParams,
  urlParams: {
    applicantId: number;
    fileType: AttachmentType;
    message?: Opt<string>;
  },
) {
  return post<AttachmentRequestResponseBody>(
    params,
    `/attachment-requests?applicantId=${urlParams.applicantId}&attachmentType=${urlParams.fileType}`,
    {},
    { message: urlParams.message },
  ).then(res => res as AttachmentRequest);
}

export function attachmentApprove(
  params: FetchParams,
  urlParams: {
    fileId: string;
    isApproved: boolean;
  },
) {
  return patch(params, `/attachments/${urlParams.fileId}/${urlParams.isApproved ? "approve" : "unapprove"}`, {}, {});
}

export function attachmentDownloadUrl(params: FetchParams, fileId: FileId): Promise<DownloadLink> {
  return get(params, `/attachments/${fileId}/download-link`, {}).then(response => response as DownloadLink);
}

export function attachmentDelete(params: FetchParams, fileId: string) {
  return del(params, `/attachments/${fileId}`).then(res => res as FileInfo[]);
}

export function getAttachmentRequests(params: FetchParams, applicantId: number) {
  return get(params, `/attachment-requests?applicantId=${applicantId}`).then(res => res as AttachmentRequest[]);
}

type Collection<A> = {
  items: A[];
  start: number;
  total: number;
};

export const useFetchParams = (): FetchParams => {
  const store = useStore();
  return fetchParams(store.getState());
};

export function phoneCall(params: FetchParams, request: PhoneCallRequest) {
  return post(params, `/call`, {}, request).then(obj => obj as PhoneCallResult);
}

export const useAcknowledgeAnnouncement = () => {
  const params = useFetchParams();
  return useMutation((id: number) => post<{}>(params, `/announcements/${id}/ack`, {}, {}), {
    onSuccess: () => {
      queryClient.invalidateQueries(["announcements"]);
    },
  });
};

export const useAddAnnouncement = (onSuccess: () => void) => {
  const params = useFetchParams();
  return useMutation((body: { detail: string; headline: string }) => post(params, `/announcements`, {}, body).then(obj => obj as AnnouncementDTO), {
    onSuccess,
  });
};

export const fetchAnnouncements = (params: FetchParams) => get(params, `/announcements`).then(obj => obj as Collection<AnnouncementDTO>);

export const fetchUnreadAnnouncements = (params: FetchParams) =>
  get(params, `/announcements/missing`).then(obj => obj as Collection<AnnouncementDTO>);

export const useAnnouncements = (unread: boolean = false) => {
  const params = useFetchParams();
  return useQuery<Collection<AnnouncementDTO>, Error>(["announcements", { unread }], () =>
    unread ? fetchUnreadAnnouncements(params) : fetchAnnouncements(params),
  );
};

export const fetchCheckAllEntryRequirements = (params: FetchParams, qualifications: Qualifications) =>
  post(params, `/qualifications/entry`, {}, qualifications).then(obj => obj as RequirementsCheckDTO[]);

export const useCheckAllEntryRequirements = (qualifications: Qualifications) => {
  const params = useFetchParams();

  return useQuery<RequirementsCheckDTO[], Error>(
    ["qualifications", "entry", qualifications],
    () => fetchCheckAllEntryRequirements(params, qualifications),
    { keepPreviousData: true },
  );
};

export const addAnnouncementToCache = (newValue: AnnouncementDTO) => {
  function updateCache(unread: boolean) {
    queryClient.setQueryData<Collection<AnnouncementDTO> | undefined>(["announcements", { unread }], current => {
      if (!current) return;
      return {
        ...current,
        items: current.items ? [...current.items, newValue] : [newValue],
        total: current.total + 1,
      };
    });
  }

  if (!newValue.acknowledged) {
    updateCache(true);
  }
  updateCache(false);
};

export class PartialSuccess extends Error {
  data: Messages;

  constructor(data: Messages) {
    super();
    this.data = data;
    this.name = "ValidationError"; // (2)
  }
}

export const useAddUsers = (options?: UseMutationOptions<Messages, Error, User[]>) => {
  const params = useFetchParams();
  return useMutation((newUsers: User[]) => {
    return post<Messages>(params, `/users/many`, {}, newUsers).then(res => {
      // this is special in that it can partially succeed.
      if (res.length > 0) {
        throw new PartialSuccess(res);
      }
      return res;
    });
  }, options);
};

export const useAddClearingPlusApplicants = (options?: UseMutationOptions<Messages, Error, { upload: File; schoolCode: SchoolCode }>) => {
  const params = useFetchParams();
  return useMutation(({ upload, schoolCode }: { upload: File; schoolCode: SchoolCode }) => {
    return post<Messages>(
      params,

      `/ucas/applicants?schoolCode=${schoolCode}`,
      {},
      upload,
    );
  }, options);
};

export function useBulkUpdateProgramme(
  options?: UseMutationOptions<
    Messages,
    Error,
    {
      queryParams: { closeWaitingList?: boolean };
      programmes: Programme[];
    }
  >,
) {
  const params = useFetchParams();
  return useMutation(({ queryParams, programmes }: { queryParams: { closeWaitingList?: boolean }; programmes: Programme[] }) => {
    return post<Messages>(params, `/programmes/many`, queryParams, programmes);
  }, options);
}

const fetchSubjects = (params: FetchParams) => get(params, `/subjects`).then(obj => obj as Subjects);

export const useSubjects = () => {
  const params = useFetchParams();
  return useQuery<Subjects, Error>("subjects", () => fetchSubjects(params));
};

export const useProgrammeAuditEvents = (programmeCode: ProgrammeCode) => {
  const params = useFetchParams();

  const options: UseQueryOptions<ProgrammeAuditEvents, Error> = {
    queryKey: "programme-audit-events/" + programmeCode,
    queryFn: () => fetchProgrammeAuditEvents(params, programmeCode),
    staleTime: 30 * 1000,
  };

  return useQuery<ProgrammeAuditEvents, Error>(options);
};

export const useFeatureFlags = () => {
  const params = useFetchParams();
  const options: UseQueryOptions<FeatureFlags, Error> = {
    queryKey: "feature-flags",
    queryFn: () => fetchFeatureFlags(params),
    staleTime: 60 * 1000,
  };
  return useQuery<FeatureFlags, Error>(options);
};

const CACHE_KEYS = {
  selectorList: "selector-list",
};

export const useSelectorList = ({ schoolCode }: { schoolCode: SchoolCode | null }) => {
  const params = useFetchParams();
  return useQuery<SelectorListRowActions[], Error>([CACHE_KEYS.selectorList, schoolCode], () => fetchSelectorList(params, { schoolCode }), {
    useErrorBoundary: true,
  });
};

export const useApplySelectorListAction = () => {
  const queryClient = useQueryClient();
  const params = useFetchParams();
  return useMutation((action: SelectorAction) => applySelectorListAction(params, action), {
    useErrorBoundary: true,
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.selectorList);
    },
  });
};

export const useIgnoreSelectorListAction = () => {
  const queryClient = useQueryClient();
  const params = useFetchParams();
  return useMutation((action: SelectorAction) => ignoreSelectorListAction(params, action), {
    useErrorBoundary: true,
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.selectorList);
    },
  });
};

export const useUnignoreSelectorListAction = () => {
  const queryClient = useQueryClient();
  const params = useFetchParams();
  return useMutation((action: SelectorAction) => unignoreSelectorListAction(params, action), {
    useErrorBoundary: true,
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.selectorList);
    },
  });
};

export const useApplySelectorListActions = () => {
  const queryClient = useQueryClient();
  const params = useFetchParams();
  return useMutation((actions: SelectorAction[]) => applySelectorListActions(params, actions), {
    useErrorBoundary: true,
    onSuccess: () => {
      queryClient.invalidateQueries(CACHE_KEYS.selectorList);
    },
  });
};
