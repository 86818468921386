import classNames from "classnames";
import { OfferStatusEnum, PredictionGroupingEnum } from "@qmspringboard/shared/dist/model/enums";
import React from "react";
import PredictionBarChart from "./PredictionBarChart";
import styles from "./Predictions.module.css";
import { ColumnGroup, CustomColumn, NumericColumn } from "./type";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";

function column(column: Omit<NumericColumn, "type">): NumericColumn {
  return { type: "NumericColumn", ...column };
}

function customColumn(column: Omit<CustomColumn, "type">): CustomColumn {
  return { type: "CustomColumn", ...column };
}

function columnGroup(group: ColumnGroup): ColumnGroup {
  return group;
}

const ControlIcon = styled(Icon)`
  float: right;
  color: #aaa;
`;

export const columnGroups: ColumnGroup[] = [
  columnGroup({
    title: null,
    columns: [
      customColumn({
        title: "Name",
        align: "left",
        minWidth: "10em",
        // eslint-disable-next-line react/display-name
        body: row => (
          <>
            {row.control && <ControlIcon name="flag" />}
            <span>{row.label}</span>
          </>
        ),
      }),
    ],
  }),
  columnGroup({
    title: null,
    show: qss => qss.showCharts,
    columns: [
      customColumn({
        title: "Charts",
        minWidth: "20em",
        align: "left",
        // eslint-disable-next-line react/display-name
        body: (row, qss) => (
          <PredictionBarChart
            ufAimHome={qss.fees === "overseas" ? null : row.ufAimHome}
            ufAimOverseas={qss.fees === "home" ? null : row.ufAimOverseas}
            lower={row.methods.overall.lower}
            predicted={row.methods.overall.predicted}
            upper={row.methods.overall.upper}
          />
        ),
      }),
    ],
  }),
  columnGroup({
    title: "Overall",
    show: qss => qss.showOverall,
    columns: [
      column({
        title: "UFAimH",
        titleStyle: qss => (qss.showCharts ? classNames(styles.ufAimHome) : null),
        align: "center",
        show: qss => (qss.fees === "overall" || qss.fees === "home") && qss.grouping === PredictionGroupingEnum.Group,
        body: row => row.ufAimHome ?? 0,
        percentage: true,
      }),
      column({
        title: "UFAimO",
        titleStyle: qss => (qss.showCharts ? classNames(styles.ufAimOverseas) : null),
        align: "center",
        show: qss => (qss.fees === "overall" || qss.fees === "overseas") && qss.grouping === PredictionGroupingEnum.Group,
        body: row => row.ufAimOverseas ?? 0,
        percentage: true,
      }),
      column({
        title: "Def",
        tooltip: "Minimum definite intake",
        titleStyle: qss => (qss.showCharts ? classNames(styles.prediction, styles.lower) : null),
        body: row => row.methods.overall.lower,
        percentage: true,
      }),
      column({
        title: "Pred",
        tooltip: "Predicted intake",
        titleStyle: qss => (qss.showCharts ? classNames(styles.prediction, styles.predicted) : null),
        body: row => row.methods.overall.predicted,
        percentage: true,
      }),
      column({
        title: "Pos",
        tooltip: "Maximum possible intake",
        titleStyle: qss => (qss.showCharts ? classNames(styles.prediction, styles.upper) : null),
        body: row => row.methods.overall.upper,
        percentage: true,
      }),
      column({
        title: "Pend",
        tooltip: "Outstanding applications (Pos - Def)",
        body: row => row.methods.overall.pending,
        percentage: true,
      }),
      column({
        title: "???",
        tooltip: "Unclassified applications",
        body: row => row.methods.overall.unknown,
        percentage: true,
      }),
    ],
  }),
  columnGroup({
    title: "Offer Status",
    show: qss => qss.showOfferStatusSummary,
    columns: OfferStatusEnum.entries.map(status =>
      column({
        title: status.value,
        tooltip: status.label,
        body: row => row.methods.overall.offerStatus[status.value],
        percentage: false,
      }),
    ),
  }),
  columnGroup({
    title: "UCAS",
    show: qss => qss.showUcas,
    columns: [
      column({
        title: "Def",
        tooltip: "Minimum definite UCAS intake",
        body: row => row.methods.ucas.bounds.lower,
        percentage: true,
      }),
      column({
        title: "Pred",
        tooltip: "Predicted UCAS intake",
        body: row => row.methods.ucas.bounds.predicted,
        percentage: true,
      }),
      column({
        title: "Pos",
        tooltip: "Maximum possible UCAS intake",
        body: row => row.methods.ucas.bounds.upper,
        percentage: true,
      }),
      column({
        title: "Pend",
        tooltip: "Outstanding UCAS offers (Pos - Def)",
        body: row => row.methods.ucas.bounds.pending,
        percentage: true,
      }),
      column({
        title: "CF",
        tooltip: "CF__ UCAS offers",
        body: row => row.methods.ucas.cf,
        percentage: true,
      }),
      column({
        title: "CI",
        tooltip: "CI__ UCAS offers",
        body: row => row.methods.ucas.ci,
        percentage: true,
      }),
      column({
        title: "CIUI",
        tooltip: "CIUI UCAS offers",
        body: row => row.methods.ucas.ciui,
        percentage: true,
      }),
      column({
        title: "UI",
        tooltip: "UI__ UCAS offers",
        body: row => row.methods.ucas.ui,
        percentage: true,
      }),
      column({
        title: "???",
        tooltip: "Unclassified UCAS offers",
        body: row => row.methods.ucas.bounds.unknown,
        percentage: true,
      }),
    ],
  }),
  columnGroup({
    title: "Clearing",
    show: qss => qss.showClearing,
    columns: [
      column({
        title: "Def",
        tooltip: "Minimum definite Clearing intake",
        body: row => row.methods.clearing.lower,
        percentage: true,
      }),
      column({
        title: "Pred",
        tooltip: "Predicted Clearing intake",
        body: row => row.methods.clearing.predicted,
        percentage: true,
      }),
      column({
        title: "Pos",
        tooltip: "Maximum possible Clearing intake",
        body: row => row.methods.clearing.upper,
        percentage: true,
      }),
      column({
        title: "Pend",
        tooltip: "Outstanding Clearing offers (Pos - Def)",
        body: row => row.methods.clearing.pending,
        percentage: true,
      }),
      column({
        title: "???",
        tooltip: "Unclassified Clearing offers",
        body: row => row.methods.clearing.unknown,
        percentage: true,
      }),
    ],
  }),
];
