import React from "react";
import { applicant as portalApplicantStrings } from "./strings";

export const applicant = {
  ...portalApplicantStrings,
  qualificationFillType: "What kind of qualifications do they have?",
  qualificationTookGCSE: "What kind of secondary education qualifications did they take?",
  qualificationHasMinimumGCSE: "They have 5 or more GCSEs at grade C/4 or above (required for all programmes)",
  qualificationHasMinimumGCSEMedical: "They have 6 or more GCSEs with 3 at grade 6 and 3 at grade 7 (required for some medical programmes)",
  qualificationHasOtherAdvisory: <span>Check they have provided any relevant English qualifications above</span>,
};
