import { omit } from "lodash";
import shortid from "shortid";
import { AppError } from "../../errors";
import { AppDispatch, AppReducer, AppThunkAction } from "../actions";
import { AppState, GetState } from "../state";
import { CLEAR_MUTEX, ERROR, MyAction, SET_MUTEX, UNAUTHORIZED_ERROR } from "./actions";
import { defaultErrorHandler } from "./error";
import { initialState, MyState } from "./state";

// Store and selectors ==========================

function myState(state: AppState): MyState {
  return state.fetch;
}

function getMutex(state: AppState, mutex: string): string | undefined {
  return myState(state)[mutex];
}

// Actions and reducers =========================

const setMutex = (mutex: string, value: string): MyAction => ({
  type: SET_MUTEX,
  mutex,
  value,
});

const clearMutex = (mutex: string): MyAction => ({ type: CLEAR_MUTEX, mutex });

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MUTEX:
      return { ...state, [action.mutex]: action.value };
    case CLEAR_MUTEX:
      return omit(state, action.mutex);
    case ERROR:
      console.error(action);
      return state;
    case UNAUTHORIZED_ERROR:
      console.error(action);
      return state;
    default:
      return state;
  }
};

export default reducer;

// Error handling ===============================

// Async action creators ========================

interface FetchWithMutexParams<Data> {
  mutex: string;
  request: (fn1: AppDispatch, fn2: GetState) => Promise<Data>;
  pending?: (fn1: AppDispatch, fn2: GetState, requestId: string) => void;
  success: (fn1: AppDispatch, fn2: GetState, data: Data) => void;
  error?: (fn1: AppDispatch, fn2: GetState, error: AppError) => void;
}

export function fetchWithMutex<Data>(params: FetchWithMutexParams<Data>): AppThunkAction<Promise<Data>> {
  const { mutex, request, pending, success, error } = params;

  const errorHandler = error ?? defaultErrorHandler;

  return function (dispatch: AppDispatch, getState: GetState): Promise<Data> {
    const requestId = shortid.generate();

    dispatch(setMutex(mutex, requestId));

    pending && pending(dispatch, getState, requestId);

    return request(dispatch, getState)
      .then(
        (response: Data): Data => {
          const _reqId = getMutex(getState(), mutex);

          if (requestId === _reqId) {
            success(dispatch, getState, response);
            dispatch(clearMutex(mutex));
          } else {
            console.warn("Ignored out-of-sequence response");
          }

          return response;
        },
        (error: AppError): Data => {
          const _reqId = getMutex(getState(), mutex);

          if (requestId === _reqId) {
            errorHandler(dispatch, getState, error);
          } else {
            console.warn("Ignored out-of-sequence response");
          }

          throw error;
        },
      )
      .then(
        (value: Data): Data => value,
        (error: AppError): Promise<Data> => {
          // dispatch(clearMutex(mutex));
          throw error;
        },
      );
  };
}
