import useAppDispatch from "./useAppDispatch";
import { showModal, hideModal, destroyModal } from "../reducers/modal";

export interface UseModalResult {
  show: (props?: {}) => void;
  hide: () => void;
  destroy: () => void;
}

export default function useModal(name: string): UseModalResult {
  const dispatch = useAppDispatch();

  const show = (props?: {}) => dispatch(showModal(name, props));
  const hide = () => dispatch(hideModal(name));
  const destroy = () => dispatch(destroyModal(name));

  return { show, hide, destroy };
}
