import { A2GradeEnum, ASGradeEnum, BtecGradeEnum, IBGradeEnum, ExtendedProjectGradeEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import { isQualificationType, QualificationType } from "@qmspringboard/shared/dist/model/qualifications";
import { checkExhausted, Opt } from "@qmspringboard/shared/dist/utils";
import { orderBy } from "lodash";
import React, { ReactElement } from "react";
import styled from "styled-components";
import { BaseFieldProps, DropdownField, DropdownOptions, NullableTextInput } from "./fields";

export interface QualificationGradeFieldProps extends BaseFieldProps<Opt<string>> {
  type: QualificationType | string;
}

const ConstrainedTextField = styled(NullableTextInput)`
  width: 100%;

  input {
    width: 100%;
    max-width: 100%;
  }
`;

interface IntegerGradeFieldProps extends BaseFieldProps<Opt<string>> {
  max: number;
}

function IntegerGradeField(props: IntegerGradeFieldProps): ReactElement {
  const { max, ...rest } = props;

  return <ConstrainedTextField {...rest} placeholder={`Out of ${max}`} />;
}

interface NumberGradeFieldProps extends BaseFieldProps<Opt<string>> {
  max: number;
}

function NumberGradeField(props: NumberGradeFieldProps): ReactElement {
  const { max, ...rest } = props;

  return <ConstrainedTextField {...rest} placeholder={`Out of ${max}.0`} />;
}

interface GradeDropdownProps extends BaseFieldProps<Opt<string>> {
  placeholder?: string;
  options: DropdownOptions<string | null>;
}

function GradeDropdown(props: GradeDropdownProps) {
  return <DropdownField search clearable fluid {...props} />;
}

const lexicalize = (options: DropdownOptions<string | null>) => orderBy(options, "label");

const btecExtendedOptions: DropdownOptions<string | null> = [
  { value: null, label: "" },
  { value: "D*D*D*", label: "D*D*D*" },
  { value: "D*D*D", label: "D*D*D" },
  { value: "D*DD", label: "D*DD" },
  { value: "DDD", label: "DDD" },
  { value: "DDM", label: "DDM" },
  { value: "DMM", label: "DMM" },
  { value: "MMM", label: "MMM" },
  { value: "MMP", label: "MMP" },
  { value: "MPP", label: "MPP" },
  { value: "PPP", label: "PPP" },
];

const btecNationalOptions: DropdownOptions<string | null> = [
  { value: null, label: "" },
  { value: "D*D*", label: "D*D*" },
  { value: "D*D", label: "D*D" },
  { value: "DD", label: "DD" },
  { value: "DM", label: "DM" },
  { value: "MM", label: "MM" },
  { value: "MP", label: "MP" },
  { value: "PP", label: "PP" },
];

export default function QualificationGradeField(props: QualificationGradeFieldProps): ReactElement {
  const { type, ...rest } = props;

  if (isQualificationType(type)) {
    switch (type) {
      case "A2":
        return <GradeDropdown placeholder="A*, A, B, C, ..." options={A2GradeEnum.dropdownOptionsWithBlank(false, "")} {...rest} />;

      case "AS":
        return <GradeDropdown placeholder="A, B, C, ..." options={ASGradeEnum.dropdownOptionsWithBlank(false, "")} {...rest} />;

      case "EPQ":
        return (
          <GradeDropdown
            placeholder="A*, A, B, C, D, E"
            options={lexicalize(ExtendedProjectGradeEnum.dropdownOptionsWithBlank(false, ""))}
            {...rest}
          />
        );

      case "IBOverall":
        return <IntegerGradeField {...rest} max={45} />;

      case "IBHigher":
      case "IBStandard":
        return <GradeDropdown {...rest} placeholder="7, 5, 4, 3, ..." options={IBGradeEnum.dropdownOptionsWithBlank(false, "")} />;

      case "BtecExtendedRqf":
      case "BtecExtendedQcf":
        return <GradeDropdown {...props} options={btecExtendedOptions} />;

      case "BtecNationalRqf":
      case "BtecNationalQcf":
        return <GradeDropdown {...props} options={btecNationalOptions} />;

      case "BtecSubsidiaryRqf":
      case "BtecSubsidiaryQcf":
        return <GradeDropdown {...rest} options={BtecGradeEnum.dropdownOptionsWithBlank(false, "")} />;

      case "FrenchBacOverall":
      case "FrenchBac":
        return <IntegerGradeField {...rest} max={20} />;

      case "IndianHsNatOverall":
      case "IndianHsWbOverall":
      case "IndianHsOtherOverall":
      case "IndianHsNat":
      case "IndianHsWb":
      case "IndianHsOther":
        return <IntegerGradeField {...rest} max={100} />;

      case "GermanAbiturOverall":
        return <NumberGradeField {...rest} max={6} />;
      case "GermanAbitur":
        return <IntegerGradeField {...rest} max={15} />;

      case "PolishMatura":
        return <IntegerGradeField {...rest} max={100} />;

      case "SpanishBacOverall":
        return <NumberGradeField {...rest} max={10} />;
      case "SpanishBac":
        return <IntegerGradeField {...rest} max={10} />;

      case "ItalianDiplomaOverall":
        return <IntegerGradeField {...rest} max={100} />;
      case "ItalianDiploma":
        return <IntegerGradeField {...rest} max={10} />;

      case "UsDiplomaOverall":
        return <NumberGradeField {...rest} max={4} />;
      case "UsDiploma":
        return <IntegerGradeField {...rest} max={5} />;

      case "UCATScore":
        return <IntegerGradeField {...rest} max={3600} />;
      case "UCATBand":
        return <IntegerGradeField {...rest} max={4} />;

      default:
        return checkExhausted(type);
    }
  } else {
    return <ConstrainedTextField placeholder="Grade" {...props} />;
  }
}
