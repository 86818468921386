import { ApplicationLiveness, IgnoredOptionEnum } from "./enums";
import { ApplicationLivenessEnum } from "./enums";
import { IgnoredOption, SchoolCode, SelectorAction } from "./types";
import { Opt } from "../utils";

export type SelectorActionType =
  | "ApplicationStatusChange"
  | "ClearingDecisionChange"
  | "ClearingResponseChange"
  | "ConfirmationDecisionChange"
  | "ConfirmationResponseChange"
  | "EntryPointChange"
  | "ErrorAction"
  | "FeeStatusChange"
  | "ImportApplication"
  | "InitialDecisionChange"
  | "InitialResponseChange"
  | "LinkApplication"
  | "NameChange"
  | "TelephoneChange"
  | "NationalityChange"
  | "DateOfBirthChange"
  | "SitsHomeEmailChange"
  | "ProgrammeChange"
  | "StudentIdChange"
  | "CocoChange";

export type SelectorActionFilters = {
  type: Opt<SelectorActionType>;
  liveness: Opt<ApplicationLiveness>;
  ignoredOption: IgnoredOption;
  schoolCode: Opt<SchoolCode>;
};

export type SelectorActionFilterPredicate = (args: { action: SelectorAction; liveness: Opt<ApplicationLiveness> }) => boolean;

export function selectorActionFilterPredicate({ type, liveness, ignoredOption }: SelectorActionFilters): SelectorActionFilterPredicate {
  return function ({ action, liveness: l }) {
    // Do we show an action based on ignored status? Yes or No.
    //                 Ignore option selected by user
    // Action        | Exclude | Include | Only
    // ------------  | ------- | ------- | ----
    // Ignored       |   No    |  Yes    | Yes
    // Not Ignored   |  Yes    |  Yes    | No

    const ignoreMatches =
      ignoredOption === IgnoredOptionEnum.Include ||
      (action.ignored && ignoredOption == IgnoredOptionEnum.Only) ||
      (!action.ignored && ignoredOption == IgnoredOptionEnum.Exclude);

    const actionTypeMatches = type == null ? true : action.type === type;

    const showNewApplicationsInLiveView = ApplicationLivenessEnum.Live === liveness && l == null;

    const livenessMatches = liveness == null ? true : l === liveness || showNewApplicationsInLiveView;

    return actionTypeMatches && livenessMatches && ignoreMatches;
  };
}
