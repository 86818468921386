import React from "react";
import { School, SchoolCode } from "../model/types";
import { Button, ButtonGroup, Grid } from "semantic-ui-react";

interface State {}

interface Props {
  readOnly: boolean;
  allSchools: School[];
  selectedSchools: SchoolCode[];
  onInclude: (school: School) => void;
  onExclude: (school: School) => void;
}

export function abbreviate(school: SchoolCode) {
  return school.substring(0, 4).toUpperCase();
}

/**
 * A set of buttons, one for each school, that can be toggled on and off.
 */
export default class SchoolCheckButtons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    let { allSchools, onInclude, onExclude, selectedSchools, readOnly } = this.props;

    return (
      <Grid verticalAlign="top">
        <Grid.Column>
          <Grid.Row>
            {!readOnly && (
              <ButtonGroup fluid basic compact size="mini" toggle>
                {allSchools.map((school, index) => (
                  <Button
                    key={index}
                    active={selectedSchools.includes(school.code)}
                    onClick={() => (selectedSchools.includes(school.code) ? onExclude(school) : onInclude(school))}
                  >
                    {abbreviate(school.code)}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}
