import React, { MouseEvent } from "react";
import { Icon, SemanticICONS } from "semantic-ui-react";
import styled, { css } from "styled-components";

export interface SidebarIconButtonProps {
  disabled?: boolean;
  onClick?: (evt: MouseEvent) => void;
  icon: SemanticICONS;
  title: string;
}

const SimpleButton = styled.button`
  float: right;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  ${props =>
    props.disabled
      ? css`
          color: #aaa;
        `
      : css`
          color: rgba(0, 0, 0, 0.6);
          &:hover {
            color: #1e70bf;
          }
        `};
`;

export default function SidebarIconButton(props: SidebarIconButtonProps) {
  const { disabled, onClick, icon, title } = props;
  return (
    <SimpleButton disabled={disabled} onClick={onClick}>
      <Icon name={icon} title={title} />
    </SimpleButton>
  );
}
