import * as React from "react";
import { UseMutateFunction } from "react-query";
import { useApplySelectorListActions, useSelectorList } from "../api";
import { groupBy, toPairs } from "lodash";
import { selectorActionFilterPredicate, SelectorActionFilters } from "../model/selectorList";
import { ActionResult, ApplicantDTO, SelectorAction, SelectorListRowActions } from "../model/types";
import { Opt } from "../utils";

export type SelectorListApplicant = {
  ucasPersonalId: string;
  surname: Opt<string>;
  forenames: Opt<string>;
  existingApplicant: Opt<ApplicantDTO>;
  selectorRows: SelectorListRowActions[];
};

export type SelectorListActionsByApplicant = {
  applicants: SelectorListApplicant[];
};

type FilteredSelectorListActionsByApplicant = {
  applicants: SelectorListApplicant[];
  countAll: number;
  countRecommended: number;
};
const groupSelectorListActionsByApplicant = (data: SelectorListRowActions[]): SelectorListActionsByApplicant => ({
  applicants: toPairs(groupBy(data, action => action.selector.ucasPersonalId)).map(([ucasPersonalId, selectorRows]) => ({
    ucasPersonalId,
    surname: selectorRows[0].selector.surname,
    forenames: selectorRows[0].selector.forenames,
    existingApplicant: selectorRows[0].existingApplicant,
    selectorRows,
  })),
});

const ungroupSelectorActions = (data: SelectorListActionsByApplicant): SelectorAction[] => {
  const ans: SelectorAction[] = [];
  data.applicants.forEach(applicant => {
    applicant.selectorRows.forEach(row => {
      row.actions.forEach(action => {
        if ((action.type === "LinkApplication" || action.type === "ImportApplication") && !action.recommended) {
          // do nothing - don't bulk apply non-recommended Link/Import application actions
          // see https://github.com/qmspringboard/qmspringboard/issues/1725
        } else {
          ans.push(action);
        }
      });
    });
  });
  return ans;
};

const filterSelectorListApplicants = (
  data: SelectorListActionsByApplicant,
  filters: SelectorActionFilters,
): FilteredSelectorListActionsByApplicant => {
  // This uses mutable arrays to filter the incoming array of SelectorListApplicants in a single pass:

  const pred = selectorActionFilterPredicate(filters);

  const applicants: SelectorListApplicant[] = [];
  let countAll = 0;
  let countRecommended = 0;

  data.applicants.forEach((appt: SelectorListApplicant) => {
    const selectorRows: SelectorListRowActions[] = [];

    appt.selectorRows.forEach((row: SelectorListRowActions) => {
      const actions: SelectorAction[] = [];

      row.actions.forEach((action: SelectorAction) => {
        if (
          pred({
            action,
            liveness: row.linkedApplication && row.linkedApplication.liveness,
          })
        ) {
          actions.push(action);

          countAll = countAll + 1;
          countRecommended = action.recommended ? countRecommended + 1 : countRecommended;
        }
      });

      if (actions.length !== 0) {
        selectorRows.push({ ...row, actions });
      }
    });

    if (selectorRows.length !== 0) {
      applicants.push({ ...appt, selectorRows });
    }
  });

  return {
    ...data,
    applicants,
    countAll,
    countRecommended,
  };
};
export type ApplyAction = UseMutateFunction<ActionResult, unknown, SelectorAction, unknown>;

export type IgnoreAction = UseMutateFunction<ActionResult, unknown, SelectorAction, unknown>;

export type UnignoreAction = UseMutateFunction<ActionResult, unknown, SelectorAction, unknown>;

export type HookReturn =
  | { status: "error"; data: null; error: unknown }
  | { status: "loading"; data: null }
  | {
      status: "success";
      data: SelectorListApplicant[];
      saveAllFiltered: (recommended: boolean) => void;
      count: { all: number; recommended: number };
    };

export function useSelectorListActions({ filters }: { filters: SelectorActionFilters }): HookReturn {
  const query = useSelectorList({ schoolCode: filters.schoolCode || null });
  const applyMany = useApplySelectorListActions();

  return React.useMemo(() => {
    switch (query.status) {
      case "error": {
        return {
          status: "error",
          data: null,
          error: query.error,
        };
      }
      case "idle":
      case "loading": {
        return {
          status: "loading",
          data: null,
        };
      }
      case "success": {
        const actions = groupSelectorListActionsByApplicant(query.data);
        const result = filterSelectorListApplicants(actions, filters);

        const saveAllFiltered = (recommended: boolean) => {
          const all = ungroupSelectorActions(result);
          const actions = recommended ? all.filter(action => action.recommended) : all;

          applyMany.mutate(actions);
        };

        switch (applyMany.status) {
          case "success":
          case "idle": {
            return {
              status: "success",
              data: result.applicants,
              saveAllFiltered,
              count: {
                all: result.countAll,
                recommended: result.countRecommended,
              },
            };
          }
          case "error": {
            return {
              status: "error",
              data: null,
              error: applyMany.error,
            };
          }
          case "loading": {
            return {
              status: "loading",
              data: null,
            };
          }
        }
      }
    }
  }, [query, filters, applyMany]);
}
