/* eslint-disable @typescript-eslint/no-explicit-any */

import { Maybe } from "purify-ts/Maybe";

export type Optional<T> = T | null | undefined;
export type Guard<A> = (value: unknown) => value is A;
export type GuardAs<A> = (value: unknown) => A;

export function guardError(hint: string, expected: unknown, received: unknown) {
  return {
    type: "GuardError",
    hint,
    expected,
    received,
  };
}

export function guard<A>(hint: string, guard: Guard<A>): GuardAs<A> {
  return function (value: unknown): A {
    if (guard(value)) {
      return value;
    } else {
      console.error("Failed guard", JSON.stringify({ hint, guard, value }, null, 2));
      throw guardError(hint, guard, value);
    }
  };
}

export function isUnknown(_value: unknown): _value is unknown {
  return true;
}

export function isObject(value: unknown): value is {} {
  return typeof value === "object" && value != null;
}

export function hasKeyOfAnyType<K extends string>(value: unknown, key: K): value is { [k_ in K]: {} } {
  return typeof value === "object" && value != null && key in value;
}

export function hasKey<K extends string, V>(value: unknown, key: K, guard: (value: unknown) => value is V): value is { [_ in K]: V } {
  return hasKeyOfAnyType(value, key) && guard(value[key]);
}

export function hasOptionalKey<K extends string, V>(value: unknown, key: K, guard: (value: unknown) => value is V): value is { [_ in K]: V } {
  return hasKeyOfAnyType(value, key) ? guard(value[key]) : isObject(value);
}

export function hasTypeTag<T extends string>(value: unknown, type: T): value is { type: T } {
  return hasKeyOfAnyType(value, "type") && value.type === type;
}

export function isUndefined(value: unknown): value is undefined {
  return value === undefined;
}

export function isNull(value: unknown): value is null {
  return value === null;
}

export function isBoolean(value: unknown): value is boolean {
  return typeof value === "boolean";
}

export function isInteger(value: unknown): value is number {
  return typeof value === "number" && Number.isInteger(value);
}

export function isNumber(value: unknown): value is number {
  return typeof value === "number";
}

export function isString(value: unknown): value is string {
  return typeof value === "string";
}

export function isOneOf<A>(...values: A[]) {
  return function (value: unknown): value is A {
    return (values as unknown[]).includes(value);
  };
}

const uuidRegex = /([a-z0-9]{8})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{4})-([a-z0-9]{12})/i;

export function isValidUuid(value: unknown): value is string {
  return typeof value === "string" && uuidRegex.test(value);
}

// Matches ISO8601 UTC timestamps with/without milliseconds and with/without a 'Z' suffix.
const iso8601Regex = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?Z?/;

export function isValidIso8601(value: unknown): value is string {
  return typeof value === "string" && iso8601Regex.test(value);
}

export function isNullable<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A | null {
    return value === null || guard(value);
  };
}

export function isUndefinable<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A | undefined {
    return value === undefined || guard(value);
  };
}

export function isOptional<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is Optional<A> {
    return value == null || guard(value);
  };
}

export function isArray(value: unknown): value is unknown[] {
  return Array.isArray(value);
}

export function isArrayOf<A>(guard: (value: unknown) => value is A) {
  return function (value: unknown): value is A[] {
    return Array.isArray(value) && value.reduce((memo, item) => memo && guard(item), true);
  };
}

export function isDictOf<A>(guard: (arg: any) => arg is A) {
  return function (value: any): value is { [name: string]: A } {
    return (
      typeof value === "object" &&
      value != null &&
      Object.keys(value).reduce<boolean>((a, b) => a && typeof b === "string", true) &&
      Object.keys(value).reduce<boolean>((a, b) => {
        return a && b in value && guard(value[b]);
      }, true)
    );
  };
}

export function isMaybe<A>(guard: (arg: any) => arg is A) {
  return function (value: any): value is Maybe<A> {
    if (value.isJust != null && value.isNothing != null) {
      const maybe = value as Maybe<unknown>;
      return maybe.mapOrDefault(guard, true);
    } else {
      return false;
    }
  };
}

/* eslint-enable @typescript-eslint/no-explicit-any */
