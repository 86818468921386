import React, { ComponentType, useContext } from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import Navbar from "../components/Navbar";
import { Location } from "history";
import { warnAboutBeingInClearing } from "@qmspringboard/app/src/model/inClearing";
import { FeatureFlagsContext } from "@qmspringboard/app/src/FeatureFlagsProvider";

interface WrapperProps {
  location: Location;
}

export default function loggedInWrapper<A extends WrapperProps>(ComposedComponent: ComponentType<A>) {
  return function LoggedInWrapper(props: A) {
    const featureFlags = useContext(FeatureFlagsContext);
    return (
      <div>
        <Navbar location={props.location} />
        {warnAboutBeingInClearing(featureFlags.clearingConfig) && (
          <>
            <Segment size={"massive"} textAlign={"center"} color={"red"}>
              <p>
                Clearing is now in progress. This is the <b>{featureFlags.environmentFriendlyName.toUpperCase()}</b> site. Are you sure you want to be
                here?
              </p>
              <p>
                If not{" "}
                <Button positive size={"huge"} href={"https://qmspringboard.com"}>
                  Go to the <b>LIVE</b> site
                </Button>
              </p>
            </Segment>
            <Segment textAlign={"center"} color={"red"}></Segment>
          </>
        )}
        <ComposedComponent {...props} />
        <Container as="footer" style={{ minHeight: "20em" }} />
      </div>
    );
  };
}
