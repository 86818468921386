import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Dimmer, Loader } from "semantic-ui-react";
import * as pot from "../api/pot";
import { FieldLabel } from "../components/fields";
import { SearchProps, withSearch } from "../components/SearchHOC";
import Title from "../components/Title";
import RoleList from "../components/RoleList";
import { emptySearchResults } from "../model/search";
import { Role } from "../model/types";
import * as teams from "../reducers/teams";
import * as roleSearch from "../reducers/roleSearch";
import { RouteComponentProps } from "react-router";

interface Props extends SearchProps<Role>, RouteComponentProps {
  //
}

class RoleListPage extends Component<Props> {
  render() {
    const { pagerComponent, searchComponent, sortComponent, refetch } = this.props;
    const results = pot.getOrElse(this.props.results, emptySearchResults());
    const fetching = pot.fetching(this.props.results);
    const fetchError = pot.error(this.props.results);

    return (
      <Title title="Roles">
        <Dimmer.Dimmable as={Container}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading</Loader>
          </Dimmer>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={10} tablet={10} computer={12}>
                <FieldLabel>{searchComponent}</FieldLabel>
              </Grid.Column>
              <Grid.Column mobile={6} tablet={6} computer={4}>
                <FieldLabel>
                  <Button.Group primary>
                    <Button fluid primary as={Link} to="/admin/role/new">
                      Add Role
                    </Button>
                  </Button.Group>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <RoleList refetch={refetch} results={results} fetching={fetching} fetchError={fetchError} sortComponent={sortComponent} />
          {pagerComponent}
        </Dimmer.Dimmable>
      </Title>
    );
  }
}

const ConnectedRoleListPage: ComponentType<{}> = connect(
  state => ({
    results: roleSearch.results(state),
    fetching: roleSearch.fetching(state),
    currentTeam: teams.currentTeam(state),
    searchBySchool: false,
  }),
  {
    search: roleSearch.search,
    reset: roleSearch.reset,
  },
)(withSearch(RoleListPage));

export default ConnectedRoleListPage;
