import React, { Component, ReactElement, Ref } from "react";
import { Radio, RadioProps } from "semantic-ui-react";
import { Opt } from "../../utils";
import { BaseFieldProps, SemanticPointing } from "./base";
import FieldWrapper from "./FieldWrapper";

export interface ExtraRadioProps {
  forwardRef?: Ref<Component<RadioProps>>;
  label?: string | ReactElement;
  name?: string;
  errorPointing?: SemanticPointing;
}

export interface RadioFieldProps<A extends string> extends BaseFieldProps<Opt<A>>, ExtraRadioProps {
  checkedValue: A;
}

export default function RadioField<A extends string>({
  forwardRef,
  value,
  checkedValue,
  onChange,
  readOnly,
  disabled,
  messages,
  errorPointing,
  label,
  name,
}: RadioFieldProps<A>) {
  return (
    <FieldWrapper readOnly={readOnly} messages={messages} errorPointing={errorPointing}>
      <Radio
        ref={forwardRef}
        checked={value === checkedValue}
        onChange={() => onChange?.(checkedValue)}
        disabled={disabled}
        readOnly={readOnly}
        label={label}
        name={name}
      />
    </FieldWrapper>
  );
}
