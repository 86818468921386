import { useSelector } from "react-redux";
import { allSchools } from "../reducers/teams";
import { admissionsTeam, schoolsToTeams } from "@qmspringboard/app/src/model/team";

export default function useAllSchools() {
  return useSelector(allSchools);
}

export function useAllTeams() {
  const schools = useAllSchools();
  return [admissionsTeam, ...schoolsToTeams(schools)];
}
