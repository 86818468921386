import * as api from "../../api";
import { InternationalEquiv } from "../../model/types";
import { Opt } from "../../utils";
import { AppReducer } from "../actions";
import { defaultErrorHandler, fetchWithMutex } from "../fetch";
import { rootSelector as routeSelector } from "../route";
import { AppState } from "../state";
import { initialState, MyState } from "./state";

// Selectors

export function myState(state: AppState): MyState {
  return routeSelector(state).internationalEquivs;
}

export function data(state: AppState): Opt<InternationalEquiv[]> {
  return myState(state).data;
}

export function fetching(state: AppState): boolean {
  return myState(state).fetching;
}

// Actions

const FETCH = "internationalEquivs/FETCH";
const FETCHED = "internationalEquivs/FETCHED";

// Reducer

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        fetching: true,
      };
    case FETCHED:
      return {
        ...state,
        data: action.data,
        fetching: false,
      };
    default:
      return state;
  }
};

export default reducer;

// Action creators

export function fetch() {
  return fetchWithMutex<InternationalEquiv[]>({
    mutex: "internationalEquivs",

    request(dispatch, getState) {
      return api.fetchInternationlEquivs(api.fetchParams(getState()));
    },

    success(dispatch, getState, data) {
      dispatch({ type: FETCHED, data });
    },

    error: defaultErrorHandler,
  });
}
