import { ReportingGroup } from "./types";
import * as rule from "./rules";

export const reportingGroupRule: rule.Rule<ReportingGroup> = rule
  .create()
  .field("code", rule.nonEmpty(rule.error("You must enter a code")))
  .field("code", rule.whenNonEmpty(rule.regex(/^.{1,16}$/, rule.error("Must be a 1-to-16 character reporting group code"))))
  .field("name", rule.nonEmpty(rule.error("You must enter a name")))
  .field("ufAimHome", rule.gte(0))
  .field("ufAimOverseas", rule.gte(0))
  .finish();
