import React from "react";
import { UseQueryResult } from "react-query";
import { Loader } from "semantic-ui-react";

type Props<T> = {
  children: (data: T) => JSX.Element | null;
  ErrorComponent?: React.ComponentType<{ error: unknown }>;
  LoadingComponent?: React.ComponentType;
  query: UseQueryResult<T, Error>;
};

const APIError = ({ error }: { error: unknown }) => {
  console.error({ error });
  return <div>:(</div>;
};

const Loading = () => <Loader>Loading</Loader>;

function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

function ReactQueryContainer<T>({ query, ErrorComponent = APIError, LoadingComponent = Loading, children }: Props<T>) {
  switch (query.status) {
    case "error": {
      return <ErrorComponent error={query.error} />;
    }
    case "idle": {
      return <LoadingComponent />;
    }
    case "loading": {
      return <LoadingComponent />;
    }
    case "success": {
      return children(query.data);
    }
    default: {
      return assertNever(query);
    }
  }
}

const typedMemo: <T>(c: T) => T = React.memo;
export default typedMemo(ReactQueryContainer);
