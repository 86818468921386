import { ddmmhhmm, formatLondon, parseIso } from "@qmspringboard/shared/dist/utils/date";
import { qualificationHasSubject } from "@qmspringboard/shared/dist/validators";
import { chain, isEqual } from "lodash";
import React from "react";
import { Icon, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import { Qualifications } from "../model/applicant";
import { ApplicationChoiceEnum, EntryPointEnum, FeeStatusEnum, OfferStatusEnum, QualificationTypeEnum } from "../model/enums";
import { programmeCodeToUcasCourseCode } from "../model/programme";
import {
  ApplicantDetails,
  ApplicantDTO,
  Application,
  EmailMessage,
  EnglishQualification,
  AttachmentType,
  Label,
  Note,
  Qualifications as QualificationsDTO,
  PhoneCallAttempted,
  SchoolCode,
  SecondaryEducationQualifications,
  SelectorAction,
  SelectorListDataRow,
} from "../model/types";
import { Opt, ReactNodeLike } from "../utils";
import ApplicationStatusIndicator, { Statuses } from "./ApplicationStatusIndicator";

const DefinitionTable = ({ ...props }) => <Table columns="4" definition {...props} />;

const DetailsTable = styled(DefinitionTable)`
  background: transparent !important;

  td:first-child {
    background: rgba(0, 0, 0, 0.03) !important;
  }

  td {
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const ApplicantDetailsView = ({ details }: { details: ApplicantDetails }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>UCAS Personal ID</Table.Cell>
        <Table.Cell>{details.ucasPersonalId || "-"}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Name</Table.Cell>

        <Table.Cell>
          <ApplicantNameView surname={details.surname} forenames={details.forenames} />
        </Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Email</Table.Cell>
        <Table.Cell>{details.email || "-"}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>SITS Home Email</Table.Cell>
        <Table.Cell>{details.sitsHomeEmail || "-"}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Date of birth</Table.Cell>
        <Table.Cell>{details.dateOfBirth || "-"}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Telephone 1</Table.Cell>
        <Table.Cell>{details.telephone1 || "-"}</Table.Cell>
      </Table.Row>

      <Table.Row>
        <Table.Cell>Telephone 2</Table.Cell>
        <Table.Cell>{details.telephone2 || "-"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Fee Status</Table.Cell>
        <Table.Cell>
          {FeeStatusEnum.labelOf(details.feeStatus)} ({details.feeStatus})
        </Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const QualificationsView = ({ qualifications: quals }: { qualifications: Qualifications }) => <QualificationsDTOView qualifications={quals} />;

const EnglishQualifications = ({ value }: { value: EnglishQualification | undefined | null }): React.ReactElement => {
  if (!value) {
    return <span>-</span>;
  }

  switch (value.type) {
    case "Gcse": {
      return (
        <Table>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>GCSE</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Grade</Table.Cell>
            <Table.Cell>{value.grade}</Table.Cell>
          </Table.Row>
        </Table>
      );
    }
    case "Toefl":
    case "Ielts": {
      return (
        <Table>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>{value.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Overall {value.overall}</Table.Cell>
            <Table.Cell>Listening {value.listening}</Table.Cell>
            <Table.Cell>Reading {value.reading}</Table.Cell>
            <Table.Cell>Writing {value.writing}</Table.Cell>
            <Table.Cell>Speaking {value.speaking}</Table.Cell>
          </Table.Row>
        </Table>
      );
    }
    case "Other": {
      return <span>-</span>;
    }
  }
};

const SecondaryQualifications = ({ value }: { value: SecondaryEducationQualifications | undefined | null }): React.ReactElement => {
  if (!value) {
    return <span>-</span>;
  }

  switch (value.type) {
    case "Gcse": {
      return (
        <Table>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>GCSE</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Has 5 GCSEs Grade C or above</Table.Cell>
            <Table.Cell>{value.has5orMoreAtC4OrAbove ? "Yes" : "No"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Has 6 GCSEs with 3 at grade 6 and 3 at grade 7 </Table.Cell>
            <Table.Cell>{value.has6orMoreAt6and7 ? "Yes" : "No"}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Has Maths B or above</Table.Cell>
            <Table.Cell>{value.hasMathsAtB5OrAbove ? "Yes" : "No"}</Table.Cell>
          </Table.Row>
        </Table>
      );
    }
    case "Other": {
      return (
        <Table>
          <Table.Row>
            <Table.Cell>Type</Table.Cell>
            <Table.Cell>Other</Table.Cell>
          </Table.Row>
        </Table>
      );
    }
  }
};

export const QualificationsDTOView = ({ qualifications: quals }: { qualifications: QualificationsDTO }) => {
  return (
    <>
      <DetailsTable>
        <Table.Body>
          {quals.list.map((qual, index) => {
            const typeLabel = QualificationTypeEnum.isValue(qual.tpe) ? QualificationTypeEnum.labelOf(qual.tpe) : qual.tpe;

            const subjectLabel = qualificationHasSubject(qual.tpe) ? `in ${qual.subject ?? "unknown subject"}` : null;

            const gradeLabel = `${qual.grade ?? "-"}`;

            return (
              <Table.Row key={index}>
                <Table.Cell>
                  {typeLabel} {subjectLabel}
                </Table.Cell>
                <Table.Cell>{gradeLabel}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </DetailsTable>
      <DetailsTable>
        <Table.Body>
          <Table.Row>
            <Table.Cell>English</Table.Cell>
            <Table.Cell>
              <EnglishQualifications value={quals.english} />
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Secondary Education</Table.Cell>
            <Table.Cell>
              <SecondaryQualifications value={quals.secondaryEducation} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </DetailsTable>
    </>
  );
};

export const ApplicationView = ({ application: appn }: { application: Application }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Choice</Table.Cell>
        <Table.Cell>
          {ApplicationChoiceEnum.labelOf(appn.choice)} ({appn.choice})
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Programme</Table.Cell>
        <Table.Cell>
          {programmeCodeToUcasCourseCode(appn.programmeCode)}{" "}
          {appn.oldProgrammeCode == null ? null : `(changing from ${programmeCodeToUcasCourseCode(appn.oldProgrammeCode)})`}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Method and Choice</Table.Cell>
        <Table.Cell>{ApplicationChoiceEnum.labelOf(appn.choice)}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>UCAS / SITS Status</Table.Cell>
        <Table.Cell>
          <ApplicationStatusIndicator statuses={appn} />
        </Table.Cell>
      </Table.Row>
      {appn.offerStatus && (
        <Table.Row>
          <Table.Cell>Offer Status</Table.Cell>
          <Table.Cell>
            {OfferStatusEnum.labelOf(appn.offerStatus)} ({appn.offerStatus})
          </Table.Cell>
        </Table.Row>
      )}
      {appn.offerStatus && (
        <Table.Row>
          <Table.Cell>Offer Expiry</Table.Cell>
          <Table.Cell>{appn.offerExpiry ? formatLondon(parseIso(appn.offerExpiry), ddmmhhmm) : "-"}</Table.Cell>
        </Table.Row>
      )}
      <Table.Row>
        <Table.Cell>Entry Point</Table.Cell>
        <Table.Cell>
          {EntryPointEnum.labelOf(appn.entryPoint)} ({appn.entryPoint})
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Change of Course Offer (COCO)?</Table.Cell>
        <Table.Cell>{appn.coco ? "Yes" : "No"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Handed Over</Table.Cell>
        <Table.Cell>
          {appn.handover ? (
            <span>
              <Icon name="checkmark" color="green" /> Yes
            </span>
          ) : (
            <span>
              <Icon name="close" color="red" /> No
            </span>
          )}
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Prediction Override</Table.Cell>
        <Table.Cell>{appn.prediction ? `${appn.prediction}%` : "-"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Prediction Override Expiry</Table.Cell>
        <Table.Cell>{appn.predictionExpiry ? formatLondon(parseIso(appn.predictionExpiry), ddmmhhmm) : "-"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Selector List Application ID</Table.Cell>
        <Table.Cell>{appn.selectorListKey || "-"}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Springboard Application ID</Table.Cell>
        <Table.Cell>{appn.id || "-"}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const LabelView = ({ label, padded, showSchool }: { label: Label; padded?: boolean; showSchool?: boolean }) => {
  const text = showSchool ? (
    <label>
      {label.schoolCode || "Central Admissions"}&mdash;{label.text}
    </label>
  ) : (
    label.text
  );

  function icon() {
    switch (label.labelType) {
      case "std":
        return <Icon name="tag" />;
      case "fao":
        return <Icon name="users" />;
      case "todo":
        return <Icon name={label.checked ? "checkmark" : "close"} color={label.checked ? "green" : "red"} />;
    }
  }

  return (
    <div style={padded ? { marginLeft: "8em" } : {}}>
      {icon()}
      {text}
    </div>
  );
};

export const LabelsView = ({ labels }: { labels: Label[] }) => {
  const lists = chain(labels)
    .sortBy(label => label.text)
    .groupBy(label => label.schoolCode || "Central Admissions")
    .toPairs()
    .sortBy(([schoolCode, _labels]) => schoolCode)
    .value();

  return (
    <DetailsTable>
      <Table.Body>
        {lists.map(([schoolCode, labels]) => (
          <Table.Row key={schoolCode}>
            <Table.Cell>{schoolCode}</Table.Cell>
            <Table.Cell>
              {labels.map(label => (
                <LabelView key={`${label.prototypeId}-${label.applicantId}`} label={label} />
              ))}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </DetailsTable>
  );
};

export const UcasImportView = ({ schoolCode }: { schoolCode: SchoolCode }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>School</Table.Cell>
        <Table.Cell>{schoolCode}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const NoteView = ({ note }: { note: Note }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Author</Table.Cell>
        <Table.Cell>
          {note.authorName} ({note.authorSchoolCode || "Central"})
        </Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Created</Table.Cell>
        <Table.Cell>{note.timestamp}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Text</Table.Cell>
        <Table.Cell>{note.text}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const PhoneCallAttemptedDetailsView = ({ attempt }: { attempt: PhoneCallAttempted }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Phone number</Table.Cell>
        <Table.Cell>{attempt.number}</Table.Cell>
      </Table.Row>
      {attempt.error && (
        <Table.Row>
          <Table.Cell>Error message</Table.Cell>
          <Table.Cell>{attempt.error}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  </DetailsTable>
);

export const AttachmentRequestedView = ({ fileType, message }: { fileType: AttachmentType; message: Opt<string> }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Attachment Type</Table.Cell>
        <Table.Cell>{fileType}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>Message</Table.Cell>
        <Table.Cell>{message}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const AttachmentActionView = ({ fileId, fileName, fileType }: { fileId: string; fileName: string; fileType: AttachmentType }) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Attachment Type</Table.Cell>
        <Table.Cell>{fileType}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>File Name</Table.Cell>
        <Table.Cell>{fileName}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>File Id</Table.Cell>
        <Table.Cell>{fileId}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const AttachmentUploadedToSITSActionView = ({
  fileId,
  fileName,
  sitsFileName,
  fileType,
}: {
  fileId: string;
  fileName: string;
  sitsFileName: string;
  fileType: AttachmentType;
}) => (
  <DetailsTable>
    <Table.Body>
      <Table.Row>
        <Table.Cell>Attachment Type</Table.Cell>
        <Table.Cell>{fileType}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>File Name</Table.Cell>
        <Table.Cell>{fileName}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>SITS File Name</Table.Cell>
        <Table.Cell>{sitsFileName}</Table.Cell>
      </Table.Row>
      <Table.Row>
        <Table.Cell>File Id</Table.Cell>
        <Table.Cell>{fileId}</Table.Cell>
      </Table.Row>
    </Table.Body>
  </DetailsTable>
);

export const EmailView = ({ email }: { email: EmailMessage }) => (
  <div>
    <DetailsTable>
      <Table.Body>
        <Table.Row>
          <Table.Cell>To</Table.Cell>
          <Table.Cell>{email.toName ? `${email.toName} <${email.toAddress}>` : email.toAddress}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>From</Table.Cell>
          <Table.Cell>{email.fromName ? `${email.fromName} <${email.fromAddress}>` : email.fromAddress}</Table.Cell>
        </Table.Row>
        {email.bccAddress && (
          <Table.Row>
            <Table.Cell>BCC</Table.Cell>
            <Table.Cell>{email.bccAddress}</Table.Cell>
          </Table.Row>
        )}
        {email.replyToAddress && (
          <Table.Row>
            <Table.Cell>Reply To</Table.Cell>
            <Table.Cell>{email.replyToAddress}</Table.Cell>
          </Table.Row>
        )}
        <Table.Row>
          <Table.Cell>Subject</Table.Cell>
          <Table.Cell>{email.subject}</Table.Cell>
        </Table.Row>
      </Table.Body>
    </DetailsTable>

    <Segment>
      <div dangerouslySetInnerHTML={{ __html: email.htmlBody }} />
    </Segment>
  </div>
);

export const SelectorListDataRowView = ({
  row,
  applicant,
  application,
  showDiffs,
  highlightAction,
}: {
  row: SelectorListDataRow;
  applicant: Opt<ApplicantDTO>;
  application: Opt<Application>;
  showDiffs: boolean;
  highlightAction?: string;
}) => {
  const [showFullSelectorKey, setShowFullSelectorKey] = React.useState(false);
  const threeColumn = !!(applicant && application);

  function TableRow<A>({
    name,
    clearingPlusValue,
    sitsValue,
    renderValue,
    highlight,
    onClick,
  }: {
    name: string;
    clearingPlusValue: Opt<A>;
    sitsValue: A;
    renderValue: (a: A) => ReactNodeLike;
    highlight: boolean;
    onClick?: () => void;
  }) {
    const style =
      threeColumn && showDiffs && !isEqual(clearingPlusValue, sitsValue)
        ? { borderLeft: "3px solid red" }
        : highlight
          ? { borderLeft: "3px solid #5f5" }
          : {};

    return (
      <Table.Row onClick={onClick}>
        <Table.Cell>{name}</Table.Cell>
        {threeColumn && <Table.Cell style={style}>{clearingPlusValue ? renderValue(clearingPlusValue) : "-"}</Table.Cell>}
        {threeColumn && <Table.Cell>{highlight && <span>&lArr;</span>}</Table.Cell>}
        <Table.Cell>{renderValue(sitsValue)}</Table.Cell>
      </Table.Row>
    );
  }

  const statusChangeAction = (highlightAction: Opt<string>): boolean =>
    highlightAction != null &&
    [
      "InitialDecisionChange",
      "InitialResponseChange",
      "ConfirmationDecisionChange",
      "ConfirmationResponseChange",
      "ClearingDecisionChange",
      "ClearingResponseChange",
      "ApplicationStatusChange",
    ].indexOf(highlightAction) >= 0;

  const applicationStatuses = (arg: Application): Statuses => ({
    choice: arg.choice,
    initialDecision: arg.initialDecision,
    initialResponse: arg.initialResponse,
    confirmationDecision: arg.confirmationDecision,
    confirmationResponse: arg.confirmationResponse,
    clearingDecision: arg.clearingDecision,
    clearingResponse: arg.clearingResponse,
    applicationStatus: arg.applicationStatus,
    offerStatus: arg.offerStatus,
    offerExpiry: arg.offerExpiry,
  });

  const selectorListStatuses = (arg: SelectorListDataRow): Statuses => ({
    choice: arg.choice,
    initialDecision: arg.initialDecision,
    initialResponse: arg.initialResponse,
    confirmationDecision: arg.confirmationDecision,
    confirmationResponse: arg.confirmationResponse,
    clearingDecision: arg.clearingDecision,
    clearingResponse: arg.clearingResponse,
    applicationStatus: arg.applicationStatus,
    offerStatus: null,
    offerExpiry: null,
  });

  return (
    <DetailsTable>
      {threeColumn && (
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="4" />
            <Table.HeaderCell width="6">Springboard</Table.HeaderCell>
            <Table.HeaderCell width="1" />
            <Table.HeaderCell width="6">Selector List</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        <TableRow
          name="UCAS Personal ID"
          clearingPlusValue={applicant && applicant.details.ucasPersonalId}
          sitsValue={row.ucasPersonalId}
          renderValue={value => value}
          highlight={false}
        />
        <TableRow
          name="Student ID"
          clearingPlusValue={applicant && applicant.details.studentId}
          sitsValue={row.studentId}
          renderValue={value => value}
          highlight={highlightAction === "StudentIdChange"}
        />
        <TableRow
          name="Name"
          clearingPlusValue={
            applicant && {
              surname: applicant.details.surname,
              forenames: applicant.details.forenames,
            }
          }
          sitsValue={{ surname: row.surname, forenames: row.forenames }}
          renderValue={value => <ApplicantNameView surname={value.surname} forenames={value.forenames} />}
          highlight={highlightAction === "NameChange"}
        />
        <TableRow
          name="Telephone"
          clearingPlusValue={applicant && [applicant.details.telephone1, applicant.details.telephone2].filter(x => x).join(" / ")}
          sitsValue={row.contactTelephone}
          renderValue={value => value}
          highlight={highlightAction === "TelephoneChange"}
        />
        <TableRow
          name="Nationality"
          clearingPlusValue={applicant && applicant.details.nationality}
          sitsValue={row.nationality}
          renderValue={value => value}
          highlight={highlightAction === "NationalityChange"}
        />
        <TableRow
          name="Method and Choice"
          clearingPlusValue={application && application.choice}
          sitsValue={row.choice}
          renderValue={value => value && ApplicationChoiceEnum.labelOf(value)}
          highlight={false}
        />
        <TableRow
          name="Programme"
          clearingPlusValue={
            application && {
              code: application.programmeCode,
              oldCode: application.oldProgrammeCode,
            }
          }
          sitsValue={{ code: row.programmeCode, oldCode: null }}
          renderValue={value => (
            <span>
              {programmeCodeToUcasCourseCode(value.code)}{" "}
              {value.oldCode == null ? null : <span>&lArr; {programmeCodeToUcasCourseCode(value.oldCode)}</span>}
            </span>
          )}
          highlight={highlightAction === "ProgrammeChange"}
        />
        <TableRow
          name="Status"
          clearingPlusValue={application && applicationStatuses(application)}
          sitsValue={selectorListStatuses(row)}
          renderValue={value => <ApplicationStatusIndicator statuses={value} />}
          highlight={statusChangeAction(highlightAction)}
        />
        <TableRow
          name="Fee Status"
          clearingPlusValue={applicant && applicant.details.feeStatus}
          sitsValue={row.feeStatus}
          renderValue={value => (
            <span>
              {FeeStatusEnum.labelOf(value)} ({value})
            </span>
          )}
          highlight={highlightAction === "FeeStatusChange"}
        />
        <TableRow
          name="Entry Point"
          clearingPlusValue={application && application.entryPoint}
          sitsValue={row.entryPoint}
          renderValue={value => (
            <span>
              {EntryPointEnum.labelOf(value)} ({value})
            </span>
          )}
          highlight={highlightAction === "EntryPointChange"}
        />
        <TableRow
          name="Change of Course Offer (COCO)?"
          clearingPlusValue={application && application.coco}
          sitsValue={row.coco}
          renderValue={value => (value ? "Yes" : "No")}
          highlight={highlightAction === "CocoChange"}
        />
        <TableRow
          name="Selector List Application ID"
          clearingPlusValue={application && application.selectorListKey}
          sitsValue={row.selectorListKey}
          renderValue={value => (showFullSelectorKey ? value : value.slice(0, 16) + "...")}
          onClick={() => setShowFullSelectorKey(!showFullSelectorKey)}
          highlight={false}
        />
        <TableRow
          name="Date of Birth"
          clearingPlusValue={applicant && applicant.details.dateOfBirth}
          sitsValue={row.dob}
          renderValue={value => value}
          highlight={false}
        />
        <TableRow
          name="SITS Home Email Address"
          clearingPlusValue={applicant && applicant.details.sitsHomeEmail}
          sitsValue={row.sitsHomeEmail}
          renderValue={value => value}
          highlight={false}
        />
        <TableRow
          name="CSV File Line Number"
          clearingPlusValue={row.lineNumber}
          sitsValue={row.lineNumber}
          renderValue={value => value}
          highlight={false}
        />
      </Table.Body>
    </DetailsTable>
  );
};

export const SelectorActionView = ({ action }: { action: SelectorAction }) => <pre>{JSON.stringify(action, null, 2)}</pre>;

const ApplicantNameView = ({ surname, forenames }: { surname: Opt<string>; forenames: Opt<string> }) => (
  <span>
    {surname && forenames && `${surname}, ${forenames}`}
    {surname && !forenames && surname}
    {!surname && forenames && forenames}
    {!surname && !forenames && "-"}
  </span>
);
