import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as pot from "../api/pot";
import { LabelPrototype, SearchResults } from "../model/types";
import * as labelPrototype from "../reducers/labelPrototypeSearch";

export default function useLabelPrototypes(): [SearchResults<LabelPrototype> | null, { status: pot.Pot<SearchResults<LabelPrototype>>["state"] }] {
  const dispatch = useDispatch();
  const resource = useSelector(labelPrototype.results);
  const status = resource.state;
  const empty = pot.isEmpty(resource);

  useEffect(() => {
    // Labels in the store get cleared out on team change.
    // If they get set back to empty, we ensure they get fetched again:
    if (empty) {
      dispatch(labelPrototype.searchAllTeams());
    }
  }, [dispatch, empty]);

  return [resource.state === "ready" ? resource.value : null, { status }];
}
