import { calcExpiry, customOfferExpiry, defaultOfferExpiry } from "@qmspringboard/shared/dist/model/offerExpiry";
import { formatIso, parseIso } from "@qmspringboard/shared/dist/utils/date";
import { addDays, subMinutes } from "date-fns";
import React, { useCallback } from "react";
import { Dropdown, Grid } from "semantic-ui-react";
import styled from "styled-components";
import { OfferExpiryEnum } from "../model/enums";
import { ApplicationChoice, OfferExpiry, OfferStatus, SchoolExpirySettings } from "../model/types";
import { Opt } from "../utils";
import { BaseFieldProps, NullableDateTimeField } from "./fields";

export interface ExpiryFieldProps extends BaseFieldProps<Opt<string>> {
  size?: string;
  getDefault: () => Opt<string>;
  getChoice: (value: OfferExpiry) => Opt<string>;
}

export interface OfferExpiryFieldProps extends BaseFieldProps<Opt<string>> {
  choice: ApplicationChoice;
  offerStatus: OfferStatus;
  schoolExpirySettings: Opt<SchoolExpirySettings>;
  size?: string;
}

export interface PredictionExpiryFieldProps extends BaseFieldProps<Opt<string>> {
  size?: string;
}

const CompactGrid = styled(Grid)`
  margin: 0 !important;
  .row {
    padding: 0 !important;
    .grid {
      margin: 0 !important;
    }
  }
`;

const CompactGridColumn = styled(Grid.Column)`
  padding-left: 5px !important;
  padding-right: 5px !important;
  .ui.grid {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
`;

const CompactDropdown = styled(Dropdown)`
  padding-left: 0.6em !important;
  padding-right: 0.6em !important;
  .icon {
    margin: 0 !important;
  }
`;

const dropdownOptions = OfferExpiryEnum.entries.map(entry => ({
  value: entry.value,
  text: entry.label,
}));

export function ExpiryField({ readOnly, disabled, messages, value, onChange, getDefault, getChoice }: ExpiryFieldProps) {
  return (
    <CompactGrid>
      <Grid.Row>
        <CompactGridColumn mobile={5} tablet={4} computer={4} textAlign="right" verticalAlign="middle">
          <small>expires</small>
        </CompactGridColumn>
        <CompactGridColumn mobile={9} tablet={10} computer={10} verticalAlign="middle">
          <NullableDateTimeField readOnly={readOnly} disabled={disabled} messages={messages} value={value} onChange={onChange} size="mini" />
        </CompactGridColumn>
        <CompactGridColumn mobile={2} tablet={2} computer={2} verticalAlign="middle">
          {!readOnly && (
            <CompactDropdown button floating inline labeled={false} direction="left" icon="caret down" compact={true} scrolling={true}>
              <Dropdown.Menu>
                <Dropdown.Item text="Default" onClick={() => onChange?.(getDefault())} />
                {dropdownOptions.map(option => (
                  <Dropdown.Item key={option.value} text={option.text} onClick={() => onChange?.(getChoice(option.value))} />
                ))}
              </Dropdown.Menu>
            </CompactDropdown>
          )}
        </CompactGridColumn>
      </Grid.Row>
    </CompactGrid>
  );
}

export function OfferExpiryField({
  readOnly,
  disabled,
  messages,
  value,
  onChange,
  choice,
  offerStatus,
  schoolExpirySettings,
}: OfferExpiryFieldProps) {
  const handleChange = (newValue: Opt<string>) => {
    if (onChange) {
      if (newValue) {
        const time = parseIso(newValue);
        if (time.getHours() === 0 && time.getMinutes() === 0) {
          return onChange(formatIso(subMinutes(addDays(time, 1), 1)));
        }
      }
      onChange(newValue);
    }
  };

  const getChoice = useCallback(
    (value: OfferExpiry) => schoolExpirySettings && customOfferExpiry(value, choice, offerStatus, schoolExpirySettings),
    [choice, offerStatus, schoolExpirySettings],
  );

  const getDefault = useCallback(
    () => schoolExpirySettings && defaultOfferExpiry(choice, offerStatus, schoolExpirySettings),
    [choice, offerStatus, schoolExpirySettings],
  );

  return (
    <ExpiryField
      readOnly={readOnly}
      disabled={disabled}
      messages={messages}
      value={value}
      onChange={handleChange}
      getChoice={getChoice}
      getDefault={getDefault}
    />
  );
}

export function PredictionExpiryField(props: PredictionExpiryFieldProps) {
  const { readOnly, disabled, messages, value, onChange } = props;

  const getChoice = useCallback((value: OfferExpiry) => {
    const date = calcExpiry(value, [7, 0], [20, 0], new Date());
    return date && formatIso(date);
  }, []);

  const getDefault = useCallback(() => getChoice(OfferExpiryEnum.SevenDay), [getChoice]);

  return (
    <ExpiryField
      readOnly={readOnly}
      disabled={disabled}
      messages={messages}
      value={value}
      onChange={onChange}
      getChoice={getChoice}
      getDefault={getDefault}
    />
  );
}
