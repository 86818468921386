import { guessFeeCategory as guessFeeCategory } from "@qmspringboard/shared/dist/model/feeCategory";
import { Location } from "history";
import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
// import { withRouter } from "react-router";
import { Container, Dimmer, Grid, Loader, Segment } from "semantic-ui-react";
import ApplicantDetailsEditor from "../components/ApplicantDetailsEditor";
import ApplicantSidebar from "../components/ApplicantSidebar";
import ApplicationsEditor from "../components/ApplicationsEditor";
import EditInPlaceForm from "../components/EditInPlaceForm";
import ErrorDisplay from "../components/ErrorDisplay";
import FeeCategoryEditor from "../components/FeeCategoryEditor";
import QualificationsEditor from "../components/QualificationsEditor";
import Title from "../components/Title";
import { RequirementsChecksProvider } from "../hooks/useRequirementsChecks";
import { ApplicantDetails, ApplicantEditorState, Qualifications } from "../model/applicant";
import { Messages, subErrors } from "../model/errors";
import { Permissions } from "../model/permission";
import { Team, teamToSchoolCode } from "../model/team";
import { format as formatTrackingNumber } from "../model/trackingNumber";
import { Application, ApplicationChoice, Label, Note, SchoolCode, User } from "../model/types";
import * as applicantUpdate from "../reducers/applicantUpdate";
import { Sections } from "../reducers/applicantUpdate/state";
import * as auth from "../reducers/auth";
import * as teams from "../reducers/teams";
import { Opt, safeParseInt } from "../utils";
import { ShowModalFunc, withModal } from "../utils/modal";
import { locationQueryParams } from "../utils/queryParams";

interface Params {
  applicantId: string;
}

interface Props {
  match: Match<Params>;
  location: Location;
  currentUser: Opt<User>;
  permissions: Permissions;
  dto: ApplicantEditorState;
  qualifications: Qualifications;
  fetching: boolean;
  fetched: boolean;
  saving: Sections;
  editing: Sections;
  anythingEditing: boolean;
  editingApplication: Application | null;
  messages: Messages;
  onLoad: (apptId: number, appnId?: Opt<number>) => void;
  onUpdateApplicantDetails: (newDetails: ApplicantDetails) => void;
  onStartEditingApplicantDetails: () => void;
  onCancelEditingApplicantDetails: () => void;
  onSaveApplicantDetails: () => void;
  onUpdateQualifications: (quals: Qualifications) => void;
  onStartEditingQualifications: () => void;
  onCancelEditingQualifications: () => void;
  onSaveQualifications: () => void;
  onAddApplication: (choice: ApplicationChoice) => void;
  onUpdateApplication: (appn: Application) => void;
  onRemoveApplication: (appn: Application) => void;
  onUpdateLabel: (oldLabel: Label, neLabel: Label) => void;
  onSetHandover: (appn: Application, handover: boolean) => void;
  onAddNote: (author: User, schoolCode?: Opt<SchoolCode>) => void;
  onSaveNote: (oldNote: Note, newNote: Note) => void;
  onUpdateNote: (oldNote: Note, newNote: Note) => void;
  onDeleteNote: (note: Note) => void;
  selectedApplication: Application | null;
  selectedApplicationInitial: Application | null;
  selectedApplicationIndex: number | null;
  setSelectedApplication: (application: Application) => void;
  isDirty: () => boolean;
  currentTeam: Team;
  allTeams: Team[];
  showModal: ShowModalFunc;
}

class ApplicantUpdatePage extends React.Component<Props> {
  handleAddNote = () => {
    const { onAddNote, currentUser, currentTeam } = this.props;
    if (currentUser) {
      onAddNote(currentUser, teamToSchoolCode(currentTeam.code));
    } else {
      console.error("Attempt to add note without currentUser");
    }
  };

  handleDeleteNote = (note: Note) => {
    const { onDeleteNote } = this.props;
    this.props.showModal("confirm", {
      onPrimaryClick: () => onDeleteNote(note),
      content: "Are you sure you want to delete this note?",
    });
  };

  componentDidMount() {
    const { applicantId: applicantIdString } = this.props.match.params;
    const { application: applicationIdString } = locationQueryParams(this.props.location);
    const applicantId = safeParseInt(applicantIdString, undefined);
    if (applicantId != null) {
      this.props.onLoad(applicantId, safeParseInt(applicationIdString, undefined));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { applicantId: oldApplicantIdString } = this.props.match.params;
    const { applicantId: newApplicantIdString } = nextProps.match.params;

    const newApplicantId = safeParseInt(newApplicantIdString, this.props.dto.applicant.id);

    const oldApplicantId = safeParseInt(oldApplicantIdString, this.props.dto.applicant.id);

    if (newApplicantId !== oldApplicantId) {
      this.props.onLoad(newApplicantId, safeParseInt(locationQueryParams(this.props.location).application, undefined));
    }
  }

  wrappedGuessFeeCategory = () =>
    guessFeeCategory(
      this.props.dto.applicant.details.feeStatus,
      this.props.dto.applicant.details.ukResidency,
      this.props.dto.applicant.details.ukImmigrationStatus,
    );

  render() {
    const {
      permissions,
      dto,
      saving,
      editing,
      anythingEditing,
      editingApplication,
      fetching,
      messages,
      onUpdateApplicantDetails,
      onStartEditingApplicantDetails,
      onCancelEditingApplicantDetails,
      onSaveApplicantDetails,
      onUpdateQualifications,
      onStartEditingQualifications,
      onCancelEditingQualifications,
      onSaveQualifications,
      onAddApplication,
      onRemoveApplication,
      onUpdateApplication,
      onUpdateLabel,
      onSetHandover,
      onSaveNote,
      onUpdateNote,
      qualifications,
      selectedApplication,
      selectedApplicationInitial,
      selectedApplicationIndex,
      setSelectedApplication,
      currentTeam,
      allTeams,
      isDirty,
      currentUser,
    } = this.props;

    const splitErrors = subErrors(messages, {
      applicant: true,
      applications: true,
      transfers: true,
      _email_: true,
    });

    const detailsErrors = subErrors(splitErrors.applicant, {
      details: true,
      qualifications: true,
    });

    const canEditApplicantDetails = permissions.canUpdateApplicantDetails();

    const canEditQualifications = permissions.canUpdateQualifications();

    const canEditAnyApplications = true;

    return (
      <Title title={dto == null || dto.applicant.id < 0 ? "Loading" : formatTrackingNumber(dto.applicant.id)}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            <RequirementsChecksProvider qualifications={dto.applicant.qualifications}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <ErrorDisplay messages={splitErrors._rest_} />
                    <ErrorDisplay messages={detailsErrors._rest_} />

                    <EditInPlaceForm
                      editing={editing.details}
                      saving={saving.details}
                      messages={detailsErrors.details}
                      readOnly={!canEditApplicantDetails}
                      isDirty={isDirty}
                      anythingEditing={anythingEditing}
                      onEdit={onStartEditingApplicantDetails}
                      onCancel={onCancelEditingApplicantDetails}
                      onSave={onSaveApplicantDetails}
                      style={{ marginTop: 0 }}
                    >
                      {dto && (
                        <>
                          <ApplicantDetailsEditor
                            value={dto.applicant.details}
                            duplicates={dto.duplicates}
                            messages={detailsErrors.details}
                            readOnly={!editing.details}
                            onChange={onUpdateApplicantDetails}
                          />
                          <FeeCategoryEditor
                            value={dto.applicant.details}
                            messages={detailsErrors.details}
                            readOnly={!editing.details}
                            onChange={onUpdateApplicantDetails}
                          />
                        </>
                      )}
                    </EditInPlaceForm>

                    <EditInPlaceForm
                      editing={editing.qualifications}
                      saving={saving.qualifications}
                      messages={detailsErrors.qualifications}
                      readOnly={!canEditQualifications}
                      isDirty={isDirty}
                      anythingEditing={anythingEditing}
                      onEdit={onStartEditingQualifications}
                      onCancel={onCancelEditingQualifications}
                      onSave={onSaveQualifications}
                    >
                      {dto && (
                        <QualificationsEditor
                          value={dto.applicant.qualifications}
                          messages={detailsErrors.qualifications}
                          readOnly={!editing.qualifications || !canEditQualifications}
                          onChange={onUpdateQualifications}
                        />
                      )}
                    </EditInPlaceForm>

                    <Segment>
                      <ApplicationsEditor
                        currentTeam={currentTeam}
                        allTeams={allTeams}
                        permissions={permissions}
                        value={dto.applications}
                        anythingEditing={anythingEditing}
                        editingApplication={editingApplication}
                        readOnly={!canEditAnyApplications}
                        qualifications={qualifications}
                        guessedFeeCategory={this.wrappedGuessFeeCategory()}
                        applicantSaved
                        messages={splitErrors.applications}
                        emailMessages={editingApplication != null ? splitErrors._email_ : []}
                        onAddApplication={onAddApplication}
                        selectedApplication={selectedApplication}
                        selectedApplicationInitial={selectedApplicationInitial}
                        selectedApplicationIndex={selectedApplicationIndex}
                        setSelectedApplication={setSelectedApplication}
                        onRemoveApplication={onRemoveApplication}
                        onUpdateApplication={onUpdateApplication}
                        canRemoveApplications={false}
                      />
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <ApplicantSidebar
                      dto={dto}
                      permissions={permissions}
                      currentTeam={currentTeam}
                      allTeams={allTeams}
                      user={currentUser}
                      anythingEditing={anythingEditing}
                      onUpdateLabel={onUpdateLabel}
                      onSetHandover={onSetHandover}
                      onAddNote={this.handleAddNote}
                      onSaveNote={onSaveNote}
                      onUpdateNote={onUpdateNote}
                      onDeleteNote={this.handleDeleteNote}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </RequirementsChecksProvider>
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default connect(
  state => ({
    currentUser: auth.currentUser(state),
    permissions: auth.permissions(state),
    dto: applicantUpdate.dto(state),
    fetching: applicantUpdate.fetching(state),
    fetched: applicantUpdate.fetched(state),
    saving: applicantUpdate.saving(state),
    editing: applicantUpdate.editing(state),
    anythingEditing: applicantUpdate.anythingEditing(state),
    editingApplication: applicantUpdate.editingApplication(state),
    messages: applicantUpdate.messages(state),
    qualifications: applicantUpdate.qualifications(state),
    selectedApplication: applicantUpdate.selectedApplication(state),
    selectedApplicationInitial: applicantUpdate.selectedApplicationInitial(state),
    selectedApplicationIndex: applicantUpdate.selectedApplicationIndex(state),
    isDirty: applicantUpdate.anythingDirty(state),
    currentTeam: teams.currentTeam(state),
    allTeams: teams.allTeams(state),
  }),
  {
    onLoad: applicantUpdate.load,
    onUpdateApplicantDetails: applicantUpdate.updateApplicantDetails,
    onStartEditingApplicantDetails: applicantUpdate.startEditingApplicantDetails,
    onCancelEditingApplicantDetails: applicantUpdate.cancelEditingApplicantDetails,
    onSaveApplicantDetails: applicantUpdate.saveApplicantDetails,
    onUpdateQualifications: applicantUpdate.updateQualifications,
    onStartEditingQualifications: applicantUpdate.startEditingQualifications,
    onCancelEditingQualifications: applicantUpdate.cancelEditingQualifications,
    onSaveQualifications: applicantUpdate.saveQualifications,
    onAddApplication: applicantUpdate.addApplication,
    setSelectedApplication: applicantUpdate.setSelectedApplication,
    onUpdateApplication: applicantUpdate.updateApplication,
    onRemoveApplication: applicantUpdate.removeApplication,
    onUpdateLabel: applicantUpdate.saveLabel,
    onSetHandover: applicantUpdate.setHandover,
    onAddNote: applicantUpdate.addNote,
    onSaveNote: applicantUpdate.saveNote,
    onUpdateNote: applicantUpdate.updateNote,
    onDeleteNote: applicantUpdate.deleteNote,
  },
)(withModal(ApplicantUpdatePage));
