import React, { ReactElement } from "react";
import { Button } from "semantic-ui-react";
import { ToolbarSegment } from "./ParamsToolbar";
import { QueryStringState } from "./type";

export interface ColumnsToolbarProps {
  qss: QueryStringState;
  pushQss: (qss: QueryStringState) => void;
}

export default function ColumnsToolbar(props: ColumnsToolbarProps): ReactElement | null {
  const { qss, pushQss } = props;

  const { showCharts, showOfferStatusSummary, showOverall, showUcas, showClearing } = qss;

  return (
    <ToolbarSegment basic vertical>
      <Button toggle active={showCharts} onClick={() => pushQss({ ...qss, showCharts: !showCharts })}>
        Show charts
      </Button>
      <Button toggle active={showOverall} onClick={() => pushQss({ ...qss, showOverall: !showOverall })}>
        Show overall
      </Button>
      <Button
        toggle
        active={showOfferStatusSummary}
        onClick={() =>
          pushQss({
            ...qss,
            showOfferStatusSummary: !showOfferStatusSummary,
          })
        }
      >
        Show offer summary
      </Button>
      <Button toggle active={showUcas} onClick={() => pushQss({ ...qss, showUcas: !showUcas })}>
        Show UCAS
      </Button>
      <Button toggle active={showClearing} onClick={() => pushQss({ ...qss, showClearing: !showClearing })}>
        Show clearing
      </Button>
    </ToolbarSegment>
  );
}
