import React from "react";
import ModalConfirm from "./ModalConfirm";
import ModalAlert from "./ModalAlert";
import ModalMessage from "./ModalMessage";
import ModalApplicationScript from "./ModalApplicationScript";
import ModalChangeProgramme from "./ModalChangeProgramme";
import ModalConfirmChangeOfProgramme from "./ModalConfirmChangeOfProgramme";
import ModalInput from "./ModalInput";
import ModalInterSchoolChangeOfProgramme from "./ModalInterSchoolChangeOfProgramme";
import ModalAuditTrail from "./ModalAuditTrail";
import ModalAddFile from "./ModalAddFile";

export default function ModalContainer() {
  return (
    <div id="modal-container">
      <ModalConfirm />
      <ModalAlert />
      <ModalMessage />
      <ModalApplicationScript />
      <ModalChangeProgramme />
      <ModalInput />
      <ModalInterSchoolChangeOfProgramme />
      <ModalConfirmChangeOfProgramme />
      <ModalAuditTrail />
      <ModalAddFile />
    </div>
  );
}
