import React from "react";
import { Button, Container, Header, Segment } from "semantic-ui-react";
import { ShowModalFunc, withModal } from "../utils/modal";

type Props = {
  applicantId: number;
  showModal: ShowModalFunc;
};

const ApplicantAuditTrail = (props: Props) => {
  const handleClick = () => {
    props.showModal("auditTrail", {
      title: "Change history",
      applicantId: props.applicantId,
    });
  };

  return (
    <Container>
      <Header as="h3" dividing>
        Change History
      </Header>

      <Segment basic vertical>
        <Button basic fluid onClick={handleClick}>
          Show History
        </Button>
      </Segment>
    </Container>
  );
};

export default withModal(ApplicantAuditTrail);
