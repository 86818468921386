import { ExpirySettings, SchoolCode, SchoolExpirySettings } from "@qmspringboard/shared/dist/model/types";
import Opt from "@qmspringboard/shared/dist/utils/opt";
import * as api from "../../api";
import * as pot from "../../api/pot";
import { AppReducer } from "../actions";
import { fetchWithMutex } from "../fetch";
import { rootSelector as routeSelector } from "../route";
import { AppState } from "../state";
import { initialState, MyState } from "./state";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export function rootSelector(state: AppState): MyState {
  return routeSelector(state).offerExpirySettings;
}

export function settingsPot(state: AppState): pot.Pot<ExpirySettings> {
  return rootSelector(state).settings;
}

export function allSettings(state: AppState): Opt<ExpirySettings> {
  return pot.getOrElse(rootSelector(state).settings, undefined);
}

export function schoolSettings(schoolCode: SchoolCode) {
  return (state: AppState): Opt<SchoolExpirySettings> => {
    return allSettings(state)?.[schoolCode];
  };
}

const REQUEST = "expirySettings/REQUEST";
const SUCCESS = "expirySettings/SUCCESS";
const FAILURE = "expirySettings/FAILURE";

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST:
      return { settings: pot.pending(action.requestId) };
    case SUCCESS:
      return { settings: pot.ready(action.resource) };
    case FAILURE:
      return { settings: pot.failed(action.error) };
    default:
      return state;
  }
};

export function fetchExpirySettings() {
  return fetchWithMutex<ExpirySettings>({
    mutex: "expirySettings",

    request(dispatch, getState) {
      return api.fetchExpirySettings(api.fetchParams(getState()));
    },

    pending(dispatch, getState, requestId) {
      dispatch({ type: REQUEST, requestId });
    },

    success(dispatch, getState, resource) {
      dispatch({ type: SUCCESS, resource });
    },

    error(dispatch, getState, error) {
      dispatch({ type: FAILURE, error });
    },
  });
}

export default reducer;

export function useExpirySettings(): [boolean, ExpirySettings] {
  const dispatch = useAppDispatch();

  const settings = useSelector(settingsPot);

  useEffect(() => {
    if (pot.isEmpty(settings)) {
      dispatch(fetchExpirySettings());
    }
  }, [dispatch, settings]);

  return [pot.fetching(settings), pot.getOrElse(settings, {})];
}
