import React from "react";
import useAllSchools from "../hooks/useAllSchools";
import { SchoolCode } from "../model/types";
import { Opt } from "../utils";

import DropdownField, { DropdownOptions } from "./fields/DropdownField";

const empty = {
  label: "-- All Schools",
  value: null,
};

export const SchoolDropdown = ({ value, onChange }: { value: Opt<SchoolCode>; onChange: (value: Opt<SchoolCode>) => void }) => {
  const allSchools = useAllSchools();
  const options: DropdownOptions<SchoolCode | null> = React.useMemo(
    () => [
      empty,
      ...allSchools.map(school => ({
        value: school.code,
        label: `${school.code} - ${school.name}`,
      })),
    ],
    [allSchools],
  );

  return <DropdownField fluid placeholder="-- All Schools --" value={value} onChange={onChange} options={options} readOnly={false} />;
};
