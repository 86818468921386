import { push } from "connected-react-router";
import { Location } from "history";
import { debounce, some } from "lodash";
import React, { ChangeEvent, ComponentType, MouseEvent } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Icon, Input, List, Segment } from "semantic-ui-react";
import styled from "styled-components";
import * as pot from "../api/pot";
import { DOWN_ARROW_KEY, ENTER_KEY, PAGE_SIZE, UP_ARROW_KEY } from "../constants";
import { Permissions } from "../model/permission";
import { SearchParams } from "../model/search";
import { Team, teamToSchoolCode } from "../model/team";
import { SavedSearch, SearchResults, SchoolCode } from "../model/types";
import { showModal } from "../reducers/modal";
import { createSavedSearch, deleteSavedSearch, fetchSavedSearches, savedSearches } from "../reducers/savedSearches";
import { withCurrentSchoolCode, withCurrentTeam } from "../reducers/teams";
import { Opt, ReactNodeLike, safeParseInt } from "../utils";
import { ShowModalFunc } from "../utils/modal";
import { formatQueryString, locationQueryParams, RelaxedQueryParams, updateLocation } from "../utils/queryParams";
import * as toast from "./toast";

export interface SearchFieldProps {
  placeholder?: string;
  savedSearches?: pot.Pot<SavedSearch[]>;
}

interface AllSearchFieldProps extends RouteComponentProps {
  currentTeam: Team;
  schoolCode: Opt<SchoolCode>;
  push: (location: Location) => void;
  fetchSavedSearches: (q: Opt<string>) => Promise<SavedSearch[]>;
  createSavedSearch: (savedSearch: SavedSearch) => Promise<SavedSearch>;
  deleteSavedSearch: (savedSearch: SavedSearch) => Promise<void>;
  showModal: ShowModalFunc;
  permissions: Permissions;
  searchBySchool: boolean;
  search: (school: Opt<SchoolCode>, params: SearchParams) => Promise<SearchResults<unknown>>;
  reset?: () => void;
  savedSearches: pot.Pot<SavedSearch[]>;
  placeholder?: string;
}

interface SearchFieldState {
  searchString: string;
  menuVisible: boolean;
  menuSelected: Opt<number>;
}

interface SearchMenuProps {
  savedSearches: SavedSearch[];
  menuSelected: Opt<number>;
  showDeleteButton: boolean;
  onSelect: (evt: MouseEvent, search: SavedSearch) => void;
  onDelete: (evt: MouseEvent, search: SavedSearch) => void;
}

const OuterMenuWrapper = styled.div`
  position: relative;
`;

const InnerMenuWrapper = styled(Segment)`
  position: absolute !important;
  width: 100% !important;
  z-index: 9999 !important;
  max-height: 80vh !important;
  overflow-y: scroll !important;
  padding: 4px !important;
`;

const DeleteButton = styled(Button)`
  padding-right: 0 !important;
  background: transparent !important;
`;

export function connectSearchField(component: ComponentType<AllSearchFieldProps>) {
  return withRouter(
    withCurrentTeam(
      withCurrentSchoolCode(
        connect(
          state => ({
            savedSearches: savedSearches(state),
          }),
          {
            push,
            showModal,
            fetchSavedSearches,
            createSavedSearch,
            deleteSavedSearch,
          },
        )(component),
      ),
    ),
  );
}

class SearchField extends React.Component<AllSearchFieldProps, SearchFieldState> {
  constructor(props: AllSearchFieldProps) {
    super(props);

    this.state = {
      searchString: locationQueryParams(props.location).q ?? "",
      menuVisible: false,
      menuSelected: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.refreshSavedSearches();
  }

  componentDidMount() {
    this.doSearch(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: AllSearchFieldProps) {
    if (nextProps.currentTeam.code !== this.props.currentTeam.code || nextProps.schoolCode !== this.props.schoolCode) {
      this.reset(() => {
        this.doSearch(nextProps);
        this.refreshSavedSearches(nextProps);
      });
    } else if (nextProps.location !== this.props.location) {
      this.doSearch(nextProps);
    }
  }

  doSearch(props: AllSearchFieldProps) {
    const { location, searchBySchool, currentTeam } = props;

    const { q, page: pageString, count: countString, sortby, sortdir } = locationQueryParams(location);

    const page = safeParseInt(pageString, 0);
    const count = safeParseInt(countString, 0);

    const pageSize = count || PAGE_SIZE;

    const school = searchBySchool ? teamToSchoolCode(currentTeam.code) : undefined;

    const query: SearchParams = {
      q,
      start: (page ? page - 1 : 0) * pageSize,
      count: pageSize,
      sortby,
      sortdir,
    };

    this.props.search(school, query);
  }

  reset = (next?: () => void) => {
    this.setState({ searchString: "" }, () => {
      this.props.push(updateLocation(this.props.location, { search: "" }));
      next?.();
    });
    this.props.reset?.();
  };

  handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.stopPropagation();
    this.internalHandleChange(evt.target.value);
  };

  internalHandleChange = (searchString: string) => {
    this.setState({ ...this.state, searchString }, this.asyncHandleSearch);
  };

  handleSelect = (evt: MouseEvent, search: SavedSearch) => {
    evt.stopPropagation();

    this.setState(
      {
        ...this.state,
        searchString: search.searchString,
        menuVisible: false,
      },
      this.handleSearch,
    );
  };

  handleSearch = () => {
    const { searchString } = this.state;
    const query = locationQueryParams(this.props.location);
    const page = searchString === query.q ? query.page : undefined;
    this.setQuery(searchString.length === 0 ? {} : { ...query, q: searchString, page });
  };

  asyncHandleSearch = debounce(this.handleSearch, 500);

  handleReset = () => {
    this.setState({ ...this.state, searchString: "" }, () => this.setQuery({}));
  };

  setQuery = (query: RelaxedQueryParams) => {
    this.props.push(updateLocation(this.props.location, { search: formatQueryString(query) }));
  };

  handleKeyUp = (evt: KeyboardEvent) => {
    switch (evt.keyCode) {
      case ENTER_KEY:
        if (this.state.menuVisible) {
          this.closeMenu(this.handleSearch);
        } else {
          this.handleSearch();
        }
        break;

      case UP_ARROW_KEY:
        evt.stopPropagation();
        this.menuUp();
        break;

      case DOWN_ARROW_KEY:
        evt.stopPropagation();
        this.menuDown();
        break;

      default:
      // Do nothing
    }
  };

  toggleMenu = () => {
    this.setState({ ...this.state, menuVisible: !this.state.menuVisible });
  };

  closeMenu = (next?: () => void) => {
    const index = this.state.menuSelected;
    const opts = pot.getOrElse(this.props.savedSearches, []);

    const searchString = index != null && opts.length > index ? opts[index].searchString : this.state.searchString;

    this.setState(
      {
        ...this.state,
        searchString,
        menuVisible: false,
        menuSelected: null,
      },
      next,
    );
  };

  menuDown = () => {
    const oldSelected: Opt<number> = this.state.menuSelected;

    const numOptions: number = pot.getOrElse(this.props.savedSearches, []).length;

    const newVisible: boolean = numOptions > 0;

    const newSelected: Opt<number> = newVisible == null ? null : oldSelected != null && oldSelected < numOptions - 1 ? oldSelected + 1 : 0;

    this.setState({
      ...this.state,
      menuVisible: newVisible,
      menuSelected: newSelected,
    });
  };

  menuUp = () => {
    const visible: boolean = this.state.menuVisible;

    const oldSelected: Opt<number> = this.state.menuSelected;

    const newSelected: Opt<number> = visible == null ? null : oldSelected != null && oldSelected > 0 ? oldSelected - 1 : null;

    const newVisible: boolean = newSelected != null;

    this.setState({
      ...this.state,
      menuVisible: newVisible,
      menuSelected: newSelected,
    });
  };

  menuSelect = (index: number) => {
    const opts = pot.getOrElse(this.props.savedSearches, []);

    if (opts.length > index) {
      this.setState(
        {
          ...this.state,
          searchString: opts[index].searchString,
          menuVisible: false,
          menuSelected: null,
        },
        this.handleSearch,
      );
    }
  };

  refreshSavedSearches = (props: AllSearchFieldProps = this.props) => {
    this.props.fetchSavedSearches(props.schoolCode);
  };

  handleSave = () => {
    this.props.showModal("input", {
      title: "Save search",
      primaryButtonText: "Save",
      onPrimaryClick: this.confirmSave,
      renderContent: (input: ReactNodeLike) => (
        <div>
          <Segment basic>Enter a title to save your search string:</Segment>

          {input}

          <Segment basic>Once saved, other people in your department will be able to select it from the drop down menu in the search bar.</Segment>
        </div>
      ),
    });
  };

  confirmSave = (title: string) => {
    this.props
      .createSavedSearch({
        id: -1,
        schoolCode: this.props.schoolCode,
        title,
        searchString: this.state.searchString,
      })
      .then(() => this.refreshSavedSearches())
      .then(() => toast.success("Search saved"));
  };

  handleDelete = (evt: MouseEvent, search: SavedSearch) => {
    evt.stopPropagation();

    this.setState({ ...this.state, menuVisible: false }, () => {
      this.props.showModal("confirm", {
        title: `Delete ${search.title}?`,
        onPrimaryClick: () => this.confirmDelete(search),
        content: <Segment basic>Are you sure you want to delete this saved search for all users in your school?</Segment>,
      });
    });
  };

  confirmDelete = (search: SavedSearch) => {
    this.props
      .deleteSavedSearch(search)
      .then(() => this.refreshSavedSearches())
      .then(() => toast.success("Search deleted"));
  };

  render() {
    const opts = pot.getOrElse(this.props.savedSearches, []);
    const { searchString, menuVisible, menuSelected } = this.state;

    const canDelete = this.props.permissions.canUpdateSavedSearches(this.props.currentTeam.code);

    if (opts.length === 0) {
      return this.renderSearchField();
    } else {
      return (
        <OuterMenuWrapper>
          {this.renderSearchField()}
          {menuVisible && searchString.length === 0 && (
            <SearchMenu
              savedSearches={opts}
              menuSelected={menuSelected}
              showDeleteButton={canDelete}
              onSelect={this.handleSelect}
              onDelete={this.handleDelete}
            />
          )}
        </OuterMenuWrapper>
      );
    }
  }

  renderSearchField = () => {
    return (
      <Input
        fluid
        action
        iconPosition="left"
        value={this.state.searchString}
        placeholder={this.props.placeholder || "Search"}
        autoComplete="off"
        onChange={this.handleChange}
        onKeyUp={this.handleKeyUp}
      >
        <Icon name="search" />
        <input />
        {this.renderSaveButton()}
        {this.renderResetButton()}
        {this.renderMenuButton()}
        <Button>Search</Button>
      </Input>
    );
  };

  renderSaveButton = () => {
    const opts = pot.getOrElse(this.props.savedSearches, []);
    const str = this.state.searchString;

    const exactMatch = some(opts, opt => opt.searchString.toLowerCase() === str.toLowerCase());

    const canSave = this.state.searchString.length > 0 && this.props.permissions.canUpdateSavedSearches(this.props.currentTeam.code);

    return canSave && !exactMatch ? <Button basic icon="save" onClick={this.handleSave} /> : null;
  };

  renderMenuButton = () => {
    const opts = pot.getOrElse(this.props.savedSearches, []);
    const str = this.state.searchString;

    return opts.length > 0 && str.length === 0 ? (
      this.state.menuVisible ? (
        <Button basic icon="caret up" onClick={this.toggleMenu} />
      ) : (
        <Button basic icon="caret down" onClick={this.toggleMenu} />
      )
    ) : null;
  };

  renderResetButton = () => {
    return this.state.searchString.length > 0 ? <Button basic icon="close" onClick={this.handleReset} /> : null;
  };
}

const SearchMenu = ({ menuSelected, onSelect, onDelete, showDeleteButton, savedSearches }: SearchMenuProps) => {
  return (
    <InnerMenuWrapper>
      <List selection>
        {savedSearches.map((option, index) => (
          <List.Item key={index} active={index === menuSelected} onClick={(evt: MouseEvent) => onSelect(evt, option)}>
            {showDeleteButton && (
              <List.Content floated="right">
                <DeleteButton icon onClick={(evt: MouseEvent) => onDelete(evt, option)}>
                  <Icon name="close" alt="Delete" />
                </DeleteButton>
              </List.Content>
            )}

            <List.Content>
              <List.Header>{option.title}</List.Header>
              <List.Description>{option.searchString}</List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    </InnerMenuWrapper>
  );
};

export default connectSearchField(SearchField);
