import * as pot from "../../api/pot";
import { SavedSearch } from "../../model/types";

export type MyState = {
  searches: pot.Pot<SavedSearch[]>;
};

export const initialState: MyState = {
  searches: pot.empty(),
};
