import { ddmm, ddmmyyyyhhmm, formatIso, formatLondon, hhmm, parseIso, parseLocal, yyyymmdd } from "@qmspringboard/shared/dist/utils/date";
import React, { useMemo } from "react";
import { Grid } from "semantic-ui-react";
import styled from "styled-components";
import { Opt } from "../../utils";
import { BaseFieldProps, SemanticInputSize } from "./base";
import { TextInput } from "./TextInput";

type DateComponents = [year: string, month: string, day: string, hour: string, minute: string];

// maybe use new Date?
const parseComponents = ([year, month, day, hour, minute]: DateComponents): Date =>
  parseLocal(`${day}/${month}/${year} ${hour}:${minute}`, ddmmyyyyhhmm);

export interface NullableDateTimeFieldProps extends BaseFieldProps<Opt<string>> {
  fluid?: boolean;
  size?: SemanticInputSize;
  tabIndex?: number;
}

const CompactGridColumn = styled(Grid.Column)`
  padding-left: 5px !important;
  padding-right: 5px !important;
`;

const CompactTextInput = styled(TextInput)`
  input {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
`;

export default function NullableDateTimeField(props: NullableDateTimeFieldProps) {
  const { value, onChange, readOnly, disabled, messages, fluid, size, tabIndex } = props;

  let formattedTime = "";
  let formattedDate = "";

  // we could probably do away with this but it makes re-creating local state easier
  // when our value has been invalidated to null. another way to do this would be
  // to handle invalid another way
  const [lastDate, setLastDate] = React.useState<string | null | undefined>(value);

  // keep the above in sync
  React.useEffect(() => {
    if (value) {
      const dt = parseIso(value);
      if (dt) {
        // we have received a new valid value from parent, make us controlled again.
        setLastDate(value);
        setLocalDateValue(null);
        setLocalTimeValue(null);
      }
    }
  }, [value]);

  if (lastDate) {
    const dt = parseIso(lastDate);
    if (!dt) {
      throw new Error("value must be ISO8601");
    }

    formattedDate = formatLondon(dt, ddmm);
    formattedTime = formatLondon(dt, hhmm);
  }

  const [date, time] = useMemo(() => {
    if (lastDate == null) {
      return [];
    } else {
      const parsed = parseIso(lastDate);
      return [formatLondon(parsed, yyyymmdd), formatLondon(parsed, hhmm)];
    }
  }, [lastDate]);

  const [localDateValue, setLocalDateValue] = React.useState<string | null>(null);
  const [localTimeValue, setLocalTimeValue] = React.useState<string | null>(null);

  const [year, month, day] = (localDateValue || date)?.split("-") || [];
  const [hour, minute] = (localTimeValue || time)?.split(":") || [];

  const handleChange = (value: DateComponents) => {
    try {
      const maybeValidDateString = formatIso(parseComponents(value));
      const valid = parseIso(maybeValidDateString);
      if (valid) {
        if (onChange) {
          setLastDate(maybeValidDateString);
          onChange(maybeValidDateString);
        }
        setLocalDateValue(null);
        setLocalTimeValue(null);
      } else {
        if (onChange) {
          onChange(null);
        }
      }
    } catch (error) {
      // If any parse errors occer, do nothing
    }
  };

  const handleDateChange = (value: string) => {
    setLocalDateValue(value);
    const match = value.match(/^(\d?\d)\/(\d?\d)$/);
    const year = new Date().getFullYear();
    if (match) {
      handleChange([String(year), match[2], match[1], hour, minute]);
    } else {
      if (onChange) {
        //set invalid
        onChange(null);
      }
    }
  };

  const handleTimeChange = (value: string) => {
    setLocalTimeValue(value);
    const match = value.match(/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/);
    if (match) {
      handleChange([year, month, day, match[1], match[2]]);
    } else {
      if (onChange) {
        //set invalid
        onChange(null);
      }
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <CompactGridColumn width={8}>
          <CompactTextInput
            value={localDateValue || formattedDate}
            onChange={handleDateChange}
            placeholder="dd/mm"
            readOnly={readOnly}
            disabled={disabled}
            messages={messages}
            localError="Expected dd/mm"
            fluid={fluid}
            size={size}
            tabIndex={tabIndex}
          />
        </CompactGridColumn>
        <CompactGridColumn width={8}>
          <CompactTextInput
            value={localTimeValue || formattedTime}
            onChange={handleTimeChange}
            placeholder="hh:mm"
            readOnly={readOnly}
            disabled={disabled}
            localError="Expected hh:mm"
            fluid={fluid}
            size={size}
            tabIndex={tabIndex}
          />
        </CompactGridColumn>
      </Grid.Row>
    </Grid>
  );
}
