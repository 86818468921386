import { Segment, Header, Icon, Table } from "semantic-ui-react";
import React from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { Messages } from "../model/errors";
import ErrorDisplay from "./ErrorDisplay";

function FileUploadZone({
  requiredFields,
  value,
  messages,
  onDrop,
}: // onClear,
{
  requiredFields?: string[];
  value?: File[];
  onDrop: DropzoneOptions["onDrop"];
  onClear: () => void;
  messages: Messages;
}) {
  const { getRootProps, getInputProps, isDragAccept, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <ErrorDisplay messages={messages} />
      <Segment placeholder textAlign="center" inverted={isDragActive} color={isDragAccept ? "green" : isDragActive ? "red" : undefined}>
        <Header icon>
          <Icon name="file excel outline" />
          Drag CSV here or click to {value ? "replace" : "add"}.
        </Header>

        {requiredFields ? <span>Each row should contain {requiredFields.join(", ")}</span> : null}
        {value && value.length > 0 && (
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>File Name</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {value.map(file => (
                <Table.Row key={file.name}>
                  <Table.Cell>{file.name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Segment>
    </div>
  );
}
export default FileUploadZone;
