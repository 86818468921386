import { combineReducers, Reducer } from "redux";
import { AppAction } from "../actions";
import teams from "../teams";
import { initialState, MyState } from "./state";

const userReducer: Reducer<MyState, AppAction> = combineReducers({
  teams,
});

export default (state: MyState = initialState, action: AppAction) => {
  switch (action.type) {
    case "auth/LOGOUT":
      return userReducer(undefined, action);

    default:
      return userReducer(state, action);
  }
};
