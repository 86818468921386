import lodash from "lodash";
import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { Dropdown } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";
import useLabelPrototypes from "../hooks/useLabelPrototypes";
import { isAdmissionsTeamCode, schoolToTeamCode, Team } from "../model/team";
import { LabelPrototype } from "../model/types";
import { useCurrentTeam } from "../reducers/teams";
import { formatQueryString } from "../utils/queryParams";

function labelIcon(proto: LabelPrototype, done?: boolean): SemanticICONS {
  switch (proto.labelType) {
    case "std":
      return "tag";
    case "fao":
      return "users";
    case "todo":
      return done ? "check square" : "square";
  }
}

function labelText(proto: LabelPrototype, done?: boolean): string {
  switch (proto.labelType) {
    case "std":
      return proto.text;
    case "fao":
      return "FAO: " + proto.text;
    case "todo":
      return done ? `Done: ${proto.text}` : `Todo: ${proto.text}`;
  }
}

interface LabelDropdownItemProps {
  proto: LabelPrototype;
  done?: boolean;
  onClick: (proto: LabelPrototype, done?: boolean) => void;
}

function LabelDropdowItem(props: LabelDropdownItemProps) {
  const { proto, done, onClick } = props;

  return (
    <Dropdown.Item
      color="red"
      onClick={() => onClick(proto, done)}
      key={proto.id}
      text={labelText(proto, done)}
      icon={labelIcon(proto, done)}
      label={{
        empty: true,
        circular: true,
        style: { backgroundColor: proto.color },
      }}
      value={`${proto.labelType}:"${proto.text}"`}
    />
  );
}

interface LabelDropdownMenuProps {
  protos: LabelPrototype[];
  onClick: (proto: LabelPrototype, done?: boolean) => void;
}

function LabelDropdownMenu(props: LabelDropdownMenuProps) {
  const { protos, onClick } = props;

  if (protos.length === 0) {
    return null;
  } else {
    return (
      <>
        {lodash.flatMap(protos, proto =>
          proto.labelType === "todo" ? (
            <React.Fragment key={proto.id}>
              <LabelDropdowItem proto={proto} onClick={onClick} done={false} />
              <LabelDropdowItem proto={proto} onClick={onClick} done={true} />
            </React.Fragment>
          ) : (
            <LabelDropdowItem key={proto.id} proto={proto} onClick={onClick} />
          ),
        )}
      </>
    );
  }
}

function quoteTerm(term: string): string {
  return `"${term.replace(/"/g, `\\"`).replace(/'/g, `\\'`).replace(/\\/g, `\\\\`)}"`;
}

const LabelDropdown = () => {
  const currentTeam: Team = useCurrentTeam();
  const isAdmissions = useMemo(() => isAdmissionsTeamCode(currentTeam.code), [currentTeam.code]);

  const [allOptions, { status }] = useLabelPrototypes();

  const admissionsOptions = useMemo(
    () =>
      lodash
        .chain(allOptions?.items ?? [])
        .filter(item => item.schoolCode == null)
        .sortBy(item => item.labelType)
        .value(),
    [allOptions],
  );

  const schoolOptions = useMemo(
    () =>
      isAdmissions
        ? []
        : lodash
            .chain(allOptions?.items ?? [])
            .filter(item => schoolToTeamCode(item.schoolCode) === currentTeam.code)
            .sortBy(item => item.labelType)
            .value(),
    [allOptions, currentTeam, isAdmissions],
  );

  const location = useLocation();

  const handleClick = (proto: LabelPrototype, done?: boolean) => {
    // Create a search term of the form "team:labelType:term":
    const q = [
      schoolToTeamCode(proto.schoolCode),
      proto.labelType === "todo" ? (done ? "done" : "todo") : proto.labelType,
      quoteTerm(proto.text),
    ].join(":");

    // force a page reload so search remounts as it doesn't notice external querystring updates
    window.location.href = location.pathname + formatQueryString({ q });
  };

  return (
    <Dropdown text="Search by label" fluid button icon={null} loading={status === "pending"} style={{ textAlign: "center" }}>
      <Dropdown.Menu>
        <Dropdown.Menu scrolling>
          {schoolOptions.length > 0 && (
            <>
              <Dropdown.Header>{currentTeam.code} Labels</Dropdown.Header>
              <Dropdown.Divider />
            </>
          )}
          <LabelDropdownMenu protos={schoolOptions} onClick={handleClick} />
          {schoolOptions.length > 0 && admissionsOptions.length > 0 && <Dropdown.Divider />}
          {admissionsOptions.length > 0 && (
            <>
              <Dropdown.Header>Admissions Labels</Dropdown.Header>
              <Dropdown.Divider />
            </>
          )}
          <LabelDropdownMenu protos={admissionsOptions} onClick={handleClick} />
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LabelDropdown;
