import type { Theme, ThemeUIStyleObject } from "@qmspringboard/shared/dist/ui";

// see
// https://github.com/Semantic-Org/Semantic-UI/blob/master/src/themes/default/globals/site.variables

const colors = {
  black: "#1B1C1D",
  white: "#F9FAFB",
  red: "#DB2828",
  orange: "#F2711C",
  yellow: "#FBBD08",
  olive: "#B5CC18",
  green: "#21BA45",
  teal: "#00B5AD",
  blue: "#2185D0",
  violet: "#6435C9",
  purple: "#A333C8",
  pink: "#E03997",
  brown: "#A5673F",
  grey: "#767676",
  lightGrey: "#babbbc",
  negativeColor: "#DB2828",
  primary: "#2185D0",
  secondary: "#1B1C1D",
  focussedFormBorder: "#85B7D9",
  unfocussedFormBorder: "rgba(34,36,38,.15)",
  warning: "#cea600",
  error: "#DB2828",
};

const disabled = {
  backgroundColor: colors.grey,
  color: colors.white,
  cursor: "not-allowed",
};

const makeTheme = <T extends Theme>(t: T) => t;

const errorContainer: ThemeUIStyleObject = {
  display: "block",
  borderRadius: 1,
  fontWeight: "heading",
  fontFamily: "body",
  fontSize: 0,
  lineHeight: "2em",
  position: "relative",
  color: colors.white,
  px: 1,
  marginTop: "5px",
  backgroundColor: colors.error,
  ":before": {
    bottom: "100%",
    left: "40px",
    border: "solid transparent",
    content: "''",
    height: "0",
    width: "0",
    position: "absolute",
    pointerEvents: "none",
    borderColor: "rgba(136, 183, 213, 0)",
    borderBottomColor: colors.error,
    borderWidth: "5px",
  },
};

const theme = makeTheme({
  colors,
  radii: [0, ".28571429rem", 8],
  space: [0, ".67857143em", "1em"],
  fonts: {
    body: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
    heading: "Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: [".85714286rem", "1em"],
  fontWeights: {
    heading: "bold",
  },
  text: {
    heading1: {
      fontSize: 3,
    },
    heading2: {
      fontSize: 2,
    },
    error: errorContainer,
    warning: {
      ...errorContainer,
      color: colors.white,
      backgroundColor: colors.warning,
      ":before": {
        ...errorContainer[":before"],
        borderBottomColor: colors.warning,
      },
    },
  },
  forms: {
    checkbox: {
      size: "22px",
      mr: "0.3em",
      backgroundColor: "transparent",
      "input:checked ~ &": {
        color: "secondary",
      },
      "input:disabled ~ &": {
        fill: colors.grey,
        opacity: 0.3,
        cursor: "not-allowed",
      },
    },
    select: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      borderColor: colors.unfocussedFormBorder,
      lineHeight: "1.21428571em",
      fontFamily: "body",
      fontSize: 1,
      px: 2,
      py: 1,
      fill: colors.primary,
      ":disabled": {
        cursor: "not-allowed",
        borderColor: colors.grey,
        // FIXME: we are using important here because semantic-ui css has a higher specificity.
        // We can probably make this higher specificity by prefixing an id before all emotion styles
        // and adding it to the root
        opacity: "0.45 !important",
        fill: colors.grey,
      },
      ":disabled + svg": {
        display: "none",
      },
      ":focus": {
        borderColor: colors.focussedFormBorder,
      },
    },
    input: {
      flex: 1,
      fontSize: 1,
      fontFamily: "body",
      borderColor: colors.unfocussedFormBorder,
      lineHeight: "1.21428571em",
      px: 2,
      py: 1,
      ":disabled": {
        cursor: "not-allowed",
        borderColor: colors.grey,
        opacity: "0.45 !important",
        fill: colors.grey,
      },
      ":focus": {
        outline: "none",
        borderColor: colors.focussedFormBorder,
      },
    },
  },
  buttons: {
    primary: {
      color: colors.white,
      background: colors.primary,
      ":disabled": disabled,
    },
  },
  root: {
    // uses the theme values provided above
    fontFamily: "body",
  },
});
export type ExactTheme = typeof theme;
export default theme;
