import React, { SyntheticEvent } from "react";
import { connectModal } from "redux-modal";
import { Button, Header, Modal } from "semantic-ui-react";
import useKey from "use-key-hook";
import { ReactNodeLike } from "../utils";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  primaryButtonText?: ReactNodeLike;
  onPrimaryClick: (e: SyntheticEvent) => void;
  secondaryButtonText?: ReactNodeLike;
  onSecondaryClick?: (e: SyntheticEvent) => void;
  tertiaryButtonText?: ReactNodeLike;
  onTertiaryClick?: (e: SyntheticEvent) => void;
  title?: string;
  content: ReactNodeLike;
}

function ModalConfirm({
  show,
  title,
  primaryButtonText,
  handleHide,
  onPrimaryClick,
  onSecondaryClick,
  secondaryButtonText,
  onTertiaryClick,
  tertiaryButtonText,
  content,
}: Props) {
  const handlePrimaryClick = (e: SyntheticEvent) => {
    handleHide();
    onPrimaryClick(e);
  };

  const handleSecondaryClick = (e: SyntheticEvent) => {
    handleHide();
    onSecondaryClick && onSecondaryClick(e);
  };

  const handleTertiaryClick = (e: SyntheticEvent) => {
    handleHide();
    onTertiaryClick && onTertiaryClick(e);
  };

  useKey(
    (_pressedKey: string | number, e: SyntheticEvent) => {
      handlePrimaryClick(e);
    },
    {
      detectKeys: [13],
    },
  );

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Header icon="info circle" content={title || "Confirm"} />
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleSecondaryClick}>{secondaryButtonText || "Cancel"}</Button>
        {tertiaryButtonText && onTertiaryClick && <Button onClick={handleTertiaryClick}>{tertiaryButtonText}</Button>}
        <Button primary onClick={handlePrimaryClick}>
          {primaryButtonText || "Confirm"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default connectModal({ name: "confirm" })(ModalConfirm);
