import { SearchResults } from "./types";
import { Opt } from "../utils";

export interface SearchParams {
  q?: Opt<string>;
  start?: number;
  count?: Opt<number>;
  sortby?: Opt<string>;
  sortdir?: Opt<string>;
}

export interface SearchParamsWithSchool extends SearchParams {
  school: Opt<string>;
}

export function searchResults<A>(items: A[], start: number = 0, total: number = items.length): SearchResults<A> {
  return { items, start, total };
}

export function emptySearchResults<A>(): SearchResults<A> {
  return {
    items: [],
    start: 0,
    total: 0,
  };
}

export function mapSearchResults<A, B>(results: SearchResults<A>, func: (a: A) => B): SearchResults<B> {
  return {
    items: results.items.map(func),
    start: results.start,
    total: results.total,
  };
}
