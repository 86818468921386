import * as pot from "../../api/pot";
import { Messages } from "../../model/errors";

export type SchoolConfig = Record<string, unknown>;

export const initialSaveState = {
  saving: false,
  saved: false,
  messages: [] as Messages,
};

export type SaveState = typeof initialSaveState;

export type MyState = {
  resource: pot.Pot<SchoolConfig>;
  dirty: SchoolConfig;
  save: SaveState;
};

export const initialState: MyState = {
  resource: pot.empty(),
  dirty: {},
  save: initialSaveState,
};
