import React, { ComponentType, ReactElement } from "react";
import { Label } from "semantic-ui-react";
import styled from "styled-components";
import { Messages } from "../model/errors";
import { ReactNodeLike } from "../utils";
import { WithModalProps, withModal } from "../utils/modal";

interface Props {
  messages: Messages;
  attached?: boolean;
  children?: ReactNodeLike;
}

interface ErrorProps {
  error?: boolean;
  title?: string;
  content: string | ReactElement;
  pointing?: boolean | "above" | "left" | "right" | "below";
}

export interface ErrorDisplayProps {
  messages: Messages;
  attached?: boolean;
  pointing?: boolean | "above" | "left" | "right" | "below";
}

const Standalone = ({ error, content }: ErrorProps) => (
  <Label icon="warning sign" color={error ? "red" : "orange"} content={content} style={{ marginBottom: ".25em" }} />
);

const Attached = ({ error, content, ...props }: ErrorProps) => (
  <Label
    pointing={props.pointing || "above"}
    icon="warning sign"
    color={error ? "red" : "orange"}
    content={content}
    style={{ marginBottom: ".25em" }}
  />
);

export default function ErrorDisplay({ messages, attached, ...props }: ErrorDisplayProps): JSX.Element {
  if (!messages || messages.length === 0) {
    return <></>;
  }

  const warnings = messages.filter(e => e.level === "warning");
  const errors = messages.filter(e => e.level === "error");
  const Component = attached ? Attached : Standalone;

  return (
    <span>
      {errors.map((e, i) => (
        <Component {...props} key={i} title={e.path.join(".")} error content={e.text} />
      ))}
      {warnings.map((e, i) => (
        <Component {...props} title={e.path.join(".")} key={i} content={e.text} />
      ))}
    </span>
  );
}

export function withErrorDisplay(ComposedComponent: ComponentType) {
  type WithErrorDisplayProps = {
    messages: Messages;
  };

  return function WithErrorDisplay<A extends WithErrorDisplayProps>({ messages, ...props }: A) {
    return (
      <div>
        <ErrorDisplay messages={messages} />
        <ComposedComponent {...props} />
      </div>
    );
  };
}

const ClickableDiv = styled.div`
  cursor: help;
  position: relative;
`;

const Warning = styled(Label)`
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
`;

const ToggleableMessagesDisplay: React.ComponentType<Props> = withModal(
  class ToggleableMessagesDisplayInner extends React.Component<Props & WithModalProps> {
    errors = () => {
      return this.props.messages.filter(e => e.level === "error");
    };

    showDetail = () => {
      const errors = this.errors();

      if (errors && errors.length > 0) {
        this.props.showModal("alert", {
          title: "You need to resolve some errors on the form...",
          content: <ErrorDisplay messages={errors} />,
        });
      }
    };

    render() {
      const { children } = this.props;

      const errors = this.errors();

      return (
        <ClickableDiv onClick={this.showDetail} style={{ display: "inline-block" }}>
          {children}
          {errors && errors.length > 0 && (
            <Warning color="red" size="mini" onClick={this.showDetail}>
              {errors.length} errors
            </Warning>
          )}
        </ClickableDiv>
      );
    }
  },
);

export { ToggleableMessagesDisplay };
