import React, { Component, ReactElement } from "react";
import { Button, Dimmer, Loader } from "semantic-ui-react";
import styled from "styled-components";
import { Message as MessageType } from "../model/types";
import { ShowModalFunc, withModal } from "../utils/modal";
import { ToggleableMessagesDisplay } from "./ErrorDisplay";

interface Props {
  editing: boolean;
  anythingEditing: boolean;
  saving: boolean;
  readOnly: boolean;
  isDirty: () => boolean;
  messages?: MessageType[];
  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;
  children: ReactElement;
  showModal: ShowModalFunc;
  extraControls?: ReactElement;
  style?: React.CSSProperties;
}

const EditableSegment = styled.div<{ editing?: boolean }>`
  position: relative;
  border-radius: 0.28rem;
  border-color: ${props => (props.editing ? "#1578CD" : "rgba(34,36,38,.15)")};
  border-style: solid;
  border-width: 1px !important;
  padding: 15px;
  background-color: ${props => (props.editing ? "#EAF5FF" : "transparent")};
  margin-bottom: 1em;
`;

const Protected = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const FloatingButtons = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: right;
  pointer-events: none;

  button {
    margin-right: 0 !important;
    margin-left: 0.25em !important;
  }

  > * {
    pointer-events: all;
  }

  @media only screen and (max-width: 1199px) {
    position: relative;
    padding-bottom: 1em;
    min-height: 4em;
  }
`;

export default withModal(
  class EditInPlaceForm extends Component<Props> {
    handleClick = () => {
      if (this.props.readOnly) {
        return;
      }

      if (this.props.anythingEditing && !this.props.editing) {
        this.props.showModal("alert", {
          title: "Already Editing",
          content: "You are editing another part of the form, Cancel or save those changes first before trying to edit this",
        });
      } else {
        !this.props.editing && this.props.onEdit();
      }
    };

    handleCancelClick = () => {
      this.props.isDirty()
        ? this.props.showModal("confirm", {
            onPrimaryClick: this.props.onCancel,
            content: "You have unsaved changes, cancelling will remove them. Are you sure?",
          })
        : this.props.onCancel();
    };

    handleSaveClick = () => {
      this.props.onSave();
    };

    render() {
      // Edit can be a controlled property or can use internal state
      /* const editing = (typeof this.props.editing === 'undefined') ? this.state.editing : this.props.editing;*/
      const { editing, anythingEditing, messages, saving, readOnly, children, extraControls, style = {} } = this.props;

      const errors = messages ? messages.filter(msg => msg.level === "error") : [];

      return (
        <EditableSegment editing={editing} style={style}>
          <Dimmer.Dimmable dimmed={saving}>
            <Dimmer active={saving} inverted>
              {saving && <Loader>Saving</Loader>}
            </Dimmer>

            {!readOnly && (
              <FloatingButtons>
                {!editing && extraControls}
                {!editing && <Button icon="write" disabled={anythingEditing} onClick={this.handleClick} content="Edit" />}
                {editing && <Button onClick={this.handleCancelClick}>Cancel</Button>}
                {editing && (
                  <ToggleableMessagesDisplay messages={errors}>
                    <Button primary onClick={this.handleSaveClick} disabled={errors && errors.length > 0}>
                      Save
                    </Button>
                  </ToggleableMessagesDisplay>
                )}
              </FloatingButtons>
            )}

            {children}

            {!editing && anythingEditing && <Protected />}
          </Dimmer.Dimmable>
        </EditableSegment>
      );
    }
  },
);
