import { map } from "lodash";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import ultimatePagination, { ITEM_TYPES } from "ultimate-pagination";
import * as pot from "../api/pot";
import { SearchResults } from "../model/types";
import { locationQueryParams, formatQueryString, updateLocation } from "../utils/queryParams";
import { Location } from "history";

interface PagerProps<A> {
  pageSize: number;
  results: pot.Pot<SearchResults<A>>;
}

export default function Pager<A>(props: PagerProps<A>) {
  const { results: resultsPot, pageSize } = props;

  const results = pot.get(resultsPot);

  const location = useLocation();

  if (results) {
    const { start, total } = results;

    if (total > pageSize) {
      const totalPages = Math.ceil(total / pageSize);

      const currentPage = 1 + Math.max(0, Math.min(totalPages - 1, Math.floor(start / pageSize)));

      const config = { currentPage, totalPages };

      const model = ultimatePagination.getPaginationModel(config);

      const pageLoc = (page: number): Location =>
        updateLocation(location, {
          search: formatQueryString({ ...locationQueryParams(location), page }),
        });

      const pages = map(model, ({ type, key, value, isActive }) => {
        switch (type) {
          case ITEM_TYPES["PAGE"]:
            return (
              <Menu.Item key={key} as={Link} to={pageLoc(value)} active={isActive}>
                {value}
              </Menu.Item>
            );

          case ITEM_TYPES["ELLIPSIS"]:
            return <Menu.Item key={key}>...</Menu.Item>;

          case ITEM_TYPES["PREVIOUS_PAGE_LINK"]:
            return (
              <Menu.Item key={key} as={Link} to={pageLoc(value)}>
                <Icon name="angle left" />
              </Menu.Item>
            );

          case ITEM_TYPES["FIRST_PAGE_LINK"]:
            return (
              <Menu.Item key={key} as={Link} to={pageLoc(value)}>
                <Icon name="angle double left" />
              </Menu.Item>
            );

          case ITEM_TYPES["NEXT_PAGE_LINK"]:
            return (
              <Menu.Item key={key} as={Link} to={pageLoc(value)}>
                <Icon name="angle right" />
              </Menu.Item>
            );

          case ITEM_TYPES["LAST_PAGE_LINK"]:
            return (
              <Menu.Item key={key} as={Link} to={pageLoc(value)}>
                <Icon name="angle double right" />
              </Menu.Item>
            );

          default:
            return null;
        }
      });

      return (
        <Menu floated="right" pagination>
          {pages}
        </Menu>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
}
