import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import React, { Attributes, ReactElement, ReactNode } from "react";
import { Table } from "semantic-ui-react";
import { columnGroups } from "./column";
import { COLUMN_GROUP_BORDER } from "./HeaderRows";
import { formatPercentage } from "./row";
import { QueryStringState, Row } from "./type";

// HACK: The `extends Attributes` clause below defines the `key` prop.
//
// We normally don't need to explicitly define this
// because JSX implicitly assumes it's always there.
// However...
//
// For performance reasons in `PredictionsPage`,
// we're calling `DataRow` like a function rather than like a React component:
//
//     DataRow({ ... })
//
// instead of
//
//     <DataRow ... >
//
// This requires us to explicitly define the Typescript type of the key.
export interface DataRowProps extends Attributes {
  as: string;
  qss: QueryStringState;
  row: Row;
}

export default function DataRow(props: DataRowProps): ReactElement | null {
  const { key, as, qss, row } = props;

  return (
    <Table.Row key={key}>
      {columnGroups.map((group, index) => {
        const show = group.show?.(qss) ?? true;

        if (show) {
          return (
            <React.Fragment key={index}>
              {group.columns.map((col, index) => {
                const show = col.show?.(qss) ?? true;

                if (show) {
                  let body: ReactNode;
                  switch (col.type) {
                    case "CustomColumn":
                      body = col.body(row, qss);
                      break;

                    case "NumericColumn":
                      body = col.percentage ? formatPercentage(col.body(row, qss)) : col.body(row, qss);
                      break;

                    default:
                      body = checkExhausted(col);
                      break;
                  }

                  const style = {
                    borderLeftWidth: index === 0 ? COLUMN_GROUP_BORDER : undefined,
                  };

                  return (
                    <Table.Cell key={index} as={as} textAlign={col.align ?? "center"} verticalAlign="middle" style={style}>
                      {body}
                    </Table.Cell>
                  );
                } else {
                  return null;
                }
              })}
            </React.Fragment>
          );
        } else {
          return null;
        }
      })}
    </Table.Row>
  );
}
