import React, { Ref } from "react";
import { Input as SemanticInput, InputProps } from "semantic-ui-react";
import ErrorDisplay from "../ErrorDisplay";
import { BaseFieldProps, MappedFieldProps, SemanticPointing } from "./base";
import useInputDebounce from "./useInputDebounce";

export type ExtraInputProps<I = SemanticInput> = {
  forwardRef?: Ref<I>;
  // Settings for debouncing keypresses (defaults to ):
  debounce?: number;
  // Error message to display if the local value is invalid:
  localError?: string;
  // Where to display the pointer on the error message speech bubble:
  errorPointing?: SemanticPointing;
  className?: string;
  updateWhenFocused_EXPERIMENTAL?: boolean;
} & Partial<
  Pick<
    InputProps,
    | "labelPosition"
    | "size"
    | "action"
    | "icon"
    | "label"
    | "tabIndex"
    | "maxLength"
    | "fluid"
    | "placeholder"
    | "type"
    | "onFocus"
    | "onBlur"
    | "onKeyUp"
    | "onKeyDown"
    | "onKeyPress"
    | "onFocus"
  >
>;

export interface BaseInputProps<A> extends BaseFieldProps<A>, MappedFieldProps<A>, ExtraInputProps {}

export function BaseInput<A>(props: BaseInputProps<A>) {
  const {
    value,
    format,
    id,
    validate,
    onChange,
    readOnly,
    disabled,
    debounce,
    messages,
    // ExtraInputProps:
    forwardRef,
    onFocus,
    onBlur,
    onKeyUp,
    onKeyDown,
    onKeyPress,
    type,
    placeholder,
    localError,
    errorPointing,
    label,
    labelPosition,
    size,
    icon,
    action,
    fluid,
    maxLength,
    tabIndex,
    className,
    updateWhenFocused_EXPERIMENTAL,
  } = props;

  const { textValue, handleFocus, handleBlur, handleChange, localMessages } = useInputDebounce({
    value,
    format,
    validate,
    onChange,
    onFocus,
    onBlur,
    debounce,
    messages,
    localError,
    updateWhenFocused_EXPERIMENTAL,
  });

  return (
    <>
      <SemanticInput
        id={id}
        value={textValue}
        onChange={(_, data) => handleChange(data.value)}
        disabled={readOnly || disabled}
        // ExtraInputProps:
        ref={forwardRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        label={label}
        labelPosition={labelPosition}
        type={type}
        size={size}
        icon={icon}
        action={action}
        fluid={fluid}
        maxLength={maxLength}
        tabIndex={tabIndex}
        className={className}
      />
      {!readOnly && <ErrorDisplay pointing={errorPointing} attached messages={localMessages} />}
    </>
  );
}
