import React from "react";
import Title from "../components/Title";
import { Container, List, Header, Button } from "semantic-ui-react";
import { useAnnouncements, useAcknowledgeAnnouncement } from "../api";
import ReactQueryContainer from "../components/ReactQueryContainer";
import DateTime from "../components/DateTime";

export function AnnouncementList() {
  const announcements = useAnnouncements(false);
  const { mutate: setAcknowledgedFromId } = useAcknowledgeAnnouncement();
  const handleAcknowledge = React.useCallback(() => {
    if (announcements.status === "success") {
      const maxSeenId = announcements.data.items.reduce((acc, a) => (a.id > acc ? a.id : acc), 0);
      setAcknowledgedFromId(maxSeenId);
    }
  }, [announcements, setAcknowledgedFromId]);

  const unread = announcements.data?.items.filter(f => !f.acknowledged) || [];

  return (
    <>
      <Button disabled={unread.length === 0} floated="right" onClick={handleAcknowledge}>
        Mark all as read
      </Button>
      <Header as="h2" content="Announcements" />

      <ReactQueryContainer query={announcements}>
        {result => {
          if (result.total === 0) {
            return <div>No announcments</div>;
          }
          return (
            <List divided relaxed>
              {result.items.map(announcement => (
                <List.Item key={announcement.id}>
                  <List.Content floated="right">
                    <List.Description>{announcement.authorName}</List.Description>
                    <List.Description>
                      <DateTime iso={announcement.timestamp} />
                    </List.Description>
                  </List.Content>
                  <List.Icon name="circle" color={announcement.acknowledged ? "grey" : "blue"} size="small" verticalAlign="middle" />
                  <List.Content>
                    <List.Header>{announcement.headline}</List.Header>
                    <List.Description>{announcement.detail}</List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          );
        }}
      </ReactQueryContainer>
    </>
  );
}

function AnnouncementsPage() {
  return (
    <Title title="Announcements">
      <Container>
        <AnnouncementList />
      </Container>
    </Title>
  );
}

export default AnnouncementsPage;
