import { notify } from "react-notify-toast";
import "./toast.css";

type ToastType = "success" | "warning" | "error";

const defaultTimeout = 3000;

type Options = {
  type?: ToastType;
  timeout?: number;
};

const styles = {
  success: { background: "#21ba45", text: "#fff", borderRadius: 3 },
  warning: { background: "#f2711c", text: "#fff", borderRadius: 3 },
  error: { background: "#cc181e", text: "#fff", borderRadius: 3 },
};

export function show(text: string, options: Options = {}) {
  const type = options.type || "success";
  const timeout = options.timeout || defaultTimeout;
  const style = styles[type];
  notify.show(text, "custom", timeout, style);
}

export const success = (text: string, timeout?: number) => show(text, { type: "success", timeout });

export const warning = (text: string, timeout?: number) => show(text, { type: "warning", timeout });

export const error = (text: string, timeout?: number) => show(text, { type: "error", timeout });
