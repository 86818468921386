import { isAfter } from "date-fns";
import { flatten, get, indexOf, isEqual, isUndefined } from "lodash";
import { parseIso } from "@qmspringboard/shared/dist/utils/date";
import { Enum } from "./enums";
import { Opt } from "../utils";
import { Message } from "./types";

export type Level = "error" | "warning";
export type Rule<A> = (a: A) => Array<Message>;
export type PathPrefix = string | number | (string | number)[];

export function prefixToPath(prefix?: PathPrefix) {
  if (prefix == null) {
    return [];
  } else if (typeof prefix === "string" || typeof prefix === "number") {
    return [prefix];
  } else {
    return prefix;
  }
}

export function error(text: string, prefix?: PathPrefix): Message[] {
  return [{ level: "error", text, path: prefixToPath(prefix) }];
}

export function warn(text: string, prefix?: PathPrefix): Message[] {
  return [{ level: "warning", text, path: prefixToPath(prefix) }];
}

export function pass<A>(): Rule<A> {
  return (_actual: A) => [];
}

export function fail<A>(messages: Message[]): Rule<A> {
  return (_actual: A) => messages;
}

export function test<A>(func: (a: A) => boolean, messages: Message[]): Rule<A> {
  return (actual: A) => (func(actual) ? [] : messages);
}

export function required<A>(messages: Message[]): Rule<Opt<A>> {
  return (actual: Opt<A>) => (actual == null ? messages : []);
}

export function optional<A>(rule: Rule<A>): Rule<Opt<A>> {
  return (actual: Opt<A>) => (actual == null ? [] : rule(actual));
}

export function nonEmpty(messages: Message[]): Rule<Opt<string>> {
  return (actual: Opt<string>) => (actual && actual.length > 0 ? [] : messages);
}

export function whenEmpty(rule: Rule<unknown>): Rule<Opt<string>> {
  return (actual: Opt<string>) => (actual == null || actual === "" ? rule(actual) : []);
}

export function whenNonEmpty<A extends string>(rule: Rule<A>): Rule<Opt<A>> {
  return (actual: Opt<A>) => (actual == null || actual === "" ? [] : rule(actual));
}

export function nonNegative(messages: Message[]): Rule<number> {
  return (actual: number) => (actual >= 0 ? [] : messages);
}

export function oneOf<A>(expected: A[], messages: Message[]): Rule<A> {
  return (actual: A) => (indexOf(expected, actual) >= 0 ? [] : messages);
}

export function notOneOf<A>(expected: A[], messages: Message[]): Rule<A> {
  return (actual: A) => (indexOf(expected, actual) < 0 ? [] : messages);
}

export function eq<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (actual === expected ? [] : messages);
}

export function ne<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (actual !== expected ? [] : messages);
}

export function deepEqual<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (isEqual(actual, expected) ? [] : messages);
}

export function notDeepEqual<A>(expected: A, messages: Message[]): Rule<A> {
  return (actual: A) => (!isEqual(actual, expected) ? [] : messages);
}

export function gt(expected: number, messages: Message[] = error(`Value must be greater than ${expected}.`)): Rule<number> {
  return (actual: number) => (actual > expected ? [] : messages);
}

export function gte(expected: number, messages: Message[] = error(`Value must be ${expected} or greater.`)): Rule<number> {
  return (actual: number) => (actual >= expected ? [] : messages);
}

export function lt(expected: number, messages: Message[] = error(`Value must be less than ${expected}.`)): Rule<number> {
  return (actual: number) => (actual < expected ? [] : messages);
}

export function lte(expected: number, messages: Message[] = error(`Value must be ${expected} or less.`)): Rule<number> {
  return (actual: number) => (actual <= expected ? [] : messages);
}

export function inclusiveRange(min: number, max: number, messages: Message[]): Rule<number> {
  return (actual: number) => (actual >= min && actual <= max ? [] : messages);
}

export function enumValue<A extends string>(enumeration: Enum<A>, messages: Message[]): Rule<string> {
  return (actual: string) => (enumeration.isValue(actual) ? [] : messages);
}

export function regex(regex: RegExp, messages: Message[]): Rule<string> {
  return (actual: string) => (regex.test(actual) ? [] : messages);
}

export function lengthLte(maxLength: number, messages: Message[]): Rule<string> {
  return (actual: string) => (actual.length <= maxLength ? [] : messages);
}

export function dateAfter(expected: Date, messages: Message[]): Rule<string> {
  return (actual: string) => (isAfter(parseIso(actual), expected) ? [] : messages);
}

export function prefix<A>(rule: Rule<A>, prefix: PathPrefix): Rule<A> {
  return (actual: A) =>
    rule(actual).map(msg => {
      const path = typeof prefix === "string" || typeof prefix === "number" ? [prefix, ...msg.path] : prefix.concat(msg.path);

      switch (msg.level) {
        case "error":
          return { level: "error", text: msg.text, path };
        case "warning":
          return { level: "warning", text: msg.text, path };
        default:
          return { level: "error", text: msg.text, path };
      }
    });
}

export function array<A>(rule: Rule<A>): Rule<A[]> {
  return (actual: A[]) => flatten(actual.map((item, index) => prefix(rule, index)(item)));
}

export function and<A>(...rules: Rule<A>[]): Rule<A> {
  return (actual: A) => flatten(rules.map(rule => rule(actual)));
}

export function or<A>(...rules: Rule<A>[]): Rule<A> {
  return (actual: A) =>
    rules.reduce<Message[]>((msgs, rule) => {
      return msgs.length > 0 ? msgs : rule(actual);
    }, []);
}

function keyToPath(prefix: PathPrefix) {
  if (typeof prefix === "string" || typeof prefix === "number") {
    return [String(prefix)];
  } else {
    return prefix.map(String);
  }
}

export class RuleBuilder<A> {
  rule: Rule<A>;

  constructor(rule: Rule<A> = pass()) {
    this.rule = rule;
  }

  field<B>(key: PathPrefix, rule: Rule<B>): RuleBuilder<A> {
    const prefixed = prefix(rule, key);
    return new RuleBuilder((actual: A) => {
      const field = get(actual, keyToPath(key));
      if (isUndefined(field)) {
        return error(`Property not found: ${JSON.stringify(key)}`, key);
      } else {
        const a = this.rule(actual);
        const b = prefixed(field);
        return a.concat(b);
      }
    });
  }

  /** run rules when we pass the test */
  union<B>(narrowFunc: (union: A) => boolean, rule: Rule<B>): RuleBuilder<A> {
    return new RuleBuilder((actual: A) => {
      const a = this.rule(actual);
      if (narrowFunc(actual)) {
        // FIXME how can we correctly guard for this???
        const b = rule(actual as unknown as B);
        return a.concat(b);
      } else {
        return a;
      }
    });
  }

  /** run rule builder when we pass the test */
  unionLazy<B>(narrowFunc: (union: A) => boolean, createRule: () => Rule<B>): RuleBuilder<A> {
    return new RuleBuilder((actual: A) => {
      const rule = createRule();
      const a = this.rule(actual);
      if (narrowFunc(actual)) {
        // FIXME how can we correctly guard for this???
        const b = rule(actual as unknown as B);
        return a.concat(b);
      } else {
        return a;
      }
    });
  }

  fieldWith<B>(key: PathPrefix, createRule: (a: A) => Rule<B>): RuleBuilder<A> {
    return new RuleBuilder((actual: A) => {
      const field = get(actual, keyToPath(key));
      if (isUndefined(field)) {
        return error(`Property not found: ${JSON.stringify(key)}`, key);
      } else {
        const prefixed = prefix(createRule(actual), key);
        const a = this.rule(actual);
        const b = prefixed(field);
        return a.concat(b);
      }
    });
  }

  and(rule: Rule<A>): RuleBuilder<A> {
    return new RuleBuilder(and(this.rule, rule));
  }

  contramap<B>(func: (b: B) => A): RuleBuilder<B> {
    return new RuleBuilder((actual: B) => this.rule(func(actual)));
  }

  finish(): Rule<A> {
    return this.rule;
  }
}

export function create<A>(rule: Rule<A> = pass()): RuleBuilder<A> {
  return new RuleBuilder<A>(rule);
}
