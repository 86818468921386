import { InternationalEquiv } from "../../model/types";
import { Opt } from "../../utils";

export type MyState = {
  data: Opt<InternationalEquiv[]>;
  fetching: boolean;
};

export const initialState: MyState = {
  data: null,
  fetching: false,
};
