import React, { Component, MouseEvent } from "react";
import { connectModal } from "redux-modal";
import { Button, Header, Message, Modal } from "semantic-ui-react";
import { ProgrammeCode } from "../model/programme";
import { RequirementsCheckDTO } from "../model/types.generated";
import { Opt, ReactNodeLike } from "../utils";
import { DropdownField, FieldLabel } from "./fields";
import { DropdownOptions } from "./fields/DropdownField";

interface Props {
  primaryButtonText?: ReactNodeLike;
  secondaryButtonText?: ReactNodeLike;
  onPrimaryClick?: (code: ProgrammeCode) => (e: MouseEvent) => void;
  onSecondaryClick?: (e: MouseEvent) => void;
  handleHide: () => void;
  handleDestroy: () => void;
  show: boolean;
  title?: string;
  placeholder?: string;
  options: DropdownOptions<RequirementsCheckDTO>;
  initialValue: ProgrammeCode;
}

interface State {
  newValue: Opt<ProgrammeCode>;
}

export default connectModal({ name: "interSchoolChangeOfProgramme" })<Props>(
  class ModalAlert extends Component<Props, State> {
    state = {
      newValue: null,
    };

    onPrimaryClick = (e: MouseEvent) => {
      this.props.onPrimaryClick?.(this.state.newValue ?? this.props.initialValue)(e);
      this.props.handleHide();
    };

    onSecondaryClick = (e: MouseEvent) => {
      this.props.onSecondaryClick?.(e);
      this.props.handleHide();
    };

    render() {
      const { show, title, primaryButtonText, secondaryButtonText, options } = this.props;

      const fixedOptions: DropdownOptions<ProgrammeCode> = options.map(v => ({
        ...v,
        value: v.value.programme.code,
      }));

      return (
        <Modal open={show} onClose={this.props.handleHide} size="small">
          <Header icon="warning circle" content={title || "Alert"} />
          <Modal.Content>
            <Header as="h4">Choose a new programme</Header>

            <p>Once you transfer an application to a new school you will no longer be able to edit it.</p>

            <Message negative>
              <Message.Header>Warning!</Message.Header>

              <p>
                <em>Before making this change, speak to the target School and make sure they are happy to accept the applicant.</em>
              </p>
            </Message>

            <FieldLabel>
              <DropdownField
                value={this.state.newValue || this.props.initialValue}
                onChange={newValue => this.setState({ newValue })}
                placeholder={this.props.placeholder}
                fluid
                search
                options={fixedOptions}
                readOnly={false}
              />
            </FieldLabel>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onSecondaryClick}>{secondaryButtonText || "Cancel"}</Button>
            <Button primary onClick={this.onPrimaryClick}>
              {primaryButtonText || "Start change of programme (saves the application)"}
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }
  },
);
