import { push } from "connected-react-router";
import * as api from "../../api";
import * as toast from "../../components/toast";
import { Checked, Message, User } from "../../model/types";
import { userRule } from "../../model/user";
import { AppDispatch, AppReducer } from "../actions";
import { fetchWithMutex, validationErrorHandler } from "../fetch";
import { AppState, GetState } from "../state";
import * as teams from "../teams";
import { emptyUser, initialState, MyState } from "./state";

///////////////
// Selectors //
///////////////

export const myState = (state: AppState): MyState => state.route.userUpdate;

export const user = (state: AppState): User => myState(state).user;

export const messages = (state: AppState): Message[] => myState(state).clientMessages.concat(myState(state).serverMessages);

export const fetching = (state: AppState): boolean => myState(state).fetching;

export const fetched = (state: AppState): boolean => myState(state).fetched;

/////////////
// Actions //
/////////////

const INITIALISE = "userUpdate/INITIALISE";
const LOAD_START = "userUpdate/LOAD_START";
const LOAD_SUCCESS = "userUpdate/LOAD_SUCCESS";
const UPDATE = "userUpdate/UPDATE";
const SAVE_START = "userUpdate/SAVE_START";
const SAVE_SUCCESS = "userUpdate/SAVE_SUCCESS";
const SET_SERVER_MESSAGES = "userUpdate/SET_SERVER_MESSAGES";

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState(), action) => {
  function clearServerMessages(state: MyState): MyState {
    return { ...state, serverMessages: [] };
  }

  function commitChanges(state: MyState) {
    return { ...state, originalUser: state.user };
  }

  function updateAndValidate(state: MyState, user: User) {
    return { ...state, user, clientMessages: userRule(user) };
  }

  switch (action.type) {
    case INITIALISE:
      return commitChanges(updateAndValidate({ ...state, fetching: false, serverMessages: [] }, emptyUser(action.team)));
    case LOAD_START:
      return { ...state, fetching: true };
    case LOAD_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false, fetched: true }, action.user));
    case UPDATE:
      return clearServerMessages(updateAndValidate(state, action.user));
    case SAVE_START:
      return { ...state, fetching: true };
    case SAVE_SUCCESS:
      return commitChanges(updateAndValidate({ ...state, fetching: false }, action.user));
    case SET_SERVER_MESSAGES:
      return { ...state, fetching: false, serverMessages: action.messages };
    default:
      return state;
  }
};

export default reducer;

/////////////////////
// Action Creators //
/////////////////////

export const initialise = () => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: INITIALISE, team: teams.currentTeam(getState()).code });
  };
};

export function load(id: number) {
  return fetchWithMutex<User>({
    mutex: "userLoad",

    request(dispatch, getState) {
      return api.fetchUser(api.fetchParams(getState()), id);
    },

    pending(dispatch, getState) {
      dispatch({ type: LOAD_START });
    },

    success(dispatch, getState, user) {
      dispatch({ type: LOAD_SUCCESS, user });
    },
  });
}

export const update = (user: User) => ({ type: UPDATE, user });

export function save(user: User) {
  return fetchWithMutex<Checked<User>>({
    mutex: "userSave",

    request(dispatch, getState) {
      return user.id >= 0 ? api.updateUser(api.fetchParams(getState()), user) : api.insertUser(api.fetchParams(getState()), user);
    },

    pending(dispatch, getState) {
      dispatch({ type: SAVE_START });
      dispatch({ type: SET_SERVER_MESSAGES, messages: [] });
    },

    success(dispatch, getState, checked) {
      checked.data && toast.success("User saved");
      checked.data && dispatch({ type: SAVE_SUCCESS, user: checked.data });
      checked.messages && dispatch({ type: SET_SERVER_MESSAGES, messages: checked.messages });
      checked.data && dispatch(push("/admin/user"));
    },

    error: validationErrorHandler((error, messages) => ({
      type: SET_SERVER_MESSAGES,
      error,
      messages,
    })),
  });
}
