import React from "react";
import Title from "../components/Title";
import { Button, Container, Message, Form, Divider } from "semantic-ui-react";
import { useAddAnnouncement } from "../api";
import { useInputField } from "../utils";
import { AnnouncementList } from "./AnnouncementsPage";

const initialState = {
  detail: "",
  headline: "",
};

function CreateAnnouncementPage() {
  const [value, setValue] = React.useState(initialState);
  const field = useInputField(value, setValue);

  const { mutate: addMutation, status } = useAddAnnouncement(() => {
    setValue(initialState);
  });

  const handleAdd = React.useCallback(() => {
    addMutation(value);
  }, [addMutation, value]);

  const valid = value.headline.length > 0;
  return (
    <Title title="Add Announcement">
      <Container>
        <Message
          color="yellow"
          icon="warning"
          header="Send a notification to all Springboard users"
          content="Messages sent from here will be distributed to all users"
        />
        <Form>
          <Form.Field>
            <label>Headline</label>
            <input placeholder="Headline for notification" {...field("headline")} />
          </Form.Field>
          <Form.Field>
            <label>Detail</label>
            <textarea placeholder="Detail of notification" {...field("detail")} />
          </Form.Field>
          <Button disabled={!valid} type="submit" onClick={handleAdd}>
            Send to all Users
          </Button>
          {status === "success" && <Message color="green" header="Message successfully sent" />}
          {status === "error" && <Message color="red" header="There was an issue sending the message" />}
        </Form>
        <Divider />
        <AnnouncementList />
      </Container>
    </Title>
  );
}

export default CreateAnnouncementPage;
