import { AppError, ConcurrentModificationError, ForbiddenError, UnauthorizedError } from "../errors";
import { concurrentModificationModal, defaultModal, forbiddenModal, youNeedToLoginDialog } from "./errorModals";

interface ModalErrorProps {
  error: AppError;
  onOk: () => void;
}

export const ModalErrorDialog = (props: ModalErrorProps) => {
  const { error, onOk } = props;

  if (error instanceof ForbiddenError) {
    return forbiddenModal(error, onOk);
  } else if (error instanceof ConcurrentModificationError) {
    return concurrentModificationModal(error, onOk);
  } else if (error instanceof UnauthorizedError) {
    return youNeedToLoginDialog();
  } else {
    // NotFoundError | ValidationError | UnknownError
    return defaultModal(error);
  }
};
