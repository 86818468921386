import lodash from "lodash";
import { hourAndMinuteToString, stringToHourAndMinute } from "@qmspringboard/shared/dist/model/localTime";
import { HourAndMinute } from "@qmspringboard/shared/dist/model/types";
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Opt } from "../../utils";
import { BaseFieldProps } from "./base";
import { ExtraInputProps } from "./BaseInput";
import NullableTextInput from "./NullableTextInput";
import { Messages, errorMessage, combineMessages } from "../../model/errors";
import useDeepEqualEffect from "../../hooks/useDeepEqualEffect";

const CompactNullableTextInput = styled(NullableTextInput)`
  input {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
`;

export interface NullableTimeInputProps extends BaseFieldProps<Opt<HourAndMinute>>, ExtraInputProps {
  disallowMidnightMessage?: string;
}

function validLocalTimeString(str: string): boolean {
  return /[0-9]{1,2}[.:][0-9]{1,2}/.test(str);
}

export default function NullableTimeInput(props: NullableTimeInputProps) {
  const { value, onChange, placeholder, messages, disallowMidnightMessage, ...rest } = props;

  const [timeString, setTimeString] = useState<Opt<string>>();

  const valid = useMemo(() => timeString == null || validLocalTimeString(timeString), [timeString]);

  const allMessages: Opt<Messages> = useMemo(() => {
    if (valid) {
      if (disallowMidnightMessage != null && value?.[0] === 0 && value?.[1] === 0) {
        return combineMessages(messages, [errorMessage("warning", disallowMidnightMessage)]);
      } else {
        return messages;
      }
    } else {
      return combineMessages(messages, [errorMessage("error", "Enter a 24 hour time in hh:mm format")]);
    }
  }, [valid, disallowMidnightMessage, value, messages]);

  // NOTE: Be careful editing this -- eslint won't check the dependencies array!
  useDeepEqualEffect(() => {
    if (value == null) {
      setTimeString(null);
    } else {
      const local = timeString == null ? null : stringToHourAndMinute(timeString);
      if (!lodash.isEqual(value, local)) {
        setTimeString(hourAndMinuteToString(value));
      }
    }
  }, [value]); // this deliberately doesn't include timeString

  const handleChange = (timeString: Opt<string>) => {
    setTimeString(timeString);
    if (timeString == null || timeString == "") {
      onChange?.(null);
    } else if (validLocalTimeString(timeString)) {
      onChange?.(stringToHourAndMinute(timeString));
    }
  };

  return (
    <CompactNullableTextInput value={timeString} onChange={handleChange} placeholder={placeholder ?? "hh:mm"} messages={allMessages} {...rest} />
  );
}
