import { push } from "connected-react-router";
import { connect, useSelector } from "react-redux";
import { createSelector } from "reselect";
import * as api from "../../api";
import * as pot from "../../api/pot";
import { admissionsTeam, isAdmissionsTeamCode, schoolsToTeams, Team, TeamCode, teamToSchoolCode } from "../../model/team";
import { School, SearchResults, SchoolCode } from "../../model/types";
import { AppDispatch, AppReducer, AppThunkAction } from "../actions";
import { fetchWithMutex } from "../fetch";
import { AppState, GetState } from "../state";
import { MyState as UserState } from "../user/state";
import { emptyState, initialState, LoadedState, MyState } from "./state";

///////////////////
// Derived state //
///////////////////

export const AdmissionsMode = "ADMISSIONS";
export const SchoolMode = "SCHOOL";
export type UIMode = "ADMISSIONS" | "SCHOOL";

function teamUiMode(team: Team): UIMode {
  return isAdmissionsTeamCode(team.code) ? AdmissionsMode : SchoolMode;
}

/////////////
// Actions //
/////////////

const INITIALISE = "team/INITIALISE";
export const SELECT_TEAM = "team/SELECT_TEAM"; // used in schools reducer to reset state
const LOADED = "team/LOADED";

export function initialise(defaultTeamCode?: TeamCode) {
  return fetchWithMutex<SearchResults<School>>({
    mutex: "teamInitialise",

    request(dispatch, getState) {
      return api.fetchSchools(api.fetchParams(getState()));
    },

    success(dispatch, getState, results) {
      dispatch({ type: LOADED, schools: results.items, defaultTeamCode });
    },
  });
}

export const setCurrentTeam = (code: TeamCode): AppThunkAction => {
  return (dispatch: AppDispatch, getState: GetState) => {
    dispatch({ type: SELECT_TEAM, code });
    dispatch(push("/"));
  };
};

///////////////
// Selectors //
///////////////

// This is a recreation of a selector from the user reducer.
// The user reducer depends on this reducer...
// this redundancy avoids a cyclic dependency between the two.
const userSelector = (state: AppState): UserState => state.user;

const potSelector: (state: AppState) => pot.Pot<LoadedState> = createSelector(userSelector, state => state.teams.pot);

const rootSelector: (state: AppState) => LoadedState = createSelector(potSelector, teamsPot => pot.getOrElse(teamsPot, emptyState));

export const fetchingTeams: (state: AppState) => boolean = createSelector(potSelector, teamsPot => !pot.hasValue(teamsPot));

export const currentTeam: (state: AppState) => Team = createSelector(rootSelector, state => state.currentTeam);

export const allTeams: (state: AppState) => Team[] = createSelector(rootSelector, state => state.allTeams);

export const allSchools: (state: AppState) => School[] = createSelector(rootSelector, state => state.allSchools);

export const currentUIMode: (state: AppState) => UIMode = createSelector(currentTeam, teamUiMode);

export const currentSchoolCode: (state: AppState) => SchoolCode | null = createSelector(currentTeam, team => teamToSchoolCode(team.code));

/////////////
// Reducer //
/////////////

const reducer: AppReducer<MyState> = (state = initialState, action) => {
  switch (action.type) {
    case INITIALISE:
      return initialState;

    case LOADED: {
      const { schools, defaultTeamCode } = action;
      const allSchools: School[] = schools;
      const allTeams: Team[] = schoolsToTeams(allSchools);

      const defaultTeam = allTeams.find(t => t.code === defaultTeamCode) || admissionsTeam;

      const currentTeam: Team = pot.fold(
        state.pot,
        () => defaultTeam,
        loaded => allTeams.find(team => team.code === loaded.currentTeam.code) || admissionsTeam,
      );

      return { pot: pot.ready({ allSchools, allTeams, currentTeam }) };
    }

    case SELECT_TEAM: {
      const { code } = action;

      const currentTeam: Team = pot.fold(
        state.pot,
        () => admissionsTeam,
        loaded => loaded.allTeams.find(team => team.code === code) || admissionsTeam,
      );

      return {
        pot: pot.map(state.pot, loaded => ({ ...loaded, currentTeam })),
      };
    }

    default:
      return state;
  }
};

export default reducer;

/////////////////
// Connections //
/////////////////

export const withFetchingTeams = connect((state: AppState) => ({
  fetchingTeams: fetchingTeams(state),
}));

export const withCurrentTeam = connect((state: AppState) => ({
  currentTeam: currentTeam(state),
}));

export const withCurrentSchoolCode = connect((state: AppState) => ({
  schoolCode: teamToSchoolCode(currentTeam(state).code),
}));

export const withCurrentUIMode = connect((state: AppState) => ({
  uiMode: currentUIMode(state),
}));

export const withAllSchools = connect((state: AppState) => ({
  allSchools: allSchools(state),
}));

export const withAllTeams = connect((state: AppState) => ({
  allTeams: allTeams(state),
}));

///////////
// Hooks //
///////////

export function useCurrentTeam() {
  return useSelector(currentTeam);
}
