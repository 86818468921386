import { applicationRequiresConfirmationEmail } from "@qmspringboard/shared/dist/model/application";
import { ApplicantDetails } from "@qmspringboard/shared/dist/model/applications.generated";
import { Qualifications, templateQualifications } from "@qmspringboard/shared/dist/model/qualifications";
import { QualificationBoardEnum } from "@qmspringboard/shared/dist/model/enums.generated";
import { ApplicantDTO, ApplicantEditorDTO, Application, Label, Note } from "./types";

// for compatibility
export type Applicant = ApplicantDTO;
export type { ApplicantDetails, Qualifications };

// legacy
export type DuplicateApplicants = {
  ucasPersonalId: Array<ApplicantDTO>;
  studentId: Array<ApplicantDTO>;
  email: Array<ApplicantDTO>;
};

export type ApplicantEditorState = {
  applicant: ApplicantDTO;
  applications: Application[];
  duplicates: DuplicateApplicants;
  labels: Label[];
  notes: Note[];
};

export const emptyQualifications: Qualifications = {
  list: templateQualifications(QualificationBoardEnum.ALevel),
  version: 1,
};

export const emptyDuplicateApplicants: DuplicateApplicants = Object.freeze({
  ucasPersonalId: [],
  studentId: [],
  email: [],
});

export function toApplicantEditorDTO(editorState: ApplicantEditorState): ApplicantEditorDTO {
  const { applicant, applications, labels, notes } = editorState;

  return {
    applicant,
    applications,
    labels,
    notes,
  };
}

export function fromApplicantEditorDTO(dto: ApplicantEditorDTO): ApplicantEditorState {
  const { applicant, applications, labels, notes } = dto;

  return {
    applicant,
    duplicates: emptyDuplicateApplicants,
    applications,
    labels,
    notes,
  };
}

export const applicantRequiresConfirmationEmail = (currDto: ApplicantEditorState, origDto?: ApplicantEditorState | null) =>
  currDto.applications.find(currAppn => {
    const origAppn = origDto && origDto.applications.find(origAppn => origAppn.id === currAppn.id);

    return applicationRequiresConfirmationEmail(currAppn, origAppn);
  }) != null;
