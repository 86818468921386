import { show, hide, destroy } from "redux-modal";

// redux-modal doesn't use string literal types in its action definitions.
// We re-define the types and constructors for the actions here as a layer of sanity.

export type ReduxModalAction =
  | { type: "@redux-modal/SHOW"; payload: { modal: string; props: unknown } }
  | { type: "@redux-modal/HIDE"; payload: { modal: string } }
  | { type: "@redux-modal/DESTROY"; payload: { modal: string } };

export function showModal(modal: string, props?: {}): ReduxModalAction {
  return show(modal, props) as ReduxModalAction;
}

export function hideModal(modal: string): ReduxModalAction {
  return hide(modal) as ReduxModalAction;
}

export function destroyModal(modal: string): ReduxModalAction {
  return destroy(modal) as ReduxModalAction;
}
