import { applicationChoiceToMethod } from "@qmspringboard/shared/dist/model/application";
import { defaultOfferExpiry } from "@qmspringboard/shared/dist/model/offerExpiry";
import React from "react";
import { Grid, Header, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { FeeCategory, OfferStatusEnum } from "../model/enums";
import { Permissions } from "../model/permission";
import { Application, Message, OfferStatus, Programme } from "../model/types";
import { Opt } from "../utils";
import ErrorDisplay from "./ErrorDisplay";
import { OfferExpiryField } from "./ExpiryField";
import { FieldWrapper, RadioField } from "./fields";
import { useExpirySettings } from "../reducers/offerExpirySettings";

interface Props {
  onChange: (appn: Application) => void;
  readOnly: boolean;
  allProgrammes: Programme[];
  permissions: Permissions;
  value: Application;
  initialValue: Opt<Application>;
  statusesToShow: OfferStatus[];
  messages: Message[];
  guessedFeeCategory: FeeCategory | null;
}

const SuperCompactGridRow = styled(Grid.Row)`
  padding-top: 0.25em !important;
  padding-bottom: 0.25em !important;
`;

const CompactGridRow = styled(Grid.Row)`
  padding-top: 0.75em !important;
  padding-bottom: 0.75em !important;
`;

export default function ClearingOfferStatusEditor(props: Props) {
  const { value: application, initialValue, readOnly, allProgrammes, permissions, onChange, statusesToShow, messages, guessedFeeCategory } = props;

  const programme = allProgrammes.find(prog => prog.code === application.programmeCode);

  const [_fetchingExpirySettings, allExpirySettings] = useExpirySettings();

  const schoolExpirySettings = programme && allExpirySettings[programme.schoolCode];

  // This works around some typing issues
  // converting string to OfferStatus | null:
  const handleOfferStatusChange = (offerStatus: Opt<OfferStatus>) =>
    onChange({
      ...application,
      offerStatus,
      offerExpiry: schoolExpirySettings && defaultOfferExpiry(application.choice, offerStatus, schoolExpirySettings),
    });

  // We can show Invite to Interview (I) radio button if:
  // - the application is already set to I
  // or
  // - the user has permission in the school to set Invite to Interview
  function interviewStatusApplicable(): boolean {
    return programme ? application.offerStatus === OfferStatusEnum.Interview || permissions.canInviteToInterview(programme.schoolCode) : false;
  }

  // Permissions ------------------------------

  const method = applicationChoiceToMethod(application.choice);

  function canChangeOfferStatusTo(newOfferStatus: OfferStatus): boolean {
    return programme
      ? permissions.canChangeOfferStatusTo({
          method,
          programme,
          oldOfferStatus: initialValue == null ? null : initialValue.offerStatus,
          newOfferStatus,
          guessedFeeCategory,
        })
      : false;
  }

  function expiryField(offerStatus: OfferStatus) {
    return (
      <FieldWrapper readOnly={readOnly || !canChangeOfferStatusTo(offerStatus)}>
        <OfferExpiryField
          value={application.offerExpiry}
          onChange={offerExpiry => onChange({ ...application, offerExpiry })}
          size="mini"
          readOnly={readOnly}
          choice={application.choice}
          offerStatus={offerStatus}
          schoolExpirySettings={schoolExpirySettings}
        />
      </FieldWrapper>
    );
  }

  // End permissions --------------------------

  return (
    <Grid style={{ marginTop: ".5em", marginBottom: "1em" }}>
      <SuperCompactGridRow>
        <Grid.Column width={16}>
          <Header as="h4">
            Offer Status &nbsp;&nbsp;
            <ErrorDisplay attached pointing="left" messages={messages} />
          </Header>
        </Grid.Column>
      </SuperCompactGridRow>
      {statusesToShow.includes(OfferStatusEnum.Made) && (
        <SuperCompactGridRow verticalAlign="middle">
          <Grid.Column mobile={16} tablet={16} computer={7}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Made)}
              onChange={handleOfferStatusChange}
              checkedValue={OfferStatusEnum.Made}
              name="offerStatus"
              label={
                <label>
                  Standard verbal offer ({OfferStatusEnum.Made}) <Icon name="mail" />
                </label>
              }
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            {application.offerStatus === OfferStatusEnum.Made && expiryField(OfferStatusEnum.Made)}
          </Grid.Column>
        </SuperCompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.MadePreRelease) && (
        <SuperCompactGridRow verticalAlign="middle">
          <Grid.Column mobile={16} tablet={16} computer={7}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.MadePreRelease)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.MadePreRelease}
              label={
                <label>
                  Pre-release verbal offer ({OfferStatusEnum.MadePreRelease}) <Icon name="mail" />
                </label>
              }
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            {application.offerStatus === OfferStatusEnum.MadePreRelease && expiryField(OfferStatusEnum.MadePreRelease)}
          </Grid.Column>
        </SuperCompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.Interview) && interviewStatusApplicable() && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Interview)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.Interview}
              label={
                <label>
                  Interview ({OfferStatusEnum.Interview}) <Icon name="mail" />
                </label>
              }
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.SelfReferral) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.SelfReferral)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.SelfReferral}
              label={`Possible self-referral (${OfferStatusEnum.SelfReferral})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.OnlineReferral) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.OnlineReferral)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.OnlineReferral}
              label={`On-line referral (${OfferStatusEnum.OnlineReferral})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.Accepted) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Accepted)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.Accepted}
              label={`Applicant has referred themselves on UCAS Track (${OfferStatusEnum.Accepted})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.Confirmed) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Confirmed)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.Confirmed}
              label={`School confirms referral (${OfferStatusEnum.Confirmed})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.OnHold) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.OnHold)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.OnHold}
              label={`On hold (${OfferStatusEnum.OnHold})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {
        // For Clearing in 2020: hide waiting list; only show as an option if the
        // application was set to waiting list before this change.
      }
      {statusesToShow.includes(OfferStatusEnum.WaitingList) && application.offerStatus && application.offerStatus === OfferStatusEnum.WaitingList && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.WaitingList)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.WaitingList}
              label={
                <label>
                  On waiting list ({OfferStatusEnum.WaitingList}) <Icon name="mail" />
                </label>
              }
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.Declined) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Declined)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.Declined}
              label={`Applicant declines offer (${OfferStatusEnum.Declined})`}
            />
          </Grid.Column>
        </CompactGridRow>
      )}
      {statusesToShow.includes(OfferStatusEnum.Rejected) && (
        <CompactGridRow verticalAlign="middle">
          <Grid.Column width={16}>
            <RadioField
              value={application.offerStatus}
              readOnly={readOnly}
              disabled={!canChangeOfferStatusTo(OfferStatusEnum.Rejected)}
              onChange={handleOfferStatusChange}
              name="offerStatus"
              checkedValue={OfferStatusEnum.Rejected}
              label={
                <label>
                  School rejects applicant ({OfferStatusEnum.Rejected}) <Icon name="mail" />
                </label>
              }
            />
          </Grid.Column>
        </CompactGridRow>
      )}
    </Grid>
  );
}
