import { isHourAndMinute } from "@qmspringboard/shared/dist/model/localTime";
import { Location } from "history";
import lodash, { flowRight } from "lodash";
import React, { Component, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Button, Container, Dimmer, Form, Grid, Header, Icon, Loader, Menu, Segment } from "semantic-ui-react";
import styled from "styled-components";
import ClassifierRulesEditor from "../components/ClassifierRulesEditor";
import ErrorDisplay from "../components/ErrorDisplay";
import { DropdownField, DropdownOptions, FieldLabel, NullableTextInput, NullableTimeInput } from "../components/fields";
import { EmailTemplateEditor, OfferScriptEditor, TransferScriptEditor } from "../components/TemplateEditor";
import Title from "../components/Title";
import { fetchOnTeamChange } from "../hoc/fetchOnTeamChange";
import useAppDispatch from "../hooks/useAppDispatch";
import { EmailTypeEnum, OfferExpiryEnum, OfferScriptTypeEnum } from "../model/enums";
import { Messages } from "../model/errors";
import { Permissions } from "../model/permission";
import { schoolToTeamCode } from "../model/team";
import { EmailParts } from "../model/templateParts";
import { SchoolCode, TelephoneScript } from "../model/types";
import * as auth from "../reducers/auth";
import * as schoolConfig from "../reducers/schoolConfig";
import { currentSchoolCode } from "../reducers/teams";
import { Opt, prefixMessages, ReactNodeLike } from "../utils";
import { hasKey, isObject, isString } from "../utils/guard";

// Parameters for the useSetting hook (see below):
interface UseSettingResult {
  value: unknown;
  onChange: (value: unknown) => void;
  messages: Messages;
}

function useCurrentSchool(): string {
  const school = useSelector(currentSchoolCode);
  if (school == null) {
    throw new Error("No current school in config editor!");
  } else {
    return school;
  }
}

// Hook accessing a single School setting in Redux.
// Values aren't typechecked here.
// Type errors indicate programmer error
// so we use guard expressions in components that use this hook
// and swap the component out for an error message.
function useSetting(id: string): UseSettingResult {
  const dispatch = useAppDispatch();
  const value = useSelector(schoolConfig.getValue(id));
  const onChange = useCallback((value: unknown) => dispatch(schoolConfig.setValue(id)(value)), [dispatch, id]);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const messages = useSelector(schoolConfig.messagesFor(id));
  return { value, onChange, messages };
}

function useCombinedSettings(subSettings: Record<string, UseSettingResult>): UseSettingResult {
  const value: Record<string, unknown> = useMemo(
    () =>
      lodash
        .chain(subSettings)
        .map((subSetting, name) => [name, subSetting.value])
        .fromPairs()
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(subSettings).map(sub => sub.value),
  );

  const onChange = useCallback(
    (value: Record<string, unknown>): void => {
      lodash.forEach(value, (subValue, name) => {
        subSettings[name].onChange(subValue);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(subSettings).map(sub => sub.onChange),
  );

  // A bit of acceptable redundancy here between useSetting and combineSettings:
  const messages: Messages = useMemo(
    () =>
      lodash
        .chain(subSettings)
        .map((subSetting, name) => prefixMessages(subSetting.messages, [name]))
        .flatten()
        .value(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(subSettings).map(sub => sub.messages),
  );

  return {
    value: value as unknown,
    onChange: onChange as (value: unknown) => void,
    messages,
  };
}

// Props for a component for editing a single setting:
interface SettingFieldProps {
  id: string;
  label: string;
}

// Props for a component for editing a set of settings with a common prefix:
interface PrefixedSettingFieldProps {
  idPrefix: string;
  label: string;
}

// Component for editing a nullable string:
function TextSettingField(props: SettingFieldProps) {
  const { id, label } = props;

  const { value, onChange, messages } = useSetting(id);

  return (
    <FieldLabel label={label}>
      {value === null || value === undefined || typeof value === "string" ? (
        <NullableTextInput value={value} onChange={onChange} />
      ) : (
        <pre>Expected string or null, received {JSON.stringify(value, null, 2)}</pre>
      )}
      <ErrorDisplay messages={messages} />
    </FieldLabel>
  );
}

// Component for editing a nullable string in the format hh:mm:
function TimeSettingField(props: SettingFieldProps & { disallowMidnight?: boolean }) {
  const { id, label, disallowMidnight } = props;

  const { value, onChange, messages } = useSetting(id);

  return (
    <FieldLabel label={label}>
      <NullableTimeInput
        value={isHourAndMinute(value) ? value : null}
        onChange={onChange}
        label="24h, London Time Zone"
        disallowMidnightMessage={disallowMidnight ? "Don't use midnight as the end of a day. Use 23:59 instead!" : undefined}
      />
      <ErrorDisplay messages={messages} />
    </FieldLabel>
  );
}

interface DropdownSettingFieldProps extends SettingFieldProps {
  options: DropdownOptions<Opt<string>>;
}

// Component for editing an offer expiry setting:
function DropdownSettingField(props: DropdownSettingFieldProps) {
  const { id, label, options } = props;

  const { value, onChange, messages } = useSetting(id);
  return (
    <FieldLabel label={label}>
      {value === null || value === undefined || typeof value === "string" ? (
        <DropdownField value={value} options={options} onChange={onChange} />
      ) : (
        <pre>Expected timestamp or null, received {JSON.stringify(value, null, 2)}</pre>
      )}
      <ErrorDisplay messages={messages} />
    </FieldLabel>
  );
}

// Component for editing an offer expiry setting:
function ExpirySettingField(props: SettingFieldProps) {
  const { id, label } = props;
  const options = useMemo(() => OfferExpiryEnum.dropdownOptions(false), []);
  return <DropdownSettingField id={id} label={label} options={options} />;
}

// Component for editing classifier rules:
function ClassifierSettingField(props: SettingFieldProps) {
  const { id } = props;

  const { value, onChange, messages } = useSetting(id);

  return typeof value === "string" ? (
    <ClassifierRulesEditor value={value} onChange={onChange} messages={messages} />
  ) : (
    <pre>Expected string, received {JSON.stringify(value, null, 2)}</pre>
  );
}

function isEmailParts(value: unknown): value is EmailParts {
  return isObject(value) && hasKey(value, "subject", isString) && hasKey(value, "body", isString);
}

function EmailTemplateSettingField(props: PrefixedSettingFieldProps) {
  const { idPrefix } = props;

  const fromAddressId = `${idPrefix}FromAddress`;
  const fromNameId = `${idPrefix}FromName`;
  const bccAddressId = `${idPrefix}BccAddress`;
  const subjectTemplateId = `${idPrefix}SubjectTemplate`;
  const bodyTemplateId = `${idPrefix}BodyTemplate`;

  const school = useCurrentSchool();
  const { value, onChange, messages } = useCombinedSettings({
    subject: useSetting(subjectTemplateId),
    body: useSetting(bodyTemplateId),
  });

  return (
    <>
      <TextSettingField id={fromAddressId} label="From Address" />
      <TextSettingField id={fromNameId} label="From Name" />
      <TextSettingField id={bccAddressId} label="BCC Address" />
      {isEmailParts(value) ? (
        <EmailTemplateEditor value={value} onChange={onChange} school={school} messages={messages} />
      ) : (
        <pre>Expected email template, received {JSON.stringify(value, null, 2)}</pre>
      )}
    </>
  );
}

function isTelephoneScript(value: unknown): value is TelephoneScript {
  return isObject(value) && hasKey(value, "title", isString) && hasKey(value, "content", isString);
}

function TransferScriptSettingField(props: PrefixedSettingFieldProps) {
  const { idPrefix } = props;

  const titleTemplateId = `${idPrefix}TitleTemplate`;
  const contentTemplateId = `${idPrefix}Template`;

  const school = useCurrentSchool();
  const { value, onChange, messages } = useCombinedSettings({
    title: useSetting(titleTemplateId),
    content: useSetting(contentTemplateId),
  });

  return isTelephoneScript(value) ? (
    <TransferScriptEditor value={value} onChange={onChange} school={school} messages={messages} />
  ) : (
    <pre>Expected telephone script template, received {JSON.stringify(value, null, 2)}</pre>
  );
}

function OfferScriptSettingField(props: PrefixedSettingFieldProps) {
  const { idPrefix } = props;

  const titleTemplateId = `${idPrefix}TitleTemplate`;
  const contentTemplateId = `${idPrefix}Template`;

  const school = useCurrentSchool();
  const { value, onChange, messages } = useCombinedSettings({
    title: useSetting(titleTemplateId),
    content: useSetting(contentTemplateId),
  });

  return isTelephoneScript(value) ? (
    <OfferScriptEditor value={value} onChange={onChange} school={school} messages={messages} />
  ) : (
    <pre>Expected telephone script template, received {JSON.stringify(value, null, 2)}</pre>
  );
}

interface SettingsPaneProps {
  title: ReactNodeLike;
  children: ReactNodeLike;
}

const SettingsPageHeader = styled(Header)`
  margin-top: 0.5em !important;
  margin-bottom: 1em !important;
`;

function SettingsPane(props: SettingsPaneProps) {
  const { title, children } = props;
  return (
    <>
      <SettingsPageHeader>{title}</SettingsPageHeader>
      <Container style={{ clear: "both" }}>
        <Form as="div">{children}</Form>
      </Container>
    </>
  );
}

interface SettingsTab {
  path: string;
  title: ReactNodeLike;
  show?: (permissions: Permissions, schoolCode: Opt<string>) => boolean;
  render: () => ReactNodeLike;
}

const generalSettings: SettingsTab = {
  path: "/admin/settings/general",
  title: "General settings",
  show: (permissions: Permissions, schoolCode: Opt<string>) => permissions.canUpdateSchoolConfig(schoolToTeamCode(schoolCode as Opt<SchoolCode>)),
  render() {
    return (
      <SettingsPane title="UCAS Track">
        <TextSettingField id="ucasTrackCollegeName" label="College name" />
        <TextSettingField id="ucasTrackCollegeCode" label="College code" />
      </SettingsPane>
    );
  },
};

const offerDeadlines: SettingsTab = {
  path: "/admin/settings/offerDeadlines",
  title: "Offer Deadlines",
  show: (permissions: Permissions, schoolCode: Opt<string>) => permissions.canUpdateSchoolDeadlines(schoolToTeamCode(schoolCode as Opt<SchoolCode>)),
  render() {
    return (
      <>
        <SettingsPane title="Clearing Offer Deadlines (Standard Offers)">
          <TimeSettingField id="clearingStandardOfferStartOfDay" label="Start of Office Day" />
          <TimeSettingField id="clearingStandardOfferEndOfDay" label="End of Office Day" disallowMidnight={true} />
          <ExpirySettingField id="clearingStandardDefaultExpiry" label="Default Offer Expiry" />
        </SettingsPane>
        <SettingsPane title="Clearing Offer Deadlines (Pre-Release Offers)">
          <TimeSettingField id="clearingPreReleaseOfferStartOfDay" label="Start of Office Day" />
          <TimeSettingField id="clearingPreReleaseOfferEndOfDay" label="End of Office Day" disallowMidnight={true} />
          <ExpirySettingField id="clearingPreReleaseDefaultExpiry" label="Default Offer Expiry" />
        </SettingsPane>
      </>
    );
  },
};

const classifierRules: SettingsTab = {
  path: "/admin/settings/classifierRules",
  title: "Classifier Rules",
  show: (permissions: Permissions, schoolCode: Opt<string>) => permissions.canUpdateClassifierRules(schoolToTeamCode(schoolCode as Opt<SchoolCode>)),
  render() {
    return (
      <SettingsPane title="Classifier Rules">
        <ClassifierSettingField id="classifierText" label="Classifier Rules" />
      </SettingsPane>
    );
  },
};

const offerScriptSections: SettingsTab[] = OfferScriptTypeEnum.entries.map(({ value, label }) => ({
  path: `/admin/settings/script/${value}`,
  title: (
    <span>
      <Icon name="volume control phone" /> {label}
    </span>
  ),
  show: (permissions: Permissions, schoolCode: Opt<string>) =>
    permissions.canUpdateTelephoneScripts(schoolToTeamCode(schoolCode as Opt<SchoolCode>)) &&
    (value !== "clearingInterviewScript" || (schoolCode != null && permissions.canInviteToInterview(schoolCode))),
  render() {
    return (
      <SettingsPane title={`${label} Script`}>
        <OfferScriptSettingField idPrefix={value} label={label} />
      </SettingsPane>
    );
  },
}));

const offerEmailSections: SettingsTab[] = EmailTypeEnum.entries.map(({ value, label }) => ({
  path: `/admin/settings/email/${value}`,
  title: (
    <span>
      <Icon name="mail outline" /> {label}
    </span>
  ),
  show: (permissions: Permissions, schoolCode: Opt<string>) =>
    value !== "handover" && // Handover has been hidden since 2023
    permissions.canUpdateEmailTemplates(schoolToTeamCode(schoolCode as Opt<SchoolCode>)) &&
    (value !== "clearingInterview" || (schoolCode != null && permissions.canInviteToInterview(schoolCode))),
  render() {
    return (
      <SettingsPane title={`${label} Emails`}>
        <EmailTemplateSettingField key={value} idPrefix={value} label={label} />
      </SettingsPane>
    );
  },
}));

const transferScript: SettingsTab = {
  path: "/admin/settings/transfer",
  title: (
    <span>
      <Icon name="volume control phone" /> Hotline transfer
    </span>
  ),
  show: (_p, _s) => false, // Hotline transfer hasn't been used since circa. 2022
  render() {
    return (
      <SettingsPane title="Hotline Transfer Script">
        <TransferScriptSettingField idPrefix="transferScript" label="Hotline Transfer Script" />
      </SettingsPane>
    );
  },
};

const tabs: SettingsTab[] = [generalSettings, offerDeadlines, classifierRules, transferScript, ...offerScriptSections, ...offerEmailSections];

interface Props {
  location: Location;
  dirty: boolean;
  fetching: boolean;
  fetched: boolean;
  saving: boolean;
  onLoad: () => void;
  handleSave: () => void;
  currentSchoolCode: Opt<string>;
  permissions: Permissions;
}

// NOTE: These must sum to 16.
// The types on Semantic UI Grid prevent us doing math to enforce this:
const LEFT_WIDTH = 4;
const RIGHT_WIDTH = 12;

class SchoolConfigPage extends Component<Props> {
  render() {
    const { location, dirty, saving, fetching, handleSave, currentSchoolCode, permissions } = this.props;

    const visibleTabs = tabs.filter(tab => tab.show?.(permissions, currentSchoolCode) ?? true);

    return (
      <Title title="School Settings">
        <Container>
          {!currentSchoolCode && (
            <Segment basic padded textAlign="center">
              Select a school to configure its settings.
            </Segment>
          )}
          {currentSchoolCode && (
            <Dimmer.Dimmable as={Grid}>
              <Dimmer active={saving || fetching} inverted>
                <Loader>
                  {saving && "Saving"}
                  {fetching && "Loading"}
                </Loader>
              </Dimmer>
              <Grid.Row>
                <Grid.Column width={LEFT_WIDTH}>
                  <Menu fluid vertical tabular>
                    <Route path="/admin/settings" />
                    {visibleTabs.map(({ path, title }) => (
                      <Menu.Item as={Link} key={path} active={location.pathname === path} to={path}>
                        {title}
                      </Menu.Item>
                    ))}
                  </Menu>
                </Grid.Column>
                <Grid.Column width={RIGHT_WIDTH}>
                  <Button floated="right" disabled={!dirty} primary onClick={handleSave} content="Save all changes" />
                  <Container>
                    <Switch>
                      {visibleTabs.map(({ path, render }) => (
                        <Route key={path} path={path} render={render} />
                      ))}
                      <Redirect from="/admin/settings" exact to={visibleTabs[0]?.path || "/admin/settings/general"} />
                    </Switch>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Dimmer.Dimmable>
          )}
        </Container>
      </Title>
    );
  }
}

export default flowRight(
  fetchOnTeamChange(schoolConfig.initialise),
  connect(
    state => ({
      dirty: schoolConfig.isDirty(state),
      saving: schoolConfig.saving(state),
      fetching: schoolConfig.fetching(state),
      fetched: schoolConfig.fetched(state),
      currentSchoolCode: currentSchoolCode(state),
      permissions: auth.permissions(state),
    }),
    {
      handleSave: schoolConfig.save,
    },
  ),
)(SchoolConfigPage);
