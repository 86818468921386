import {
  templateQualifications,
  Qualification,
  QualificationBoard,
  Qualifications,
  EnglishQualification,
  SecondaryEducationQualifications as SecondaryEducationQualificationsType,
} from "@qmspringboard/shared/dist/model/qualifications";
import reducer, {
  addQualificationAction,
  prefillQualificationsAction,
  QualificationsAction,
  removeQualificationAction,
  updateEnglishQualificationAction,
  updateSecondaryEducationQualificationAction,
  updateQualificationAction,
} from "@qmspringboard/shared/dist/reducers/qualifications";
import { SecondaryEducationQualifications } from "@qmspringboard/shared/dist/components/SecondaryEducationQualifications";
import React, { ReactElement, useCallback } from "react";
import { Button, Container, Dropdown, Form, Grid, Header, Message } from "semantic-ui-react";
import styled from "styled-components";
import { QualificationBoardEnum, QualificationTypeEnum } from "../model/enums";
import { addPathPrefix, indexedErrors, Messages, subErrors } from "../model/errors";
import ErrorDisplay from "./ErrorDisplay";
import { BaseFieldProps, FieldLabel } from "./fields";
import QualificationEditor from "./QualificationEditor";
import { WrappedMessage } from "./RequirementsCheckView";
import { applicant } from "@qmspringboard/shared/dist/adminStrings";
import { MessageHandling } from "@qmspringboard/shared/dist/ui/Form";
import { QualificationsEnglish } from "@qmspringboard/shared/dist/components/QualificationsEnglish";

interface QualificationsEditorProps extends BaseFieldProps<Qualifications> {
  readOnly: boolean;
  messages: Messages;
}

const PaddedHeader = styled(Header)`
  padding-top: 5px !important;
  padding-bottom: 10px !important;
`;

export default function QualificationsEditor({ value, messages, onChange, readOnly }: QualificationsEditorProps): ReactElement {
  const list = value.list;

  const splitErrors = subErrors(messages, {
    list: true,
    english: true,
    secondaryEducation: true,
    maths: true,
  });

  const listMessages = indexedErrors(splitErrors.list, list.length);

  const dispatch = useCallback((action: QualificationsAction) => onChange?.(reducer(value, action)), [onChange, value]);

  const add = useCallback(
    () => dispatch(addQualificationAction(list.length === 0 ? QualificationTypeEnum.A2 : list[list.length - 1].tpe)),
    [dispatch, list],
  );

  const remove = (index: number) => dispatch(removeQualificationAction(index));

  const update = (index: number) => (qualification: Qualification) => dispatch(updateQualificationAction(index, qualification));

  const prefillBoard = useCallback(
    (board?: QualificationBoard) => {
      dispatch(prefillQualificationsAction(templateQualifications(board)));
    },
    [dispatch],
  );

  const onEnglishChange = useCallback(
    (english: EnglishQualification | null | undefined) => {
      return dispatch(updateEnglishQualificationAction(english));
    },
    [dispatch],
  );

  const onSecondaryEducationChange = useCallback(
    (secondary: SecondaryEducationQualificationsType) => {
      return dispatch(updateSecondaryEducationQualificationAction(secondary));
    },
    [dispatch],
  );

  const thisYear = new Date().getFullYear();

  const hasPreviousYearQualifications = () => list.some(qualification => qualification.yearObtained !== thisYear);

  const hasAllPreviousYearQualifications = () => list.every(qualification => qualification.yearObtained !== thisYear);

  const someOrAllInPreviousYear = () => (hasAllPreviousYearQualifications() ? "all" : "some");

  return (
    <Container>
      <PaddedHeader>Qualifications</PaddedHeader>
      <Form as="div">
        {!readOnly && messages.length > 0 && splitErrors._rest_.length > 0 && (
          <div>
            <ErrorDisplay messages={splitErrors._rest_} />
          </div>
        )}
        <Grid>
          {list.length === 0 && (
            <Grid.Row centered style={{ marginBottom: 10 }}>
              <Grid.Column mobile={3} tablet={3} computer={3}>
                <Button fluid compact onClick={() => prefillBoard(QualificationBoardEnum.ALevel)}>
                  A2
                </Button>
              </Grid.Column>
              <Grid.Column mobile={3} tablet={3} computer={3}>
                <Button fluid compact onClick={() => prefillBoard(QualificationBoardEnum.IB)}>
                  IB
                </Button>
              </Grid.Column>
              <Grid.Column mobile={3} tablet={3} computer={3}>
                <Button fluid compact onClick={() => prefillBoard(QualificationBoardEnum.BtecRqf)}>
                  BTEC
                </Button>
              </Grid.Column>
              <Grid.Column mobile={3} tablet={3} computer={3}>
                <Dropdown text="Other" button fluid compact>
                  <Dropdown.Menu>
                    {QualificationBoardEnum.values.map(board => {
                      switch (board) {
                        case QualificationBoardEnum.ALevel:
                          return null;
                        case QualificationBoardEnum.IB:
                          return null;
                        case QualificationBoardEnum.BtecRqf:
                          return null;
                        default:
                          return <Dropdown.Item key={board} text={QualificationBoardEnum.labelOf(board)} onClick={() => prefillBoard(board)} />;
                      }
                    })}

                    <Dropdown.Item text="Custom" onClick={() => prefillBoard(undefined)} />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          )}
          {list.map((value, index) => (
            <QualificationEditor
              key={index}
              value={value}
              messages={listMessages[index]}
              readOnly={readOnly}
              onChange={update(index)}
              onRemove={() => remove(index)}
            />
          ))}
          {!readOnly && list.length > 0 && (
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <Button fluid onClick={add}>
                  Add Qualification
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
          {!readOnly && list.length > 0 && hasPreviousYearQualifications() && (
            <Grid.Row centered>
              <WrappedMessage warning>
                <Message.Content>
                  This applicant has confirmed {someOrAllInPreviousYear()} of their qualifications were completed in a previous year.
                </Message.Content>
                <Message.Content>
                  <strong>Please ensure they are referred to the school for consideration.</strong>
                </Message.Content>
              </WrappedMessage>
            </Grid.Row>
          )}
          {list.length > 0 && (
            <Grid.Row>
              <Grid.Column width={16}>
                <FieldLabel label="English Language">
                  <MessageHandling messages={addPathPrefix(["english"], splitErrors.english)}>
                    {({ unhandledMessages }) => {
                      return (
                        <>
                          <QualificationsEnglish
                            applicantStrings={applicant}
                            value={value.english}
                            readOnly={readOnly}
                            onChange={onEnglishChange}
                            namespace={`english`}
                          />
                          <ErrorDisplay messages={unhandledMessages} />
                        </>
                      );
                    }}
                  </MessageHandling>
                </FieldLabel>
                <FieldLabel label="Secondary Education">
                  <MessageHandling messages={addPathPrefix(["secondaryEducation"], splitErrors.secondaryEducation)}>
                    {({ unhandledMessages }) => {
                      return (
                        <>
                          <SecondaryEducationQualifications
                            applicantStrings={applicant}
                            readOnly={readOnly}
                            value={value.secondaryEducation}
                            onChange={onSecondaryEducationChange}
                            namespace={`secondaryEducation`}
                          />
                          <ErrorDisplay messages={unhandledMessages} />
                        </>
                      );
                    }}
                  </MessageHandling>
                </FieldLabel>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Form>
    </Container>
  );
}
