import { ApplicantId } from "@qmspringboard/shared/src/model/core.generated";
import { ApplicantViewersChanged } from "@qmspringboard/app/src/model/applicantviews.generated";
import { API_ROOT } from "@qmspringboard/app/src/api";
import useWebSocket, { Options } from "react-use-websocket";

export function useApplicantViewers(applicantId: ApplicantId, onChange: (changed: ApplicantViewersChanged) => void) {
  const url = API_ROOT + "/applicants/" + applicantId + "/view";

  function onMessage(event: MessageEvent): void {
    onChange(JSON.parse(event.data) as ApplicantViewersChanged);
  }

  const options: Options = {
    share: true,
    onMessage: onMessage,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
  };
  return useWebSocket(url, options);
}
