import { Application } from "@qmspringboard/shared/src/model/applications.generated";
import { ApplicationChoiceEnum, OfferStatusEnum } from "@qmspringboard/shared/src/model/enums.generated";

export const willHaveScript = (appn: Application): boolean => {
  const { choice, offerStatus, id } = appn;
  if (id < 0) {
    return false;
  }
  if (choice === ApplicationChoiceEnum.Clearing) {
    switch (offerStatus) {
      case OfferStatusEnum.Made:
        return true;
      case OfferStatusEnum.MadePreRelease:
        return true;
      case OfferStatusEnum.WaitingList:
        return true;
      case OfferStatusEnum.Interview:
        return true;
      case OfferStatusEnum.Declined:
        return true;
      case OfferStatusEnum.Rejected:
        return true;
      default:
        return false;
    }
  }
  return false;
};
