import React from "react";
import { Icon } from "semantic-ui-react";
import { Opt } from "../utils";

interface UcasLinkProps {
  value?: Opt<string>;
  className?: Opt<string>;
}

const UcasLink = ({ value, className }: UcasLinkProps) => {
  if (value == null) {
    return (
      <span className={className || ``} style={{ color: "#aaa" }} title="Enter a UCAS Personal ID to view on UCAS Web-Link">
        <Icon name="external" /> UCAS Web-Link
      </span>
    );
  } else {
    return (
      <a
        className={className || ``}
        target="_blank"
        rel="noopener noreferrer"
        href={`https://weblink.ucas.com/ucaslink/ApplicantEnquiryServlet?appid=${value}`}
        onClick={evt => evt.stopPropagation()}
      >
        <Icon name="external" /> UCAS Web-Link
      </a>
    );
  }
};

export default UcasLink;
