import useWebSocket, { Options } from "react-use-websocket";
import { AnnouncementDTO } from "@qmspringboard/app/src/model/announcements.generated";
import { API_ROOT } from "@qmspringboard/app/src/api";
import { z } from "zod";

/**
 * Assumes that we're logged in and have a valid authentication token in the FetchParams. The server will only send us
 * announcements if we can first send it that valid token.
 */
export function useSubscribeToAnnouncements(onAnnouncement: (announcement: AnnouncementDTO) => void) {
  const url = API_ROOT + "/announcements/subscribe";

  const announcementDTOSchema = z.object({
    acknowledged: z.boolean(),
    headline: z.string(),
    detail: z.string().nullish(),
    timestamp: z.string(),
    authorUserId: z.number().nullish(),
    authorName: z.string(),
    id: z.number(),
  });

  function onMessage(event: MessageEvent): void {
    try {
      const dto = JSON.parse(event.data);
      const parseResult = announcementDTOSchema.safeParse(dto);
      if (parseResult.success) {
        return onAnnouncement(parseResult.data);
      } else {
        console.error(parseResult.error);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const options: Options = {
    share: true,
    onMessage: onMessage,
    shouldReconnect: () => true,
    reconnectAttempts: 10,
  };
  return useWebSocket(url, options);
}
