import React, { Component, Ref, useCallback, useMemo } from "react";
import { Dropdown as SemanticUiDropdown, DropdownProps as SemanticUiDropdownProps } from "semantic-ui-react";
import { BaseFieldProps, SemanticPointing } from "./base";
import { DropdownOptions, ExtraDropdownProps, DropdownOption } from "./DropdownField";
import FieldWrapper from "./FieldWrapper";
import { TextInput } from "./TextInput";

type SemanticUiDropdownValue = boolean | number | string | (boolean | number | string)[] | undefined;

export interface MultiDropdownFieldProps<A> extends BaseFieldProps<A[]>, ExtraDropdownProps {
  forwardRef?: Ref<Component<SemanticUiDropdownProps>>;
  errorPointing?: SemanticPointing;
  options: DropdownOptions<A>;
  search?: boolean | ((options: DropdownOptions<A>, query: string) => DropdownOptions<A>);
}

export default function MultiDropdownField<A>(props: MultiDropdownFieldProps<A>) {
  const {
    forwardRef,
    value,
    options,
    onChange,
    readOnly,
    disabled,
    messages,
    errorPointing,
    // ExtraDropdownProps
    placeholder,
    fluid,
    search,
    clearable,
  } = props;

  const handleChange = useCallback(
    (labels: SemanticUiDropdownValue) => {
      if (Array.isArray(labels)) {
        const values = options.filter(opt => labels.indexOf(opt.label) >= 0).map(opt => opt.value);

        onChange?.(values);
      } else {
        // eslint-disable-next-line no-console
        console.warn("MultiDropdownField.handleChange: expected string[], found", labels);
      }
    },
    [options, onChange],
  );

  const selectedLabels = useMemo(() => options.filter(opt => value.indexOf(opt.value) >= 0).map(opt => opt.label), [options, value]);

  const semanticOption = useCallback(
    (option: DropdownOption<A>) => ({
      key: option.label,
      value: option.label,
      text: option.label,
      content: option.content,
      active: option.active || false,
      disabled: option.disabled || false,
    }),
    [],
  );

  const semanticOptions = useMemo(() => options.map(semanticOption), [options, semanticOption]);

  const semanticSearch = useMemo(
    () =>
      search == null
        ? undefined
        : typeof search === "boolean"
          ? true
          : (_ignored: unknown, query: string) => search(options, query).map(semanticOption),
    [options, search, semanticOption],
  );

  return (
    <FieldWrapper readOnly={readOnly} messages={messages} errorPointing={errorPointing}>
      {readOnly ? (
        <TextInput value={selectedLabels.join(", ")} readOnly={true} placeholder={placeholder} fluid={fluid} disabled={disabled} />
      ) : (
        <SemanticUiDropdown
          ref={forwardRef}
          value={selectedLabels}
          options={semanticOptions}
          search={semanticSearch}
          onChange={(_, p) => handleChange(p.value)}
          disabled={disabled}
          multiple
          selection
          // ExtraDropdownOptions
          placeholder={placeholder}
          fluid={fluid}
          clearable={clearable}
        />
      )}
    </FieldWrapper>
  );
}
