import { Maybe } from "purify-ts/Maybe";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Messages } from "../../model/errors";

export interface InputDebounceProps<A> {
  value: A;
  format: (value: A) => string;
  validate: (value: string) => Maybe<A>;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  onChange?: (value: A) => void;
  // Is this an editable field, or are we just using it for display purposes?
  // Read-only fields don't display error messages:
  // readOnly?: boolean;
  // // Is this field disabled?
  // disabled?: boolean;
  // Settings for debouncing keypresses (defaults to ):
  debounce?: number;
  messages?: Messages;
  // Error message to display if the local value is invalid:
  localError?: string;
  // [DG] I added this prop to work around a bug in AutocompleteField
  // where changes caused by pressing "Enter" while the menu was open weren't reflected in the focused text field.
  // I'm retiscent to change this behaviour globally this close to Clearing,
  // so I've set up this horrible looking prop to ensure we only use it in one place.
  // This is something to review next time we have a complete product review with the QA team.
  updateWhenFocused_EXPERIMENTAL?: boolean;
}

const defaultDebounce = 50; // milliseconds

export interface InputDebounceResult {
  textValue: string;
  handleFocus: (e: SyntheticEvent) => void;
  handleBlur: (e: SyntheticEvent) => void;
  handleChange: (text: string) => void;
  localMessages: Messages;
}

export default function useInputDebounce<A>(props: InputDebounceProps<A>): InputDebounceResult {
  const { value, format, validate, onFocus, onBlur, onChange, debounce, messages, localError, updateWhenFocused_EXPERIMENTAL } = props;

  const [focused, setFocused] = useState(false);
  const [textValue, setTextValue] = useState<string>(format(value));
  const [hasLocalError, setLocalError] = useState<boolean>(false);

  const localMessages = useMemo<Messages>(() => {
    const temp = messages ?? [];
    return hasLocalError ? [{ level: "error", text: localError ?? "Invalid input", path: [] }, ...temp] : temp;
  }, [hasLocalError, localError, messages]);

  useEffect(() => {
    if (!focused || updateWhenFocused_EXPERIMENTAL) {
      setTextValue(format(value));
    }
  }, [value, focused, format, updateWhenFocused_EXPERIMENTAL]);

  const asyncHandleChange = useCallback(
    (text: string) => {
      validate(text)
        .ifJust(a => {
          setLocalError(false);
          onChange?.(a);
        })
        .ifNothing(() => {
          setLocalError(true);
        });
    },
    [onChange, validate],
  );

  const debouncedChange = useDebouncedCallback(asyncHandleChange, debounce ?? defaultDebounce);

  const handleChange = useCallback(
    (text: string) => {
      setTextValue(text);
      debouncedChange(text);
    },
    [debouncedChange],
  );

  const handleFocus = useCallback(
    (evt: SyntheticEvent) => {
      setFocused(true);
      onFocus?.(evt);
    },
    [onFocus],
  );

  const handleBlur = useCallback(
    (evt: SyntheticEvent) => {
      debouncedChange.flush();
      // flushed so become controlled again
      setFocused(false);
      onBlur?.(evt);
    },
    [onBlur, debouncedChange],
  );

  return {
    textValue,
    handleFocus,
    handleBlur,
    handleChange,
    localMessages,
  };
}
