import React from "react";
import { Accordion, AccordionPanelProps, Divider, Grid, Header } from "semantic-ui-react";
import styled from "styled-components";
import {
  ApplicationChoiceEnum,
  ApplicationStatusEnum,
  ClearingDecisionEnum,
  ClearingResponseEnum,
  ConfirmationDecisionEnum,
  ConfirmationResponseEnum,
  FeeStatusEnum,
  InitialDecisionEnum,
  InitialResponseEnum,
  OfferStatusEnum,
} from "../model/enums";
import { Messages } from "../model/errors";
import { ReactNodeLike } from "../utils";
import ErrorDisplay from "./ErrorDisplay";
import RichTextArea from "./fields/RichTextArea";

// HACK: I've duplicated some styles between Code and CodeBlock
// because Typescript 3.9 reports a cyclic type error
// if we get CodeBlock to refer to Code directly:
const Code = styled.code`
  background-color: #f8f0f2;
  color: #c61d43;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 3px;
  padding-right: 3px;
`;

// HACK: I've duplicated some styles between Code and CodeBlock
// because Typescript 3.9 reports a cyclic type error
// if we get CodeBlock to refer to Code directly:
const CodeBlock = styled.code`
  background-color: #f8f0f2;
  color: #c61d43;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 3px;
  padding-right: 3px;

  min-width: 60px;
  text-align: center;
  display: inline-block;
  margin: 0;
`;

const toList = ({ value, label }: { value: string; label: string }) => (
  <Grid.Row key={value}>
    <Grid.Column width={5}>
      <CodeBlock>{value}</CodeBlock>
    </Grid.Column>
    <Grid.Column width={11}>{label}</Grid.Column>
  </Grid.Row>
);

const toSection = ({ name, code, options }: { name: string; code: string; options: ReactNodeLike }) => ({
  key: code,
  title: { content: name },
  content: {
    content: (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <p>
              <Code>{code} = CODE</Code>
            </p>
            <p>
              where <Code>CODE</Code> is one of the following:
            </p>
          </Grid.Column>
        </Grid.Row>
        {options}
      </Grid>
    ),
  },
});

interface Section {
  name: string;
  code: string;
  options: AccordionPanelProps[];
}

const sectionConfig: Section[] = [
  {
    name: "Application Status",
    code: ApplicationStatusEnum.name,
    options: ApplicationStatusEnum.entries.map(toList),
  },
  {
    name: "Fee Code",
    code: FeeStatusEnum.name,
    options: FeeStatusEnum.entries.map(toList),
  },
  {
    name: "Clearing Decision",
    code: ClearingDecisionEnum.name,
    options: ClearingDecisionEnum.entries.map(toList),
  },
  {
    name: "Clearing Response",
    code: ClearingResponseEnum.name,
    options: ClearingResponseEnum.entries.map(toList),
  },
  {
    name: "Initial Decision",
    code: InitialDecisionEnum.name,
    options: InitialDecisionEnum.entries.map(toList),
  },
  {
    name: "Initial Response",
    code: InitialResponseEnum.name,
    options: InitialResponseEnum.entries.map(toList),
  },
  {
    name: "Confirmation Decision",
    code: ConfirmationDecisionEnum.name,
    options: ConfirmationDecisionEnum.entries.map(toList),
  },
  {
    name: "Confirmation Response",
    code: ConfirmationResponseEnum.name,
    options: ConfirmationResponseEnum.entries.map(toList),
  },
  {
    name: "Application Choice",
    code: ApplicationChoiceEnum.name,
    options: ApplicationChoiceEnum.entries.map(toList),
  },
  {
    name: "Offer Status",
    code: OfferStatusEnum.name,
    options: OfferStatusEnum.entries.map(toList),
  },
  {
    name: "Change Of Course Offers (COCOs)",
    code: "COCO",
    options: [
      { value: "Y", label: "The application is a Change Of Course Offer" },
      { value: "N", label: "The application is not a Change Of Course Offer" },
    ].map(toList),
  },
  {
    name: "Expired Tests",
    code: "Expired",
    options: [
      { value: "Y", label: "The application has expired" },
      { value: "N", label: "The application has not expired (but will)" },
    ].map(toList),
  },
];

const panels = [
  {
    key: "ptests",
    title: { content: "Programme Tests" },
    content: {
      content: (
        <div>
          <p>
            <Code>Programme = CODE</Code>
          </p>
          <p>
            where <Code>CODE</Code> is a 4-digit programme Code.
          </p>
        </div>
      ),
    },
  },
  ...sectionConfig.map(toSection),
];

const editorConfig = {
  lineWrapping: true,
  lineNumbers: true,
  smartIndent: false,
  mode: "ruby",
};

interface ClassifierRulesEditorProps {
  value: string;
  onChange: (value: string) => void;
  messages: Messages;
}

export default function ClassifierRulesEditor(props: ClassifierRulesEditorProps) {
  const { value, onChange, messages } = props;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={12}>
          <RichTextArea config={editorConfig} value={value} onChange={onChange} />
          <ErrorDisplay messages={messages} />
        </Grid.Column>
        <Grid.Column width={4}>
          <Header as="h5">Syntax</Header>

          <div>
            <p>
              Classifier rules are <em>if</em> rules:
            </p>

            <p>
              <Code>if TEST then RULE else RULE</Code>
            </p>

            <p>
              Where <Code>TEST</Code> is a yes/no expression and <Code>RULE</Code> is a prediction such as
              <Code>100%</Code>, <Code>25%</Code>, <Code>unknown</Code>, or another <Code>if</Code> rule.
            </p>

            <p>Tests always have a field name on the left and a value on the right. Click one of the following for more information:</p>
          </div>

          <Divider hidden />
          <Accordion defaultActiveIndex={0} panels={panels} styled />
          <Divider hidden />
          <p>
            You can use a <Code>#</Code> to write a comment for your own benefit. Anything from the <Code>#</Code> to the end of the line is ignored.
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
