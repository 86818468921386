import { parseIso } from "@qmspringboard/shared/dist/utils/date";
import { isBefore } from "date-fns";
import React, { Component } from "react";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";
import {
  ApplicationChoiceEnum,
  ApplicationStatusEnum,
  ClearingDecisionEnum,
  ClearingResponseEnum,
  ConfirmationDecisionEnum,
  ConfirmationResponseEnum,
  Enum,
  InitialDecisionEnum,
  InitialResponseEnum,
  OfferStatusEnum,
} from "../model/enums";
import {
  ApplicationChoice,
  ApplicationStatus,
  ClearingDecision,
  ClearingResponse,
  ConfirmationDecision,
  ConfirmationResponse,
  InitialDecision,
  InitialResponse,
  OfferStatus,
} from "../model/types";
import { Opt } from "../utils";

export type Statuses = {
  choice: ApplicationChoice;
  initialDecision?: InitialDecision | null;
  initialResponse?: InitialResponse | null;
  confirmationDecision?: ConfirmationDecision | null;
  confirmationResponse?: ConfirmationResponse | null;
  clearingDecision?: ClearingDecision | null;
  clearingResponse?: ClearingResponse | null;
  applicationStatus?: ApplicationStatus | null;
  offerStatus?: OfferStatus | null;
  offerExpiry?: string | null;
};

type Props = {
  statuses: Statuses;
  showOfferStatus?: boolean;
  style?: Record<string, unknown>;
};

const colours = ["#cfc", "#aff", "#fca", "#fcf", "#f7f7cc"];

function label<A>(srcEnum: Enum<A>) {
  return function (appn: Statuses, value: unknown): string {
    return srcEnum.labelOf(value) || "Blank";
  };
}

function getKey<K extends keyof Statuses>(key: K) {
  return function (application: Statuses): Statuses[K] {
    return application[key];
  };
}

interface StatusIndicator {
  description: string;
  colour: string;
  value: (statuses: Statuses) => Opt<string>;
  label: (statuses: Statuses, value: Opt<string>) => string;
}

const offerStatus: StatusIndicator = {
  description: "Offer Status",
  colour: colours[0],
  value: (appn: Statuses) => {
    const expired = appn.offerExpiry && isBefore(parseIso(appn.offerExpiry), new Date()) ? "-E" : "";

    return `${appn.offerStatus || "?"}${expired}`;
  },
  label: (appn: Statuses, _value: Opt<string>) => {
    const status = OfferStatusEnum.labelOf(appn.offerStatus) || "Blank";
    const expiry = appn.offerExpiry && isBefore(parseIso(appn.offerExpiry), new Date()) ? " (Expired)" : "";
    return status + expiry;
  },
};

const initialResponse: StatusIndicator = {
  description: "Initial Response",
  colour: colours[1],
  value: getKey("initialResponse"),
  label: label(InitialResponseEnum),
};

const initialDecision: StatusIndicator = {
  description: "Initial Decision",
  colour: colours[1],
  value: getKey("initialDecision"),
  label: label(InitialDecisionEnum),
};

const confirmationResponse: StatusIndicator = {
  description: "Confirmation Response",
  colour: colours[2],
  value: getKey("confirmationResponse"),
  label: label(ConfirmationResponseEnum),
};

const confirmationDecision: StatusIndicator = {
  description: "Confirmation Decision",
  colour: colours[2],
  value: getKey("confirmationDecision"),
  label: label(ConfirmationDecisionEnum),
};

const clearingResponse: StatusIndicator = {
  description: "Clearing Response",
  colour: colours[1],
  value: getKey("clearingResponse"),
  label: label(ClearingResponseEnum),
};

const clearingDecision: StatusIndicator = {
  description: "Clearing Decision",
  colour: colours[1],
  value: getKey("clearingDecision"),
  label: label(ClearingDecisionEnum),
};

const applicationStatus: StatusIndicator = {
  description: "Application Status",
  colour: colours[3],
  value: getKey("applicationStatus"),
  label: label(ApplicationStatusEnum),
};

// const types = {
//   initialResponse,
//   initialDecision,
//   confirmationResponse,
//   confirmationDecision,
//   clearingResponse,
//   clearingDecision,
//   applicationStatus,
// };

const ucasStatuses = (_showOfferStatus: boolean) => [initialDecision, initialResponse, confirmationDecision, confirmationResponse, applicationStatus];

const clearingStatuses = (showOfferStatus: boolean) => [
  ...(showOfferStatus ? [offerStatus] : []),
  clearingDecision,
  clearingResponse,
  applicationStatus,
];

const config = {
  [ApplicationChoiceEnum.Clearing]: clearingStatuses,
  [ApplicationChoiceEnum.First]: ucasStatuses,
  [ApplicationChoiceEnum.Second]: ucasStatuses,
  [ApplicationChoiceEnum.Third]: ucasStatuses,
  [ApplicationChoiceEnum.Fourth]: ucasStatuses,
  [ApplicationChoiceEnum.Fifth]: ucasStatuses,
  [ApplicationChoiceEnum.Extra]: ucasStatuses,
};

const Status = styled.span<{ type: StatusIndicator }>`
  display: inline-block;
  font-family: monospace;
  background-color: ${props => props.type.colour};
  padding: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  cursor: default;
`;

const StatusWrapper = styled.span`
  white-space: nowrap;
`;

export default class ApplicationStatusIndicator extends Component<Props> {
  render() {
    const { statuses, style, showOfferStatus } = this.props;
    const showStatuses = config[statuses.choice](showOfferStatus == null ? true : showOfferStatus);
    return (
      <StatusWrapper style={style}>
        {showStatuses.map((status: StatusIndicator, i: number) => {
          return (
            <Popup
              key={i}
              size="mini"
              trigger={<Status type={status}>{status.value(statuses) || "_"}</Status>}
              content={`${status.description}: ${status.label(statuses, status.value(statuses))}`}
            />
          );
        })}
      </StatusWrapper>
    );
  }
}
