import { ddmm, ddmmhhmm, hhmm } from "@qmspringboard/shared/dist/utils/date";

export const PAGE_SIZE = 25;
export const DATE_FORMAT = ddmm;
export const TIME_FORMAT = hhmm;
export const DATE_TIME_FORMAT = ddmmhhmm;

export const ENTER_KEY = 13;
export const UP_ARROW_KEY = 38;
export const DOWN_ARROW_KEY = 40;
