import { Segment, Grid, Container, Divider, Dimmer, Loader } from "semantic-ui-react";
import { SchoolCode } from "../model/types";
import { FieldLabel, DropdownField } from "../components/fields";
import useAllSchools from "../hooks/useAllSchools";
import { RouteComponentProps } from "react-router";
import Title from "../components/Title";
import SubmitButton from "../components/SubmitButton";
import { useAddClearingPlusApplicants } from "../api";
import React from "react";
import ErrorDisplay from "../components/ErrorDisplay";
import FileUploadZone from "../components/FileUploadZone";
import { ValidationError } from "../errors";
import { Messages } from "../model/errors";

function BulkApplicantImportPage({ history }: RouteComponentProps) {
  const allSchools = useAllSchools();
  const [school, setSchool] = React.useState<SchoolCode | null>(null);
  const {
    mutate: uploadApplicantFile,
    status,
    error: serverMessages,
    reset,
  } = useAddClearingPlusApplicants({
    onSuccess: () => {
      history.push(school ? `/?q=${school}:todo%3A"UCAS%20Clearing%20Plus"` : `/?q=todo%3A"UCAS%20Clearing%20Plus"`);
    },
  });

  const messages: Messages =
    status === "error"
      ? serverMessages instanceof ValidationError
        ? serverMessages.messages
        : [
            {
              path: [],
              level: "error",
              text: "Unknown server error",
            },
          ]
      : [];

  const teamOptions = React.useMemo(
    () =>
      allSchools.map(school => ({
        value: school.code,
        label: `${school.code} - ${school.name}`,
      })),
    [allSchools],
  );

  const [droppedFile, setDroppedFile] = React.useState<File | null>(null);

  React.useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [droppedFile, school]);

  const onDrop = React.useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];
      setDroppedFile(file);
    }
  }, []);

  return (
    <Title title="Upload Clearing Plus Applicants">
      <Dimmer.Dimmable as={Container}>
        <Dimmer active={status === "loading"} inverted>
          <Loader>Saving</Loader>
        </Dimmer>
        <ErrorDisplay messages={messages} />
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <FileUploadZone messages={[]} onDrop={onDrop} value={droppedFile ? [droppedFile] : undefined} onClear={() => setDroppedFile(null)} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <FieldLabel label="Home School">
                <DropdownField fluid value={school} readOnly={false} placeholder="Select a School" onChange={setSchool} options={teamOptions} />
              </FieldLabel>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />

        <Segment basic vertical textAlign="right">
          <SubmitButton
            disabled={!droppedFile || !school}
            onClick={() => droppedFile && school && uploadApplicantFile({ upload: droppedFile, schoolCode: school })}
            messages={messages}
          >
            Import Applicants
          </SubmitButton>
        </Segment>
      </Dimmer.Dimmable>
    </Title>
  );
}

export default BulkApplicantImportPage;
