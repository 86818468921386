import lodash from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { Container, Header, Icon, ModalProps, Segment } from "semantic-ui-react";
import useProgrammeList from "../hooks/useProgrammeList";
import { Permissions } from "../model/permission";
import { admissionsTeamCode, Team, TeamCode, teamCodeToString, teamToSchoolCode } from "../model/team";
import { Application, Label } from "../model/types";
import ApplicantLabel from "./ApplicantLabel";
import { assertNever } from "../utils";

interface Props extends ModalProps {
  applicantId: number;
  applications: Application[];
  labels: Label[];
  teamCode: TeamCode;
  allTeams: Team[];
  onUpdateLabel: (oldLabel: Label, newLabel: Label) => void;
  permissions: Permissions;
}

export default function ApplicantLabels(props: Props) {
  const { applications, labels, teamCode, allTeams, onUpdateLabel, permissions } = props;

  const programmes = useProgrammeList();

  const [showOtherTeams, setShowOtherTeams] = useState(false);

  const canUpdateLabels = useCallback((teamCode: TeamCode) => permissions.canUpdateLabels(teamCode), [permissions]);

  const handleLabelClick = useCallback(
    (label: Label) => {
      switch (label.labelType) {
        case "std":
        case "fao":
          onUpdateLabel(label, { ...label, selected: !label.selected });
          break;

        case "todo":
          if (!label.selected) {
            onUpdateLabel(label, { ...label, selected: true, checked: false });
          } else if (!label.checked) {
            onUpdateLabel(label, { ...label, selected: true, checked: true });
          } else {
            onUpdateLabel(label, { ...label, selected: false, checked: false });
          }
          break;

        default:
          assertNever(label.labelType);
      }
    },
    [onUpdateLabel],
  );

  const admissionsTeam = useMemo(() => allTeams.find(team => team.code === admissionsTeamCode && team.code !== teamCode), [allTeams, teamCode]);

  const applicationTeams: Team[] = useMemo(
    () =>
      lodash
        .chain(applications)
        .map(appn => programmes.find(prog => appn.programmeCode === prog.code))
        .map(prog => (prog == null ? null : allTeams.find(team => prog.schoolCode === teamToSchoolCode(team.code))))
        .uniq()
        .flatMap(team => (team != null && team.code !== teamCode && team.code !== admissionsTeam?.code ? [team] : []))
        .value(),
    [allTeams, teamCode, admissionsTeam, applications, programmes],
  );

  const otherTeams: Team[] = useMemo(
    () =>
      lodash
        .chain(allTeams)
        .filter(team => team.code !== teamCode && team.code !== admissionsTeam?.code && !applicationTeams.some(t => t.code === team.code))
        .value(),
    [allTeams, teamCode, admissionsTeam, applicationTeams],
  );

  function renderLabelList(teamCode: TeamCode, mySchool: boolean, labels: Label[]) {
    const title: string = teamCode;
    const readOnly: boolean = !canUpdateLabels(teamCode);

    const items = labels
      .map(label =>
        teamToSchoolCode(teamCode) === label.schoolCode ? (
          <ApplicantLabel
            key={`${label.prototypeId}-${label.applicantId}`}
            label={label}
            readOnly={readOnly}
            onClick={readOnly ? undefined : () => handleLabelClick(label)}
          />
        ) : null,
      )
      .filter(x => x != null);

    return (
      <Container key={teamCodeToString(teamCode)} style={mySchool ? undefined : { marginBottom: "1.5em" }}>
        <Header size="small">Labels for {title}</Header>

        {items}

        {items.length === 0 && (
          <Segment basic textAlign="center">
            {readOnly ? `This applicant has no labels for ${title}.` : 'Click "+" above to add a label.'}
          </Segment>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Header as="h3" dividing>
        Labels
      </Header>

      {renderLabelList(teamCode, true, labels)}
      {admissionsTeam && renderLabelList(admissionsTeam.code, true, labels)}
      {applicationTeams.map(team => renderLabelList(team.code, true, labels))}

      {otherTeams.length > 0 && (
        <Segment basic vertical>
          {/* eslint-disable-next-line */}
          <a onClick={() => setShowOtherTeams(!showOtherTeams)} style={{ cursor: "pointer" }}>
            <Icon name={showOtherTeams ? "caret up" : "caret down"} />
            {showOtherTeams ? "Hide labels for other schools" : "Show labels for other schools"}
          </a>
        </Segment>
      )}

      {showOtherTeams && otherTeams.map(otherTeam => renderLabelList(otherTeam.code, false, labels))}
    </Container>
  );
}
