import { LabelPrototype, Message, unsafeTag, SchoolCode } from "../../model/types";
import labelColors from "../../utils/labelColors";

export type MyState = {
  fetching: boolean;
  fetched: boolean;
  proto: LabelPrototype;
  originalGroup: LabelPrototype;
  clientMessages: Message[];
  serverMessages: Message[];
};

export const emptyLabelPrototype = (schoolCode: SchoolCode = unsafeTag<"SchoolCode">("")): LabelPrototype => ({
  schoolCode,
  labelType: "std",
  text: "",
  color: labelColors[0],
  isSystem: false,
  id: -1,
});

export const initialState = (): MyState => {
  const proto = emptyLabelPrototype();

  return {
    fetching: false,
    fetched: false,
    proto: proto,
    originalGroup: proto,
    clientMessages: [],
    serverMessages: [],
  };
};
