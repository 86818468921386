import React from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import styled, { css } from "styled-components";
import { Label } from "../model/types";
import Color from "color";

interface Props {
  label: Label;
  readOnly: boolean;
  onClick?: () => void;
}

const StyledLabel = styled.div<{
  labelColor: string;
  textColor: string;
  iconColor: string;
  readOnly: boolean;
}>`
  position: relative;
  width: 100%;
  min-height: 1.5em;
  vertical-align: center;
  margin: 0.5em 0;
  padding: 0.5em;
  border-radius: 3px;
  background-color: ${props => props.labelColor};
  color: ${props => props.textColor};
  font-size: 0.8rem;

  .checkbox label {
    color: ${props => props.textColor} !important;
    font-size: 0.8rem;
  }

  i {
    position: absolute;
    color: ${props => props.iconColor} !important;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 3px;
    z-index: 2;
  }

  ${props =>
    props.readOnly
      ? css``
      : css`
          &:hover .overlay {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.05);
          }
        `}

  .text {
    position: relative;
    top: 0;
    bottom: 0;
    left: 2em;
    right: 0;
    margin-right: 2em;
  }
`;

function labelColor(label: Label) {
  return label.selected ? label.color : "#eee";
}

function textColor(label: Label) {
  return label.selected ? (Color(label.color).isLight() ? "inherit" : "#fff") : "#aaa";
}

function iconColor(label: Label) {
  return label.selected ? (Color(label.color).isLight() ? "rgba(0,0,0,.2)" : "rgba(255,255,255,.7)") : "rgba(0,0,0,.2)";
}

export default function ApplicantLabel(props: Props) {
  const { label, readOnly, onClick } = props;

  switch (label.labelType) {
    case "std":
      return (
        <StyledLabel labelColor={labelColor(label)} textColor={textColor(label)} iconColor={iconColor(label)} readOnly={readOnly} onClick={onClick}>
          <div className="overlay"></div>
          <Icon name="tag" />
          <div className="text">{label.text}</div>
        </StyledLabel>
      );

    case "fao":
      return (
        <StyledLabel labelColor={labelColor(label)} textColor={textColor(label)} iconColor={iconColor(label)} readOnly={readOnly} onClick={onClick}>
          <div className="overlay"></div>
          <Icon name="users" />
          <div className="text">{label.text}</div>
        </StyledLabel>
      );

    case "todo":
      return (
        <StyledLabel labelColor={labelColor(label)} textColor={textColor(label)} iconColor={iconColor(label)} readOnly={readOnly} onClick={onClick}>
          <div className="overlay"></div>
          {label.selected ? <Checkbox checked={label.checked} disabled={readOnly} label={label.text} /> : <div className="text">{label.text}</div>}
        </StyledLabel>
      );
  }
}
