import React from "react";

import { Application, Programme } from "../model/types";

import { ApplicationMethod, ApplicationMethodEnum } from "../model/enums";

import { ApplicantEditorState, applicantRequiresConfirmationEmail } from "../model/applicant";

import { applicationRequiresConfirmationEmail, applicationChoiceToMethod, isLiveApplication } from "@qmspringboard/shared/dist/model/application";

import { TeamCode, foldTeamCode } from "../model/team";
import { AppDispatch } from "./actions";
import { Opt } from "../utils";
import { showModal } from "./modal";

export function handoverConfirmModalCpsStep(dispatch: AppDispatch, handover: boolean, applications: Application[]) {
  return function (next: () => void) {
    const numLiveApplications = applications.filter(isLiveApplication).length;

    if (!handover) {
      // Reverting handover:
      next();
    } else if (numLiveApplications === 1) {
      // Handing over with one live application:
      next();
    } else {
      // Handing over with more than one live application:
      dispatch(
        showModal("confirm", {
          title: "Hand over to admissions",
          content: <p>This applicant has {numLiveApplications} live application(s). Are you sure you want to hand it over to admissions?</p>,
          primaryButtonText: "Yes!",
          onPrimaryClick: next,
        }),
      );
    }
  };
}

export function handoverConfirmEmailModalCpsStep(dispatch: AppDispatch, handover: boolean, email?: Opt<string>) {
  return function (next: (sendEmail: boolean) => void) {
    if (handover) {
      if (email == null) {
        dispatch(
          showModal("confirm", {
            title: "Cannot send email",
            content: <p>The applicant has no email address. We cannot send them a confirmation email.</p>,
            primaryButtonText: "Save without sending",
            onPrimaryClick: () => next(true),
          }),
        );
      } else {
        dispatch(
          showModal("confirm", {
            title: "Send email confirmation?",
            content: <p>Do you want to send a confirmation email to the applicant?</p>,
            primaryButtonText: "Yes - send the email",
            secondaryButtonText: "No - just save the application",
            onPrimaryClick: () => next(true),
            onSecondaryClick: () => next(false),
          }),
        );
      }
    } else {
      next(false);
    }
  };
}

/**
 * Creates a function that:
 * - displays a "create application confirmation" modal if appropriate;
 * - calls next() in continuation passing style.
 */
export function duplicateApplicationConfirmationModalCpsStep(
  dispatch: AppDispatch,
  method: ApplicationMethod,
  existingAppns: Application[],
  teamCode: TeamCode,
  programmes: Programme[],
) {
  return function (next: () => void) {
    foldTeamCode(teamCode, next, schoolCode => {
      const applicationSchool = (appn: Application): string | undefined => {
        const prog = programmes.find(prog => prog.code === appn.programmeCode);
        return prog && prog.schoolCode;
      };

      const otherAppn = existingAppns.find(appn => {
        const appnMethod = applicationChoiceToMethod(appn.choice);
        return isLiveApplication(appn) && applicationSchool(appn) === schoolCode && appnMethod === method;
      });

      if (otherAppn != null) {
        const methodLabel = ApplicationMethodEnum.labelOf(method) || "";

        const otherMethod = applicationChoiceToMethod(otherAppn.choice);
        const otherMethodLabel = ApplicationMethodEnum.labelOf(otherMethod) || "";

        dispatch(
          showModal("confirm", {
            title: `Create ${methodLabel} application`,
            content: (
              <p>
                This applicant has an existing live {otherMethodLabel} application to a programme in your School.{" "}
                {method === otherMethod && (
                  <span>
                    Unless the applicant specifically wants to apply to two different programmes at the same time, you should probably edit the
                    existing application instead of creating a new one.
                  </span>
                )}{" "}
                Are you sure you want to create a new application?
              </p>
            ),
            primaryButtonText: "Yes - create a new application!",
            onPrimaryClick: next,
          }),
        );
      } else next();
    });
  };
}

function emailConfirmationModalCpsStep(dispatch: AppDispatch, requiresEmail: boolean, emailAddress?: Opt<string>) {
  return function (next: (sendEmail: boolean) => void) {
    if (requiresEmail) {
      if (emailAddress == null) {
        dispatch(
          showModal("confirm", {
            title: "Cannot send email",
            content: <p>The applicant has no email address. We cannot send them email confirmation.</p>,
            primaryButtonText: "Save without sending",
            onPrimaryClick: () => next(true),
          }),
        );
      } else {
        dispatch(
          showModal("confirm", {
            title: "Send email confirmation?",
            content: <p>Do you want to send email confirmation to the applicant?</p>,
            primaryButtonText: "Yes - send the email",
            secondaryButtonText: "No - just save the application",
            onPrimaryClick: () => next(true),
            onSecondaryClick: () => next(false),
          }),
        );
      }
    } else {
      next(true);
    }
  };
}

export function applicantEmailConfirmationModalCpsStep(dispatch: AppDispatch, dto: ApplicantEditorState) {
  return emailConfirmationModalCpsStep(dispatch, applicantRequiresConfirmationEmail(dto), dto.applicant.details.email);
}

export function applicationEmailConfirmationModalCpsStep(
  dispatch: AppDispatch,
  emailAddress: Opt<string>,
  appn: Application,
  orig?: Opt<Application>,
) {
  return emailConfirmationModalCpsStep(dispatch, applicationRequiresConfirmationEmail(appn, orig), emailAddress);
}
