import React from "react";
import { Permissions } from "../model/permission";

import { Checkbox, Container, List, Segment } from "semantic-ui-react";

import { PermissionType } from "../model/types";

import { GlobalPermissionTypeEnum, SchoolPermissionTypeEnum } from "../model/enums";

import { FieldLabel } from "./fields";
import { Opt } from "../utils";

function has(perms: PermissionType[], type: PermissionType): boolean {
  return perms.find(perm => perm === type) != null;
}

const toggle = (perms: PermissionType[], type: PermissionType): PermissionType[] => {
  return has(perms, type) ? perms.filter(perm => perm !== type) : [...perms, type];
};

type Props = {
  value: PermissionType[];
  readOnly: boolean;
  onChange: (permissions: PermissionType[]) => void;
  permissions: Permissions; // Checks on what the user can do.
};

type State = {
  selectedSchool: Opt<string>;
};

interface PermissionCheckboxProps {
  type: PermissionType; // The permission we are a checkbox for
  school?: string;
  value: PermissionType[]; // The permissions the user has
  readOnly: boolean;
  onChange: (value: PermissionType[]) => void;
}

function PermissionCheckbox(props: PermissionCheckboxProps) {
  const { type, value, readOnly, onChange } = props;

  return (
    <Checkbox
      checked={has(value, type)}
      disabled={readOnly}
      label={GlobalPermissionTypeEnum.labelOf(type) || SchoolPermissionTypeEnum.labelOf(type)}
      onChange={() => onChange(toggle(value, type))}
    />
  );
}

interface PermissionCheckboxesProps {
  types: PermissionType[];
  school?: string;
  value: PermissionType[];
  readOnly: boolean;
  onChange: (value: PermissionType[]) => void;
}

const PermissionCheckboxes = ({
  types, // The permissions we are displaying
  school,
  value, // The permissions the user has (to check the checkboxes)
  readOnly,
  onChange,
}: PermissionCheckboxesProps) => (
  <List style={{ marginBottom: "1.5em" }}>
    {types.map((type, index) => (
      <List.Item key={index}>
        <PermissionCheckbox type={type} school={school} readOnly={readOnly} value={value} onChange={onChange} />
      </List.Item>
    ))}
  </List>
);

export default class PermissionsEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { value, onChange, readOnly, permissions } = this.props;

    return (
      <Container>
        <Segment>
          {permissions.canUpdateDeveloperPermissions() && (
            <FieldLabel label="Developer Permissions">
              <PermissionCheckboxes
                types={[GlobalPermissionTypeEnum.SuperUser, GlobalPermissionTypeEnum.CanActAsOtherUsers]}
                readOnly={readOnly}
                school={undefined}
                value={value}
                onChange={onChange}
              />
            </FieldLabel>
          )}

          <FieldLabel label="Basic Permissions">
            <PermissionCheckboxes
              types={[
                GlobalPermissionTypeEnum.CanCreateApplicants,
                GlobalPermissionTypeEnum.CanUpdateApplicantDetails,
                GlobalPermissionTypeEnum.CanUpdateQualifications,
                GlobalPermissionTypeEnum.CanUpdateNonALevelQualifications,
              ]}
              readOnly={readOnly}
              school={undefined}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>

          <FieldLabel label="Hotline Permissions">
            <PermissionCheckboxes
              types={[
                GlobalPermissionTypeEnum.CanUpdateAdmissionsLabels,
                GlobalPermissionTypeEnum.CanUpdateAdmissionsNotes,
                GlobalPermissionTypeEnum.CanTransferApplicants,
                GlobalPermissionTypeEnum.CanMakeHotlineClearingOffers,
              ]}
              readOnly={readOnly}
              school={undefined}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>

          <FieldLabel label="Administrative Permissions">
            <PermissionCheckboxes
              types={[
                GlobalPermissionTypeEnum.CanUpdateProgrammes,
                GlobalPermissionTypeEnum.CanMakeInterSchoolChangesOfProgramme,
                GlobalPermissionTypeEnum.CanUpdateUsers,
                GlobalPermissionTypeEnum.CanAddAnnouncements,
                GlobalPermissionTypeEnum.CanImportUcasClearingPlusApplicants,
                GlobalPermissionTypeEnum.CanViewInternationalEquivsTable,
                GlobalPermissionTypeEnum.CanUpdateAdmissionsSavedSearches,
                GlobalPermissionTypeEnum.CanUpdateAdmissionsLabelPrototypes,
                GlobalPermissionTypeEnum.CanUpdateAdmissionsTargets,
                GlobalPermissionTypeEnum.CanExportSPOData,
                GlobalPermissionTypeEnum.CanExportTransferGroups,
                GlobalPermissionTypeEnum.CanSyncSelectorList,
              ]}
              readOnly={readOnly}
              school={undefined}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>

          <FieldLabel label={`Basic School Permissions`}>
            <PermissionCheckboxes
              types={[SchoolPermissionTypeEnum.CanUpdateSchoolLabels, SchoolPermissionTypeEnum.CanUpdateSchoolNotes]}
              readOnly={readOnly}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>

          <FieldLabel label={`School Offer Permissions`}>
            <PermissionCheckboxes
              types={[
                SchoolPermissionTypeEnum.CanPutOnClearingWaitingList,
                SchoolPermissionTypeEnum.CanMakeClearingOffers,
                SchoolPermissionTypeEnum.CanRejectClearingApplications,
                SchoolPermissionTypeEnum.CanAcceptClearingApplications,
                SchoolPermissionTypeEnum.CanInterviewApplications,
                SchoolPermissionTypeEnum.CanUpdateUcasApplications,
                SchoolPermissionTypeEnum.CanMakeOffersOnClosedProgrammes,
              ]}
              readOnly={readOnly}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>

          <FieldLabel label={`School Administrative Permissions`}>
            <PermissionCheckboxes
              types={[
                SchoolPermissionTypeEnum.CanUpdatePredictionOverrides,
                // Handover has been hidden as an option in the UI since 2023:
                // SchoolPermissionTypeEnum.CanHandoverApplications,
                SchoolPermissionTypeEnum.CanUpdateCocoStatuses,
                SchoolPermissionTypeEnum.CanUpdateUcasSitsFields,
                SchoolPermissionTypeEnum.CanUpdateReportingGroups,
                SchoolPermissionTypeEnum.CanUpdateSchoolSavedSearches,
                SchoolPermissionTypeEnum.CanUpdateSchoolLabelPrototypes,
                SchoolPermissionTypeEnum.CanUpdateSchoolConfig,
                SchoolPermissionTypeEnum.CanUpdateSchoolDeadlines,
                SchoolPermissionTypeEnum.CanUpdateClassifierRules,
                SchoolPermissionTypeEnum.CanUpdateTelephoneScripts,
                SchoolPermissionTypeEnum.CanUpdateEmailTemplates,
              ]}
              readOnly={readOnly}
              value={value}
              onChange={onChange}
            />
          </FieldLabel>
        </Segment>
      </Container>
    );
  }
}
