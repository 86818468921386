import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import ApplicantTransferCompletePage from "../components/ApplicantTransferCompletePage";
import ApplicantCreatePage from "./ApplicantCreatePage";
import ApplicantPrintPage from "./ApplicantPrintPage";
import ApplicantSearchPage from "./ApplicantSearchPage";
import ApplicantUpdatePage from "./ApplicantUpdatePage";

const baseName = "/applicant";

const applicantRoutes = [
  {
    path: baseName + "/search",
    component: ApplicantSearchPage,
  },
  {
    path: baseName + "/new", // create new
    component: ApplicantCreatePage,
  },
  {
    path: baseName + "/:applicantId/complete", // created
    component: ApplicantTransferCompletePage,
  },
  {
    path: baseName + "/:applicantId/print", // created
    component: ApplicantPrintPage,
  },
  {
    path: baseName + "/:applicantId", // edit applicant
    component: ApplicantUpdatePage,
  },
];

interface Props extends RouteComponentProps {
  //
}

export default class Applicant extends React.Component<Props> {
  render() {
    return (
      <Switch>
        {applicantRoutes.map(({ path, component }, i) => (
          <Route key={i} path={path} component={component} />
        ))}
      </Switch>
    );
  }
}
