import { Opt } from "../utils";

export function formatNameWithInitials(surname: Opt<string>, forenames: Opt<string>) {
  const initials = forenames?.replace(/[^\w]*(\w)\w*[^w]?/g, "$1").toUpperCase();

  if (surname == null) {
    if (initials == null) {
      return `-`;
    } else {
      return `???, ${initials}`;
    }
  } else {
    if (initials == null) {
      return surname;
    } else {
      return `${surname}, ${initials}`;
    }
  }
}
