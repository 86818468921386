import { isCompletelyClosed, isCompletelyFull } from "@qmspringboard/shared/dist/model/programme";
import { checkExhausted } from "@qmspringboard/shared/dist/utils";
import Opt from "@qmspringboard/shared/dist/utils/opt";
import lodash from "lodash";
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import useInterval from "react-useinterval";
import { Dimmer, Icon, Label, Loader, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import Title from "../components/Title";
import { SncProgrammeSummary } from "../model/types";
import * as snc from "../reducers/snc";

const REFRESH_TTL = 60; // seconds

interface SncPageProps {
  dto: Opt<SncProgrammeSummary[]>;
  fetching: boolean;
  fetch: () => void;
}

const ResponsiveTable = styled.div`
  margin: 0 2em 2em;
  overflow-x: auto;
`;

type SortOrder = "code" | "name" | "schoolCode" | "status" | "isClosedHome" | "isClosedOverseas" | "fullGroupsHome" | "fullGroupsOverseas";

const TweakedHeaderCell = styled(Table.HeaderCell)`
  border-left: 1px solid rgba(34, 36, 38, 0.1) !important;
`;

function SncPage(props: SncPageProps): ReactElement | null {
  const { dto: unsorted, fetching, fetch } = props;

  const [ttl, setTtl] = useState(REFRESH_TTL); // seconds

  const [sortOrder, setSortOrder] = useState<SortOrder>("code");
  const [sortAsc, setSortAsc] = useState(true);

  const sortedAttr = useCallback(
    (order: SortOrder): "ascending" | "descending" | undefined => (order === sortOrder ? (sortAsc ? "ascending" : "descending") : undefined),
    [sortOrder, sortAsc],
  );

  const resort = useCallback(
    (order: SortOrder) => {
      if (order === sortOrder) {
        setSortAsc(!sortAsc);
      } else {
        setSortOrder(order);
        setSortAsc(true);
      }
    },
    [sortOrder, sortAsc],
  );

  const sortFunc: (snc: SncProgrammeSummary) => string | number = useMemo(() => {
    switch (sortOrder) {
      case "code":
        return dto => dto.code;
      case "name":
        return dto => dto.name;
      case "schoolCode":
        return dto => dto.schoolCode;
      case "status":
        return dto =>
          isCompletelyClosed(dto) || isCompletelyFull(dto)
            ? -3
            : dto.isClosedOverseas || dto.isFullOverseas
              ? -2
              : dto.isClosedHome || dto.isFullHome
                ? -1
                : 0;
      case "isClosedHome":
        return dto => (dto.isClosedHome ? -1 : 0);
      case "isClosedOverseas":
        return dto => (dto.isClosedOverseas ? -1 : 0);
      case "fullGroupsHome":
        return dto => -dto.fullGroupsHome.length;
      case "fullGroupsOverseas":
        return dto => -dto.fullGroupsOverseas.length;
      default:
        return checkExhausted(sortOrder);
    }
  }, [sortOrder]);

  const sorted = useMemo(
    () =>
      unsorted == null ? null : sortAsc ? lodash.chain(unsorted).sortBy(sortFunc).value() : lodash.chain(unsorted).sortBy(sortFunc).reverse().value(),
    [unsorted, sortFunc, sortAsc],
  );

  useInterval(() => {
    if (ttl <= 0) {
      fetch();
      setTtl(REFRESH_TTL);
    } else {
      setTtl(ttl - 1);
    }
  }, 1000);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <Title title="Programme Status">
      <Segment basic>
        <Dimmer.Dimmable dimmed={fetching}>
          <Dimmer active={fetching} inverted>
            <Loader>Loading</Loader>
          </Dimmer>

          {sorted && (
            <ResponsiveTable>
              <Table striped celled unstackable sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell sorted={sortedAttr("code")} onClick={() => resort("code")} rowSpan={2} verticalAlign="bottom">
                      Code
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("name")} onClick={() => resort("name")} rowSpan={2} verticalAlign="bottom">
                      Name
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("schoolCode")} onClick={() => resort("schoolCode")} rowSpan={2} verticalAlign="bottom">
                      School
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("status")} onClick={() => resort("status")} rowSpan={2} verticalAlign="bottom">
                      Status
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} textAlign="center" disabled={true}>
                      Closed?
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} textAlign="center" disabled={true}>
                      Full?
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} textAlign="center" disabled={true}>
                      Full groups
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <TweakedHeaderCell sorted={sortedAttr("isClosedHome")} onClick={() => resort("isClosedHome")}>
                      Home
                    </TweakedHeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("isClosedOverseas")} onClick={() => resort("isClosedOverseas")}>
                      Overseas
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("fullGroupsHome")} onClick={() => resort("fullGroupsHome")}>
                      Home
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("fullGroupsOverseas")} onClick={() => resort("fullGroupsOverseas")}>
                      Overseas
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("fullGroupsHome")} onClick={() => resort("fullGroupsHome")}>
                      Home
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortedAttr("fullGroupsOverseas")} onClick={() => resort("fullGroupsOverseas")}>
                      Overseas
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {sorted.map(row => {
                    const { code, name, schoolCode, isFullHome, isFullOverseas, isClosedHome, isClosedOverseas, fullGroupsHome, fullGroupsOverseas } =
                      row;

                    const completelyClosed = isCompletelyClosed(row);
                    const completelyFull = isCompletelyFull(row);

                    return (
                      <Table.Row key={code}>
                        <Table.Cell>{code}</Table.Cell>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{schoolCode}</Table.Cell>
                        <Table.Cell>
                          {completelyClosed ? (
                            <Label color="red">Closed</Label>
                          ) : completelyFull ? (
                            <Label color="orange">Full</Label>
                          ) : isClosedHome || isFullHome ? (
                            <Label color="teal">Overseas only</Label>
                          ) : isClosedOverseas || isFullOverseas ? (
                            <Label color="teal">Home only</Label>
                          ) : (
                            <Label>Spaces</Label>
                          )}
                        </Table.Cell>
                        <Table.Cell textAlign="center">{isClosedHome ? <Icon name="checkmark" color="red" /> : null}</Table.Cell>
                        <Table.Cell textAlign="center">{isClosedOverseas ? <Icon name="checkmark" color="red" /> : null}</Table.Cell>
                        <Table.Cell textAlign="center">{fullGroupsHome.length > 0 ? <Icon name="checkmark" color="red" /> : null}</Table.Cell>
                        <Table.Cell textAlign="center">{fullGroupsOverseas.length > 0 ? <Icon name="checkmark" color="red" /> : null}</Table.Cell>
                        <Table.Cell>{fullGroupsHome.join(", ")}</Table.Cell>
                        <Table.Cell>{fullGroupsOverseas.join(", ")}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </ResponsiveTable>
          )}

          <Segment basic textAlign="center">
            <small>Page will refresh in {ttl} seconds.</small>
          </Segment>
        </Dimmer.Dimmable>
      </Segment>
    </Title>
  );
}

export default connect(
  state => ({
    dto: snc.data(state),
    fetching: snc.fetching(state),
  }),
  {
    fetch: snc.fetch,
  },
)(SncPage);
