import React from "react";
import { BaseFieldProps } from "./base";
import { BaseTextArea, ExtraTextAreaProps } from "./BaseTextArea";
import { format, validate } from "./NullableTextInput";
import { Opt } from "../../utils";

export interface NullableTextAreaProps extends BaseFieldProps<Opt<string>>, ExtraTextAreaProps {}

export default function NullableTextArea(props: NullableTextAreaProps) {
  return <BaseTextArea format={format} validate={validate} {...props} />;
}
