import React from "react";
import styles from "./AnnouncementNotificationContainer.module.css";
import Portal from "./Portal";
import Notification from "./Notification";
import { addAnnouncementToCache, useAcknowledgeAnnouncement, useAnnouncements } from "../api";
import { useLocation } from "react-router";
import { useSubscribeToAnnouncements } from "@qmspringboard/app/src/hooks/useSubscribeToAnnouncements";
import { InternalMessagesContext } from "@qmspringboard/app/src/components/InternalMessageContext";

function AnnouncementNotificationContainer() {
  const location = useLocation();
  const announcements = useAnnouncements(true);
  const { mutate: handleAcknowledge } = useAcknowledgeAnnouncement();
  const internalMessages = React.useContext(InternalMessagesContext);

  useSubscribeToAnnouncements(addAnnouncementToCache);

  if (location.pathname === "/announcements") {
    return null;
  }

  switch (announcements.status) {
    case "error": {
      // FIXME: maybe show a notification
      console.error(announcements.error);
      return null;
    }
    case "idle":
    case "loading": {
      return null;
    }
    case "success": {
      const unreadAnnouncements = announcements.data.items.filter(a => !a.acknowledged);

      // only bother creating a portal if we have announcements
      if (unreadAnnouncements.length === 0 && internalMessages.messages.length === 0) {
        return null;
      }

      return (
        <Portal className={styles.portal} id="announcements">
          <div className={styles.notificationContainer}>
            <Notification
              maxToPreview={3}
              messages={unreadAnnouncements}
              onAcknowledge={() => {
                const maxSeenId = unreadAnnouncements.reduce((acc, a) => (a.id > acc ? a.id : acc), 0);
                handleAcknowledge(maxSeenId);
              }}
            />
            <Notification
              maxToPreview={3}
              messages={internalMessages.messages}
              onAcknowledge={() => {
                internalMessages.clear();
              }}
            />
          </div>
        </Portal>
      );
    }
  }
}

export default React.memo(AnnouncementNotificationContainer);
