import React from "react";
import { Checkbox, Form, Grid } from "semantic-ui-react";
import { subErrors, Messages } from "../model/errors";
import { Permissions } from "../model/permission";
import { admissionsTeam, teamToSchoolCode } from "../model/team";
import { User } from "../model/types";
import ErrorDisplay from "./ErrorDisplay";
import { DropdownField, FieldLabel, TextInput } from "./fields";
import useAllSchools from "../hooks/useAllSchools";
import UserRoleEditor from "./UserRoleEditor";
import useAllRoles from "../hooks/useAllRoles";

interface Props {
  value: User;
  messages: Messages;
  onChange: (value: User) => void;
  readOnly: boolean;
  permissions: Permissions;
}

export default function UserEditor({ value, messages, readOnly, onChange }: Props) {
  const allSchools = useAllSchools();
  const allRoles = useAllRoles();
  const splitErrors = subErrors(messages, {
    username: true,
    email: true,
    surname: true,
    forenames: true,
    schoolCode: true,
    permissions: true,
    defaultSchoolCode: true,
  });

  const teamOptions = [
    {
      value: teamToSchoolCode(admissionsTeam.code),
      label: admissionsTeam.name,
    },
    ...allSchools.map(school => ({
      value: school.code,
      label: `${school.code} - ${school.name}`,
    })),
  ];

  return (
    <Form as="div">
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label="Username">
              <TextInput value={value.username} readOnly={readOnly} onChange={username => onChange({ ...value, username })} />
              <ErrorDisplay attached messages={splitErrors.username} />
            </FieldLabel>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label="Email">
              <TextInput value={value.email} readOnly={readOnly} onChange={email => onChange({ ...value, email })} />
              <ErrorDisplay attached messages={splitErrors.email} />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label="Surname">
              <TextInput value={value.surname} readOnly={readOnly} onChange={surname => onChange({ ...value, surname })} />
              <ErrorDisplay attached messages={splitErrors.surname} />
            </FieldLabel>
          </Grid.Column>

          <Grid.Column mobile={16} tablet={8} computer={8}>
            <FieldLabel label="Forenames">
              <TextInput value={value.forenames} readOnly={readOnly} onChange={forenames => onChange({ ...value, forenames })} />
              <ErrorDisplay attached messages={splitErrors.forenames} />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label="Home School">
              <DropdownField
                fluid
                value={value.defaultSchoolCode}
                readOnly={readOnly}
                placeholder="Central Admissions"
                onChange={defaultSchoolCode => onChange({ ...value, defaultSchoolCode })}
                options={teamOptions}
              />
              <ErrorDisplay attached messages={splitErrors.defaultSchoolCode} />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <FieldLabel label="Can Login">
              <Checkbox
                checked={value.canLogin}
                label="Allow the user to login?"
                readOnly={readOnly}
                onChange={_checked => onChange({ ...value, canLogin: !value.canLogin })}
              />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <FieldLabel label="Roles">
              <UserRoleEditor
                allSchools={allSchools}
                allRoles={allRoles}
                userRoles={value.roles}
                defaultSchoolCode={value.defaultSchoolCode}
                onChange={maybeRoles =>
                  onChange({
                    ...value,
                    // If the interface gives us missing roles, trim these entries out from an update of the user
                    roles: maybeRoles.flatMap(mr => (mr.role ? [{ ...mr, role: mr.role }] : [])),
                  })
                }
              />
              <ErrorDisplay attached messages={splitErrors.permissions} />
            </FieldLabel>
          </Grid.Column>
        </Grid.Row>

        {splitErrors._rest_.length > 0 && (
          <Grid.Row>
            <Grid.Column width={16}>
              <ErrorDisplay messages={splitErrors._rest_} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Form>
  );
}
