import React from "react";
import { Dimmer, Label, Loader, Table, Icon } from "semantic-ui-react";
import styled from "styled-components";
import { LabelTypeEnum } from "../model/enums";
import { LabelPrototype, SearchResults } from "../model/types";
import { ReactNodeLike } from "../utils";
import ClickableCell from "./ClickableCell";

interface Props {
  results: SearchResults<LabelPrototype>;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  fetching: boolean;
}

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default class LabelPrototypeList extends React.Component<Props> {
  render() {
    const {
      results: { items },
      fetching,
    } = this.props;

    return (
      <Dimmer.Dimmable dimmed={fetching}>
        <Dimmer active={fetching} inverted>
          <Loader>Loading</Loader>
        </Dimmer>

        <ResponsiveTable>
          <Table selectable unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>School</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Text</Table.HeaderCell>
                <Table.HeaderCell>Color</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{items.length === 0 ? this.renderNoItems() : items.map(this.renderItem)}</Table.Body>
          </Table>
        </ResponsiveTable>
      </Dimmer.Dimmable>
    );
  }

  renderNoItems = () => {
    return (
      <Table.Row key="noitems">
        <Table.Cell colSpan={11} textAlign="center">
          <div style={{ padding: 10 }}>Nothing to show</div>
        </Table.Cell>
      </Table.Row>
    );
  };

  url = (item: LabelPrototype) => {
    return `/admin/label/${item.id}`;
  };

  renderItem = (proto: LabelPrototype) => {
    const url = this.url(proto);

    return (
      <Table.Row key={proto.id}>
        <ClickableCell to={url}>{proto.schoolCode || "Admissions"}</ClickableCell>
        <ClickableCell to={url}>
          <Icon name={proto.labelType === "std" ? "tag" : proto.labelType === "fao" ? "users" : "check"} />
          {LabelTypeEnum.labelOf(proto.labelType)}
        </ClickableCell>
        <ClickableCell to={url}>{proto.text}</ClickableCell>
        <ClickableCell to={url}>
          <Label style={{ backgroundColor: proto.color }} />
        </ClickableCell>
      </Table.Row>
    );
  };
}
