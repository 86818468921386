import "codemirror/lib/codemirror.css";
import "codemirror/mode/handlebars/handlebars";
import "codemirror/mode/ruby/ruby";
import React, { useEffect, useRef, useState } from "react";
import { Button, Grid, Segment, Tab } from "semantic-ui-react";
import styled from "styled-components";
import * as api from "../api";
import { fetchParams } from "../api";
import useAppDispatch from "../hooks/useAppDispatch";
import { Messages, subErrors } from "../model/errors";
import { EmailParts } from "../model/templateParts";
import { Previews, TelephoneScript } from "../model/types";
import { Opt } from "../utils";
import ErrorDisplay from "./ErrorDisplay";
import { FieldLabel, TextInput } from "./fields";
import RichTextArea, { UnstyledRichTextArea } from "./fields/RichTextArea";

interface EmailPreviewProps {
  value: Opt<EmailParts>;
}

function EmailPreview(props: EmailPreviewProps) {
  const { value } = props;
  return (
    <Segment basic vertical loading={value == null}>
      <FieldLabel label="Subject">
        <Segment dangerouslySetInnerHTML={{ __html: value?.subject ?? "" }} />
      </FieldLabel>
      <FieldLabel label="Body">
        <Segment dangerouslySetInnerHTML={{ __html: value?.body ?? "" }} />
      </FieldLabel>
    </Segment>
  );
}

interface ScriptPreviewProps {
  value: Opt<TelephoneScript>;
}

function ScriptPreview(props: ScriptPreviewProps) {
  const { value } = props;
  return (
    <>
      <FieldLabel label="Title">
        <Segment dangerouslySetInnerHTML={{ __html: value?.title ?? "" }} />
      </FieldLabel>
      <FieldLabel label="Content">
        <Segment dangerouslySetInnerHTML={{ __html: value?.content ?? "" }} />
      </FieldLabel>
    </>
  );
}

type TemplateField = {
  label: string;
  value: string;
};

const TemplateTag = styled(Button)`
  margin-bottom: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface TemplateFieldSidebarProps {
  fields: TemplateField[];
  onFieldClick: (field: TemplateField) => void;
}

function TemplateFieldSidebar(props: TemplateFieldSidebarProps) {
  const { fields, onFieldClick } = props;
  return (
    <FieldLabel label="Template Syntax">
      <p>
        Format the message using{" "}
        <a target="_blank" rel="noopener noreferrer" href="https://daringfireball.net/projects/markdown/syntax">
          Markdown
        </a>
        .
      </p>
      <p>Insert applicant data using the following tags:</p>
      {fields.map(field => (
        <FieldLabel key={field.value} label={field.label}>
          <TemplateTag fluid content={field.value} onClick={() => onFieldClick(field)} />
        </FieldLabel>
      ))}
    </FieldLabel>
  );
}

function usePreviews<A>(value: A, school: string, fetch: (params: api.FetchParams, script: A, options: { school: string }) => Promise<Previews<A>>) {
  const dispatch = useAppDispatch();

  const [previews, setPreviews] = useState<Opt<Previews<A>>>();

  useEffect(() => {
    dispatch((dispatch, getState) => {
      setPreviews(null);
      fetch(fetchParams(getState()), value, { school }).then(setPreviews);
    });
  }, [dispatch, value, school, fetch]);

  return previews;
}

export interface EmailTemplateEditorProps {
  value: EmailParts;
  onChange: (parts: EmailParts) => void;
  school: string;
  messages: Messages;
}

const emailTemplateFields: TemplateField[] = [
  { value: "{{ tracking }}", label: "Tracking Number" },
  { value: "{{ tracking_number }}", label: "Tracking Number (alias)" },
  { value: "{{ ucas_personal_id }}", label: "UCAS Personal ID" },
  { value: "{{ forename }}", label: "Forename" },
  { value: "{{ forenames }}", label: "Forenames" },
  { value: "{{ surname }}", label: "Surname" },
  { value: "{{ name }}", label: "Full Name" },
  { value: "{{ qualifications }}", label: "Qualifications" },
  { value: "{{ english_qualification }}", label: "English Qualification" },
  { value: "{{ maths_qualification }}", label: "Maths Qualification" },
  {
    value: "{{ core_qualifications }}",
    label: "Core Qualifications (English and Maths)",
  },
  { value: "{{ programme }}", label: "Programme Code and Name" },
  { value: "{{ programme_code }}", label: "Programme Code" },
  { value: "{{ school }}", label: "School" },
  { value: "{{ school_code }}", label: "School Code" },
  { value: "{{ telephone }}", label: "School Telephone" },
  { value: "{{ deadline }}", label: "Offer Deadline" },
  { value: "{{ ucas_track_name }}", label: "UCAS Track Name" },
  { value: "{{ ucas_track_code }}", label: "UCAS Track Code" },
  { value: "{{ rejection_reason }}", label: "Rejection Reason" },
  {
    value: "{{#rejected_expired}}\nYour message\n{{/rejected_expired}}",
    label: "Reason Rejected: Expired",
  },
];

export function EmailTemplateEditor(props: EmailTemplateEditorProps) {
  const { value, onChange, school, messages } = props;

  const editorRef = useRef<UnstyledRichTextArea>(null);

  const onFieldClick = (field: TemplateField) => {
    editorRef.current?.insertAtCursor(field.value);
  };

  const previews = usePreviews(value, school, api.fetchEmailPreviews);

  const subMessages = subErrors(messages, {
    subject: true,
    body: true,
  });

  const editorTabPane = {
    menuItem: "Edit Template",
    // eslint-disable-next-line react/display-name
    render: () => (
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <ErrorDisplay messages={subMessages._rest_} />
            <FieldLabel label="Subject">
              <TextInput value={value.subject} onChange={subject => onChange?.({ ...value, subject })} messages={subMessages.subject} />
            </FieldLabel>
            <FieldLabel label="Body">
              <RichTextArea
                ref={editorRef}
                value={value.body}
                onChange={(body: string) => onChange?.({ ...value, body })}
                messages={subMessages.body}
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={4}>
            <TemplateFieldSidebar fields={emailTemplateFields} onFieldClick={onFieldClick} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ),
  };

  const completePreviewTabPane = {
    menuItem: "Preview Complete Application",
    // eslint-disable-next-line react/display-name
    render: () => <EmailPreview value={previews?.complete} />,
  };

  const incompletePreviewTabPane = {
    menuItem: "Preview Incomplete Application",
    // eslint-disable-next-line react/display-name
    render: () => <EmailPreview value={previews?.incomplete} />,
  };

  return <Tab panes={[editorTabPane, completePreviewTabPane, incompletePreviewTabPane]} />;
}

interface ScriptEditorProps {
  value: TelephoneScript;
  onChange: (parts: TelephoneScript) => void;
  school: string;
  templateFields: TemplateField[];
  fetchPreviews: api.ApiPreviewFetchFunc<TelephoneScript>;
  messages: Messages;
}

function ScriptEditor(props: ScriptEditorProps) {
  const { value, onChange, school, templateFields, fetchPreviews, messages } = props;

  const subMessages = subErrors(messages, {
    title: true,
    content: true,
  });

  const editorRef = useRef<UnstyledRichTextArea>(null);

  const onFieldClick = (field: TemplateField) => {
    editorRef.current?.insertAtCursor(field.value);
  };

  const previews = usePreviews(value, school, fetchPreviews);

  const editorTabPane = {
    menuItem: "Edit Template",
    // eslint-disable-next-line react/display-name
    render: () => (
      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <ErrorDisplay messages={subMessages._rest_} />
            <FieldLabel label="Title">
              <TextInput value={value.title} onChange={title => onChange?.({ ...value, title })} messages={subMessages.title} />
            </FieldLabel>
            <FieldLabel label="Script">
              <RichTextArea
                ref={editorRef}
                value={value.content}
                onChange={(content: string) => onChange?.({ ...value, content })}
                messages={subMessages.content}
              />
            </FieldLabel>
          </Grid.Column>
          <Grid.Column width={4}>
            <TemplateFieldSidebar fields={templateFields} onFieldClick={onFieldClick} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ),
  };

  const completePreviewTabPane = {
    menuItem: "Preview Complete Application",
    // eslint-disable-next-line react/display-name
    render: () => <ScriptPreview value={previews?.complete} />,
  };

  const incompletePreviewTabPane = {
    menuItem: "Preview Incomplete Application",
    // eslint-disable-next-line react/display-name
    render: () => <ScriptPreview value={previews?.incomplete} />,
  };

  return <Tab panes={[editorTabPane, completePreviewTabPane, incompletePreviewTabPane]} />;
}

export interface TransferScriptEditorProps {
  value: TelephoneScript;
  onChange: (parts: TelephoneScript) => void;
  school: string;
  messages: Messages;
}

const transferScriptTemplateFields: TemplateField[] = [
  { value: "{{ tracking }}", label: "Tracking Number" },
  { value: "{{ tracking_number }}", label: "Tracking Number (alias)" },
  { value: "{{ ucas_personal_id }}", label: "UCAS Personal ID" },
  { value: "{{ forename }}", label: "Forename" },
  { value: "{{ forenames }}", label: "Forenames" },
  { value: "{{ surname }}", label: "Surname" },
  { value: "{{ name }}", label: "Full Name" },
  { value: "{{ school }}", label: "School" },
  { value: "{{ transfer_group }}", label: "Transfer Group" },
  { value: "{{ transfer_instructions }}", label: "Transfer Instructions" },
  { value: "{{ transfer_telephone }}", label: "Transfer Telephone" },
];

export function TransferScriptEditor(props: TransferScriptEditorProps) {
  const { value, onChange, school, messages } = props;

  return (
    <ScriptEditor
      value={value}
      onChange={onChange}
      school={school}
      templateFields={transferScriptTemplateFields}
      fetchPreviews={api.fetchTransferScriptPreviews}
      messages={messages}
    />
  );
}

export interface OfferScriptEditorProps {
  value: TelephoneScript;
  onChange: (parts: TelephoneScript) => void;
  school: string;
  messages: Messages;
}

const offerScriptTemplateFields: TemplateField[] = [
  { value: "{{ tracking }}", label: "Tracking Number" },
  { value: "{{ tracking_number }}", label: "Tracking Number (alias)" },
  { value: "{{ ucas_personal_id }}", label: "UCAS Personal ID" },
  { value: "{{ forename }}", label: "forename" },
  { value: "{{ forenames }}", label: "forenames" },
  { value: "{{ surname }}", label: "Surname" },
  { value: "{{ name }}", label: "Full Name" },
  { value: "{{ programme_code }}", label: "Programme Code" },
  { value: "{{ programme }}", label: "Programme Code and Name" },
  { value: "{{ school }}", label: "School" },
  { value: "{{ deadline }}", label: "Offer Deadline" },
  { value: "{{ ucas_track_name }}", label: "ucas_track_name" },
  { value: "{{ ucas_track_code }}", label: "ucas_track_code" },
];

export function OfferScriptEditor(props: OfferScriptEditorProps) {
  const { value, onChange, school, messages } = props;

  return (
    <ScriptEditor
      value={value}
      onChange={onChange}
      school={school}
      templateFields={offerScriptTemplateFields}
      fetchPreviews={api.fetchOfferScriptPreviews}
      messages={messages}
    />
  );
}
