import update from "immutability-helper";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Button, Checkbox, Dimmer, Icon, Loader, Message, Popup, Segment, Table } from "semantic-ui-react";
import styled from "styled-components";
import * as api from "../api";
import { AppError, ValidationError } from "../errors";
import { Permissions } from "../model/permission";
import { programmeCodeToRouteCode, programmeCodeToString, programmeCodeToUcasCourseCode, unsafeStringToProgrammeCode } from "../model/programme";
import { schoolToTeamCode } from "../model/team";
import { Programme, ProgrammeCode, Requirements, SearchResults } from "../model/types";
import { withPermissions } from "../reducers/auth";
import { Opt, ReactNodeLike } from "../utils";
import { withModal, WithModalProps } from "../utils/modal";
import ClickableCell from "./ClickableCell";
import ModalBulkEditEntryRequirements from "./ModalBulkEditEntryRequirements";
import ModalBulkEditKeywords from "./ModalBulkEditKeywords";
import RequirementsView from "./RequirementsView";

type Props = {
  results: SearchResults<Programme>;
  sortComponent: (by: string, label: string, initialDir?: "asc" | "desc") => ReactNodeLike;
  fetching: boolean;
  fetchError: Opt<AppError>;
  permissions: Permissions;
  refetch: () => void;
};

const ResponsiveTable = styled.div`
  overflow-x: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

const createUrl = (prog: Programme) => {
  return `/admin/programme/${programmeCodeToString(prog.code)}`;
};

function NoItems({ fetchError }: { fetchError: Opt<AppError> }) {
  return (
    <Table.Row key="noitems">
      <Table.Cell colSpan={8} textAlign="center">
        <Segment basic>{fetchError ? `Could not load results. ${fetchError.allMessages()}` : "Nothing to show"}</Segment>
      </Table.Cell>
    </Table.Row>
  );
}

function ProgrammeRow({
  prog,
  handleSelectionChange,
  selected,
  editable,
}: {
  prog: Programme;
  selected: boolean;
  handleSelectionChange: (value: boolean) => void;
  editable: boolean;
}) {
  const url = createUrl(prog);

  return (
    <>
      <Table.Row key={programmeCodeToString(prog.code)} textAlign="center">
        <Table.Cell>
          {editable && <Checkbox checked={editable && selected} onChange={(_, data) => handleSelectionChange(data.checked ?? false)} />}
        </Table.Cell>
        <ClickableCell to={url}>{programmeCodeToUcasCourseCode(prog.code)}</ClickableCell>
        <ClickableCell to={url}>{programmeCodeToRouteCode(prog.code)}</ClickableCell>
        <ClickableCell to={url} textAlign="left">
          {prog.name}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {prog.schoolCode}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          {prog.jointSchoolCode || "-"}
        </ClickableCell>
        <ClickableCell to={url} textAlign="center">
          <RequirementsView requirements={prog.entryRequirements} />
        </ClickableCell>
        <Table.Cell textAlign="center">
          <Popup
            trigger={prog.allowHotlineClearingOffers ? <Icon name="checkmark" color="green" /> : <Icon name="close" color="red" />}
            content={prog.allowHotlineClearingOffers ? "Hotline operators can make clearing offers" : "Hotline operators can not make offers"}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">
          <Popup
            trigger={<Icon name="home" color={prog.isClosedHome ? "red" : "green"} />}
            content={prog.isClosedHome ? "Closed to home applicants" : "Accepting home applicants"}
          />
          <Popup
            trigger={<Icon name="globe" color={prog.isClosedOverseas ? "red" : "green"} />}
            content={prog.isClosedHome ? "Closed to overseas applicants" : "Accepting overseas applicants"}
          />
        </Table.Cell>
      </Table.Row>
      {prog.keywords && (
        <Table.Row disabled>
          <Table.Cell></Table.Cell>
          <Table.Cell colSpan={9}>{prog.keywords}</Table.Cell>
        </Table.Row>
      )}
    </>
  );
}

type SelectionSet = { [id: string]: true };

function ProgrammeList({ results: { items }, fetching, fetchError, sortComponent, showModal, refetch, permissions }: Props & WithModalProps) {
  const apiParams = useParams();
  const [selection, setSelection] = useState<SelectionSet>({});
  const [showRequirementsModal, setShowRequirementsModal] = useState(false);
  const [showKeywordsModal, setShowKeywordsModal] = useState(false);
  const {
    mutate: bulkUpdate,
    status,
    error,
  } = api.useBulkUpdateProgramme({
    onSuccess: () => {
      setShowRequirementsModal(false);
      setShowKeywordsModal(false);
      setSelection({});
      refetch();
    },
  });

  const messages = error instanceof ValidationError ? error.messages : [];

  const busy = status === "loading";

  const selectAll = (state: Opt<boolean>) => {
    if (!state) {
      setSelection({});
    } else {
      const editable = (prog: Programme) => permissions.canUpdateProgrammes(schoolToTeamCode(prog.schoolCode));
      const set = items.reduce((a, c) => ({ ...a, [programmeCodeToString(c.code)]: editable(c) }), {});

      setSelection({
        ...selection,
        ...set,
      });
    }
  };

  const anySelected = Object.keys(selection).length > 0;
  const allSelected = items.length === Object.keys(selection).length;

  const onChange = (code: ProgrammeCode) => (state: Opt<boolean>) => {
    if (state) {
      setSelection({ ...selection, [code]: true });
    } else {
      setSelection(update(selection, { $unset: [code] }));
    }
  };

  const handleBulkDelete = (programmes: ProgrammeCode[]) => {
    api.deleteManyProgrammes(apiParams, programmes).then(results => {
      // undefined results are programmes we could not delete
      const failed = programmes.filter((_code, i) => results[i] === undefined);
      setShowRequirementsModal(false);
      setShowKeywordsModal(false);
      setSelection({});
      refetch();

      if (failed.length > 0) {
        showModal("alert", {
          title: "Some programmes could not be deleted",
          content: <div>The following programme(s) could not be deleted because they have applications: {failed.join(", ")}</div>,
        });
      }
    });
  };

  const handleBulkDeleteWithConfirmation = () => {
    const confirm = () => {
      showModal("confirm", {
        primaryButtonText: "Delete programmes",
        onPrimaryClick: () => {
          handleBulkDelete(
            // The keys on the selection object are the programme codes:
            Object.keys(selection).map(unsafeStringToProgrammeCode),
          );
        },
        title: "Delete Selected Programmes?",
        content: (
          <div>This will remove the selected programmes from Springboard, except for programmes with applications which will be retained.</div>
        ),
      });
    };

    return confirm;
  };

  const handleBulkSet = (patch: Partial<Programme>, flags: { closeWaitingList?: boolean }) => {
    const programmesToUpdate = items.filter(item => selection[item.code]);
    const updates = programmesToUpdate.map(programme => ({
      ...programme,
      ...patch,
    }));
    bulkUpdate({ programmes: updates, queryParams: flags });
  };

  const handleBulkOpenWithWarning = () => {
    return () => {
      showModal("confirm", {
        onPrimaryClick: () => {
          handleBulkSet({ isClosedHome: false, isClosedOverseas: false }, {});
        },
        content: "Are you sure you want to open all selected programmes?",
      });
    };
  };

  const handleBulkCloseHomeWithWarning = () => {
    return () => {
      showModal("confirm", {
        primaryButtonText: "Yes - send rejection emails",
        onPrimaryClick: () => {
          handleBulkSet({ isClosedHome: true }, { closeWaitingList: true });
        },
        tertiaryButtonText: "No - just prevent new offers",
        onTertiaryClick: () => {
          handleBulkSet({ isClosedHome: true }, { closeWaitingList: false });
        },
        title: "Close Selected Programmes",
        content: (
          <div>
            Would you like to close the waiting list for home applicants to the selected programmes? Doing so will reject every applicant on the
            waiting list and send them all a confirmation email. The process is irreversible.
          </div>
        ),
      });
    };
  };

  const handleBulkCloseOverseasWithWarning = () => {
    return () => {
      showModal("confirm", {
        primaryButtonText: "Yes - send rejection emails",
        onPrimaryClick: () => {
          handleBulkSet({ isClosedOverseas: true }, { closeWaitingList: true });
        },
        tertiaryButtonText: "No - just prevent new offers",
        onTertiaryClick: () => {
          handleBulkSet({ isClosedOverseas: true }, { closeWaitingList: false });
        },
        title: "Close Selected Programmes",
        content: (
          <div>
            Would you like to close the waiting list for overseas applicants to the selected programmes? Doing so will reject every applicant on the
            waiting list and send them all a confirmation email. The process is irreversible.
          </div>
        ),
      });
    };
  };

  return (
    <Dimmer.Dimmable dimmed={busy || fetching}>
      {error && <Message header="Couldn't bulk update programmes" content="There was a problem updating the programmes" negative />}
      <Dimmer active={busy || fetching} inverted>
        <Loader>Loading</Loader>
      </Dimmer>

      <ResponsiveTable>
        <Table selectable unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                <Checkbox indeterminate={!allSelected && anySelected} checked={allSelected} onChange={(_, data) => selectAll(data.checked)} />
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("ucas", "UCAS")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("route", "Route")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">{sortComponent("name", "Name")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("school", "Home School")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("jointSchool", "Joint School")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Entry Requirements</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("allowHotlineClearingOffers", "Allow hotline offers?")}</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">{sortComponent("open", "Open?")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {anySelected && (
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={11} selectable={false}>
                  <Button size="small" compact onClick={() => setShowKeywordsModal(true)}>
                    Edit Keywords
                  </Button>
                  <Button size="small" compact onClick={() => setShowRequirementsModal(true)}>
                    Edit Requirements
                  </Button>
                  <Button size="small" compact onClick={handleBulkOpenWithWarning()}>
                    Open Programmes
                  </Button>
                  <Button size="small" compact onClick={handleBulkCloseHomeWithWarning()}>
                    Close Programmes to Home Applicants
                  </Button>
                  <Button size="small" compact onClick={handleBulkCloseOverseasWithWarning()}>
                    Close Programmes to Overseas Applicants
                  </Button>
                  <Button size="small" compact onClick={handleBulkDeleteWithConfirmation()}>
                    Delete Programmes
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            {items.length === 0 ? (
              <NoItems fetchError={fetchError} />
            ) : (
              items.map((item: Programme) => (
                <ProgrammeRow
                  key={programmeCodeToString(item.code)}
                  prog={item}
                  handleSelectionChange={onChange(item.code)}
                  editable={permissions.canUpdateProgrammes(schoolToTeamCode(item.schoolCode))}
                  selected={selection[programmeCodeToString(item.code)] || false}
                />
              ))
            )}
          </Table.Body>
        </Table>
      </ResponsiveTable>
      <ModalBulkEditEntryRequirements
        messages={messages}
        show={showRequirementsModal}
        onPrimaryClick={(_e: React.MouseEvent<HTMLButtonElement>, entryRequirements: Requirements) => {
          handleBulkSet({ entryRequirements }, {});
        }}
        onHide={() => {
          setShowRequirementsModal(false);
        }}
      />
      <ModalBulkEditKeywords
        messages={messages}
        show={showKeywordsModal}
        onPrimaryClick={(keywords: string) => {
          handleBulkSet({ keywords }, {});
        }}
        onHide={() => {
          setShowKeywordsModal(false);
        }}
      />
    </Dimmer.Dimmable>
  );
}

export default withPermissions(withModal(ProgrammeList));
