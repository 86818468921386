import { Location } from "history";
import { CombinedState as ApplicantUpdateState, initialCombinedState as initialApplicantUpdateState } from "../applicantUpdate/state";
import { initialState as initialReportingGroupUpdateState, MyState as ReportingGroupUpdateState } from "../reportingGroupUpdate/state";
import { initialState as initialLabelPrototypeUpdateState, MyState as LabelPrototypeUpdateState } from "../labelPrototypeUpdate/state";
import { initialState as initialOfferExpirySettingsState, MyState as OfferExpirySettingsState } from "../offerExpirySettings/state";
import { initialState as initialPredictionsState, MyState as PredictionsState } from "../predictions/state";
import { initialState as initialProgrammeUpdateState, MyState as ProgrammeUpdateState } from "../programmeUpdate/state";
import { initialState as initialSchoolState, MyState as SchoolState } from "../school/state";
import { initialState as initialSncState, MyState as SncState } from "../snc/state";
import { initialState as initialInternationalState, MyState as InternationalState } from "../internationalEquivs/state";
import { initialState as initialUserSearchState, MyState as UserSearchState } from "../userSearch/state";
import { initialState as initialUserUpdateState, MyState as UserUpdateState } from "../userUpdate/state";
import { initialState as initialRoleSearchState, MyState as RoleSearchState } from "../roleSearch/state";
import { initialState as initialRoleUpdateState, MyState as RoleUpdateState } from "../roleUpdate/state";
import { initialState as initialSelectorListSidebarState, MyState as SelectorListSidebarState } from "../selectorListSidebar/state";

export type LastRouteState = {
  from: null | { location: Location };
  to: null | { location: Location };
};

export const initialLastRouteState: LastRouteState = {
  from: null,
  to: null,
};

export interface MyState {
  applicantUpdate: ApplicantUpdateState;
  predictions: PredictionsState;
  snc: SncState;
  internationalEquivs: InternationalState;
  userSearch: UserSearchState;
  userUpdate: UserUpdateState;
  roleSearch: RoleSearchState;
  roleUpdate: RoleUpdateState;
  programmeUpdate: ProgrammeUpdateState;
  reportingGroupUpdate: ReportingGroupUpdateState;
  labelPrototypeUpdate: LabelPrototypeUpdateState;
  offerExpirySettings: OfferExpirySettingsState;
  school: SchoolState;
  lastRoute: LastRouteState;
  selectorListSidebar: SelectorListSidebarState;
}

export const initialState: MyState = {
  applicantUpdate: initialApplicantUpdateState,
  predictions: initialPredictionsState,
  snc: initialSncState,
  internationalEquivs: initialInternationalState,
  userSearch: initialUserSearchState,
  userUpdate: initialUserUpdateState(),
  roleSearch: initialRoleSearchState,
  roleUpdate: initialRoleUpdateState(),
  programmeUpdate: initialProgrammeUpdateState(),
  reportingGroupUpdate: initialReportingGroupUpdateState(),
  labelPrototypeUpdate: initialLabelPrototypeUpdateState(),
  offerExpirySettings: initialOfferExpirySettingsState,
  school: initialSchoolState(),
  lastRoute: initialLastRouteState,
  selectorListSidebar: initialSelectorListSidebarState,
};
