import React, { ReactElement } from "react";
import { Label, Table } from "semantic-ui-react";
import { columnGroups } from "./column";
import { Column, ColumnGroup, QueryStringState } from "./type";

export interface HeaderRowsProps {
  qss: QueryStringState;
}

export const COLUMN_GROUP_BORDER = 2;

function showThing(qss: QueryStringState) {
  return function (item: ColumnGroup | Column): boolean {
    return item.show?.(qss) ?? true;
  };
}

export default function HeaderRows(props: HeaderRowsProps): ReactElement | null {
  const { qss } = props;

  return (
    <>
      <Table.Row>
        {columnGroups.map((group, index) => {
          const show = showThing(qss)(group);

          const colSpan = group.columns.filter(showThing(qss)).length;

          const style = {
            borderLeftWidth: COLUMN_GROUP_BORDER,
            borderBottomWidth: group.title == null ? 0 : undefined,
          };

          if (show) {
            return (
              <Table.HeaderCell key={index} as="th" colSpan={colSpan} style={style}>
                {group.title}
              </Table.HeaderCell>
            );
          } else {
            return null;
          }
        })}
      </Table.Row>
      <Table.Row>
        {columnGroups.map((group, index) => {
          const show = showThing(qss)(group);

          if (show) {
            return (
              <React.Fragment key={index}>
                {group.columns.filter(showThing(qss)).map((col, index) => {
                  const className = col.titleStyle?.(qss) ?? undefined;

                  const style = {
                    minWidth: col.minWidth,
                    borderLeftWidth: index === 0 ? COLUMN_GROUP_BORDER : undefined,
                    borderBottomWidth: COLUMN_GROUP_BORDER,
                  };

                  return (
                    <Table.HeaderCell key={index} as="th" title={col.tooltip} textAlign={col.align ?? "center"} verticalAlign="middle" style={style}>
                      {className == null ? (
                        col.title
                      ) : (
                        <Label size="tiny" className={className}>
                          {col.title}
                        </Label>
                      )}
                    </Table.HeaderCell>
                  );
                })}
              </React.Fragment>
            );
          } else {
            return null;
          }
        })}
      </Table.Row>
    </>
  );
}
