import { flowRight } from "lodash";
import React, { Component, ComponentType } from "react";
import { connect } from "react-redux";
import { Team } from "../model/team";
import { withCurrentTeam } from "../reducers/teams";

interface FetchOnTeamChangeProps {
  currentTeam: Team;
  _doFetch: () => void;
}

export function fetchOnTeamChange<A>(_doFetch: () => void) {
  return (ComposedComponent: ComponentType<A>) => {
    type B = A & FetchOnTeamChangeProps;

    const enhance = flowRight(connect(null, { _doFetch }), withCurrentTeam);

    return enhance(
      class FetchOnSchoolChange extends Component<B> {
        componentDidMount() {
          this.props._doFetch();
        }

        UNSAFE_componentWillReceiveProps(nextProps: B) {
          if (this.props.currentTeam.code !== nextProps.currentTeam.code) {
            this.props._doFetch();
          }
        }

        render() {
          return <ComposedComponent {...this.props} />;
        }
      },
    );
  };
}
