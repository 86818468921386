import * as z from "zod";

import {
  ApplicationChoiceEnum,
  FeeStatusEnum,
  EntryPointEnum,
  InitialDecisionEnum,
  InitialResponseEnum,
  ConfirmationDecisionEnum,
  ConfirmationResponseEnum,
  ClearingDecisionEnum,
  ClearingResponseEnum,
  ApplicationStatusEnum,
  OfferStatusEnum,
} from "./enums";

import {
  qualifications as qualificationsValidation,
  emailAddressStrict,
  mapMaybeYupValidationToMessages,
} from "@qmspringboard/shared/dist/validators";
import { ApplicantDetails, ApplicantDTO, Application } from "./types";

import { ApplicantEditorState } from "./applicant";

import { isFailedApplication } from "@qmspringboard/shared/dist/model/application";

import { programmeCodeValid } from "./programme";

import * as rule from "./rules";
import { Rule } from "./rules";
import { Opt } from "../utils";
import { ageRestrictionMessage } from "@qmspringboard/shared/src/utils/age";

export const applicantDetailsRule: Rule<ApplicantDetails> = rule
  .create<ApplicantDetails>()
  .field("ucasPersonalId", rule.whenEmpty(rule.fail(rule.warn("You should enter a UCAS Personal ID."))))
  .field("ucasPersonalId", rule.whenNonEmpty(rule.regex(/^\d{10}$/, rule.error("UCAS Personal ID must be a 10-digit number."))))
  .field("surname", rule.whenEmpty(rule.fail(rule.warn("You should enter a surname."))))
  .field("forenames", rule.whenEmpty(rule.fail(rule.warn("You should enter forenames."))))
  .field("email", rule.whenEmpty(rule.fail(rule.warn("You should enter an email address."))))
  .field("studentId", rule.whenNonEmpty(rule.regex(/^\d{9,10}$/, rule.error("Student id must be 9 or 10 digits"))))
  .field("dateOfBirth", rule.whenNonEmpty(rule.lengthLte(10, rule.error("Date of birth must be in the format '2001-12-31'."))))
  .field(
    "dateOfBirth",
    rule.whenNonEmpty(
      rule.regex(/^(19)|(20)\d\d-\d\d-\d\d+$/, rule.error("Date of birth is a 4 digit year, 2 digit month, 2 digit day: 2001-06-09 for example")),
    ),
  )
  .field("email", rule.whenNonEmpty(rule.regex(/^[^@]+@[^@]+$/, rule.error("Please enter a valid email address."))))
  .field(
    "email",
    rule.whenNonEmpty(
      rule.test(email => {
        try {
          emailAddressStrict.parse(email);
          return true;
        } catch (e) {
          return false;
        }
      }, rule.warn("Check the address carefully")),
    ),
  )
  .field("countryOfDomicile", rule.whenEmpty(rule.fail(rule.warn("You should select a country."))))
  .field("nationality", rule.whenEmpty(rule.fail(rule.warn("You should select a nationality."))))
  .field("ukResidency", rule.whenEmpty(rule.fail(rule.warn("You should select an option."))))
  .field("ukImmigrationStatus", rule.whenEmpty(rule.fail(rule.warn("You should select an option."))))
  .field("feeStatus", rule.whenEmpty(rule.fail(rule.error("You must select a fee status."))))
  .field("feeStatus", rule.whenNonEmpty(rule.oneOf(FeeStatusEnum.values, rule.error("Invalid fee status."))))
  .and(details => {
    const tel1Empty = details.telephone1 == null || details.telephone1 === "";
    const tel2Empty = details.telephone2 == null || details.telephone2 === "";
    return tel1Empty && tel2Empty ? rule.warn("You should enter at least one telephone number.", "telephone1") : [];
  })
  .and(details => {
    const telSame = details.telephone1 != null && details.telephone1 !== "" && details.telephone1 === details.telephone2;
    return telSame ? rule.warn("The two telephone numbers should be different.", "telephone2") : [];
  })
  .finish();

// This rule is weird. It checks a client-specific data structure but
// assigns paths to the results to match the server data structure.
//
// This allows us to check client-specific aspects of the data
// but retain the same error structure as the service-side rules.
//
// I've marked the points of discrepancy below.

const mapFromZodToRules = <A>(fn: z.ZodType<A>): rule.Rule<unknown> => {
  // map input and change output
  return (data: unknown) => {
    try {
      fn.parse(data);
      return [];
    } catch (e) {
      return mapMaybeYupValidationToMessages(e);
    }
  };
};

export const applicantRule: Rule<ApplicantDTO> = rule
  .create()
  .field("details", applicantDetailsRule)
  .field("qualifications", mapFromZodToRules(qualificationsValidation))
  .finish();

const offerExpiryRule: Rule<Application> = (appn: Application) => {
  function checkExpires(offerExpires: Opt<boolean>) {
    if (offerExpires === true && appn.offerExpiry === null) {
      return rule.error(`Application must have an expiry time.`);
    } else {
      const expiryDate = appn.offerExpiry == null ? null : new Date(appn.offerExpiry);
      if (expiryDate && expiryDate < new Date()) {
        return rule.warn(`Expiry time is in the past.`);
      } else {
        return [];
      }
    }
  }

  switch (appn.choice) {
    case ApplicationChoiceEnum.Clearing:
      return checkExpires(appn.offerStatus === OfferStatusEnum.Made || appn.offerStatus === OfferStatusEnum.MadePreRelease);
    case ApplicationChoiceEnum.First:
    case ApplicationChoiceEnum.Second:
    case ApplicationChoiceEnum.Third:
    case ApplicationChoiceEnum.Fourth:
    case ApplicationChoiceEnum.Fifth:
    case ApplicationChoiceEnum.Extra:
    default:
      return [];
  }
};

const rejectionReasonRule: Rule<Application> = (appn: Application) => {
  if (appn.rejectionReason != null && !isFailedApplication(appn)) {
    return rule.error(`Live/successful applications must not have a rejection reason.`);
  } else {
    return [];
  }
};

const clearingApplicationRule: Rule<Application> = rule
  .create<Application>()
  .field("initialDecision", rule.whenNonEmpty(rule.fail(rule.error("Clearing applications must NOT have a initial decision."))))
  .field("initialResponse", rule.whenNonEmpty(rule.fail(rule.error("Clearing applications must NOT have a initial response."))))
  .field("confirmationDecision", rule.whenNonEmpty(rule.fail(rule.error("Clearing applications must NOT have a confirmation decision."))))
  .field("confirmationResponse", rule.whenNonEmpty(rule.fail(rule.error("Clearing applications must NOT have a confirmation response."))))
  .field("offerStatus", rule.whenEmpty(rule.fail(rule.error("Clearing applications must have an offer status."))))
  .and(rule.prefix(offerExpiryRule, "offerExpiry"))
  .finish();

const ucasApplicationRule: Rule<Application> = rule
  .create<Application>()
  .field("clearingDecision", rule.whenNonEmpty(rule.fail(rule.error("UCAS applications must NOT have a clearing decision."))))
  .field("clearingResponse", rule.whenNonEmpty(rule.fail(rule.error("UCAS applications must NOT have a clearing response."))))
  .field("offerStatus", rule.whenNonEmpty(rule.fail(rule.error("UCAS applications must NOT have an offer status."))))
  .field("offerExpiry", rule.whenNonEmpty(rule.fail(rule.error("UCAS applications must NOT have an offer expiry."))))
  .finish();

export const applicationRule: Rule<Application> = rule
  .create<Application>()
  .field("choice", rule.whenEmpty(rule.fail(rule.error("You must select a choice."))))
  .field("programmeCode", rule.required(rule.error("You must select a programme.")))
  .field("programmeCode", rule.optional(rule.test(programmeCodeValid, rule.error("Invalid programme code."))))
  .field("choice", rule.whenEmpty(rule.fail(rule.error("Missing choice code."))))
  .field("choice", rule.whenNonEmpty(rule.oneOf(ApplicationChoiceEnum.values, rule.error("Invalid choice code."))))
  .field("entryPoint", rule.whenEmpty(rule.fail(rule.error("You must select an entry point."))))
  .field("entryPoint", rule.whenNonEmpty(rule.oneOf(EntryPointEnum.values, rule.error("Invalid entry point."))))
  .field("initialDecision", rule.whenNonEmpty(rule.oneOf(InitialDecisionEnum.values, rule.error("Invalid initial decision."))))
  .field("initialResponse", rule.whenNonEmpty(rule.oneOf(InitialResponseEnum.values, rule.error("Invalid initial response."))))
  .field("confirmationDecision", rule.whenNonEmpty(rule.oneOf(ConfirmationDecisionEnum.values, rule.error("Invalid confirmation decision."))))
  .field("confirmationResponse", rule.whenNonEmpty(rule.oneOf(ConfirmationResponseEnum.values, rule.error("Invalid confirmation response."))))
  .field("clearingDecision", rule.whenNonEmpty(rule.oneOf(ClearingDecisionEnum.values, rule.error("Invalid clearing decision."))))
  .field("clearingResponse", rule.whenNonEmpty(rule.oneOf(ClearingResponseEnum.values, rule.error("Invalid clearing response."))))
  .field("applicationStatus", rule.whenNonEmpty(rule.oneOf(ApplicationStatusEnum.values, rule.error("Invalid application status."))))
  .field(
    "entryPoint",
    rule.whenNonEmpty(
      rule.oneOf(
        [EntryPointEnum.Foundation, EntryPointEnum.First],
        rule.warn(`Applicants entering into year 2 and higher do not contribute to head count predictions.`),
      ),
    ),
  )
  .field("prediction", rule.optional(rule.inclusiveRange(0, 100, rule.error("Must be in the range 0 to 100%."))))
  .fieldWith("predictionExpiry", appn =>
    appn.prediction == null
      ? rule.eq(null, rule.error("Expiry must be blank if prediction is blank."))
      : rule.ne(null, rule.error("You must provide an expiry time for your prediction override.")),
  )
  .field("predictionExpiry", rule.optional(rule.dateAfter(new Date(), rule.warn("The expiry time is in the past."))))
  .fieldWith("oldProgrammeCode", appn =>
    rule.optional(rule.ne(appn.programmeCode, rule.error("Old programme must not be the same as new programme."))),
  )
  .field("oldProgrammeCode", rule.optional(rule.test(programmeCodeValid, rule.error("Invalid old programme code."))))
  .and(rule.prefix(rejectionReasonRule, "rejectionReason"))
  .and((appn: Application) => {
    switch (appn.choice) {
      case ApplicationChoiceEnum.Clearing:
        return clearingApplicationRule(appn);
      case ApplicationChoiceEnum.First:
      case ApplicationChoiceEnum.Second:
      case ApplicationChoiceEnum.Third:
      case ApplicationChoiceEnum.Fourth:
      case ApplicationChoiceEnum.Fifth:
      case ApplicationChoiceEnum.Extra:
        return ucasApplicationRule(appn);
      default:
        return [];
    }
  })
  .finish();

export const applicantEditorStateRule: Rule<ApplicantEditorState> = rule
  .create<ApplicantEditorState>()
  .field("applicant", applicantRule)
  .field("applications", rule.array(applicationRule))
  .and(state => {
    if (state.duplicates.ucasPersonalId.find(appt => appt.details.ucasPersonalId === state.applicant.details.ucasPersonalId) != null) {
      return rule.error("Another applicant exists with the same UCAS Personal ID.", ["applicant", "details", "ucasPersonalId"]);
    } else {
      return [];
    }
  })
  .and(state => {
    if (state.duplicates.email.find(appt => appt.details.email === state.applicant.details.email) != null) {
      return rule.warn("Another applicant exists with the same email address.", ["applicant", "details", "email"]);
    } else {
      return [];
    }
  })
  .and(state => {
    if (state.duplicates.studentId.find(appt => appt.details.studentId === state.applicant.details.studentId) != null) {
      return rule.warn("Another applicant exists with the same student id.", ["applicant", "details", "studentId"]);
    } else {
      return [];
    }
  })
  .and(dto => {
    if (!dto.applicant.details.email) {
      return rule.warn("The applicant has no email address. We cannot email them with updates to their applications.", ["_email_"]);
    } else {
      return [];
    }
  })
  .finish();

/**
 * DoB is only mandatory when creating an applicant, so we add it to the other rules, which
 * also apply when editing an existing applicant
 */
export const mandatoryDoBEditorStateRule: Rule<ApplicantEditorState> = rule
  .create<ApplicantEditorState>(applicantEditorStateRule)
  .and(state => {
    return state.applicant.details.dateOfBirth ? [] : rule.error("You must enter a date of birth", ["applicant", "details", "dateOfBirth"]);
  })
  .and(state => {
    const problem = ageRestrictionMessage(state.applications, state.applicant.details.dateOfBirth);
    return problem ? rule.error(problem, ["applicant", "details", "dateOfBirth"]) : [];
  })
  .finish();
