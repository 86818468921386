import { PredictionDTO, PredictionGrouping, PredictionOrganisation } from "../../model/types";
import { Opt } from "../../utils";

export type PredictionFees = "overall" | "home" | "overseas";

export interface PredictionParams {
  org: PredictionOrganisation;
  grouping: PredictionGrouping;
  fees: PredictionFees;
}

export interface PredictionViews {
  showCharts: boolean;
  showOfferStatusSummary: boolean;
  showOverall: boolean;
  showUcas: boolean;
  showClearing: boolean;
}

export type MyState = {
  data: Opt<PredictionDTO>;
  fetching: boolean;
};

export const initialState: MyState = {
  data: null,
  fetching: false,
};
