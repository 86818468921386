import React from "react";
import { Table } from "semantic-ui-react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactNodeLike } from "../utils";
import { SemanticWIDTHS } from "semantic-ui-react/dist/commonjs/generic";

const OverlayLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface ClickableCellProps {
  to: string;
  textAlign?: "center" | "left" | "right";
  width?: SemanticWIDTHS;
  children: ReactNodeLike;
}

export default function ClickableCell(props: ClickableCellProps) {
  const { to, textAlign, width, children } = props;

  return (
    <Table.Cell textAlign={textAlign} width={width} style={{ position: "relative" }}>
      {children}
      <OverlayLink to={to} />
    </Table.Cell>
  );
}
