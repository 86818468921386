import React, { useState, MouseEvent } from "react";
import { NullableTextArea, NullableTextInput } from "./fields";
import { connectModal } from "redux-modal";
import { Form, Modal, Header, Button } from "semantic-ui-react";
import { ReactNodeLike, Opt } from "../utils";
import { ConnectModalProps } from "../utils/modal";

interface Props extends ConnectModalProps {
  primaryButtonText?: ReactNodeLike;
  onPrimaryClick: Function;
  secondaryButtonText?: ReactNodeLike;
  onSecondaryClick?: Function;
  title?: string;
  placeholder?: string;
  content?: ReactNodeLike;
  singleLineInput?: boolean;
}

function ModalMessage({
  handleHide,
  onPrimaryClick,
  onSecondaryClick,
  singleLineInput,
  show,
  content,
  title,
  secondaryButtonText,
  primaryButtonText,
  placeholder,
}: Props) {
  const [value, setValue] = useState<Opt<string>>(null);
  const handlePrimaryClick = (_e: MouseEvent) => {
    handleHide();
    onPrimaryClick(value);
  };

  const handleSecondaryClick = (e: MouseEvent) => {
    handleHide();
    onSecondaryClick?.(e);
  };

  return (
    <Modal open={show} onClose={handleHide} size="small">
      <Header icon="info circle" content={title || "Confirm"} />
      <Modal.Content>
        {content}
        <Form>
          {singleLineInput ? (
            <NullableTextInput value={value} onChange={setValue} placeholder={placeholder} />
          ) : (
            <NullableTextArea placeholder={placeholder} value={value} onChange={setValue} />
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleSecondaryClick}>{secondaryButtonText || "Cancel"}</Button>
        <Button primary onClick={handlePrimaryClick}>
          {primaryButtonText || "Confirm"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default connectModal({ name: "message" })(ModalMessage);
