import { Just, Maybe } from "purify-ts/Maybe";
import React, { useCallback } from "react";
import { Opt } from "../../utils";
import { BaseFieldProps } from "./base";
import { BaseInput, ExtraInputProps } from "./BaseInput";
import { formatDecimalPlaces, validateDecimalPlaces } from "./NumberInput";

export interface NullableNumberInputProps extends BaseFieldProps<Opt<number>>, ExtraInputProps {
  decimalPlaces?: number;
}

export default function NullableNumberInput(props: NullableNumberInputProps) {
  const { decimalPlaces, ...rest } = props;

  const format = useCallback(
    (num: Opt<number>): string => {
      return num == null ? "" : formatDecimalPlaces(num, decimalPlaces);
    },
    [decimalPlaces],
  );

  const validate = useCallback(
    (str: string): Maybe<Opt<number>> => {
      const trimmed = str.trim();
      return trimmed === "" ? Just<number | null>(null) : validateDecimalPlaces(str, decimalPlaces);
    },
    [decimalPlaces],
  );

  return <BaseInput format={format} validate={validate} {...rest} />;
}
