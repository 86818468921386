import React from "react";
import { Link } from "react-router-dom";
import { Header, Icon, Loader, Menu, Segment } from "semantic-ui-react";
import { SelectorAction } from "../model/types";
import { ApplicationChoiceEnum } from "../model/enums";
import SelectorListNoSelection from "./SelectorListNoSelection";
import { SelectorListAction } from "./SelectorListAction";
import { SelectorListDataRowView } from "./DetailsView";
import { ApplicantDetailsView, QualificationsDTOView } from "./DetailsView";
import { Opt } from "../utils";
import { SelectorListApplicant } from "../hooks/useSelectorListActions";

interface Props {
  selectedApplicant: Opt<SelectorListApplicant>;
  fetching: boolean;
  applyAction: (action: SelectorAction) => void;
  ignoreAction: (action: SelectorAction) => void;
  unignoreAction: (action: SelectorAction) => void;
}

export default function SelectorListDetail({ fetching, selectedApplicant: data, applyAction, ignoreAction, unignoreAction }: Props) {
  if (fetching) {
    return (
      <Segment basic padded>
        <Loader active inline="centered" />
      </Segment>
    );
  }

  if (!data) {
    return <SelectorListNoSelection />;
  }

  const { ucasPersonalId, surname, forenames, existingApplicant, selectorRows } = data;

  return (
    <Segment>
      <Header as="h3">
        Applicant {ucasPersonalId}: {surname}, {forenames}
      </Header>
      <Menu compact text>
        <Menu.Item>
          <a href={`https://weblink.ucas.com/ucaslink/ApplicantEnquiryServlet?appid=${ucasPersonalId}`} rel="noopener noreferrer" target="_blank">
            <Icon name="external" />
            View on UCAS Web-Link
          </a>
        </Menu.Item>
        {existingApplicant && (
          <Menu.Item>
            <Link to={`/applicant/${existingApplicant.id}`} target="_blank">
              <Icon name="external" />
              View on Springboard
            </Link>
          </Menu.Item>
        )}
      </Menu>
      {!existingApplicant && (
        <Segment basic vertical>
          There is no Springboard applicant with this UCAS Personal ID.
        </Segment>
      )}
      {existingApplicant && (
        <Segment basic vertical>
          There is a Springboard applicant with this UCAS Personal ID:
        </Segment>
      )}
      {existingApplicant && <ApplicantDetailsView details={existingApplicant.details} />}
      {existingApplicant && <QualificationsDTOView qualifications={existingApplicant.qualifications} />}
      {selectorRows.map((row, i) => (
        <React.Fragment key={row.selector.selectorListKey + ":" + i}>
          <Segment basic vertical padded>
            <Header as="h3">
              Application: {ApplicationChoiceEnum.labelOf(row.selector.choice)}
              {", "}
              {row.selector.programmeCode}
            </Header>
          </Segment>
          <SelectorListDataRowView row={row.selector} applicant={row.existingApplicant} application={row.linkedApplication} showDiffs={true} />
          {row.actions.map((action, j) => (
            <SelectorListAction
              key={j}
              data={data}
              row={row}
              action={action}
              applyAction={applyAction}
              ignoreAction={ignoreAction}
              unignoreAction={unignoreAction}
            />
          ))}
        </React.Fragment>
      ))}
    </Segment>
  );
}
