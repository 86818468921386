import { OfferStatusEnum } from "@qmspringboard/shared/dist/model/enums";
import { Bounds, Methods, OfferStatusCounts, PredictionDTORow, Ucas } from "../../model/types";
import { PredictionFees } from "../../reducers/predictions/state";
import { Row } from "./type";

export function formatPercentage(num: number): string {
  const fract = num / 100;
  const round = Math.round(fract);
  return Math.abs(fract - round) < 0.05 ? round.toFixed(0) : fract.toFixed(1);
}

function addOfferStatuses(a: OfferStatusCounts, b: OfferStatusCounts): OfferStatusCounts {
  const combined = Object.assign(
    {},
    ...OfferStatusEnum.entries.map(status => {
      const key = status.value;
      const value = (a[key] || 0) + (b[key] || 0);
      return { [key]: value };
    }),
  );

  return combined;
}

function addBounds(a: Bounds, b: Bounds): Bounds {
  return {
    lower: a.lower + b.lower,
    upper: a.upper + b.upper,
    predicted: a.predicted + b.predicted,
    pending: a.pending + b.pending,
    unknown: a.unknown + b.unknown,
    offerStatus: addOfferStatuses(a.offerStatus, b.offerStatus),
  };
}

function addUcas(a: Ucas, b: Ucas): Ucas {
  return {
    bounds: addBounds(a.bounds, b.bounds),
    cf: a.cf + b.cf,
    ci: a.ci + b.ci,
    ciui: a.ciui + b.ciui,
    ui: a.ui + b.ui,
  };
}

function addMethods(a: Methods, b: Methods): Methods {
  return {
    overall: addBounds(a.overall, b.overall),
    ucas: addUcas(a.ucas, b.ucas),
    clearing: addBounds(a.clearing, b.clearing),
  };
}

export function tableRows(fees: PredictionFees, rows: PredictionDTORow[]): Row[] {
  switch (fees) {
    case "home":
      return rows.map(row => ({
        label: row.label,
        control: row.control ?? false,
        ufAimHome: row.ufAimHome,
        ufAimOverseas: row.ufAimOverseas,
        methods: row.data.home,
      }));
    case "overseas":
      return rows.map(row => ({
        label: row.label,
        control: row.control ?? false,
        ufAimHome: row.ufAimHome,
        ufAimOverseas: row.ufAimOverseas,
        methods: row.data.overseas,
      }));
    case "overall":
    default:
      return rows.map(row => ({
        label: row.label,
        control: row.control ?? false,
        ufAimHome: row.ufAimHome,
        ufAimOverseas: row.ufAimOverseas,
        methods: addMethods(row.data.home, row.data.overseas),
      }));
  }
}
