import classNames from "classnames";
import React, { ReactElement, ReactNode } from "react";
import { Container } from "semantic-ui-react";
import Opt from "@qmspringboard/shared/dist/utils/opt";
import styles from "./Predictions.module.css";
import { formatPercentage } from "./row";

interface PredictionBarChartProps {
  ufAimHome: Opt<number>;
  ufAimOverseas: Opt<number>;
  lower: number;
  predicted: number;
  upper: number;
}

interface BarProps {
  color: string;
  label?: number;
  value: number;
  max: number;
}

function Group({ children }: { children: ReactNode }): ReactElement {
  return <div className={styles.group}>{children}</div>;
}

function Bar(props: BarProps): ReactElement {
  const { color, label, value, max } = props;

  const width = max === 0 ? 0 : (value / max) * 100;

  return (
    <div
      className={classNames(styles.bar, styles.prediction, color)}
      style={{
        width: `${width.toFixed(2)}%`,
      }}
    >
      {width > 20 ? formatPercentage(label ?? value) : null}
    </div>
  );
}

export default function PredictionBarChart(props: PredictionBarChartProps) {
  const { ufAimHome, ufAimOverseas, lower, predicted, upper } = props;

  const max = Math.max((ufAimHome ?? 0) + (ufAimOverseas ?? 0), upper);

  return (
    <Container fluid>
      {(ufAimHome != null || ufAimOverseas != null) && (
        <Group>
          {ufAimOverseas != null && <Bar color={styles.ufAimOverseas} label={ufAimOverseas} value={(ufAimHome ?? 0) + ufAimOverseas} max={max} />}
          {ufAimHome != null && <Bar color={styles.ufAimHome} value={ufAimHome} max={max} />}
        </Group>
      )}
      <Group>
        <Bar color={styles.upper} value={upper} max={max} />
        <Bar color={styles.predicted} value={predicted} max={max} />
        <Bar color={styles.lower} value={lower} max={max} />
      </Group>
    </Container>
  );
}
