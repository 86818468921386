import React, { PureComponent } from "react";
import { Container, Header, Segment } from "semantic-ui-react";
import shortid from "shortid";
import lodash from "lodash";
import { Permissions } from "../model/permission";
import { schoolToTeamCode, TeamCode } from "../model/team";
import { Note } from "../model/types";
import { Opt } from "../utils";
import ApplicantNote from "./ApplicantNote";
import SidebarIconButton from "./SidebarIconButton";

interface Props {
  notes: Note[];
  onAddNote: () => void;
  onSaveNote?: (oldNote: Note, newNote: Note) => void;
  onUpdateNote: (oldNote: Note, newNote: Note) => void;
  onDeleteNote: (note: Note) => void;
  permissions: Permissions;
  authorUserId: Opt<number>;
  teamCode: TeamCode;
}

export default class ApplicantNotes extends PureComponent<Props> {
  canAddNotes = (teamCode: TeamCode): boolean => this.props.permissions.canAddNotes(teamCode);

  canUpdateNotes = (teamCode: TeamCode): boolean => this.props.permissions.canUpdateNotes(teamCode);

  // HACK
  assignLocalNoteIds = (notes: Note[]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notes.forEach((note: any) => {
      note.localId = note.localId || shortid();
    });
  };

  render() {
    const { notes, onAddNote, onSaveNote, onUpdateNote, onDeleteNote, authorUserId, teamCode } = this.props;

    const orderedNotes = lodash.orderBy(notes, ["timestamp"], ["desc"]);

    const canAdd = this.canAddNotes(teamCode);

    // HACK
    this.assignLocalNoteIds(notes);

    return (
      <Container>
        {canAdd && <SidebarIconButton onClick={onAddNote} icon="add" title="Add note" />}

        <Header as="h3" dividing>
          Notes
        </Header>

        {notes.length === 0 && (
          <Segment basic textAlign="center">
            {canAdd ? 'Click "+" above to add a note.' : "This applicant has no notes."}
          </Segment>
        )}

        {orderedNotes.map(note => {
          return (
            <ApplicantNote
              note={note}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              key={(note as any).localId /* HACK */}
              readOnly={authorUserId == null || (!this.canUpdateNotes(schoolToTeamCode(note.schoolCode)) && note.authorUserId !== authorUserId)}
              onSave={onSaveNote}
              onUpdate={onUpdateNote}
              onDelete={onDeleteNote}
            />
          );
        })}
      </Container>
    );
  }
}
