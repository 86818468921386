import { A2Grade, IBGrade, isA2Grade, isBtecGrade, isIBGrade } from "@qmspringboard/shared/dist/model/qualifications";
import {
  Condition,
  OverallRequirement,
  RequiredSubject,
  RequiredSubjectAtGrade,
  RequiredSubjectNoGrade,
  Requirements,
  RequirementsAlternative,
} from "@qmspringboard/shared/dist/model/requirements";
import { isArrayOf } from "../utils/guard";
import * as rule from "./rules";

const a2GradeRule: rule.Rule<A2Grade> = rule.test(isA2Grade, rule.error("Must be a valid A2 grade."));

const ibGradeRule: rule.Rule<IBGrade> = rule.test(isIBGrade, rule.error("Must be a valid IB grade."));

const a2GradesRule: rule.Rule<A2Grade[]> = rule.test(isArrayOf(isA2Grade), rule.error("Must be a set of A2 grades."));

const ibGradesRule: rule.Rule<IBGrade[]> = rule.test(isArrayOf(isIBGrade), rule.error("Must be a set of IB grades."));

const btecGradesRule: rule.Rule<IBGrade[]> = rule.test(isArrayOf(isBtecGrade), rule.error("Must be a set of BTEC grades."));

const subjectRule = rule.nonEmpty(rule.error("You must enter a subject"));

const requiredSubjectNoGradeRule: rule.Rule<RequiredSubjectNoGrade> = rule.create().field("subject", subjectRule).finish();

const requiredSubjectAtGradeRule: rule.Rule<RequiredSubjectAtGrade> = rule
  .create()
  .field("subject", subjectRule)
  .field("a2Grade", a2GradeRule)
  .field("ibGrade", ibGradeRule)
  .finish();

const requiredSubjectRule: rule.Rule<RequiredSubject> = rule
  .create<RequiredSubject>()
  .union(r => r.type === "RequiredSubjectNoGrade", requiredSubjectNoGradeRule)
  .union(r => r.type === "RequiredSubjectAtGrade", requiredSubjectAtGradeRule)
  .finish();

const conditionRule: rule.Rule<Condition> = rule.create().field("subjects", rule.array(requiredSubjectRule)).finish();

const overallRequirementRule: rule.Rule<OverallRequirement> = rule
  .create()
  .field("a2Grades", a2GradesRule)
  .field("ibGrades", ibGradesRule)
  .field("btecGrades", rule.optional(btecGradesRule))
  .finish();

const requirementsCaseRule: rule.Rule<RequirementsAlternative> = rule
  .create<RequirementsAlternative>()
  .field("overall", overallRequirementRule)
  .field("conditions", rule.array(conditionRule))
  .finish();

export const requirementsRule: rule.Rule<Requirements> = rule.create<Requirements>().field("alternatives", rule.array(requirementsCaseRule)).finish();
