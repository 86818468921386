import Opt from "@qmspringboard/shared/dist/utils/opt";
import { ReactNode } from "react";
import { admissionsTeamCode } from "../../model/team";
import { Methods, PredictionOrganisation, unsafeTag, TeamCode } from "../../model/types";
import { PredictionParams, PredictionViews } from "../../reducers/predictions/state";

export type QueryStringState = PredictionParams & PredictionViews;

interface BaseColumn<A> {
  title: string;
  tooltip?: string;
  titleStyle?: (qss: QueryStringState) => Opt<string>;
  show?: (qss: QueryStringState) => boolean;
  body: (row: Row, qss: QueryStringState) => A;
  minWidth?: string;
  align?: "center" | "left" | "right";
}

export interface NumericColumn extends BaseColumn<number> {
  type: "NumericColumn";
  percentage: boolean; // does the server send 100x the reportable value?
}

export interface CustomColumn extends BaseColumn<ReactNode> {
  type: "CustomColumn";
}

export type Column = NumericColumn | CustomColumn;

export interface ColumnGroup {
  title: Opt<string>;
  tooltip?: string;
  show?: (qss: QueryStringState) => boolean;
  columns: Column[];
}

export interface Row {
  label: string;
  control: boolean;
  ufAimHome: Opt<number>;
  ufAimOverseas: Opt<number>;
  methods: Methods;
}

export const allOrg: PredictionOrganisation = unsafeTag<"PredictionOrganisation">("all");

export const admissionsOrg: PredictionOrganisation = unsafeTag<"PredictionOrganisation">(admissionsTeamCode);

export function unsafeSchoolOrg(str: string): PredictionOrganisation {
  return unsafeTag<"PredictionOrganisation">(str);
}

export function teamOrg(teamCode: TeamCode): PredictionOrganisation {
  return unsafeTag<"PredictionOrganisation">(teamCode);
}
