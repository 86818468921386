import React, { MouseEvent } from "react";
import { Container, Divider, Header, Icon } from "semantic-ui-react";
import { Qualifications } from "../model/applicant";
import { Messages, subErrors } from "../model/errors";
import { Permissions } from "../model/permission";
import { Application, Programme, ProgrammeCode } from "../model/types";
import { Opt } from "../utils";
import ErrorDisplay from "./ErrorDisplay";
import ProgrammeSelectionEditor from "./ProgrammeSelectionEditor";
import UCASStatusEditor, { ShowFields } from "./UcasStatusEditor";
import EntryPointEditor from "@qmspringboard/app/src/components/EntryPointEditor";
import { FeeCategory } from "../model/enums";

export interface ApplicationEditorProps {
  editorTitle: string;
  allProgrammes: Programme[];
  permissions: Permissions;
  applicantSaved: boolean;
  qualifications: Qualifications;
  guessedFeeCategory: FeeCategory | null;
  onChange: (appn: Application) => void;
  readOnly: boolean;
  value: Application;
  initialValue: Opt<Application>;
  canRemove: boolean;
  handleRemove: (appn: Application) => (evt: MouseEvent) => void;
  messages: Messages;
  emailMessages: Messages; // messages pathed as _email_ in the main DTO editor (update page only)
  startProgrammeChange?: (code: ProgrammeCode, interSchool: boolean) => void;
  cancelProgrammeChange?: () => void;
  completeProgrammeChange?: () => void;
  school: string;
}

export default function UcasApplicationEditor(props: ApplicationEditorProps) {
  const {
    editorTitle,
    allProgrammes,
    readOnly,
    qualifications,
    guessedFeeCategory,
    value,
    initialValue,
    onChange,
    canRemove,
    handleRemove,
    messages,
    emailMessages,
    startProgrammeChange,
    cancelProgrammeChange,
    completeProgrammeChange,
    permissions,
    school,
  } = props;

  const applicationSaved = value.id > 0; // use some other indicator here maybe?

  const splitErrors = subErrors(messages, { programmeCode: true });

  const statusFieldsToShow: ShowFields[] = applicationSaved ? ["INITIAL", "CONFIRMATION", "SELECTOR_LIST_KEY"] : ["INITIAL", "CONFIRMATION"];

  return (
    <div className="ui form">
      {canRemove && (
        <Container textAlign="right">
          <Icon link size="large" name="remove" onClick={handleRemove(value)} />
        </Container>
      )}
      <Header>{editorTitle}</Header>
      <Divider hidden />
      <ErrorDisplay messages={splitErrors._rest_} />
      <ErrorDisplay messages={emailMessages} />
      <ProgrammeSelectionEditor
        readOnly={readOnly}
        value={value}
        initialValue={initialValue}
        onChange={onChange}
        allProgrammes={allProgrammes}
        qualifications={qualifications}
        guessedFeeCategory={guessedFeeCategory}
        showApplicationChoice
        messages={splitErrors.programmeCode}
        startProgrammeChange={startProgrammeChange}
        cancelProgrammeChange={cancelProgrammeChange}
        completeProgrammeChange={completeProgrammeChange}
      />
      <EntryPointEditor value={value} readOnly={readOnly} onChange={onChange} />
      {statusFieldsToShow.length > 0 && (
        <UCASStatusEditor
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          showFields={statusFieldsToShow}
          messages={splitErrors._rest_}
          permissions={permissions}
          school={school}
        />
      )}
    </div>
  );
}
