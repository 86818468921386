import React from "react";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { push } from "connected-react-router";
import { schoolToTeamCode } from "../model/team";
import { Button, Container, Dimmer, Divider, Loader, Segment } from "semantic-ui-react";
import { Message, Programme, ProgrammeEditorDTO } from "../model/types";
import * as programmeUpdate from "../reducers/programmeUpdate";
import Title from "../components/Title";
import ProgrammeEditor from "../components/ProgrammeEditor";
import SubmitButton from "../components/SubmitButton";
import { withPermissions } from "../reducers/auth";
import { Permissions } from "../model/permission";
import { unsafeStringToProgrammeCode } from "../model/programme";
import { Opt } from "../utils";
import { ProgrammeAuditModal } from "@qmspringboard/app/src/components/ProgrammeAuditModal";
import { show as showModal } from "redux-modal/lib/actions";

interface Params {
  code: string;
}

interface Props {
  match: Match<Params>;
  push: Function;
  fetching: boolean;
  fetched: boolean;
  creating: boolean;
  programmeDTO: Opt<ProgrammeEditorDTO>;
  messages: Message[];
  permissions: Permissions;
  onInit: () => void;
  onLoad: (code: string) => void;
  onUpdate: (prog: Programme) => void;
  onSave: (prog: ProgrammeEditorDTO) => void;
  onDelete: (code: string) => void;
  showModal: Function;
}

type State = {
  readOnly: boolean;
};

class ProgrammeEditorPage extends React.PureComponent<Props, State> {
  state = { readOnly: false };

  componentDidMount() {
    const {
      match: {
        params: { code: unparsedCode },
      },
      onInit,
      onLoad,
    } = this.props;

    const code = unsafeStringToProgrammeCode(unparsedCode);

    if (code) {
      onLoad(code);
    } else {
      onInit();
    }

    const readOnly =
      this.props.programmeDTO == null || !this.props.permissions.canUpdateProgrammes(schoolToTeamCode(this.props.programmeDTO.programme.schoolCode));
    this.setState({ readOnly });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      match: {
        params: { code: unparsedOldCode },
      },
    } = this.props;

    const {
      match: {
        params: { code: unparsedNewCode },
      },
      onInit,
      onLoad,
    } = nextProps;

    const oldCode = unsafeStringToProgrammeCode(unparsedOldCode);
    const newCode = unsafeStringToProgrammeCode(unparsedNewCode);

    if (oldCode !== newCode) {
      if (newCode) {
        onLoad(newCode);
      } else {
        onInit();
      }
    }

    if (nextProps.programmeDTO !== this.props.programmeDTO || nextProps.permissions !== this.props.permissions) {
      const readOnly =
        nextProps.programmeDTO == null || !nextProps.permissions.canUpdateProgrammes(schoolToTeamCode(nextProps.programmeDTO.programme.schoolCode));
      this.setState({ readOnly });
    }
  }

  handleDelete = () => {
    const { programmeDTO, onDelete } = this.props;

    this.props.showModal("confirm", {
      title: "Delete programme",
      content: "Are you sure?",
      onPrimaryClick: () => programmeDTO && onDelete(programmeDTO.programme.code),
    });
  };

  handleSave = () => {
    const { programmeDTO, onSave } = this.props;
    programmeDTO && onSave(programmeDTO);
  };

  render() {
    const { creating, fetching, programmeDTO, onUpdate, messages } = this.props;
    const { readOnly } = this.state;
    const hasApplications: boolean = programmeDTO?.hasApplications || false;

    return (
      <Title title={programmeDTO == null || programmeDTO.programme.code === "" ? "New Programme" : programmeDTO.programme.code}>
        <Container>
          <Dimmer.Dimmable as={Container}>
            <Dimmer active={fetching} inverted>
              <Loader>Loading...</Loader>
            </Dimmer>

            {programmeDTO && <ProgrammeEditor programme={programmeDTO.programme} messages={messages} onChange={onUpdate} readOnly={readOnly} />}

            <Divider hidden />
            <Segment basic vertical textAlign="right">
              {programmeDTO && !readOnly && !creating && (
                <>
                  {programmeDTO && programmeDTO.programme.code && <ProgrammeAuditModal programmeCode={programmeDTO.programme.code} />}
                  {hasApplications && "This programme cannot be deleted as it has applications "}
                  <Button color={"red"} onClick={this.handleDelete} messages={[]} disabled={hasApplications}>
                    Delete
                  </Button>
                </>
              )}

              {programmeDTO && !readOnly && (
                <SubmitButton onClick={this.handleSave} messages={messages}>
                  Save
                </SubmitButton>
              )}
            </Segment>
          </Dimmer.Dimmable>
        </Container>
      </Title>
    );
  }
}

export default withPermissions(
  connect(
    state => ({
      programmeDTO: programmeUpdate.programme(state),
      fetching: programmeUpdate.fetching(state),
      fetched: programmeUpdate.fetched(state),
      creating: programmeUpdate.isNew(state),
      messages: programmeUpdate.messages(state),
    }),
    {
      push,
      onInit: programmeUpdate.initialise,
      onLoad: programmeUpdate.load,
      onUpdate: programmeUpdate.update,
      onDelete: programmeUpdate.del,
      onSave: programmeUpdate.save,
      showModal,
    },
  )(ProgrammeEditorPage),
);
